import React, { useCallback, useState, useEffect } from "react";
import logo from "../component/img/logo-xl.png";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { baseLink, apiLink, apiLink_ecom } from "../constants/DataPublic";
import { useGlobalState } from "../global";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RESET_PASSWORD_AGENT,
  FIND_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD_STAFF,
} from "../services/BASE_API";
import { ReactComponent as Close } from "../component/img/close.svg";
import PicPasscode from "../component/img/Passcodechanged.png";
import Backdrop from "@mui/material/Backdrop";

export const SendEmailResetPassword = (props) => {
  const { language, backPath } = props;
  const { state } = useLocation();
  const [email, setEmail] = useState(
    state != null ? (state.email != null ? state.email : "") : ""
  );
  const [error, setError] = useState("");
  const [routePath, setRoutePath] = useGlobalState("route");
  const [activeConfirm, setActiveConfirm] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // ภาษา
  const { t, i18n } = useTranslation();
  console.log(baseLink + routePath + backPath);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const routePathAgent = "/agent";
  const routePathStaff = "/staff";

  const handleChange = (event) => {
    console.log(event.target.value);
    setError("");
    setEmail(event.target.value);
  };
  /** Send Email Forget **/
  const [showPopupChangePass, setShowPopupChangePass] = useState(false);
  const CloseShowPopupChangePass = () => {
    setShowPopupChangePass(false);
    navigate(backPath);
  };

  useEffect(() => {
    if (email !== "") {
      setActiveConfirm(true);
    } else {
      setActiveConfirm(false);
    }
  }, [email]);

  const onConfirm = () => {
    if (email !== "") {
      if (!isValidEmail(email)) {
        setError(t("invalid_email"));
      } else {
        setError("");
        if (backPath === routePathAgent) {
          resetPasswordAget();
        } else {
          resetPasscodeStaff();
          //findUser();
        }
      }
    } else {
      setError(t("please_enter_your_email"));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onConfirm();
    }
  };
  async function resetPasswordAget() {
    setLoading(true);
    const parameter = "?email=" + email;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept-language": language,
      },
      body: JSON.stringify({
        Email: email,
      }),
    };
    console.log(requestOptions);
    await fetch(apiLink + RESET_PASSWORD_AGENT, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        setLoading(false);
        if (items["Success"] === true) {
          console.log("Change Success = true");
          setShowPopupChangePass(true);
        } else {
          setError(t("email_not_found"));
          console.log("Change Success = false");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setLoading(false);
        // alert('Error: API /afcl/Account/changepasscode/save ', error)
      });
  }

  async function findUser() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept-language": language,
      },
      body: JSON.stringify({
        Email: email,
      }),
    };
    console.log(requestOptions);
    await fetch(apiLink + FIND_USER, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        setLoading(false);
        if (items["Success"] === true) {
          console.log("FIND_USER Success = true");
          resetPasscodeStaff(data["Result"].UserId);
        } else {
          setError(t("email_not_found"));
          console.log("FIND_USER Success = false");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setLoading(false);
        // alert('Error: API /afcl/Account/changepasscode/save ', error)
      });
  }

  async function resetPasscodeStaff() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept-language": i18n.language,
      },
      body: JSON.stringify({
        //UserId: userId,
        Email: email,
      }),
    };
    console.log(requestOptions.headers);
    await fetch(apiLink + RESET_PASSWORD_STAFF, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        setLoading(false);
        if (items["Success"] === true) {
          console.log("Change Success = true");
          setShowPopupChangePass(true);
        } else {
          setError(t("email_not_found"));
          console.log("Change Success = false");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setLoading(false);
        // alert('Error: API /afcl/Account/changepasscode/save ', error)
      });
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "accept-language": language,
    //   },
    //   body: JSON.stringify({
    //     UserId: 6105,
    //     Email: email,
    //   }),
    // };
    // // console.log(requestOptions.body);
    // await fetch(apiLink + FORGOT_PASSWORD, requestOptions)
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (data) {
    //     const items = data;
    //     console.log(items);
    //     if (items["Success"] === true) {
    //       console.log("FORGOT_PASSWORD Success = true");
    //       setShowPopupChangePass(true);
    //     } else {
    //       console.log("FORGOT_PASSWORD Success = false");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("Error: ", error);
    //     // alert('Error: API /afcl/Account/changepasscode/save ', error)
    //   });
  }
  return (
    <div>
      <div className="page page-steps">
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="logosignin">
                <img src={logo} alt="" id="logosignin" />
              </div>
              <div
                className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius"
                style={{ margin: "0 auto" }}
              >
                <from>
                  <div className="mb-3">
                    <h2 className="main-title mb-3">{t("reset_password")}</h2>
                    {/* <p>{t("passcode_number")}</p> */}

                    <div className="py-2">
                      <div className="mb-3">
                        <label className="form-label text-color">
                          {t("email")}*{" "}
                        </label>
                        <div className="input-group boxInputPass mb-3">
                          <input
                            type="email"
                            onChange={(e) => handleChange(e)}
                            value={email}
                            className={
                              error == ""
                                ? "form-control boxborderPass"
                                : "form-control border danger"
                            }
                            placeholder="name@example.com"
                            onKeyDown={handleKeyDown}
                            required
                          />
                        </div>
                        <span className="text-danger">{error}</span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Button
                      type="button"
                      className="btn btn-link btn-db text-capitalize"
                      onClick={() => onConfirm()}
                      disabled={!activeConfirm}
                    >
                      {t("confirm")}
                    </Button>
                    <Modal
                      show={showPopupChangePass}
                      className="col-12 popup-changepw"
                    >
                      <a
                        href={baseLink + routePath + backPath}
                        className="close"
                      >
                        <Close
                          className="popup-close"
                          onClick={CloseShowPopupChangePass}
                        />
                      </a>
                      <div className="logosignin">
                        {" "}
                        <img src={logo} className="logo-popup" />
                      </div>
                      <div className="text-center mb-3">
                        <img src={PicPasscode} className="img-forgetpopup" />
                      </div>
                      <p className="text-center text-popup-title">
                        {t("send_email_complete")}
                      </p>
                      <div className="text-center">
                        <a
                          href={baseLink + routePath + backPath}
                          className="link-lb"
                        >
                          {t("back_to_signin")}
                        </a>
                      </div>
                    </Modal>
                  </div>
                </from>
                <div className="text-center weight-500">
                  <a href={baseLink + routePath + backPath} className="link-lb">
                    {t("back")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
    </div>
  );
};
