import React from "react";
import { Modal, Button } from "react-bootstrap";

import imgSuccess from "../img/popup-success.png";

const ModalViewPicture = (props) => {
  const { open, image, setClose } = props;
  return (
    <Modal
      show={open}
      onHide={setClose}
      backdrop="static"
      keyboard={false}
      className="success-dialog"
    >
      <Modal.Header closeButton>
        {/* <button className="btn-modal-close" onClick={setClose}>
          <i class="fa fa-times"></i>
        </button> */}
      </Modal.Header>
      <Modal.Body className="success-dialog-body">
      <img src={image} alt="picture" width={"100%"} height={"100%"} style={{maxWidth:"800px", maxHeight:"500px"}}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={setClose}>Back</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalViewPicture;
