import React from "react";
import { Modal, Button } from "react-bootstrap";

import imgFailed from "../img/paymentfail.png";
import { useTranslation } from "react-i18next";

const ModalFailed = (props) => {
  const { open, body, setClose, back } = props;
  const {t} = useTranslation();

  return (
    <Modal
      show={open}
      onHide={setClose}
      backdrop="static"
      keyboard={false}
      className="success-dialog"
    >
      <Modal.Header closeButton>
        {/* <button className="btn-modal-close" onClick={setClose}>
          <i class="fa fa-times"></i>
        </button> */}
      </Modal.Header>
      <Modal.Body className="success-dialog-body">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md">
                <img src={imgFailed}></img>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <span>{body}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={back}>{t("back")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalFailed;
