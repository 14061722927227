import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import noImg from "../../component/img/no_image.png";
import SS1 from "../../component/img/ss-1.jpg";
import { baseLink } from "../../constants/DataPublic";
import { ImageLoader } from "react-image-and-background-image-fade";
import { useGlobalState } from "../../global";

//Image//
export const PackageCard = (props) => {
  const { packageCard, pictureSize, index, PackageItemlength, packageList } =
    props;

  const [routePath, setRoutePath] = useGlobalState("route");
  const [picturePath, setPicturePath] = useState();
  const [netPrice, setNetPrice] = useState(0);
  const [numGuest, setNumGuest] = useState(0);
  const [saveWord, setSaveword] = useState("");
  const [ItemlistSort, setItemlistSort] = useState([]);

  useEffect(() => {
    if (packageCard.NoOfPax == 2) {
      setSaveword("duos");
    } else if (packageCard.NoOfPax > 2) {
      setSaveword("groups");
    } else {
      setSaveword("one");
    }

    const sort = packageCard.PackageItemLists.sort((a, b) =>
      a.ItemType > b.ItemType ? -1 : 1
    );
    setItemlistSort(sort);
    // console.log("sort lang", sort)

    if (packageCard.PackagePictureLists.length > 0) {
      let index = packageCard.PackagePictureLists.findIndex(
        (x) => x.PictureSize == pictureSize
      );
      if (index > -1) {
        setPicturePath(packageCard.PackagePictureLists[index].PictureUrl);
      } else {
        setPicturePath(noImg);
      }
    } else {
      setPicturePath(noImg);
    }

    if (packageCard.IsDiscount) {
      let netP = packageCard.PackagePrice - packageCard.DiscountPrice;
      setNetPrice(netP);
    } else setNetPrice(packageCard.PackagePrice);

    if (packageCard.PackageGuestLists.length > 0) {
      setNumGuest(packageCard.PackageGuestLists.length);
    }

    // console.log('index', index)
    // console.log('index packageCard', packageCard)
    // console.log('PackageItemlength', PackageItemlength)
  }, []);

  const addToCart = (item, isEdit) => {
    console.log("EditCart => ", isEdit, " item: ", item);
    props.onAddToCart(item, isEdit);
  };

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  // ภาษา
  const { t } = useTranslation();

  return (
    <>
      <div
        className={index < 2 ? "landingpage-small" : "landingpage"}
        style={{ width: index < 2 ? "50%" : "" }}
      >
        {/* <a href={baseLink+"/ticket/"+packageCard.PackageCode+"/"+packageCard.StepId}> */}
        <div
          className="col-lg-12 mb-4 d-flex align-items-stretch"
          style={{
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #e0e0e0",
            borderRadius: "5px",
            height: index < 2 ? "303px" : "",
          }}
        >
          <div
            className={
              index < 2
                ? "card card-hottest border-0"
                : "card card-hottest-large border-0"
            }
            style={{ display: "contents" }}
          >
            <div
              className="card-hottest-left"
              style={{ width: index < 2 ? "50%" : "70%" }}
            >
              <ImageLoader src={picturePath} transitionTime="0s">
                {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                  <>
                    {shouldShowLoader && !hasFailed && (
                      <div
                        className="card-img-top square"
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h2
                          style={{
                            color: "rgb(112 112 112)",
                            fontSize: "20px",
                          }}
                        >
                          {" "}
                          Loading ...{" "}
                        </h2>
                      </div>
                    )}
                    {hasLoaded && (
                      <>
                        {/* { !picLoad && <h2>Loading ...</h2> }  */}
                        <img
                          src={picturePath}
                          className="card-img-top square"
                          style={{ height: "100%" }}
                          alt="Card Image"
                        />
                      </>
                    )}
                  </>
                )}
              </ImageLoader>

              <div className="font-13 text-right weight-700">
                {numGuest > 0 && (
                  <div id="adults">
                    <span className="adults">
                      <img
                        src={packageCard.GuestInTicketIcon}
                        style={{ display: "inline", paddingRight: 5 }}
                        alt=""
                      />
                      {packageCard.NoOfPax} {t("pax")}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className="card-hottest-right"
              style={{ width: index < 2 ? "50%" : "30%", position: "relative" }}
            >
              <div
                className="card-body d-flex flex-column"
                style={{ padding: index < 2 ? "0px 10px 0px 10px" : "" }}
              >
                <h5
                  className="mb-1 lh-1 border-bottom-dot"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: index < 2 ? "20px" : "24px",
                    color: "#000000",
                    paddingBottom: index < 2 ? "0px" : "",
                    height: index < 2 ? "45px" : "60px",
                  }}
                >
                  {packageCard.PackageName}
                </h5>
              </div>

              <div className="card-footer text-muted">
                <div
                  style={{
                    height: index < 2 ? 40 : 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    margin: packageCard.IsDiscount ? "" : "10px",
                  }}
                >
                  <h1
                    className="weight-900 lh-1"
                    style={{
                      fontSize: "40px",
                      color: "#001D41",
                      marginRight: "10px",
                      fontWeight: "900",
                    }}
                  >
                    {(
                      packageCard.PackagePrice - packageCard.DiscountPrice
                    ).toLocaleString()}{" "}
                  </h1>
                  <h1 style={{ fontSize: "20px", color: "#001D41" }}>
                    {t("thb")}{" "}
                  </h1>
                </div>
                {packageCard.IsDiscount && (
                  <>
                    <div
                      style={{
                        height: index < 2 ? 20 : 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                      }}
                    >
                      <h1
                        className="weight-900 lh-1"
                        style={{
                          fontSize: index < 2 ? "20px" : "25px",
                          color: "#F87B17",
                          marginRight: "5px",
                          fontWeight: "900",
                          textDecorationLine: "line-through",
                        }}
                      >
                        {packageCard.PackagePrice.toLocaleString()} {t("thb")}
                      </h1>
                    </div>

                    <div
                      className="mb-1"
                      style={{
                        minHeight: 40,
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <div
                        className="save"
                        style={{
                          height: index < 2 ? 50 : "",
                          // fontSize: index < 2 ? 18 : 20,
                          textAlign: "center",
                          fontSize: 20,
                        }}
                      >
                        {t(saveWord) +
                          packageCard.DiscountPrice.toLocaleString() +
                          " " +
                          t("thb")}
                      </div>
                    </div>
                  </>
                )}
                {/* <div style={{marginTop: index < 2 ? '0px' : '10px',flexFlow: 'column wrap',height: index < 2 ? '6em' : '11em',display:'flex'}}></div> */}
                <div
                  className="area-detail"
                  style={{
                    height: index < 2 ? "" : "11em",
                    marginTop: index < 2 ? "0px" : "10px",
                  }}
                >
                  {packageList.map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="text-detail"
                        style={{
                          fontSize: index < 2 ? "13px" : "15px",
                          width:
                            index < 2
                              ? PackageItemlength > 4
                                ? "50%"
                                : "100%"
                              : PackageItemlength > 7
                              ? "50%"
                              : "100%",
                          paddingLeft:
                            index < 2
                              ? PackageItemlength > 4
                                ? 20
                                : 50
                              : PackageItemlength > 7
                              ? 50
                              : 60,
                        }}
                      >
                        {e.ItemType == "Ticket" ? "●" : "+"} {e.Qty}{" "}
                        {e.ItemName}
                      </div>
                    );
                  })}

                  <div
                    className="text-center"
                    style={{
                      position: "absolute",
                      bottom: "15px",
                      left: "20px",
                      width: "90%",
                    }}
                  >
                    <a
                      href={
                        user != null
                          ? baseLink +
                            routePath +
                            "/ticket/" +
                            packageCard.PackageCode +
                            "/" +
                            packageCard.StepId
                          : "/#"
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-main mt-auto mx-auto align-self-start "
                        onClick={() => {
                          if (!packageCard.IsSaleByItem) {
                            if (user != null) {
                              addToCart(
                                {
                                  PackageCode: packageCard.PackageCode,
                                  PackageName: packageCard.PackageName,
                                  ItemCode: "",
                                  ItemName: "",
                                  ItemType: packageCard.ItemType,
                                  Qty: 1,
                                  Tax: 0,
                                  ItemPrice: netPrice,
                                  ItemDiscount: packageCard.IsDiscount
                                    ? packageCard.DiscountPrice
                                    : 0,
                                  PriceName: packageCard.PriceName,
                                  PriceUnit: "",
                                  TotalPrice: netPrice,
                                  VisitDate: null,
                                  VisitTime: "",
                                  VisitLocation: "",
                                  ItemCodeCabana:
                                    packageCard.VisitingDateItemCode,
                                  RatePackage:
                                    packageCard.ContractVenueRatePackage,
                                  BasePrice: packageCard.PackagePrice,
                                  step: packageCard.StepId,
                                  status: "normal",
                                },
                                false
                              );
                            }
                          }
                        }}
                      >
                        {t("buy_now")}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
