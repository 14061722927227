import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseLink } from "../../constants/DataPublic";
import { ImageLoader } from "react-image-and-background-image-fade";
import { useGlobalState } from "../../global";
import ReactCardFlip from "react-card-flip";
import noImg from "../../component/img/no_image.png";
import { use } from "i18next";
import Backdrop from "@mui/material/Backdrop";
import ModalFailed from "../../component/Modal/ModalFailed";
import { Form } from "react-bootstrap";

import { apiLink_ecom } from "../../constants/DataPublic";
import { CHECK_ALLOTMENT, LOCATION_LOOKUP } from "../../services/BASE_API";
import { useNavigate } from "react-router-dom";

// Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { deDE } from "@mui/x-date-pickers/locales";
import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  TextField,
} from "@mui/material";
import "moment/locale/th";
import moment from "moment";
import { red } from "@mui/material/colors";
import { ConfirmChangeVisitDate } from "../Modal/ConfirmChangeVisitDate";

export const PackageCardBuyFlip = (props) => {
  const { packageCard, language } = props;
  const { packageList } = props;
  //   const { netPrice } = props;
  const { PackageItemlength } = props;
  const { index } = props;
  // const { routePath } = props;
  //   const { selectedI } = props;
  const { subTotalI } = props;
  const { editI } = props;
  //   const { itemQty } = props;
  //   const { selectedDate } = props;
  // const { selectedTime } = props;
  const { requiredDate, pictureSize, locationList, timeList, cabanaList } =
    props;
  const navigate = useNavigate();
  const [routePath, setRoutePath] = useGlobalState("route");

  const [isFlipped, setIsFlipped] = useState(false);
  const [netPrice, setNetPrice] = useState(0);
  const [picturePath, setPicturePath] = useState();
  const [numGuest, setNumGuest] = useState(0);

  const [itemQty, setItemQty] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [aleart, setAleart] = useState("");
  const [selectedI, setSelectedI] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [selectedTimeName, setSelectedTimeName] = useState(null);

  const [requiredVisitDated, setRequiredVisitDated] = useState(false);
  const [requiredLocationed, setRequiredLocationed] = useState(false);
  const [requiredTimed, setRequiredTimed] = useState(false);

  const [allotment, setAllotment] = useState(null);
  const [showErrorAllot, setShowErrorAllot] = useState(false);
  const [visitingDate, setVisitingDate] = useGlobalState("visitingDate");
  const [pkPrice, setPkPrice] = useState("");
  const [saveWord, setSaveword] = useState("");
  // const [visitingDate, setVisitingDate] = useGlobalState("visitingDate");
  const [openChangeVisitDate, setOpenChangeVisitDate] = useState(false); //Change VisitDate Modal

  //   const setItemQty = (item) => {
  //     props.setItemQty(item);
  //   };

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
  };

  const addToCart = (item, isEdit) => {
    props.onAddToCart(item, isEdit);
  };

  //   const setSelectedI = (item) => {
  //     props.setSelectedI(item);
  //   };

  //   const setSelectedDate = (item) => {
  //     props.setSelectedDate(item);
  //   };

  const setRequiredDate = (item) => {
    props.setRequiredDate(item);
  };

  const setRequiredTime = (item) => {
    props.setRequiredTime(item);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsFlipped(!isFlipped);
    setVisitingDate(null);
    setAllotment(null);
    setShowErrorAllot(false);
    setRequiredVisitDated(false);
    setRequiredLocationed(false);
    setRequiredTimed(false);
    setSelectedDate(null);
    setItemQty(1);
  };

  const changeVisidate = (date) => {
    setSelectedDate(date);
    if (packageCard.VisitingDateItemCode != null) {
      CallServicePostCheckAllotment(
        date,
        packageCard.VisitingDateItemCode,
        false
      );
    }
  };

  useEffect(() => {
    console.log(selectedDate);
    if (selectedDate != null) {
      //setVisitingDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (visitingDate != null) {
      setSelectedDate(visitingDate);
      sessionStorage.setItem("CabanaVisitingDate", visitingDate);
    }
  }, [visitingDate]);

  useEffect(() => {
    if (packageCard.NoOfPax == 2) {
      setSaveword("duos");
    } else if (packageCard.NoOfPax > 2) {
      setSaveword("groups");
    } else {
      setSaveword("one");
    }

    // const sort = packageCard.PackageItemLists.sort((a, b) =>
    //   a.ItemType > b.ItemType ? -1 : 1
    // );
    // setItemlistSort(sort);
    // console.log("sort lang", sort)

    if (visitingDate != null) {
      setSelectedDate(visitingDate);
    }

    if (packageCard.PackagePictureLists.length > 0) {
      let index = packageCard.PackagePictureLists.findIndex(
        (x) => x.PictureSize == pictureSize
      );
      if (index > -1) {
        setPicturePath(packageCard.PackagePictureLists[index].PictureUrl);
      } else {
        setPicturePath(noImg);
      }
    } else {
      setPicturePath(noImg);
    }

    if (packageCard.IsDiscount) {
      let netP = packageCard.PackagePrice - packageCard.DiscountPrice;
      setNetPrice(netP);
    } else setNetPrice(packageCard.PackagePrice);

    if (packageCard.PackageGuestLists.length > 0) {
      setNumGuest(packageCard.PackageGuestLists.length);
    }

    // console.log('index', index)
    // console.log('index packageCard', packageCard)
    // console.log('PackageItemlength', PackageItemlength)
  }, []);

  // useEffect(()=>{
  //   console.log(requiredVisitDated);
  // },[requiredVisitDated])

  useEffect(() => {
    moment.locale(i18n.language);
    console.log("Locate :", moment.locale());
  }, [language]);

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  // ภาษา
  const { t, i18n } = useTranslation();

  async function CallServicePostCheckAllotment(date, ItemCode, isAddCart) {
    setOpenFailedModal(false);
    setShowErrorAllot(false);
    setLoading(true);
    const allotmentList = [
      {
        ItemCode: ItemCode,
        Qty: itemQty,
      },
    ];
    let body = {
      ArrivalDate: date,
      AllotmentItems: allotmentList,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify(body),
    };
    await fetch(apiLink_ecom + CHECK_ALLOTMENT, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setAllotment(data["Result"]);
          const statusFail = item.filter((x) => x.Status == false);
          if (statusFail.length > 0) {
            // setOpenFailedModal(true);
            // setAleart(data["Result"][0]["Message"]);
            setShowErrorAllot(true);
            setRequiredVisitDated(true);
          } else {
          }
        } else {
          setOpenFailedModal(true);
          setAleart(data["Message"]);
          setRequiredVisitDated(true);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + CHECK_ALLOTMENT, error);
        setLoading(false);
        return false;
      });
  }

  const onAddCart = async (date) => {
    console.log("date", date);
    console.log("selectedDate", selectedDate);
    await CallServicePostCheckAllotment(
      selectedDate,
      packageCard.VisitingDateItemCode,
      true
    );
  };

  const addToMyCart = async () => {
    var visitingDate = sessionStorage.getItem("CabanaVisitingDate");
    console.log("selectedDate", selectedDate);
    if (packageCard.VisitingDateItemCode != null) {
      if (
        selectedDate != null &&
        selectedDate !== "" &&
        requiredVisitDated == false
      ) {
        console.log("CabanaVisitingDate", selectedDate);
        console.log("visitingDate === null", visitingDate == "null");
        console.log(
          "visitingDate",
          moment(selectedDate).format("YYYY/MM/DD") ===
            moment(visitingDate).format("YYYY/MM/DD")
        );
        if (
          visitingDate == "null" ||
          visitingDate == null ||
          moment(selectedDate).format("YYYY/MM/DD") ===
            moment(visitingDate).format("YYYY/MM/DD")
        ) {
          await addToCart(
            {
              PackageCode: packageCard.PackageCode,
              PackageName: packageCard.PackageName,
              ItemCode: "",
              ItemName: "",
              ItemType: packageCard.ItemType,
              Qty: itemQty,
              Tax: 0,
              ItemPrice: pkPrice,
              ItemDiscount: packageCard.IsDiscount
                ? packageCard.DiscountPrice
                : 0,
              PriceName: packageCard.PriceName,
              PriceUnit: "",
              TotalPrice: netPrice,
              VisitDate: selectedDate,
              VisitTime: selectedTime,
              VisitLocation: selectedLocation,
              VisitTimeName: selectedTimeName,
              VisitLocationName: selectedLocationName,
              ItemCodeCabana: packageCard.VisitingDateItemCode,
              RatePackage: packageCard.ContractVenueRatePackage,
              BasePrice: packageCard.PackagePrice,
              step: 1, //packageCard.StepId
              status: "normal",
            },
            false
          );
          navigate("/ticket/" + packageCard.PackageCode + "/" + 1, {
            state: { cabanaList: cabanaList },
          });
        } else {
          setOpenChangeVisitDate(true);
        }
        // navigate("/ticket/" + packageCard.PackageCode + "/" + 1, {
        //   state: { cabanaList: cabanaList },
        // });
      } else {
        setRequiredVisitDated(true);
      }
    } else if (packageCard.IsTransportationPackage == true) {
      if (
        selectedDate != null &&
        selectedDate !== "" &&
        selectedLocation != null &&
        selectedLocation !== "" &&
        selectedTime != null &&
        selectedTime !== ""
      ) {
        if (
          visitingDate == "null" ||
          visitingDate == null ||
          moment(selectedDate).format("YYYY/MM/DD") ===
            moment(visitingDate).format("YYYY/MM/DD")
        ) {
          await addToCart(
            {
              PackageCode: packageCard.PackageCode,
              PackageName: packageCard.PackageName,
              ItemCode: "",
              ItemName: "",
              ItemType: packageCard.ItemType,
              Qty: itemQty,
              Tax: 0,
              ItemPrice: netPrice,
              ItemDiscount: packageCard.IsDiscount
                ? packageCard.DiscountPrice
                : 0,
              PriceName: packageCard.PriceName,
              PriceUnit: "",
              TotalPrice: netPrice,
              VisitDate: selectedDate,
              VisitTime: selectedTime,
              VisitLocation: selectedLocation,
              VisitTimeName: selectedTimeName,
              VisitLocationName: selectedLocationName,
              ItemCodeCabana: packageCard.VisitingDateItemCode,
              RatePackage: packageCard.ContractVenueRatePackage,
              BasePrice: packageCard.PackagePrice,
              step: 1, //packageCard.StepId
              status: "normal",
            },
            false
          );
          navigate("/ticket/" + packageCard.PackageCode + "/" + 1);
        } else {
          setOpenChangeVisitDate(true);
        }
      } else {
        if (selectedDate == null || selectedDate === "") {
          setRequiredVisitDated(true);
        }
        if (selectedLocation == null || selectedLocation === "") {
          setRequiredLocationed(true);
        }
        if (selectedTime == null || selectedTime === "") {
          setRequiredTimed(true);
        }
      }
    }
  };

  const onSelectedLocation = (locationId) => {
    const listLocation = locationList.filter((x) => x.LocationId == locationId);
    if (listLocation.length > 0) {
      setSelectedLocationName(listLocation[0].LocationName);
    } else {
      setSelectedLocationName(null);
    }
    setSelectedLocation(locationId);
  };

  const onSelectedTime = (timeId) => {
    const listTime = timeList.filter((x) => x.TimeId == timeId);
    if (listTime.length > 0) {
      setSelectedTimeName(listTime[0].TimeName);
    } else {
      setSelectedTimeName(null);
    }
    setSelectedTime(timeId);
  };

  const onGetDateSelectVisitDate = () => {
    // let dateNow = new Date();
    // let dateSelect = new Date(dateNow);
    // dateSelect.setDate(dateNow.getDate());
    let dateSelect = moment();
    return dateSelect;
  };

  const onChangeQty = (qty) => {
    if (qty === "" || qty === "0") {
      setItemQty(1);
    } else {
      let resultQty = parseInt(qty);
      if (resultQty <= 0) {
        resultQty = 1;
      }
      setItemQty(resultQty);
    }
  };

  const onNumerPWKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
      console.log("event", event);
    }
  };

  useEffect(() => {
    setPkPrice(countRatePrice());
    if (allotment != null) {
      if (allotment[0].Available < itemQty) {
        setShowErrorAllot(true);
      } else {
        setShowErrorAllot(false);
      }
    } else {
      setShowErrorAllot(false);
    }
  }, [itemQty]);

  const countRatePrice = () => {
    console.log("countRatePrice :", itemQty);
    const rate = packageCard.ContractVenueRatePackage.find(
      (item) => itemQty >= item.QtyFrom && itemQty <= item.QtyTo //(item.QtyTo == 0 ? Infinity : item.QtyTo)
    );
    console.log(rate);
    return rate != null
      ? rate.Price
      : packageCard.PackagePrice - packageCard.DiscountPrice;
  };

  const setCloseChangeVisitDate = () => {
    setOpenChangeVisitDate(false);
  };

  const onComfirmChangeVisitDate = async () => {
    setOpenChangeVisitDate(false);
    //setVisitingDate(selectedDate);
    let cart = JSON.parse(sessionStorage.getItem("MyCart"));
    console.log("onComfirmChangeVisitDate", selectedDate);
    await cart.forEach((x, index) => {
      console.log("Round :", index, x);
      if (packageCard.PackageCode == x.PackageCode) {
        console.log("This Package", packageCard.PackageName);
        addToCart(
          {
            PackageCode: packageCard.PackageCode,
            PackageName: packageCard.PackageName,
            ItemCode: "",
            ItemName: "",
            ItemType: packageCard.ItemType,
            Qty: itemQty,
            Tax: 0,
            ItemPrice: netPrice,
            ItemDiscount: packageCard.IsDiscount
              ? packageCard.DiscountPrice
              : 0,
            PriceName: packageCard.PriceName,
            PriceUnit: "",
            TotalPrice: netPrice,
            VisitDate: selectedDate,
            VisitTime: selectedTime,
            VisitLocation: selectedLocation,
            VisitTimeName: selectedTimeName,
            VisitLocationName: selectedLocationName,
            ItemCodeCabana: packageCard.VisitingDateItemCode,
            RatePackage: packageCard.ContractVenueRatePackage,
            BasePrice: packageCard.PackagePrice,
            step: 1, //packageCard.StepId
            status: "normal",
          },
          true
        );
      } else {
        console.log("Other Package", x.PackageName, x);
        x.VisitDate = selectedDate;
        x.Qty = 0;
        addToCart(x, true);
      }
    });

    var findExist = await cart.find(
      (x) => x.PackageCode == packageCard.PackageCode
    );
    console.log("FindExist", findExist);
    if (findExist == undefined) {
      addToCart(
        {
          PackageCode: packageCard.PackageCode,
          PackageName: packageCard.PackageName,
          ItemCode: "",
          ItemName: "",
          ItemType: packageCard.ItemType,
          Qty: itemQty,
          Tax: 0,
          ItemPrice: netPrice,
          ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
          PriceName: packageCard.PriceName,
          PriceUnit: "",
          TotalPrice: netPrice,
          VisitDate: selectedDate,
          VisitTime: selectedTime,
          VisitLocation: selectedLocation,
          VisitTimeName: selectedTimeName,
          VisitLocationName: selectedLocationName,
          ItemCodeCabana: packageCard.VisitingDateItemCode,
          RatePackage: packageCard.ContractVenueRatePackage,
          BasePrice: packageCard.PackagePrice,
          step: 1, //packageCard.StepId
          status: "normal",
        },
        true
      );
    }
    // addToCart(
    //   {
    //     PackageCode: packageCard.PackageCode,
    //     PackageName: packageCard.PackageName,
    //     ItemCode: "",
    //     ItemName: "",
    //     ItemType: packageCard.ItemType,
    //     Qty: itemQty,
    //     Tax: 0,
    //     ItemPrice: netPrice,
    //     ItemDiscount: packageCard.IsDiscount
    //       ? packageCard.DiscountPrice
    //       : 0,
    //     PriceName: packageCard.PriceName,
    //     PriceUnit: "",
    //     TotalPrice: netPrice,
    //     VisitDate: visitingDate,
    //     VisitTime: selectedTime,
    //     VisitLocation: selectedLocation,
    //     VisitTimeName: selectedTimeName,
    //     VisitLocationName: selectedLocationName,
    //     ItemCodeCabana: packageCard.VisitingDateItemCode,
    //     RatePackage: packageCard.ContractVenueRatePackage,
    //     BasePrice: packageCard.PackagePrice,
    //     step: 1, //packageCard.StepId
    //     status: "normal",
    //   },
    //   false
    // );
    navigate("/ticket/" + packageCard.PackageCode + "/" + 1, {
      state: { cabanaList: cabanaList },
    });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0E315C",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.invalid .MuiOutlinedInput-notchedOutline": {
              borderColor: red[500],
            },
          },
        },
      },
    },
  });

  const today = moment();

  const FormatString = (amount) => {
    amount = amount.toString();
    const resultPrice = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  return (
    <>
      <div
        // className="card-hottest-right"
        // style={{ width: index < 2 ? "50%" : "", position: "relative" }}
        className={index < 2 ? "landingpage-small" : "landingpage"}
        style={{ width: index < 2 ? "50%" : "100%" }}
      >
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="horizontal"
          style={{ height: "100%" }}
        >
          {/* ด้านหน้า */}
          <div
            className={index < 2 ? "landingpage-small" : "landingpage"}
            style={{ width: index < 2 ? "100%" : "" }}
          >
            {/* <a href={baseLink+"/ticket/"+packageCard.PackageCode+"/"+packageCard.StepId}> */}
            <div
              className="col-lg-12 mb-4 d-flex align-items-stretch"
              style={{
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #e0e0e0",
                borderRadius: "5px",
                height: index < 2 ? "303px" : "",
              }}
            >
              <div
                className={
                  index < 2
                    ? "card card-hottest border-0"
                    : "card card-hottest-large border-0"
                }
                style={{ display: "contents" }}
              >
                <div
                  className="card-hottest-left"
                  style={{ width: index < 2 ? "50%" : "70%" }}
                >
                  <ImageLoader src={picturePath} transitionTime="0s">
                    {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                      <>
                        {shouldShowLoader && !hasFailed && (
                          <div
                            className="card-img-top square"
                            style={{
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h2
                              style={{
                                color: "rgb(112 112 112)",
                                fontSize: "20px",
                              }}
                            >
                              {" "}
                              Loading ...{" "}
                            </h2>
                          </div>
                        )}
                        {hasLoaded && (
                          <>
                            {/* { !picLoad && <h2>Loading ...</h2> }  */}
                            <img
                              src={picturePath}
                              className="card-img-top square"
                              style={{ height: "100%" }}
                              alt="Card Image"
                            />
                          </>
                        )}
                      </>
                    )}
                  </ImageLoader>

                  <div className="font-13 text-right weight-700">
                    {numGuest > 0 && (
                      <div id="adults">
                        <span className="adults">
                          <img
                            src={packageCard.GuestInTicketIcon}
                            style={{ display: "inline", paddingRight: 5 }}
                            alt=""
                          />
                          {packageCard.NoOfPax} {t("pax")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="card-hottest-right"
                  style={{
                    width: index < 2 ? "50%" : "30%",
                    position: "relative",
                  }}
                >
                  <div
                    className="card-body d-flex flex-column"
                    style={{ padding: index < 2 ? "0px 10px 0px 10px" : "" }}
                  >
                    <h5
                      className="mb-1 lh-1 border-bottom-dot"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: index < 2 ? "20px" : "24px",
                        color: "#000000",
                        paddingBottom: index < 2 ? "0px" : "",
                        height: index < 2 ? "45px" : "60px",
                      }}
                    >
                      {packageCard.PackageName}
                    </h5>
                  </div>

                  <div className="card-footer text-muted">
                    <div
                      style={{
                        height: index < 2 ? 40 : 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        margin: packageCard.IsDiscount ? "" : "10px",
                      }}
                    >
                      <h1
                        className="weight-900 lh-1"
                        style={{
                          fontSize: "40px",
                          color: "#001D41",
                          marginRight: "10px",
                          fontWeight: "900",
                        }}
                      >
                        {pkPrice.toLocaleString()}{" "}
                      </h1>
                      <h1 style={{ fontSize: "20px", color: "#001D41" }}>
                        {t("thb")}{" "}
                      </h1>
                    </div>
                    {packageCard.IsDiscount && (
                      <>
                        <div
                          style={{
                            height: index < 2 ? 20 : 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "baseline",
                          }}
                        >
                          <h1
                            className="weight-900 lh-1"
                            style={{
                              fontSize: index < 2 ? "20px" : "25px",
                              color: "#F87B17",
                              marginRight: "5px",
                              fontWeight: "900",
                              textDecorationLine: "line-through",
                            }}
                          >
                            {packageCard.PackagePrice.toLocaleString()}{" "}
                            {t("thb")}
                          </h1>
                        </div>

                        <div
                          className="mb-1"
                          style={{
                            minHeight: 40,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <div
                            className="save"
                            style={{
                              height: index < 2 ? 50 : "",
                              // fontSize: index < 2 ? 18 : 20,
                              fontSize: 20,
                              textAlign: "center",
                            }}
                          >
                            {t(saveWord) +
                              packageCard.DiscountPrice.toLocaleString() +
                              " " +
                              t("thb")}
                          </div>
                        </div>
                      </>
                    )}
                    {/* <div style={{marginTop: index < 2 ? '0px' : '10px',flexFlow: 'column wrap',height: index < 2 ? '6em' : '11em',display:'flex'}}></div> */}
                    <div
                      className="area-detail"
                      style={{
                        height: index < 2 ? "" : "11em",
                        marginTop: index < 2 ? "0px" : "10px",
                      }}
                    >
                      {packageList.map((e, i) => {
                        return (
                          <div
                            key={i}
                            className="text-detail"
                            style={{
                              fontSize: index < 2 ? "13px" : "15px",
                              width:
                                index < 2
                                  ? PackageItemlength > 4
                                    ? "50%"
                                    : "100%"
                                  : PackageItemlength > 7
                                  ? "50%"
                                  : "100%",
                              paddingLeft:
                                index < 2
                                  ? PackageItemlength > 4
                                    ? 20
                                    : 50
                                  : PackageItemlength > 7
                                  ? 50
                                  : 60,
                            }}
                          >
                            {e.ItemType == "Ticket" ? "●" : "+"} {e.Qty}{" "}
                            {e.ItemName}
                          </div>
                        );
                      })}

                      <div
                        className="text-center"
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          left: "20px",
                          width: "90%",
                        }}
                      >
                        <a
                          href={
                            user != null
                              ? baseLink +
                                routePath +
                                "/ticket/" +
                                packageCard.PackageCode +
                                "/" +
                                packageCard.StepId
                              : "/#"
                          }
                        >
                          <button
                            type="button"
                            className="btn btn-main mt-auto mx-auto align-self-start "
                            onClick={handleClick}
                          >
                            {t("buy_now")}
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ด้านหลัง */}
          <div
            style={{
              height:
                packageCard.IsTransportationPackage == true ? "380px" : "340px",
            }}
          >
            <div className="card-header d-flex">
              <a href="#" className="color-black lh-1 m-0">
                <span
                  className="material-icons flipButton font-14"
                  onClick={handleClick}
                >
                  arrow_back_ios
                </span>
              </a>
            </div>

            <div
              className="card-body d-flex flex-column"
              style={{ padding: "0.5em 1em", height: "75%" }}
            >
              {/* <h5 className="card-title mb-1 lh-1 p-2 text-center">{packageCard.PackageName}</h5> */}
              <h5
                className="card-title mb-1 lh-1 p-2 text-center"
                style={{ height: "38px" }}
              >
                {packageList.map((e, i) => {
                  if (e.IsVisitDate == true) {
                    return i == 0 ? "" + e.ItemName : "," + e.ItemName;
                  }
                })}
              </h5>
              {packageCard.IsDiscount && (
                <div className="pt-3 text-center border-top">
                  <p className="font-24 weight-900 color-orange mb-0 lh-1">
                    {pkPrice.toLocaleString()} {t("thb")}
                    {/* {"0".toLocaleString()} {t("thb")} */}
                    <span className="font-14 weight-600 color-black">
                      /{t("round")}
                    </span>
                  </p>
                  <p className="oldPrice weight-400">
                    {packageCard.PackagePrice.toLocaleString()} {t("thb")}
                  </p>
                </div>
              )}
              {!packageCard.IsDiscount && (
                <div className="mb-1">
                  {/* <p className="weight-400"> */}
                  <p className="font-24 weight-900 color-orange mb-0 lh-1 text-center">
                    {netPrice.toLocaleString()} {t("thb")}
                    {/* {"0".toLocaleString()} {t("thb")} */}
                  </p>
                </div>
              )}

              <div className="row mb-3 pb-3 border-bottom">
                <div className="col-4 m-auto">
                  <p className="color-black font-14 mb-0">{t("quantity")}</p>
                </div>

                <div className="col-8">
                  <div
                    className="input-group ms-auto"
                    style={{ width: "40%", justifyContent: "end" }}
                  >
                    <span className="input-group-btn">
                      <button
                        type="button"
                        name="quant[1]"
                        className="btn btn-number minus"
                        // disabled={selectedI}
                        data-type="minus"
                        // style={{color:"#fff", backgroundColor:"#000"}}
                        data-field="quant[1]"
                        onClick={(e) => {
                          if (allotment != null) {
                            if (allotment[0].Available < itemQty - 1) {
                              setShowErrorAllot(true);
                            } else {
                              setShowErrorAllot(false);
                            }
                          } else {
                            setShowErrorAllot(false);
                          }
                          if (itemQty > 1) setItemQty((itemQty) => itemQty - 1);
                          else setItemQty(1);
                        }}
                      >
                        <span className="material-icons">remove</span>
                      </button>
                    </span>

                    <input
                      type="text"
                      name="quant[1]"
                      // className="form-control input-number"
                      className="input-text-qty"
                      // style={{ marginLeft: "-3px" }}
                      value={itemQty && FormatString(itemQty)}
                      defaultValue={1}
                      autoComplete="off"
                      maxLength={4}
                      onKeyPress={onNumerPWKeyDown}
                      onChange={(e) => {
                        setItemQty(e.target.value);
                      }}
                      onBlur={(e) => {
                        let qty = parseInt(
                          e.target.value != undefined && e.target.value !== ""
                            ? e.target.value.replace(",", "")
                            : "1"
                        );
                        onChangeQty(qty);
                      }}
                      // disabled
                    />
                    <span className="input-group-btn">
                      <button
                        type="button"
                        className="btn btn-number"
                        data-type="plus"
                        style={{ color: "#fff", backgroundColor: "#000" }}
                        // disabled={selectedI}
                        data-field="quant[1]"
                        onClick={(e) => {
                          console.log("itemQty");
                          if (allotment != null) {
                            if (allotment[0].Available < itemQty + 1) {
                              setShowErrorAllot(true);
                            } else {
                              setShowErrorAllot(false);
                            }
                          } else {
                            setShowErrorAllot(false);
                          }

                          setItemQty((itemQty) => itemQty + 1);
                        }}
                      >
                        <span className="material-icons">add</span>
                      </button>
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`row mb-2 pb-3${
                  packageCard.IsTransportationPackage == true
                    ? ""
                    : " border-bottom"
                }`}
              >
                <div className="col-4 m-auto">
                  <p className="color-black font-14 mb-0">
                    {t("visitdate")}
                    {<span> *</span>}
                  </p>
                </div>

                <div className="col-8">
                  {/* <input
                    id={packageCard.PackageCode + index}
                    className={`form-control ${requiredDate}`}
                    type="date"
                    style={{
                      borderColor:
                        requiredVisitDated == true ? "red" : "#ced4da",
                    }}
                    min={onGetDateSelectVisitDate().toISOString().split("T")[0]} // new Date().toISOString().split("T")[0]
                    value={selectedDate}
                    // disabled={selectedI}
                    onChange={(e) => {
                      setRequiredVisitDated(false);
                      const pickDate = e.target.value;
                      if (pickDate < new Date().toISOString().split("T")[0]) {
                        let datePicker = document.getElementById(
                          packageCard.PackageCode + index
                        );
                        setSelectedDate("");
                        datePicker.focus();
                      } else {
                        changeVisidate(e.target.value);
                      }
                    }}
                  /> */}
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      dateFormats={"DD/MM/YYYY"}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        id={packageCard.PackageCode + index}
                        className={
                          requiredVisitDated
                            ? "form-control invalid"
                            : "form-control"
                        }
                        defaultValue={today}
                        type="date"
                        format={"DD/MM/YYYY"}
                        dayOfWeekFormatter={(day) => `${day}`}
                        style={{
                          borderColor:
                            requiredVisitDated == true ? "red" : "#ced4da",
                        }}
                        minDate={onGetDateSelectVisitDate()} // new Date().toISOString().split("T")[0]
                        value={
                          selectedDate == "" || selectedDate == null
                            ? null
                            : moment(selectedDate)
                        }
                        // disabled={selectedI}
                        onChange={(value) => {
                          setRequiredVisitDated(false);
                          const pickDate = value.get().toLocaleString();
                          if (
                            pickDate < new Date().toISOString().split("T")[0]
                          ) {
                            let datePicker = document.getElementById(
                              packageCard.PackageCode + index
                            );
                            setSelectedDate(null);
                            datePicker.focus();
                          } else {
                            changeVisidate(value.format("YYYY/MM/DD"));
                          }
                        }}
                        renderInput={(params) => (
                          <input
                            {...params.inputProps}
                            className={requiredVisitDated ? "invalid" : ""}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </div>
                {allotment != null && (
                  <>
                    <div className="col-4 m-auto"></div>
                    <div className="col-8">
                      <p
                        style={{
                          marginTop: "5px",
                          marginBottom: "0px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#008936",
                        }}
                      >
                        {t("available")} : {allotment[0].Available}
                      </p>
                      {showErrorAllot == true && (
                        <p
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                            marginBottom: "0px",
                            color: "#DB2020",
                          }}
                        >
                          {t("service_not_enough")}
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
              {packageCard.IsTransportationPackage == true && (
                <>
                  <div className="row mb-2 pb-3">
                    <div className="col-4 m-auto">
                      <p className="color-black font-14 mb-0">
                        {t("location")}
                        {<span> *</span>}
                      </p>
                    </div>

                    <div className="col-8">
                      <Form.Select
                        id="transportation_schedule"
                        value={selectedLocation}
                        className={`form-control`}
                        style={{
                          borderColor:
                            requiredLocationed == true ? "red" : "#ced4da",
                        }}
                        onChange={(e) => {
                          setRequiredLocationed(false);
                          onSelectedLocation(e.target.value);
                        }}
                        name="item_time"
                      >
                        <option key="blankChoice" hidden value>
                          {t("please_select")}
                        </option>
                        {locationList != null ? (
                          locationList.map((row, index) => (
                            <option value={row.LocationId}>
                              {row.LocationName}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="row mb-2 pb-3 border-bottom">
                    <div className="col-4 m-auto">
                      <p className="color-black font-14 mb-0">
                        {t("time")}
                        {<span> *</span>}
                      </p>
                    </div>

                    <div className="col-8">
                      <Form.Select
                        id="transportation_schedule"
                        value={selectedTime}
                        className={`form-control`}
                        style={{
                          borderColor:
                            requiredTimed == true ? "red" : "#ced4da",
                        }}
                        onChange={(e) => {
                          setRequiredTimed(false);
                          onSelectedTime(e.target.value);
                        }}
                        name="item_time"
                      >
                        <option key="blankChoice" hidden value>
                          {t("please_select")}
                        </option>
                        {timeList != null ? (
                          timeList.map((row, index) => (
                            <option value={row.TimeId}>{row.TimeName}</option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Form.Select>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="card-footer text-muted" style={{ height: "0px" }}>
              <div className="text-center">
                {/* <a
                  href={
                    user != null
                      ? baseLink +
                        routePath +
                        "/ticket/" +
                        packageCard.PackageCode +
                        "/" +
                        packageCard.StepId
                      : "/#"
                  }
                > */}
                <button
                  type="submit"
                  // disabled={selectedI}
                  className="btn btn-blue btn-not-full text-white mt-auto mx-auto align-self-start"
                  onClick={(e) => {
                    addToMyCart();
                    // addToCart(
                    //   {
                    //     PackageCode: packageCard.PackageCode,
                    //     PackageName: packageCard.PackageName,
                    //     ItemCode: "",
                    //     ItemName: "",
                    //     ItemType: packageCard.ItemType,
                    //     Qty: itemQty,
                    //     Tax: 0,
                    //     ItemPrice: netPrice,
                    //     ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                    //     PriceName: packageCard.PriceName,
                    //     PriceUnit: "",
                    //     TotalPrice: netPrice,
                    //     VisitDate: visitingDate,
                    //     VisitTime: selectedTime,
                    //     VisitLocation: selectedLocation,
                    //     VisitTimeName: selectedTimeName,
                    //     VisitLocationName: selectedLocationName,
                    //     ItemCodeCabana: packageCard.VisitingDateItemCode,
                    //     RatePackage: packageCard.ContractVenueRatePackage,
                    //     BasePrice: packageCard.PackagePrice,
                    //     step: 1, //packageCard.StepId
                    //     status: "normal",
                    //   },
                    //   false
                    // );
                    // navigate("/ticket/" + packageCard.PackageCode + "/" + packageCard.StepId + "#");
                  }}
                >
                  {t("select")}
                </button>
                {/* </a> */}
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}

      <ModalFailed
        open={openFailedModal}
        body={t(aleart)}
        setClose={setCloseFailedModal}
        //back={backToOrderPage}
        back={setCloseFailedModal}
      />
      <ConfirmChangeVisitDate
        open={openChangeVisitDate}
        setClose={setCloseChangeVisitDate}
        onConfirm={onComfirmChangeVisitDate}
      />
    </>
  );
};
