import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import imgFailed from "../img/alert.png";

export const ConfirmDeletePackNotFoundRate = (props) => {
  const { open, setClose, message, onConfirm } = props;
  const { t } = useTranslation();

  const onCloseModal = () => {
    setClose(false);
  };

  const onClickConfirm = () => {
    onConfirm();
  };
  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="modal-prarmeter-report"
    >
      <ModalBody>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={imgFailed}
            style={{ width: "140px", marginBottom: "50px" }}
          ></img>
        </div>
        <div className="layout-msg-confirm">
          <p>
            {t("package_not_found")} <br /> {t("please_remove")}
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="footer-order-detail-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-payment-modal"
            onClick={(e) => onClickConfirm()}
          >
            {t("confirm")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
