import { useState, useEffect } from 'react';

import { apiLink } from '../constants/DataPublic';

const useRegister = () => {
  const [canRegist, setCanRegist] = useState(false);
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState('');
  const [country, setCountry] = useState([]);
  const [province, setProvince] = useState([]);
  const [countryPhone, setCountryPhone] = useState([]);

  let userRegist = {};

  // Check Email before sign up
  function checkSignup(e) {
    console.log('Email: ', email);
    if (emailValidation()) {
      // Validate email
      checkUserExist();
    }
  }

  function emailValidation() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || email === '') {
      setMessage('warn_email');
      setCanRegist(false);
      return false;
    } else if (regex.test(email) === false) {
      setMessage('invalid_email');
      setCanRegist(false);
      return false;
    } else {
      return true;
    }
  }

  async function checkUserExist() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ Email: email }),
    };
    await fetch(apiLink + '/afcl/Account/checkuserexists', requestOptions)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        const items = data;
        console.log(items);
        if (items['Success'] === true) {
          console.log('Success = true');
          setMessage('');
          setCanRegist(true);
          getCountry();
        } else {
          console.log('Success = false');
          setMessage('duplicate_email');
          setCanRegist(false);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert('Error: API /afcl/Account/checkuserexists ', error)
      });
  }

  async function getCountry() {
    const res = await fetch(apiLink + '/afcl/Account/country');
    res
      .json()
      .then((res) => {
        setCountry(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/country ', error)
      });
  }

  async function getProvince(countryId) {
    const res = await fetch(apiLink + '/afcl/Account/city/' + countryId);
    res
      .json()
      .then((res) => {
        setProvince(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/city/ ', error)
      });
  }

  async function getCountryPhone() {
    const res = await fetch(apiLink + '/afcl/Account/country/phone');
    res
      .json()
      .then((res) => {
        setCountryPhone(res['Result']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/country/phone', error)
      });
  }

  const saveSignup = async (user) => {
    userRegist = { ...user };
    console.log('Save Signup : ', userRegist);

    let body = {};
    let apiURL = '';

    if (userRegist.provider === '') {
      body = {
        Email: userRegist.email,
        Firstname: userRegist.fName,
        Lastname: userRegist.lName,
        Passcode: userRegist.passcode,
        CountryCode: userRegist.countryCode,
        Phone: userRegist.tel,
        Photo: userRegist.photo,
        DeviceType: '',
        PushKey: '',
        DeviceKey: '',
        Line: userRegist.lineID,
      };
      apiURL = apiLink + '/afcl/Account/guest/register';
    } else {
      body = {
        Provider: userRegist.provider,
        ProviderKey: userRegist.providerKey,
        Email: userRegist.email,
        Firstname: userRegist.fName,
        Lastname: userRegist.lName,
        Passcode: userRegist.passcode,
        CountryCode: userRegist.countryCode,
        Phone: userRegist.tel,
        Photo: userRegist.photo,
        Line: userRegist.lineID,
        DeviceType: '',
        PushKey: '',
        DeviceKey: '',
      };
      apiURL = apiLink + '/afcl/Account/external/guest/register';
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    console.log(body, requestOptions);
    await fetch(apiURL, requestOptions)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        const items = data;
        console.log('Save Register: ', items);
        sessionStorage.removeItem('UserLogin');
        if (items['Success']) {
          let userLogin = {
            fName: items['Result'].User.FirstName,
            lName: items['Result'].User.LastName,
            email: items['Result'].User.Email,
            userId: items['Result'].User.UserId,
            userCode: items['Result'].User.UserCode,
            photo: items['Result'].User.UserPicture,
            channelCode: items['Result'].ChannelCode,
            isLogin: true,
          };
          sessionStorage.setItem('UserLogin', JSON.stringify(userLogin));
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: ', apiURL, ' ', error)
      });
  };

  return { canRegist, result, message, email, country, province, countryPhone, userRegist, setCanRegist, setEmail, getCountry, getProvince, getCountryPhone, checkSignup, saveSignup };
};

export default useRegister;
