import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { GET_ORDER_INFO } from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";
import { useNavigate } from "react-router-dom";

export const OrderInfoModalAgent = (props) => {
  const { t, i18n } = useTranslation();

  const { open, setClose, dataOrderInfo, orderNo } = props;
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserLogin"))
  );
  const [init, setinit] = useState(true);
  const [OrderInfoData, setOrderInfoData] = useState(null);
  const navigate = useNavigate();

  useEffect(async () => {
    console.log("OrderInfoModalAgent >> init :" + init);
    if (init == true) {
      loadOrderDetail();
      setinit(false);
    }
  });

  const loadOrderDetail = async () => {
    await CallServiceGetOrderInfo(orderNo);
  };

  async function CallServiceGetOrderInfo(orderId) {
    console.log("OrderInfoModalAgent >> orderId " + orderId);
    setinit(false);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(
      apiLink_ecom + GET_ORDER_INFO + "?OrderId=" + orderId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data");
        console.log(data["Result"]);
        setOrderInfoData(data["Result"]);
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + GET_ORDER_INFO, error);
        handleClose();
        return false;
      });
  }

  const handleClose = () => {
    setClose(false);
  };

  function dateTimeFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YYYY HH:mm:ss").toString();
  }

  function dateFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YYYY").toString();
  }

  function timeFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("HH:mm").toString();
  }

  const FormatAmount = (amount) => {
    const resultPrice = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  const preparePayment = () => {
    const payload = {
      confirmationNo: OrderInfoData.OrderNo,
      userCode: OrderInfoData.ContractId,
      totalPrice: OrderInfoData.TotalPaid,
      guestCode: OrderInfoData.CustomerCode,
      guestEmail: OrderInfoData.Email,
      route: "Booking",
      bookingStatus:
        OrderInfoData.BookStatus === "Y" || OrderInfoData.BookStatus === "C"
          ? true
          : false, // if tentaive set this to false to enable staff button
    };
    sessionStorage.setItem("PaymentRoute", JSON.stringify(payload));
    var route = JSON.parse(sessionStorage.getItem("PaymentRoute"));
    console.log(route);
    navigate("/confirm");
  };

  const FormatString = (amount) => {
    amount = amount.toString();
    const resultPrice = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="payment-history-modal"
    >
      <Modal.Header
        className="filter-header"
        style={{ margin: "10px 20px 10px 20px" }}
        closeButton
      >
        <p>{t("order_info")}</p>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="modal-title">Order Information</div> */}
        <div className="order-detail-body">
          <form class="row g-4">
            <div class="col-md-3">
              <label for="orderNo" class="form-label">
                {t("order_no")}.
              </label>
              <input
                type="text"
                class="form-control"
                id="orderNo"
                value={OrderInfoData != null ? OrderInfoData.OrderNo : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="arrival" class="form-label">
                {t("arrival")}
              </label>
              <input
                type="text"
                class="form-control"
                id="arrival"
                value={
                  OrderInfoData != null
                    ? dateFormat(OrderInfoData.ArrivalDate) +
                      " " +
                      OrderInfoData.ArrivalTime
                    : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="expire" class="form-label">
                {t("expire")}
              </label>
              <input
                type="text"
                class="form-control"
                id="expire"
                value={
                  OrderInfoData != null
                    ? dateTimeFormat(OrderInfoData.Expire)
                    : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="groupName" class="form-label">
                {t("group_name")}
              </label>
              <input
                type="text"
                class="form-control"
                id="groupName"
                value={OrderInfoData != null ? OrderInfoData.GroupName : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="orderNo" class="form-label">
                {t("firstname")}
              </label>
              <input
                type="text"
                class="form-control"
                id="orderNo"
                value={OrderInfoData != null ? OrderInfoData.FirstName : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="arrival" class="form-label">
                {t("lastname")}
              </label>
              <input
                type="text"
                class="form-control"
                id="arrival"
                value={OrderInfoData != null ? OrderInfoData.LastName : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="expire" class="form-label">
                {t("email")}
              </label>
              <input
                type="text"
                class="form-control"
                id="expire"
                value={OrderInfoData != null ? OrderInfoData.Email : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="groupName" class="form-label">
                {t("phone_no")}
              </label>
              <div class="row">
                <div class="col-8 col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    id="groupName"
                    value={OrderInfoData != null ? OrderInfoData.PhoneCode : ""}
                    disabled={true}
                  />
                </div>
                <div class="col-8 col-sm-8" style={{ paddingLeft: "0px" }}>
                  <input
                    type="text"
                    class="form-control"
                    id="groupName"
                    value={
                      OrderInfoData != null ? OrderInfoData.PhoneNumber : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <label for="totalpaid" class="form-label">
                {t("total_paid")}
              </label>
              <input
                type="text"
                class="form-control"
                id="totalpaid"
                value={
                  OrderInfoData != null
                    ? FormatAmount(OrderInfoData.TotalPaid)
                    : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="outstanding" class="form-label">
                {t("outstanding")}
              </label>
              <input
                type="text"
                class="form-control"
                id="outstanding"
                value={
                  OrderInfoData != null
                    ? FormatAmount(OrderInfoData.Outstanding)
                    : ""
                }
                disabled={true}
                style={{ color: "#DC0000", fontWeight: "500" }}
              />
            </div>
            <div class="col-md-3">
              <label for="totalcharge" class="form-label">
                {t("total_charge")}
              </label>
              <input
                type="text"
                class="form-control"
                id="totalcharge"
                value={
                  OrderInfoData != null
                    ? FormatAmount(OrderInfoData.TotalCharge)
                    : ""
                }
                disabled={true}
              />
            </div>
          </form>
          {OrderInfoData != null && (
            <div className="modal-table" style={{ marginTop: "20px" }}>
              <TableContainer sx={{ minHeight: 200 }}>
                <Table sx={{ minWidth: 650, border: 0 }}>
                  <TableHead>
                    <TableRow className="tableHead">
                      <TableCell>{t("number")}</TableCell>
                      <TableCell>{t("code")}</TableCell>
                      <TableCell>{t("name")}</TableCell>
                      <TableCell>{t("qty")}</TableCell>
                    </TableRow>
                  </TableHead>
                  {OrderInfoData.Details != null && (
                    <TableBody>
                      {OrderInfoData.Details.map((item, index) => (
                        <TableRow key={item.OrderId}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {item.PackageCode != ""
                              ? item.PackageCode
                              : item.ItemCode}
                          </TableCell>
                          <TableCell>
                            {item.PackageName != ""
                              ? item.PackageName
                              : item.ItemName}
                          </TableCell>
                          <TableCell>{FormatString(item.qty)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-order-detail-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-payment-modal"
            onClick={(e) => {
              preparePayment();
            }}
            // style={{
            //   backgroundColor:
            //     OrderInfoData != null &&
            //     (OrderInfoData.Outstanding === null ||
            //       OrderInfoData.Outstanding === 0)
            //       ? "#BEBEBE"
            //       : "#0e315c",
            //   color:
            //     OrderInfoData != null &&
            //     (OrderInfoData.Outstanding === null ||
            //       OrderInfoData.Outstanding === 0)
            //       ? "#9E9E9E"
            //       : "white",
            //   borderColor:
            //     OrderInfoData != null &&
            //     (OrderInfoData.Outstanding === null ||
            //       OrderInfoData.Outstanding === 0)
            //       ? "#BEBEBE"
            //       : "#0e315c",
            // }}
            disabled={
              OrderInfoData != null &&
              (OrderInfoData.IsOpenBill == true ||
                OrderInfoData.BookStatus === "C" ||
                OrderInfoData.Outstanding === null ||
                OrderInfoData.Outstanding === 0)
                ? true
                : false
            }
          >
            {t("payment")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
