import React, { useState, useEffect, useReducer } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  Switch,
} from "react-router-dom";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";
import Signup from "./Signup";
import Signin from "./Signin";
import ChangePass from "./ChangePass";
import Forget from "./Forget";
import Ticket from "./Ticket";
import Passcode from "./Passcode";
import SetPasscode from "./SetPasscode";
import GuestCheckout from "./GuestCheckout";
import Done from "./Done";
import Payment from "./Payment";
import PaymentOmise from "./PaymentOmise";
import NotFound from "./NotFoundCampaign";
import PaymentFail from "./PaymentFail";
import PaymentProcessing from "./PaymentProcessing";
import PaymentTruewallet from "./PaymentTruewallet";
import LandingPage from "./LandingPage";
import { apiLink, apiLink_ecom, baseLink } from "../constants/DataPublic";
import { useGlobalState } from "../global";
import SigninAgent from "./SigninAgent";
import OrderManagement from "./OrderManagement";
import SigninStaff from "./SigninStaff";
import ViewSlip from "./OrderManage/ViewSlip";
import { SendEmailResetPassword } from "./SendEmailResetPassword";
import ConfirmAmount from "./ConfirmAmount";
import { GET_CART } from "../services/BASE_API";
import moment from "moment";
import EmailConfirmation from "./EmailConfirmation";
// import { createGlobalState } from 'react-hooks-global-state';
// const { useGlobalState } = createGlobalState({ count: 0 });

const Home = () => {
  // Language
  const [language, setLanguage] = useState(null);

  const [routePath, setRoutePath] = useGlobalState("route");
  const [inactiveCam, setInactiveCam] = useGlobalState("inactiveCam");
  const [disabledPromo, setDisabledPromo] = useGlobalState("disabledPromo");
  const [loadCampaign, setLoadCampaign] = useGlobalState("loadCampaign");
  const [template, setTemplate] = useState();
  const [result, setResult] = useState();
  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [userSignup, setUserSignup] = useState({});
  const [cartItems, setCartItems] = useState(0);
  const [userCode, setUserCode] = useState("");
  const [packageSubTypeCode, setPackageSubTypeCode] = useState("");
  const [userLogin, setUserLogin] = useState(false);
  const [visitingDate, setVisitingDate] = useGlobalState("visitingDate");
  const [visitingDateCabana, setVisitingDateCabana] =
    useGlobalState("visitingDateCabana");

  const excludedRoutes = ['email-confirmation'];
  const pathLocation = window.location.pathname.split("/")[1];
  console.log("DAKSJDJASKL",pathLocation);

  const [activeTab, setActiveTab] = useState("");

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);

  let Promotion = sessionStorage.getItem("Promo");
  Promotion = JSON.parse(Promotion);

  let sesRoute = sessionStorage.getItem("Route");
  sesRoute = JSON.parse(sesRoute);

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  useEffect(() => {
    // getAllPackage()
    let sestoRoute = [
      "signin",
      "pass",
      "forget",
      "changepw",
      "signup",
      "setpw",
      "ticket",
      "guestCheckout",
      "done",
      "visitorInfo",
      "payment",
      "pending",
      "paymentfail",
      "paymentProcess",
      "login/agent",
      "login/staff",
    ];
    if (!sestoRoute.includes(window.location.pathname.split("/")[1])) {
      if (sesRoute != window.location.pathname.split("/")[1]) {
        // sessionStorage.setItem('MyCart', JSON.stringify([]));
        sessionStorage.setItem(
          "Promo",
          JSON.stringify({
            discountPromo: "",
            promoCode: "",
            havePro: false,
            packageType: "",
          })
        );
        sessionStorage.setItem(
          "Route",
          JSON.stringify(window.location.pathname.split("/")[1])
        );
      } else {
        Promotion =
          Promotion === null
            ? {
              discountPromo: "",
              promoCode: "",
              havePro: false,
              packageType: "",
            }
            : Promotion;
        sessionStorage.setItem("Promo", JSON.stringify(Promotion));
        sessionStorage.setItem(
          "Route",
          JSON.stringify(window.location.pathname.split("/")[1])
        );
      }
    }

    if (myCart != null && myCart.length > 0) {
      myCart = sessionStorage.getItem("MyCart");
      myCart = JSON.parse(myCart);
      console.log("Home Cart, ", myCart);
      setCartItems(myCart.length);
    }
  }, []);

  useEffect(() => {
    console.log("VISITING DATE", visitingDate);
  }, [visitingDate]);

  const userLogout = (logout) => {
    if (logout) {
      setGGUser({});
      setFBUser({});
      setUserSignup({});
      setCartItems(0);
      setUserLogin(false);
    }
  };

  // const addToHeader = (headtag) => {
  //   console.log('headtag', headtag);
  //   if (document.getElementById('googleHeadTag') != null) {
  //     document.getElementById('googleHeadTag').remove();
  //   }

  //   if (headtag) {
  //     var script = document.createElement('script');
  //     script.id = 'googleHeadTag';
  //     script.text = headtag.replace('</script>', '').replace('<script>', '');
  //     console.log('script', script);
  //     document.head.appendChild(script);
  //   } else {
  //     var script = document.createElement('script');
  //     script.id = 'googleBodyTag';
  //     script.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5VKXHTW');"
  //     // script.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5QSB3C5');"
  //     document.head.appendChild(script);
  //   }

  // };

  // const addToBody = (bodytag) => {
  //   console.log('bodytag', bodytag);
  //   if (document.getElementById('googleBodyTag') != null) {
  //     document.getElementById('googleBodyTag').remove();
  //   }

  //   if (bodytag) {
  //     var noscript = document.createElement('noscript');
  //     noscript.id = 'googleBodyTag';
  //     noscript.innerHTML = bodytag.replace('<noscript>', '').replace('</noscript>', '');
  //     document.body.appendChild(noscript);
  //     console.log('bodytag is', noscript);
  //   } else {
  //     var noscript = document.createElement('noscript');
  //     noscript.id = 'googleBodyTag';
  //     noscript.text = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5VKXHTW'height='0' width='0' style='display:none;visibility:hidden'></iframe>"
  //     // noscript.text = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-5QSB3C5'height='0' width='0' style='display:none;visibility:hidden'></iframe>"
  //     document.body.appendChild(noscript);
  //     console.log('null', noscript);
  //   }
  // };

  useEffect(() => {
    let checkRoute = [
      "",
      "signin",
      "pass",
      "forget",
      "changepw",
      "signup",
      "setpw",
      "ticket",
      "guestCheckout",
      "done",
      "visitorInfo",
      "payment",
      "pending",
      "paymentfail",
      "paymentOmise",
      "paymentProcess",
      "order",
      "agent",
      "staff",
      "resetpassword",
      "email-confirmation"
    ];
    try {
      if (!checkRoute.includes(window.location.pathname.split("/")[1])) {
        // getCampaign();
        if (
          window.location.pathname.split("/"[1]).includes("/confirm") &&
          user != null
        ) {
          window.location.href = "/order";
        } else {
          window.location.href = "/";
          setActiveTab("");
        }
      } else {
        // addToHeader(null);
        // addToBody(null);
        setLoadCampaign(true);
      }
    } catch {
      // console.log("addToBody");
    }

    console.log("routePath", routePath);
    // console.log("addToBody");
  }, []);

  // async function getAllPackage() {
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: { 'accept-language': 'en' },
  //   };
  //   let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : '?prefix=16two'
  //   const res = await fetch(apiLink + '/afcl/Package' + path, requestOptions);
  //   res
  //     .json()
  //     .then((res) => {
  //       console.log('Package,', res['Result']);
  //       setAllPackage(res['Result'].Packages);
  //       const sort = res['Result'].PackageTypes.sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
  //       setPackageType(sort);
  //       console.log('res[', res['Result'].PackageTypes);
  //       console.log('res[', res['Result'].Packages);
  //       console.log('sort', sort);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert('Error: API /afcl/Package ', error);
  //     });
  // }

  async function getCampaign() {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    setRoutePath("");
    setLoadCampaign(false);
    // /{culture}/api/v1/Campaign/Info
    if (window.location.pathname.split("/")[1] != "notfound") {
      const res = await fetch(
        apiLink_ecom +
        "/afcl/Package/CampaignInfo" +
        "?prefix=" +
        window.location.pathname.split("/")[1],
        requestOptions
      );
      res
        .json()
        .then((res) => {
          console.log("Res", res["Result"]);
          if (res["Result"]["IsCampaignExpired"] != null) {
            setResult(res["Result"]);
            setTemplate(res["Result"]["Template"]);
            // addToHeader(res["Result"]["GoogleTagHead"]);
            // addToBody(res["Result"]["GoogleTagBody"]);
            if (res["Result"]["IsCampaignExpired"] == true) {
              setInactiveCam(true);
              console.log("inacc", inactiveCam);
            }
            console.log(
              "window.location.pathname.split('/'",
              window.location.pathname.split("/")
            );
            setRoutePath("/" + window.location.pathname.split("/")[1]);
            if (
              window.location.pathname.split("/")[1].toLocaleLowerCase() ==
              "16two"
            ) {
              setDisabledPromo(true);
            }
            setLoadCampaign(true);
          } else {
            window.location.href = "/";
            //window.location.href = '/notfound';
          }
        })
        .catch((error) => {
          console.log(error);
          // alert('Error: API /afcl/Package/Banner ', error);
        });
    }
  }

  const getGGUser = (ggUser) => {
    setGGUser(ggUser);
    console.log("Home GGUser : ", ggUser);
  };
  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
    console.log("Home FBUser : ", fbUser);
  };

  const getUserSignup = (userSignup) => {
    setUserSignup(userSignup);
  };

  const getLanguage = (lang) => {
    console.log("lang", lang);
    setLanguage(lang);
  };

  const getCartItem = (cartQty) => {
    setCartItems(cartQty);
  };

  const getPackageSubTypeCode = (subTypeCode) => {
    setPackageSubTypeCode(subTypeCode);
  };

  const getUserLogin = (userLogin, userCode) => {
    setUserLogin(userLogin);

    if (userCode != "") {
      setUserCode(userCode);
    }
  };

  async function getUserCart(userCode, language) {
    console.log("getUserCart", userCode);

    if (userCode != "" && userCode != undefined) {
      const requestOptions = {
        method: "GET",
        headers: { "accept-language": language },
      };
      await fetch(
        // apiLink_ecom + "/afcl/Package/Cart/CheckOut/V2/" + userCode,
        apiLink_ecom + GET_CART + userCode,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          console.log("getUserCart Home", items);
          if (items["Success"] === true) {
            console.log("Success = true");

            // Cart ใหม่
            let myCart = sessionStorage.getItem("MyCart");
            myCart = JSON.parse(myCart);

            // Cart ที่ Save ไว้ก่อนหน้า
            let allCart = items["Result"]["Order"];

            let user = sessionStorage.getItem("UserLogin");
            user = JSON.parse(user);
            user.confirmationNo = allCart.ConfirmationNo;
            user.note = allCart.ContractInfo.InternalNote1;
            sessionStorage.setItem("UserLogin", JSON.stringify(user));

            // รวม Cart
            console.log("AllCart", allCart);
            let saveCart = [];
            let date = null;
            //Set Default Visiting Date
            if (allCart.Packages[0].VisitDate != null) {
              date = moment(allCart.Packages[0].VisitDate);
            }
            // else{
            //   date = allCart.GuestInfo.VisitDate != null ? moment(allCart.GuestInfo.VisitDate) : null;
            // }

            allCart.Packages.forEach((e) => {
              if (e.ItemCode === "") {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return c.PackageCode === e.PackageCode;
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: "",
                    ItemName: "",
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: "",
                    TotalPrice: e.Qty * e.PackagePrice,
                    VisitDate: e.VisitDate === null ? null : e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: "normal",
                    ItemCodeCabana: e.VisitingDateItemCode,
                    IsTransportationPackage: e.IsTransportationPackage,
                    RatePackage: e.RatePackage,
                    BasePrice: e.DiscountPrice,
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              } else {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                      return (
                        c.PackageCode === e.PackageCode &&
                        c.ItemCode === e.ItemCode
                      );
                    });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: e.ItemCode,
                    ItemName: e.ItemName,
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: e.PriceUnit,
                    TotalPrice: parseInt(e.Qty) * parseInt(e.ItemPrice),
                    VisitDate: e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: "normal",
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              }
            });
            myCart =
              myCart === null || myCart.length === 0
                ? [...saveCart]
                : [...myCart, ...saveCart];
            setCartItems(myCart.length);
            sessionStorage.setItem("MyCart", JSON.stringify(myCart));

            let guestData = allCart.GuestInfo;
            console.log("GUEST From Api", guestData);
            // Retrieve Guest Info
            let guest = {
              arrivalDate: date == null ? null : date,
              arrivalTime:
                guestData.VisitTime == null ? "09:00" : guestData.VisitTime,
              groupName: guestData.GroupName,
              fName: guestData.FirstName,
              lName: guestData.LastName,
              email: guestData.Email,
              phoneNo: guestData.PhoneNumber,
              telCode: guestData.CountryId,
              countryId: guestData.CountryId,
              note: guestData.Note,
            };
            console.log("GUEST", guest);
            sessionStorage.setItem("GuestInfo", JSON.stringify(guest));
            setVisitingDate(date == null ? null : date);
            setVisitingDateCabana(date == null ? null : date);
            if (myCart.length > 0) {
              window.location.href = baseLink + "ticket/AGPK001/1";
            }
          } else {
            console.log("Success = false");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert('Error: API /afcl/Package/Cart/CheckOut/ ', error);
        });
    }
  }

  useEffect(() => {
    if (userCode != "") {
      console.log("UserCode Change", language);
      getUserCart(userCode, language);
    } else setCartItems(0);
  }, [userCode]);

  return (
    <div>
      <Router basename={"/" + routePath}>
        {!excludedRoutes.includes(pathLocation) && <Navbar
          getLanguage={getLanguage}
          cartItems={cartItems}
          userLogout={userLogout}
          setUserCode={setUserCode}
          userLogin={userLogin}
          setUserLogin={setUserLogin}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />}
        <Routes>
          <Route
            path={"/"}
            element={
              <LandingPage
                language={language}
                getPackageSubTypeCode={getPackageSubTypeCode}
                setActiveTab={setActiveTab}
                exact
              />
            }
          />
          <Route
            path="/notfound"
            element={
              <NotFound
                language={language}
                getPackageSubTypeCode={getPackageSubTypeCode}
              />
            }
          />
          {/* <Route
            path="/pending"
            element={
              <PendingPay
                language={language}
                getPackageSubTypeCode={getPackageSubTypeCode}
              />
            }
          /> */}
          <Route
            path="/paymentfail"
            element={
              <PaymentFail
                language={language}
                getPackageSubTypeCode={getPackageSubTypeCode}
                getCartItem={getCartItem}
              />
            }
          />
          <Route
            path="/paymentProcess"
            element={
              <PaymentProcessing
                language={language}
                getPackageSubTypeCode={getPackageSubTypeCode}
                getCartItem={getCartItem}
              />
            }
          />
          {/* <Route path="/signin" element={<Signin language={language} getGGUser={getGGUser} getFBUser={getFBUser} setUserCode={setUserCode} />} /> */}
          <Route
            path="/pass"
            element={<Passcode language={language} setUserCode={setUserCode} />}
          />
          <Route path="/forget" element={<Forget language={language} />} />
          <Route
            path="/changepw"
            element={<ChangePass language={language} />}
          />
          <Route
            path="/signup"
            element={
              <Signup
                getUserSignup={getUserSignup}
                ggUser={ggUser}
                fbUser={fbUser}
              />
            }
          />
          <Route
            path="/setpw"
            element={<SetPasscode userSignup={userSignup} />}
          />
          <Route
            path="/ticket/:packageCode/:step"
            element={
              <Ticket
                language={language}
                packageSubTypeCode={packageSubTypeCode}
                getCartItem={getCartItem}
                setActiveTab={setActiveTab}
              />
            }
          />
          <Route
            path="/guestCheckout"
            element={
              <GuestCheckout
                language={language}
                getGGUser={getGGUser}
                getFBUser={getFBUser}
                setUserCode={setUserCode}
              />
            }
          />
          <Route
            path="/done"
            element={<Done language={language} getCartItem={getCartItem} />}
          />
          {/* <Route
            path="/visitorInfo"
            element={
              <VisitorInfo
                language={language}
                getGGUser={getGGUser}
                getFBUser={getFBUser}
                getCartItem={getCartItem}
                setUserCode={setUserCode}
              />
            }
          /> */}
          <Route
            path="/payment"
            element={<Payment language={language} getCartItem={getCartItem} />}
          />
          <Route
            path="/paymentOmise"
            element={
              <PaymentOmise
                language={language}
                getCartItem={getCartItem}
                setActiveTab={setActiveTab}
              />
            }
          />
          <Route
            path="/paymentOmise/trueMoney"
            element={
              <PaymentTruewallet
                language={language}
                getCartItem={getCartItem}
              />
            }
          />
          //#region login route
          <Route
            path="/agent"
            element={
              <SigninAgent
                language={language}
                getGGUser={getGGUser}
                getFBUser={getFBUser}
                setUserCode={setUserCode}
                onUserLoginChange={getUserLogin}
              />
            }
          />
          <Route
            path="/staff"
            element={
              <SigninStaff
                language={language}
                getGGUser={getGGUser}
                getFBUser={getFBUser}
                setUserCode={setUserCode}
                onUserLoginChange={getUserLogin}
              />
            }
          />
          //#endregion
          <Route
            path="/order"
            element={
              <OrderManagement
                language={language}
                setActiveTab={setActiveTab}
              />
            }
          />
          <Route
            path="/order/viewslip/"
            element={<ViewSlip />}
            language={language}
          />
          <Route
            path="agent/resetpassword"
            element={<SendEmailResetPassword backPath={"/agent"} />}
            language={language}
          />
          <Route
            path="staff/resetpassword"
            element={<SendEmailResetPassword backPath={"/staff"} />}
            language={language}
          />
          {/* <Route
            path="Savebooking"
            element={<SaveBooking getCartItem= {getCartItem}/>}
            language={language}
            
          /> */}
          <Route
            path="/confirm"
            element={
              <ConfirmAmount language={language} setActiveTab={setActiveTab} />
            }
          />

          <Route
            path="/email-confirmation"
            element={
              <EmailConfirmation
              getLanguage={getLanguage}
              />
            }
          />
        </Routes>
      </Router>
      {(window.location.pathname.split("/")[1].toLocaleLowerCase() ===
        "16two" &&
        window.location.pathname.split("/").length === 2 &&
        template === "Template5") ||
        (window.location.pathname.split("/")[2] === "" &&
          window.location.pathname.split("/")[1].toLocaleLowerCase() ===
          "16two" &&
          template === "Template5") ? (
        // <Footer />
        <></>
      ) : ( !excludedRoutes.includes(pathLocation) && <Footer /> )}
    </div>
  );
};

export default Home;
