// import { data } from 'jquery';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSSR, useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";

import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import {
  baseLink,
  apiLink,
  apiLink_ecom,
  incTax,
} from "../../constants/DataPublic";

import logo from "../img/logo-xl.png";
import imgSuccess from "../img/popup-success.png";
import { ReactComponent as Close } from "../img/close.svg";
import { ReactComponent as Success } from "../img/Success.svg";
import CartList from "./CartList";
import { useGlobalState } from "../../global";
import {
  SAVE_ORDER_V2,
  VERIFY_PROMOTION_CODE,
  CHECK_ALLOTMENT,
  SAVE_CART,
  REMOVE_CART,
} from "../../services/BASE_API";
import ModalFailed from "../../component/Modal/ModalFailed";
import ModalSuccess from "../Modal/ModalSuccess";
import { ConfirmExpiredVisitDate } from "../Modal/ConfirmExpiredVisitDate";
import { ConfirmChangeVisitDate } from "../Modal/ConfirmChangeVisitDate";

const Rightbar = (props) => {
  const { packageCode } = props;
  const { currentStep } = props;
  const { cart } = props;
  const { language } = props;
  const { paymentGateway, onUpdateVisitDateAll } = props;
  const { publicKey } = props;

  const [routePath, setRoutePath] = useGlobalState("route");
  // const [discountPromo, setPromo] = useGlobalState('discountPromo');
  const [promoCode, setPromoCode] = useGlobalState("promoCode");
  const [havePro, setHavePro] = useGlobalState("havePro");
  const [subTotal, setSubTotal] = useState(0);
  const [promo, setPromo] = useGlobalState("discountPromo");
  const [disabledPromo, setDisabledPromo] = useGlobalState("disabledPromo");
  // const [promoCode, setPromoCode] = useState();
  const [total, setTotal] = useState(0);
  const [saving, setSaving] = useState(0);
  const [savingDis, setSavingDis] = useState(0);
  const [canPay, setCanPay] = useState(true);

  const [canPlaceOrder, setCanPlaceOrder] = useGlobalState("canPlaceOrder"); //Control Place Order Button
  const [loading, setLoading] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [aleart, setAleart] = useState("");
  const [avaliableCabana, setAvaliableCabana] = useState(null);
  const [openExpiredVisitDate, setOpenExpiredVisitDate] = useState(false);

  const navigate = useNavigate();

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  let promotion = sessionStorage.getItem("Promo");
  promotion = JSON.parse(promotion);

  let guest = JSON.parse(sessionStorage.getItem("GuestInfo"));

  let visitDate = sessionStorage.getItem("CabanaVisitingDate");
  // console.log("cart begin", cart);

  // set default value
  let guestInfo = {
    GroupName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    Note: "",
  };

  // useEffect(async () => {

  //   if (keepcart.length == 0) {
  //     sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, promoType: '' }));
  //     setTotal(subTotal);
  //     setSaving(savingDis)
  //     setHavePro(false)
  //     setPromo('')
  //     setPromoCode('')
  //   }
  // }, [keepcart])

  // const countRatePrice = () => {
  //   const rate = cartItem.RatePackage.find(
  //     (item) => itemQty >= item.QtyFrom && itemQty <= item.QtyTo
  //   );
  //   return rate;
  // };

  useEffect(() => {
    myCart = JSON.parse(sessionStorage.getItem("MyCart"));
    let dateNow = new Date();
    let dateSelect = new Date(dateNow.setHours(0, 0, 0, 0));
    dateSelect.setDate(dateNow.getDate());
    // console.log("myCart >> ", cart);
    //console.log("myCart dateSelect >> ",moment(dateSelect).format('DD/MM/YYYY'));
    let expiredVisitDate = [];
    if (cart != null) {
      if (cart.length > 0) {
        cart.map((item) => {
          if (item.VisitDate != null && item.VisitDate !== "") {
            let dateOld = new Date(
              new Date(item.VisitDate).setHours(0, 0, 0, 0)
            );
            // console.log("myCart dateOld >> ",moment(dateOld).format('DD/MM/YYYY'));
            // console.log("myCart dateSelect >> ",moment(dateSelect).format('DD/MM/YYYY'));
            // console.log("myCart dateOld >> ", dateOld);
            // console.log("myCart dateSelect >> ", dateSelect);
            // console.log(
            //   "myCart dateOld < dateSelect >> ",
            //   dateOld < dateSelect
            // );
            //moment(dateOld).format('DD/MM/YYYY') < moment(dateSelect).format('DD/MM/YYYY')
            if (dateOld < dateSelect) {
              expiredVisitDate.push(item);
            } else if (dateOld != visitDate) {
            }
          }
        });
        // const expiredVisitDate = cart.filter((x)=> new Date(x.VisitDate) < dateSelect.toISOString().split("T")[0]);
        // console.log("myCart expiredVisitDate >> ", expiredVisitDate);
        if (expiredVisitDate.length > 0) {
          setOpenExpiredVisitDate(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    let subT = 0;
    let savT = 0;
    if (cart != null) {
      if (cart.length > 0) {
        cart.forEach((e) => {
          if (e.RatePackage != null) {
            const rate = e.RatePackage.find(
              (item) => e.Qty >= item.QtyFrom && e.Qty <= item.QtyTo //(item.QtyTo == 0 ? Infinity : item.QtyTo)
            );
            const ratePrice =
              rate != undefined ? rate.Price : e.BasePrice - e.ItemDiscount;
            // console.log("ratePrice", ratePrice);
            const price = e.BasePrice - ratePrice;
            savT = savT + price * e.Qty;
            subT = subT + ratePrice * e.Qty;
          } else {
            savT = savT + e.ItemDiscount * e.Qty;
            subT = subT + e.ItemPrice * e.Qty;
          }
        });
        setCanPay(true);
      } else {
        setCanPay(false);
      }
      setSubTotal(subT);
      // setSaving(savT);
      setSavingDis(savT);
    }

    // console.log("cart", cart);
    // console.log("total", total);
  }, [cart]);

  useEffect(() => {
    if (promotion.promoType === "AMT") {
      setTotal(subTotal - promotion.discountPromo);
      setSaving(savingDis + promotion.discountPromo);
      setPromo(promotion.discountPromo);
    } else {
      let discount = subTotal * (promotion.discountPromo / 100);
      setTotal(subTotal - discount);
      setSaving(savingDis + promotion.discountPromo);
      setPromo(discount);
    }
    setHavePro(promotion.havePro);
    setPromoCode(promotion.promoCode);
    // console.log("total", total);
    // console.log("saving", saving);
    // console.log("havePro", havePro);
  }, [subTotal]);

  const onEditCart = (pCode, iCode) => {
    props.onEditCart(pCode, iCode);
  };

  const onRemoveCart = (pCode, iCode) => {
    props.onRemoveCart(pCode, iCode);
  };

  const onAddToCart = (itemCart, isEdit) => {
    props.onAddToCart(itemCart, isEdit);
  };

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
  };

  const onComfirmExpiredVisitDate = () => {
    setOpenExpiredVisitDate(false);
    sessionStorage.setItem("MyCart", JSON.stringify([]));
    window.location.reload();
    // CallServicePostRemoveCart();
  };

  async function CallServicePostRemoveCart() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ContractId: user.userId,
        ConfirmationNo: user.confirmationNo,
      }),
    };
    await fetch(apiLink_ecom + REMOVE_CART, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          setOpenExpiredVisitDate(false);
          sessionStorage.setItem("MyCart", JSON.stringify([]));
          window.location.reload();
          user.confirmationNo = "";
          sessionStorage.setItem("UserLogin", JSON.stringify(user));
          sessionStorage.removeItem("GuestInfo");
          sessionStorage.removeItem("CabanaVisitingDate");
        } else {
          setOpenFailedModal(true);
          setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + REMOVE_CART, error);
        setLoading(false);
        return false;
      });
  }

  // const [guestInfo, setGuest] = useState({
  //   arrivalDate: "",
  //   arrivalTime: "",
  //   groupName: "",
  //   fName: "",
  //   lName: "",
  //   email: "",
  //   phoneNo: "",
  //   telCode: "",
  //   countryId: "",
  //   note: ""
  // });

  const saveCart = async (e, showP, cart) => {
    setLoading(true);
    guest = JSON.parse(sessionStorage.getItem("GuestInfo"));
    user = JSON.parse(sessionStorage.getItem("UserLogin"));
    if (guest != null) {
      // guestInfo = guest;
      guestInfo = {
        VisitDate: guest.arrivalDate,
        VisitTime: guest.arrivalTime,
        GroupName: guest.groupName,
        FirstName: guest.fName,
        LastName: guest.lName,
        Email: guest.email,
        PhoneNumber: guest.phoneNo,
        Note: guest.note,
        CountryId: guest.telCode,
      };
    }

    console.log(user);
    // set default value
    let contractInfo = {
      ContractId: user.userId,
      ContractName: user.fName,
      Email: user.email,
      PhoneNumber: user.phoneNo,
      InternalNote1: user.note,
      InternalNote2: user.note1,
      InternalNote3: user.note2,
      InternalNote4: user.note3,
      InternalNote5: user.note4,
      CountryId: user.countryId,
    };

    e.preventDefault();
    user = sessionStorage.getItem("UserLogin");
    user = JSON.parse(user);
    // console.log("SaveCart Entry, ", user, myCart);
    if (user !== null && (user.isLogin || user.type == "guest")) {
      let orderItem = [];
      myCart.map((e) => {
        return orderItem.push({
          PackageCode: e.PackageCode,
          ItemCode: e.ItemCode,
          Qty: e.Qty,
          // VisitDate: e.VisitDate === null ? null : e.VisitDate.substr(5, 2) + '/' + e.VisitDate.substr(8, 2) + '/' + e.VisitDate.substr(0, 4),
          VisitDate: e.VisitDate === null ? null : e.VisitDate,
          VisitTime: e.VisitTime,
          VisitLocation: e.VisitLocation,
          VisitTimeName: e.VisitTimeName,
          VisitLocationName: e.VisitLocationName,
        });
      });

      let bodytext = {
        ConfirmationNo: user.confirmationNo,
        ChannelCode: user.channelCode,
        // UserId: user.userId,
        // UserCode: user.userCode,
        GuestInfo: guestInfo,
        ContractInfo: contractInfo,
        PromotionCode: promoCode, //ทดสอบไว้ก่อน
        OrderItems: orderItem,
        Prefix: routePath.replace("/", ""),
      };

      // Email: user.email,
      //   FirstName: user.fName,
      //   LastName: user.lName,
      //   PhoneNo: user.phoneNo,
      //   IDCardNo: user.idCardNo,
      //   telCode: user.telCode,
      //   DeviceType:"",
      //   PushKey:"",
      //   DeviceKey:"",

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept-language": language,
        },
        body: JSON.stringify(bodytext),
      };
      console.log("SaveCart body", requestOptions.body);

      let APIPath = SAVE_ORDER_V2;
      if (cart) {
        APIPath = SAVE_CART;
      }
      await fetch(apiLink_ecom + APIPath, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          // console.log("Save Cart Rightbar: ", items);
          if (items["Success"]) {
            console.log("Save Cart Success: true, total = ", total);
            // console.log("Response", items["Result"].ConfirmationNo);
            user.confirmationNo = items["Result"].ConfirmationNo;
            user.totalPrice = total;
            user.fullAmount = total;
            user.guestCode = items["Result"].GuestCode;
            sessionStorage.setItem("UserLogin", JSON.stringify(user));
            user = JSON.parse(sessionStorage.getItem("UserLogin"));
            setShowPopup(showP);
            if (currentStep != 5) setCurrentStep(5);
            setLoading(false);

            if (!showP) {
              if (paymentGateway === "P003") {
                navigate("/payment");
              } else if (paymentGateway === "P004") {
                // navigate("/paymentOmise", {
                //   state: {
                //     publicKey: publicKey,
                //   },
                // });
                navigate("/confirm");
              }
            }
          } else {
            setLoading(false);
            if (!!items["Message"]) {
              setAlertText(items["Message"]);
            } else {
              setAlertText(t("package_invalid"));
            }
            console.log("Save Cart Success: false");
            setShowAlert(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);
          setAlertText(error);
          setShowAlert(true);
        });
    } else {
      navigate("/signin");
    }
  };

  const confirmOrder = async (e) => {
    user = sessionStorage.getItem("UserLogin");
    user = JSON.parse(user);

    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "checkoutOption",
      ecommerce: {
        checkout_option: {
          actionField: { step: 5 /*, 'option': user.paymentMethod*/ },
        },
      },
    });

    //console.log("userInfo.paymentMethod", user.paymentMethod)

    if (currentStep === 1) {
      checkAllotment(false, null);
      // if (allotment) {
      //   setCurrentStep(5);
      //   user.totalPrice = total;
      // }
    } else {
      checkAllotment(true, e);
    }
  };

  async function checkAllotment(isSaveOrder, dataSaveOrder) {
    let cabanaList = [];
    // let cabanaCodeList = [];
    cart.forEach((item) => {
      if (item.ItemCodeCabana != null) {
        // if (!cabanaCodeList.includes(item.ItemCodeCabana)) {
        //   cabanaCodeList.push(item.ItemCodeCabana);
        // } else {
        // }
        // arrivalDate = item.VisitDate;
        cabanaList.push({
          ArrivalDate: item.VisitDate,
          Qty: item.Qty,
          ItemCode: item.ItemCodeCabana,
        });
      }
    });
    // console.log("cabanaCodeList",cabanaCodeList);
    const totalByCabanaItemCode = cabanaList.reduce((acc, item) => {
      if (!acc[item.ItemCode]) {
        acc[item.ItemCode] = {
          ItemCode: item.ItemCode,
          Qty: 0,
          arrivalDate: item.ArrivalDate,
        };
      }
      acc[item.ItemCode].Qty += item.Qty;
      return acc;
    }, {});
    console.log("totalByCabanaItemCode", totalByCabanaItemCode);
    const result = Object.values(totalByCabanaItemCode);
    if (result.length > 0) {
      let arrivalDate = result[0].arrivalDate;
      // let newList = [];
      // cabanaCodeList.forEach((i) => {
      //   let arrivalDate = null;
      //   let qty = 0;
      //   let itemCode = null;
      //   cabanaList.map((x) => {
      //     if (i === x.ItemCode) {
      //       arrivalDate = x.ArrivalDate;
      //       qty = qty + x.Qty;
      //       itemCode = x.ItemCode;
      //     }
      //   });
      //   newList.push({
      //     ItemCode: itemCode,
      //     Qty: qty,
      //   });
      // });
      // console.log("newList", newList);
      const payload = {
        ArrivalDate: arrivalDate,
        AllotmentItems: result,
      };
      CallServicePostCheckAllotment(payload, isSaveOrder, dataSaveOrder);
    } else {
      console.log("cabanaCodeList == 0");
      if (isSaveOrder) {
        saveCart(dataSaveOrder, false);
      } else {
        // setCurrentStep(5);
        navigate("/ticket/" + myCart[0].PackageCode + "/5");
        user.totalPrice = total;
      }
    }
  }

  async function CallServicePostCheckAllotment(
    payload,
    isSaveOrder,
    dataSaveOrder
  ) {
    setLoading(true);
    let result = false;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify(payload),
    };
    await fetch(apiLink_ecom + CHECK_ALLOTMENT, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          const itemList = data["Result"];
          setAvaliableCabana(itemList);
          // let status = true;
          const statusFail = itemList.filter((x) => x.Status == false);
          console.log("statusFail", statusFail);
          if (statusFail.length == 0) {
            if (isSaveOrder) {
              saveCart(dataSaveOrder, false);
            } else {
              // setCurrentStep(5);
              user.totalPrice = total;
              navigate("/ticket/" + myCart[0].PackageCode + "/5");
            }
          } else {
            // setOpenFailedModal(true);
            // setAleart("Please reset visit date");
            // setShowErrorAvaliableCabana(true);
          }
        } else {
          setOpenFailedModal(true);
          setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + CHECK_ALLOTMENT, error);
        setLoading(false);
        result = false;
      });
    return result;
  }

  const setCartUpdate = (cartUpdate) => {
    props.setCartUpdate(cartUpdate);
  };

  const verifyPromoCode = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    let toPro = promoCode == "" ? null : promoCode;
    let prefix = routePath != "" ? "?Prefix=" + routePath.replace("/", "") : "";
    await fetch(
      apiLink_ecom + VERIFY_PROMOTION_CODE + toPro + prefix,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        if (items.Status) {
          if (items.DiscountType === "AMT") {
            let totalDiscount = subTotal - items.DiscountAmt;
            setPromo(items.DiscountAmt);
            setTotal(totalDiscount);
            setSaving(savingDis + items.DiscountAmt);
            sessionStorage.setItem(
              "Promo",
              JSON.stringify({
                discountPromo: items.DiscountAmt,
                promoCode: promoCode,
                havePro: true,
                promoType: items.DiscountType,
              })
            );
          } else {
            let discount = subTotal * (items.DiscountAmt / 100);
            let totalDiscount = subTotal - discount;
            setPromo(discount);
            setTotal(totalDiscount);
            setSaving(savingDis + discount);
            sessionStorage.setItem(
              "Promo",
              JSON.stringify({
                discountPromo: items.DiscountAmt,
                promoCode: promoCode,
                havePro: true,
                promoType: items.DiscountType,
              })
            );
          }
          setHavePro(true);
        } else {
          setSaving(savingDis);
          setTotal(subTotal);
          setPromoCode(null);
          setPromo(null);
          setAlertText("promo_notavai");
          setShowAlert(true);
          setHavePro(false);
          sessionStorage.setItem(
            "Promo",
            JSON.stringify({
              discountPromo: "",
              promoCode: "",
              havePro: false,
              promoType: "",
            })
          );
        }
        console.log("items,", items);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setAlertText(error);
        setShowAlert(true);
      });
  };

  /** Register Success. **/
  const [showPopup, setShowPopup] = useState(false);
  const CloseSignupSuccess = () => setShowPopup(false);
  const ShowSignupSuccess = () => setShowPopup(true);
  const CloseAleartModal = () => setShowAlert(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  const closeFailedModal = () => setShowAlert(false);

  const setCurrentStep = (index) => {
    console.log("index", index);
    console.log("myCart.length", myCart.length);
    if (index > 1 && myCart.length == 0) {
      setShowAlert(true);
      setAlertText("please_select_package");
    } else {
      props.setCurrentStep(index);
      console.log("done", index);
    }
  };

  // ภาษา
  const { t, i18n } = useTranslation();

  return (
    //START RIGHT CONTENT
    <div className="col-cart">
      <div className="left-wrapper">
        <div className="box box-border">
          <div className="box-cart-header">
            <div className="cart-title">
              <span className="icon material-icons">shopping_cart</span>
              <span>{t("cart")}</span>
            </div>
          </div>

          <div className="box-body cart-scrollable" data-bs-spy="scroll">
            <div className="row">
              <div className="col-6">
                <p className="font-12 weight-400 color-grey mb-0">
                  {t("item")}
                </p>
              </div>
              <div className="col-6">
                <p className="font-12 weight-400 color-grey text-end mb-0">
                  {t("price")}
                </p>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
            {cart &&
              cart.map((e, index) => {
                let checkLast = false;
                if (
                  e.step == 1 &&
                  cart.some((x) => x.step != 1) &&
                  cart.filter((x) => x.step == 1).length == 1
                ) {
                  checkLast = true;
                }
                return (
                  <CartList
                    key={index}
                    currentStep={currentStep}
                    checkLastItem={checkLast}
                    cartItem={e}
                    setCartUpdate={setCartUpdate}
                    onRemoveCart={onRemoveCart}
                    onAddToCart={onAddToCart}
                    avaliableCabana={avaliableCabana}
                    onSetAvaliableCabana={setAvaliableCabana}
                    onUpdateVisitDateAll={onUpdateVisitDateAll}
                  />
                );
              })}
          </div>

          <div className="box-body bg-grey2">
            <div className="row align-items-center">
              <div className="col-6">
                <p className="font-14 weight-400 color-grey mb-0">
                  {t("subtotal")}
                </p>
              </div>
              <div className="col-6 text-end">
                <p className="font-18 weight-700 mb-0">
                  {subTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {t("thb")}
                </p>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
            {/* <div className="row align-items-center ">
              <div className="col-6">
                <p className="font-14 weight-400 color-grey mb-0">
                  {t("promotion")}
                </p>
              </div>
              <div className="col-6 text-end">
                <p className="font-18 weight-700 mb-0">
                  {promotion.promoType == "AMT" ? (
                    <>
                      {promotion.discountPromo && (
                        <>
                          {"- " +
                            promotion.discountPromo.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                          {t("thb")}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {promotion.discountPromo && (
                        <>
                          {subTotal == 0 ? (
                            <></>
                          ) : (
                            <>
                              {"- " +
                                (
                                  subTotal *
                                  (promotion.discountPromo / 100)
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}{" "}
                              {t("thb")}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </p>
              </div>
              <div className="col-sm-12">
                <hr />
              </div>
            </div> */}
          </div>
          <div className="box-body">
            <div className="row align-items-center">
              <div className="col-6 pe-0">
                <p className="font-24 weight-700 mb-0">
                  {t("total")}{" "}
                  <span className="font-10 color-grey weight-400">
                    {t("incl_tax")} ({incTax}%)
                  </span>
                </p>
              </div>
              <div className="col-6 text-end ps-0">
                <p className="font-24 weight-900 color-orange mb-0">
                  {total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  <span className="font-18">{t("thb")}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="box-body pb-4 bg-grey2">
            <div className="row align-items-center mb-4">
              <div className="col-6">
                <p className="font-14 weight-400 color-grey mb-0">
                  {t("total_saving")}
                </p>
              </div>
              <div className="col-6 text-end">
                <p className="font-18 weight-700 mb-0">
                  {/* {saving.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {t('thb')} */}
                  {parseFloat(saving).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              {/* {currentStep < 5 && (
                <>
                  <div className="col-12">
                    <a href={baseLink + routePath + '/ticket/' + packageCode + '/5'}>
                      <button className="btn btn-main" type="button">
                        {t('checkout')}
                      </button>
                    </a>
                  </div>
                  <div className="col-12 relative">
                    <hr />
                    <span className="or">{t('or')}</span>
                  </div>
                  {routePath == "" && (
                    <div className="col-12">
                      <button className="btn btn-blue mb-3" type="button" onClick={() => setCurrentStep(currentStep + 1)}>{t('next')}</button>
                    </div>
                  )}
                </>
              )} */}
              <div className="col-12">
                <button
                  className="btn btn-main mb-3"
                  type="button"
                  onClick={confirmOrder}
                  disabled={!canPay || !canPlaceOrder}
                >
                  {t("place_order")}
                </button>
              </div>
              <div className="col-12">
                <button
                  className="btn btn-green"
                  type="button"
                  onClick={(e) => saveCart(e, true, true)}
                  disabled={!canPlaceOrder}
                  hidden={currentStep !== 5 || user.isStaff}
                >
                  {t("save_cart")}
                </button>
              </div>
            </div>
            {/* <Modal show={showPopup} className="col-12 cart-savecart">
              <a href="#" className="close">
                <Close className="popup-close" onClick={CloseSignupSuccess} />
              </a>
              <div className="logosignin">
                {" "}
                <img src={logo} className="logo-popup" />
              </div>
              <div className="text-center mb-3">
                <img src={imgSuccess} className="logo-popup" />
              </div>
              <p className="text-center text-color">{t("save_complete")}</p>
            </Modal> */}

            <ModalSuccess
              open={showPopup}
              setClose={CloseSignupSuccess}
              body={t("save_complete")}
              back={CloseSignupSuccess}
            />

            {/* <Modal show={showAlert} className="col-12 cart-placeorder">
              <a href="#" className="close">
                <Close
                  className="popup-close"
                  onClick={() => setShowAlert(false)}
                />
              </a>
              <h5 className="text-center text-danger py-5">{t(alertText)}</h5>
            </Modal> */}
            <ModalFailed
              open={showAlert}
              body={t(alertText)}
              setClose={CloseAleartModal}
              back={CloseAleartModal}
            />
          </div>
        </div>
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
      <ModalFailed
        open={openFailedModal}
        body={t(aleart)}
        setClose={setCloseFailedModal}
        //back={backToOrderPage}
        back={setCloseFailedModal}
      />
      <ConfirmExpiredVisitDate
        open={openExpiredVisitDate}
        onConfirm={onComfirmExpiredVisitDate}
      />
    </div>
    //END RIGHT CONTENT
  );
};
export default Rightbar;
