import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { ReactComponent as StatusPaid } from "../icons/status_paid.svg";
import { ReactComponent as StatusPartialPaid } from "../icons/status_partial_paid.svg";
import { ReactComponent as StatusUnPaid } from "../icons/status_unpaid.svg";
import { PAYMENT_HISTORY } from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import Backdrop from "@mui/material/Backdrop";

export const PaymentHistoryModal = (props) => {
  const { open, setClose, orderId } = props;
  console.log(orderId);
  const [paymentHistoryList, setPaymentHistoryList] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setClose(false);
  };

  useEffect(async () => {
    await CallServiceGetItemLookUp();
  }, []);

  async function CallServiceGetItemLookUp() {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(
      apiLink_ecom + PAYMENT_HISTORY + "?OrderId=" + orderId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data");
        console.log(data);
        setLoading(false);
        setPaymentHistoryList(data["Result"]);
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + PAYMENT_HISTORY, error);
        setLoading(false);
        // handleClose();
        return false;
      });
  }

  function timeFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YY HH:mm").toString();
  }

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="payment-history-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-title">
          {t("payment_history")}
        </div>
        <div className="order-detail-body">
          <TableContainer className="table-payment-history">
            <Table
              sx={{ minWidth: 650, border: 0 }}
              size="small"
              aria-label="a dense table"
              className="data-table"
              tickyHeader
            >
              <TableHead>
                <TableRow className="tableHead">
                  <TableCell width={10}>{t("status")}</TableCell>
                  <TableCell sx={{ minWidth: 180 }}>{t("checkno_refno")}</TableCell>
                  <TableCell sx={{ minWidth: 180 }}>{t("paiddate_duedate")}</TableCell>
                  <TableCell align="center" sx={{ minWidth: 180 }}>{t("total_paid")}</TableCell>
                  <TableCell sx={{ minWidth: 180 }}>{t("paid_by_cashier")}</TableCell>
                </TableRow>
              </TableHead>
              {paymentHistoryList && (
                <TableBody>
                  {paymentHistoryList.map((item, index) => (
                    <TableRow
                      key={index}
                    >
                      <TableCell component="th" scope="row">
                        {item.Status == "Y" ? (
                          <StatusPaid className="paidStatus" />
                        ) : item.Status == "P" ? (
                          <StatusPartialPaid className="paidStatus" />
                        ) : (
                          <StatusUnPaid className="paidStatus" />
                        )}
                      </TableCell>
                      <TableCell>{item.RefNo}</TableCell>
                      <TableCell>{item.PaidDate != null ? timeFormat(item.PaidDate) : ""}</TableCell>
                      <TableCell align="center" className="text-color-blue">{item.TotalPaid}</TableCell>
                      <TableCell>{item.PaidBy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </Modal.Body>
      
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
    </Modal>
  );
};
