import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { LINKS_PAY } from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";
import { useTranslation } from "react-i18next";

import imgSuccess from "../img/popup-success.png";
import CurrencyFormat from "react-currency-format";
import { Backdrop } from "@mui/material";

const LinkPay = (props) => {
  const { order, open, setClose, email, disabled } = props;

  const { t } = useTranslation();

  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [qrData, setQrData] = useState("");
  const [loading, setLoading] = useState(false);

  const [warnAmount, setWarnAmount] = useState("");
  const [warnDesc, setWarnDesc] = useState("");
  const [copyMessage, setCopyMessage] = useState("");

  const [validAmount, setValidAmount] = useState(false);
  const [validDesc, setValidDesc] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(link);
  };

  const handleAmountChange = (event) => {
    if (event.target.value >= 20) setWarnAmount("");
    let _amount = event.target.value;
    _amount = _amount == "" ? 0 : parseFloat(_amount.replace(",", "")).toFixed(2);
    console.log(_amount);
    if (_amount < 20) {
      setWarnAmount("amount_is_invalid");
      setValidAmount(false);
    }else if(_amount > order[0].outStanding){
      console.log(_amount, order[0].outStanding);
      setWarnAmount("amount_morethan_outstanding");
      setValidAmount(false);
    }else{
      setWarnAmount("");
      setValidAmount(true);
    }
    setAmount(event.target.value);
  };

  const handleDescChange = (event) => {
    if (event.target.value != "") setWarnDesc("");
    setDescription(event.target.value);
  };

  const handleLinkInputChange = (event) => {
    setLink(event.target.value);
  };

  async function callService() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
      body: JSON.stringify({
        UserCode: order[0].guestCode,
        ConfirmationNo: order[0].confirmationNo,
        PaymentType: "linkspay",
        Amount: amount,
        Description: description,
        Source: "Agent",
      }),
    };
    console.log(requestOptions);
    setTimeout(() => {
      fetch(apiLink_ecom + LINKS_PAY, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          var item = data["Result"];
          console.log(item);
          if (item == null) {
            alert("Not Found");
            setLoading(false);
            return false;
          }
          setLink(item.WebPaymentUrl);
          setQrData(item.QrcodeUrl);
          setLoading(false);
          return true;
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + LINKS_PAY, error);
          setLoading(false);
          return false;
        });
    }, 1000);
  }

  const validate = (amount) => {
    let _amount = amount;
    _amount = amount == "" ? 0 : parseFloat(amount.replace(",", "")).toFixed(2);
    console.log(amount);
    console.log(_amount, order);
    if (_amount < 20) {
      setWarnAmount("amount_is_invalid");
      setValidAmount(false);
    }else if(_amount > order[0].outStanding){
      console.log(_amount, order[0].outStanding);
      setWarnAmount("amount_morethan_outstanding");
      setValidAmount(false);
    }else{
      setWarnAmount("");
      setValidAmount(true);
    }

    if (description == null || description == "") {
      setWarnDesc("warn_empty");
      setValidDesc(false);
    }else{
      setValidDesc(true);
    }

    if (validAmount && validDesc) callService();
  };

  const onClear = () => {
    setAmount("");
    setDescription("");
    setLink("");
    setQrData("");
    setWarnAmount("")
    setWarnDesc("");
    setClose();
  };

  const onFormat = async (e) => {
    let value = await parseAmount(amount != "" ? amount : "0");
    value = FormatAmount(value);
    setAmount(value);
  };

  const parseAmount = (amount) => {
    let price = amount;
    const checkPrice = amount.split(",");
    if (checkPrice.length > 0) {
      price = amount.replace(",", "");
    }
    const v1 = parseFloat(price);
    return v1;
  };

  const FormatAmount = (amount) => {
    const resultPrice = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  return (
    <Modal
      show={open}
      onHide={onClear}
      backdrop="static"
      keyboard={false}
      className="linkpay-dialog"
    >
      <Modal.Header closeButton>
        <span>{t("create_link")}</span>
      </Modal.Header>
      <Modal.Body
        className="linkpay-dialog-body"
        style={{ justifyContent: "center", maxWidth: "100%" }}
      >
        <div className="linkpay-container">
          <form>
            <div className="row">
              <div className="col">
                <div class="linkpay-form mb-3">
                  <label for="inputAmount" class="form-label">
                  {t("amount")}
                  </label>
                  {/* <input
                    type="number"
                    class="form-control"
                    id="inputAmount"
                    min={20}
                    value={amount}
                    disabled={disabled}
                    onChange={handleAmountChange}
                    autoComplete="false"
                  /> */}
                  {/* <CurrencyFormat
                    className={
                      warnAmount == "" ? "form-control" : "form-control danger"
                    }
                    id="inputAmount"
                    value={amount}
                    onChange={handleAmountChange}
                    thousandSeparator={true}
                    decimalScale={2}
                    decimalSeparator={"."}
                    fixedDecimalScale={true}
                    autoComplete="false"
                    aria-describedby="amountHelp"
                  /> */}
                   <input
                        type="text"
                        className={warnAmount == "" ? "form-control" : "form-control danger"}
                        id="inputAmount"
                        value={amount}
                        onChange={handleAmountChange}
                        //onKeyPress={onPricePWKeyDown}
                        onBlur={onFormat}
                        autoComplete="false"
                        aria-describedby="amountHelp"
                      />
                  <small
                    id="amountHelp"
                    class="form-text text-muted"
                    style={{ paddingLeft: "10px" }}
                  >
                    {t("amount_is_invalid")}
                  </small>
                  <span className="text-danger">{t(warnAmount)}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div class="linkpay-form mb-3">
                  <label for="inputText" class="form-label">
                  {t("desc")}
                  </label>
                  <input
                    type="text"
                    className={
                      warnDesc == "" ? "form-control" : "form-control danger"
                    }
                    id="inputText"
                    value={email == "" ? "" : description}
                    disabled={disabled}
                    onChange={handleDescChange}
                  />
                  <span className="text-danger">{t(warnDesc)}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div class="linkpay-form mb-3">
                  <label for="exampleInputLink1" class="form-label">
                    {t("link")}
                  </label>
                  <div className={"input-wrapper " + copyMessage}>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputLink1"
                      value={email == "" ? "" : link}
                      disabled={true}
                      onChange={handleLinkInputChange}
                    />
                    <span class="icon-wrapper">
                      <i
                        class="fa fa-clone"
                        aria-hidden="true"
                        onClick={() => {
                          if (link != "") {
                            copyText();
                            setCopyMessage("active");
                            setTimeout(() => {
                              setCopyMessage("");
                            }, 1000);
                          }
                        }}
                        style={
                          link == ""
                            ? { cursor: "context-menu" }
                            : { cursor: "pointer" }
                        }
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {link && qrData && (
              <div className="row">
                <div className="col">
                  <div class="linkpay-form mb-3">
                    <img className="linkspay-qr" src={qrData} width={200} />
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: "1" }}
            style={{ zIndex: 1100 }}
            open
          >
            <div
              class="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
            ></div>
          </Backdrop>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col">
            <Button onClick={()=>validate(amount)} className="btn-create">
              {/* <Button onClick={validate} className="btn-create" disabled={link != ""}> */}
              {t("create")}
            </Button>
          </div>
          <div className="col">
            <Button onClick={onClear} className="btn-cancel">
            {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LinkPay;
