import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//components
import ReactLoading from "../Loading";
import Backdrop from "@mui/material/Backdrop";

//icons
import { ReactComponent as Filter } from "../icons/filter.svg";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { makeStyles } from "@mui/styles";

import {
  ThemeProvider,
  createTheme,
  createMuiTheme,
} from "@mui/material/styles";
import { Button } from "react-bootstrap";

//Services
import {
  AGENT_CANCEL_BOOKING,
  AGENT_GROUP_BOOKING,
  AGENT_PAID,
  AGENT_TODAY_ARRIVAL,
  AGENT_UNPAID,
  GET_ORDER_INFO,
  FILTER,
  STAFF_GET_GROUP_BOOKING,
  STAFF_GET_TODAY_ARRIVAL,
  STAFF_GET_PAID,
  STAFF_GET_UNPAID,
  STAFF_GET_CANCEL_BOOKING,
  STAFF_FILTER,
} from "../../services/BASE_API";
import { apiLink, apiLink_ecom, pageLimit } from "../../constants/DataPublic";
import { BookingTab } from "./BookingTab";
import { PaymentHistoryModal } from "../Modal/PaymentHistoryModal";
import { OrderInfoModalAgent } from "../Modal/OrderInfoModalAgent";
import { OrderInfoModalStaff } from "../Modal/OrderInfoModalStaff";
import { SelectItemModal } from "../Modal/SelectItemModal";
import { FilterModal } from "../Modal/FilterModal";
export const Booking = (props) => {
  const { setSelectBooking, userInfo, refresh, setRefresh, clearCheck, setClearCheck, language } = props;

  const [init, setinit] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableBody, setTableBody] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [recordStart, setRecordStart] = useState(0);
  const [recordEnd, setRecordEnd] = useState(0);
  const [orderNo, setOrderNo] = useState();
  const [orderInfo, setOrderInfo] = useState();
  const [filterData, setfilterData] = useState({
    Keyword: "",
    VisitDate: "",
    VisitTime: "",
    ExpiredDate: "",
    ExpiredTime: "",
    PaidStatus: "",
    BookingStatus: "",
    SendEmail: "",
  });

  const [dataModal, setDataModal] = useState();
  const [openPaymentHistoryModal, setOpenPaymentHistoryModal] =
    React.useState(false);
  const handleClose = () => {
    setOpenPaymentHistoryModal(false);
    setDataModal(null);
  };
  const [openOrderInformationModal, setOrderInformationModal] =
    React.useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [isTodayTab, setIsTodayTab] = useState(false);
  const [value, setValue] = useState("1"); //Tab value

  let user = JSON.parse(sessionStorage.getItem("UserLogin"));

  const heading = [
    {
      name: "group_booking",
      tabIndex: "1",
    },
    {
      name: "today_arrival",
      tabIndex: "2",
    },
    {
      name: "unpaid",
      tabIndex: "3",
    },
    {
      name: "paid",
      tabIndex: "4",
    },
    {
      name: "cancel_booking",
      tabIndex: "5",
    },
  ];

  useEffect(async () => {
    if (init == true) {
      setLoading(true);
      console.log("Initial");
      await CallService("1", 1);
      setinit(false);
    }
  });

  useEffect(() => {}, [tableBody]);

  useEffect(() => {
    setPageStart();
  }, [pageInfo]);

  useEffect(() => {
    if (refresh == true) {
      CallService(value, pageInfo.pageNumber);
      setRefresh(false);
    }
  }, [refresh]);

  function setPageStart() {
    if (pageInfo != null) {
      setRecordEnd(
        pageInfo.PageLimit * pageInfo.PageNumber > pageInfo.TotalRecords
          ? pageInfo.TotalRecords
          : pageInfo.PageLimit * pageInfo.PageNumber
      );
      if (pageInfo.TotalRecords === 0) {
        setRecordStart(0);
      } else {
        if (pageInfo.PageLimit > pageInfo.TotalRecords) {
          setRecordStart(pageInfo.PageNumber);
        } else {
          setRecordStart(
            pageInfo.PageLimit * pageInfo.PageNumber - pageInfo.PageLimit + 1
          );
        }
      }
    }
  }

  async function CallService(tabs, pageNumber) {
    setinit(false);
    setLoading(true);
    var apiPath = "";
    tabs = filterService(tabs);
    switch (tabs) {
      case "1":
        apiPath = user.isStaff ? STAFF_GET_GROUP_BOOKING : AGENT_GROUP_BOOKING;
        break;
      case "2":
        apiPath = user.isStaff ? STAFF_GET_TODAY_ARRIVAL : AGENT_TODAY_ARRIVAL;
        break;
      case "3":
        apiPath = user.isStaff ? STAFF_GET_UNPAID : AGENT_UNPAID;
        break;
      case "4":
        apiPath = user.isStaff ? STAFF_GET_PAID : AGENT_PAID;
        break;
      case "5":
        apiPath = user.isStaff
          ? STAFF_GET_CANCEL_BOOKING
          : AGENT_CANCEL_BOOKING;
        break;
      case "filter":
        apiPath = user.isStaff ? STAFF_FILTER : FILTER;
        break;
      case "default":
        apiPath = "";
        break;
    }
    if (apiPath !== "") {
      let requestOptions = {};
      if (tabs === "filter") {
        requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "EN",
          },
          body: JSON.stringify({
            PageNumber: pageNumber,
            PageLimit: pageLimit,
            ContractId: userInfo.userId,
            Keyword: filterData.Keyword,
            VisitDate: filterData.VisitDate,
            VisitTime: filterData.VisitTime,
            ExpiredDate: filterData.ExpiredDate,
            ExpiredTime: filterData.ExpiredTime,
            PaidStatus: filterData.PaidStatus,
            BookingStatus: filterData.BookingStatus,
            SendEmail: filterData.SendEmail,
          }),
        };
      } else {
        requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "EN",
          },
          body: JSON.stringify({
            PageNumber: pageNumber,
            PageLimit: pageLimit,
            ContractId: userInfo.userId,
          }),
        };
      }
      console.log(userInfo);
      console.log("Call " + apiPath);
      console.log(requestOptions);
      setTimeout(() => {
        fetch(apiLink_ecom + apiPath, requestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            // setCheckedState(new Array(data["Data"].length).fill(false));
            setCheckAll(false);
            setTableBody(data["Data"]);
            setPageInfo(data["PageInfo"]);
            setLoading(false);
          })
          .catch((error) => {
            console.log("Error: ", error);
            alert("Error: " + apiPath, error);
            setLoading(false);
            return false;
          });
      }, 1000);
    } else {
      console.log("Not found Api Path");
    }
  }

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    //if Tab === today arrival default date today and disable datepicker
    console.log("ACTIVE TAB", value);
    if (value === "2") {
      setIsTodayTab(true);
    } else {
      setIsTodayTab(false);
    }
  }, [value]);

  useEffect(() => {}, [checkAll]);

  const handleChange = async (event, newValue) => {
    console.log("handleChange" + value);
    console.log("handleChange" + newValue);
    await setTableBody(null);
    setValue(newValue);
    await CallService(newValue, 1);
  };
  const textStyle = {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "normal",
    textTransform: "capitalize",
    marginRight: "20px",
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0e315c",
        fontWeight: "bold",
      },
    },
  });

  // ภาษา
  const { t } = useTranslation();

  const handlePage = async (page) => {
    setLoading(true);
    setTableBody();
    let currentPage = 1;
    if (page === "1") {
      currentPage = pageInfo.PageNumber + 1;
    } else {
      currentPage = pageInfo.PageNumber - 1;
    }
    await CallService(value, currentPage);
    setLoading(false);
  };

  const filterService = (tabs) => {
    console.log("tabs >>" , tabs);
    console.log("filterData >>" , filterData);
    console.log("filterData >>" , filterData.Keyword !== "" ||
    filterData.VisitDate !== "" ||
    filterData.VisitTime !== "" ||
    filterData.ExpiredDate !== "" ||
    filterData.ExpiredTime !== "" ||
    filterData.PaidStatus !== "" ||
    filterData.BookingStatus !== "" ||
    filterData.SendEmail !== "");
    if (
      filterData.Keyword !== "" ||
      filterData.VisitDate !== "" ||
      filterData.VisitTime !== "" ||
      filterData.ExpiredDate !== "" ||
      filterData.ExpiredTime !== "" ||
      filterData.PaidStatus !== "" ||
      filterData.BookingStatus !== "" ||
      filterData.SendEmail !== ""
    ){
      return "filter";
    } else {
      if(tabs === "filter"){
        return value;
      } else {
        return tabs;
      }
    }
  }

  useEffect(() => {
    if (dataModal != undefined && dataModal != null) {
      setOpenPaymentHistoryModal(true);
    }
  }, [dataModal]);

  const handleCloseOrderInformationModal = () => {
    setOrderInformationModal(false);
    setOrderNo(null);
  };

  useEffect(() => {
    console.log("orderNo " + orderNo);
    if (orderNo != null) {
      setOrderInformationModal(true);
    }
  }, [orderNo]);

  useEffect(() => {
    if (orderInfo != undefined && orderInfo != null) {
      setOrderInformationModal(true);
    }
  }, [orderInfo]);

  const setCloseFilterModal = () => {
    setOpenFilter(false);
  };

  return (
    <div className="content">
      <div className="order-manage">
        <div className="mb-3">
          <Box sx={{ width: "100%", typography: "body1", height: "inherit" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 0,
                  borderColor: "divider",
                }}
              >
                <ThemeProvider theme={theme}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#0e315c",
                        height: "4px",
                      },
                    }}
                    sx={{ color: "#0e315c" }}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    className="tablist-custom"
                  >
                    {heading.map((row) => (
                      <Tab
                        label={t(row.name)}
                        value={row.tabIndex}
                        wrapped
                        sx={textStyle}
                        onClick={() => {}}
                      />
                    ))}
                    <Button
                      className="btn_filter text-capitalize btn-shadow"
                      onClick={() => {
                        setOpenFilter(!openFilter);
                      }}
                    >
                      <span>
                        <Filter />
                      </span>
                      {t("filter")}
                    </Button>
                    <div className="headerDivider1" />
                    <div className="page-info">
                      {pageInfo == null ? "" : recordStart + " - " + recordEnd}
                    </div>
                    <div className="page-info"> {t("of")} </div>
                    <div className="page-info">
                      {pageInfo == null ? "" : pageInfo.TotalRecords}
                    </div>
                    <div className="page-control-arrow">
                      <button
                        onClick={async () => {
                          if (recordStart != 1 && !loading) {
                            if (recordStart != 0) {
                              await handlePage("-1");
                            }
                          }
                        }}
                      >
                        <i class="fa fa-caret-left" aria-hidden="true"></i>
                      </button>
                      <button
                        onClick={async () => {
                          if (recordEnd != pageInfo.TotalRecords && !loading) {
                            await handlePage("1");
                          }
                        }}
                      >
                        <i class="fa fa-caret-right" aria-hidden="true"></i>
                      </button>
                    </div>
                  </TabList>
                </ThemeProvider>
              </Box>
              {loading == false && tableBody != null ? (
                <BookingTab
                  tableBody={tableBody}
                  index={value}
                  setDataModal={setDataModal}
                  checkAll={checkAll}
                  setCheckAll={setCheckAll}
                  setSelectBooking={setSelectBooking}
                  setOrderNo={setOrderNo}
                  clearCheck={clearCheck}
                  setClearCheck={setClearCheck}
                />
              ) : (
                <Backdrop
                  sx={{ color: "#fff", zIndex: "1" }}
                  style={{ zIndex: 1100 }}
                  open
                >
                  <div
                    class="spinner-border"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
                  ></div>
                </Backdrop>
              )}
            </TabContext>
          </Box>
        </div>
      </div>
      {openPaymentHistoryModal && dataModal != undefined && (
        <PaymentHistoryModal
          open={openPaymentHistoryModal}
          setClose={handleClose}
          orderId={dataModal.OrderId}
        />
      )}
      {openOrderInformationModal && (
        <div>
          {!userInfo.isStaff ? (
            <OrderInfoModalAgent
              open={openOrderInformationModal}
              setClose={handleCloseOrderInformationModal}
              orderNo={orderNo}
            />
          ) : (
            <OrderInfoModalStaff
              open={openOrderInformationModal}
              setClose={handleCloseOrderInformationModal}
              orderNo={orderNo}
            />
          )}
        </div>
      )}
      {/* {openOrderInformationModal && (
        <OrderInformationModal
          open={openOrderInformationModal}
          setClose={handleCloseOrderInformationModal}
          orderNo={orderNo}
        />
      )} */}
      <FilterModal
        open={openFilter}
        setClose={setCloseFilterModal}
        service={CallService}
        data={filterData}
        setData={setfilterData}
        isTodayTab={isTodayTab}
        language={language}
      />
    </div>
  );
};
