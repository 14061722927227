import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';

//Components
import GGLogin from '../component/GGLogin';
// import FBLogin from '../component/FBLogin';
import StepBar from '../component/Ticket/StepBar';
import showTerm from '../customHook/showTerm';
import { Markup } from 'interweave';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../component/img/close.svg';
import { ReactComponent as Vector } from '../component/img/vector.svg';
import Button from 'react-bootstrap/Button';
import logo from '../component/img/logo-xl.png';

import useLogin from '../customHook/useLogin';
import useRegister from '../customHook/useRegister';
import { baseLink, clientId, apiLink, apiLink_ecom } from '../constants/DataPublic';
import { useGlobalState } from '../global'

const GuestCheckout = (props) => {
  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);

  let { getUserCart } = useLogin({ lang: language });
  let { countryPhone, getCountryPhone } = useRegister();
  let { open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm } = showTerm();
  const { language } = props;

  const [routePath, setRoutePath] = useGlobalState('route');
  const [packageCode, setPackageCode] = useState('');
  const [currentStep, setCurrentStep] = useState(5);
  const [itemGroup, setItemGroup] = useState([]);
  const [gMessage, setGMessage] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [uMessage, setUMessage] = useState('');

  const [guestInfo, setGuestInfo] = useState({
    fName: '',
    lName: '',
    idCardNo: '',
    email: '',
    photo: '',
    telCode: '',
    phoneNo: '',
    userId: '',
    userCode: '',
    type: 'guest',
    isLogin: false,
    confirmationNo: '',
    channelCode: '',
    guestDone: false,
  });

  const [requireFname, setRequireFname] = useState('');
  const [requireLname, setRequireLname] = useState('');
  const [requireID, setRequireID] = useState('');
  const [requireEmail, setRequireEmail] = useState('');
  const [requireTel, setRequireTel] = useState('');

  const navigate = useNavigate();

  // ภาษา
  const { t } = useTranslation();

  useEffect(() => {
    if (myCart !== null && myCart.length > 0) {
      getPackageCard();
      getCountryPhone();
      guestInfo.telCode = 66;
    } else navigate('/');
  }, []);

  const getPackageCard = async () => {
    const res = await fetch(apiLink + '/afcl/Package/Ticket');
    res
      .json()
      .then((res) => {
        let packageTypeList = res['Result']['PackageSteps'];
        packageTypeList = packageTypeList.sort((a, b) => (a.StepId > b.StepId ? 1 : -1));
        setItemGroup(packageTypeList);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Ticket/ ', error)
      });
  };

  const confirmGuest = (e) => {
    e.preventDefault();

    if (guestInfo.fName === '') {
      setRequireFname('warn_firstname');
    } else setRequireFname('');

    if (guestInfo.lName === '') {
      setRequireLname('warn_lastname');
    } else setRequireLname('');

    // if (guestInfo.idCardNo === '') {
    //   setRequireID('warn_id');
    // } else setRequireID('');

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const validMail = regex.test(guestInfo.email);
    if (guestInfo.email === '') {
      setRequireEmail('warn_email');
    } else if (!validMail) {
      setRequireEmail('invalid_email');
    } else setRequireEmail('');

    if (guestInfo.phoneNo === '') {
      setRequireTel('warn_phone');
    } else setRequireTel('');

    // && guestInfo.idCardNo
    if (guestInfo.fName != '' && guestInfo.lName != '' != '' && guestInfo.email != '' && validMail && guestInfo.phoneNo != '') {
      visitorRegister();
    }
  };

  async function visitorRegister() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept-language': language },
      body: JSON.stringify({
        Email: guestInfo.email,
        FirstName: guestInfo.fName,
        LastName: guestInfo.lName,
        PhoneNo: guestInfo.phoneNo,
        IDCardNo: guestInfo.idCardNo,
        CountryId: guestInfo.telCode,
        DeviceType: '',
        PushKey: '',
        DeviceKey: '',
      }),
    };


    await fetch(apiLink_ecom + '/afcl/Account/visitor/register', requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        if (items['Success'] === true) {
          guestInfo.userCode = items['Result']['User'].UserCode;
          guestInfo.userId = items['Result']['User'].UserId;
          guestInfo.channelCode = items['Result']['ChannelCode'];
          sessionStorage.setItem('UserLogin', JSON.stringify(guestInfo));
          navigate('/ticket/' + myCart[0].packageCode + '/5');
        } else {
          setGMessage(items['Message']);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        alert('Error: API /afcl/Account/visitor/register ', error)
      });
  }

  const cursorFocus = function (elem) {
    var x = window.scrollX,
      y = window.scrollY;
    setTimeout(function () {
      window.scrollTo(x, y);
    }, 100);
  };

  const checkpassword = async () => {
    console.log('Check Password');
    if (email === '' || password === '') {
      setUMessage('warn_email_pass');
      if (email === '') {
        cursorFocus(document.getElementById('signinEmail'));
      } else if (password === '') {
        cursorFocus(document.getElementById('signinPass'));
      }
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept-language': language },
        body: JSON.stringify({
          Email: email,
          Password: password,
        }),
      };
      console.log(requestOptions);
      await fetch(apiLink_ecom + '/afcl/Account/checkpassword', requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          const items = data;
          console.log(items);
          if (items['Success']) {
            let user = {
              fName: items['Result']['User'].FirstName,
              lName: items['Result']['User'].LastName,
              email: items['Result']['User'].Email,
              userId: items['Result']['User'].UserId,
              userCode: items['Result']['User'].UserCode,
              photo: items['Result']['User'].UserPicture,
              isLogin: true,
              channelCode: items['Result']['ChannelCode'],
              confirmationNo: '',
              idCardNo: '',
              phoneNo: '',
              type: 'user',
              guestDone: false,
            };
            sessionStorage.setItem('UserLogin', JSON.stringify(user));
            getUserCart(items['Result']['User'].UserCode, language).then(function () {
              navigate('/ticket/' + myCart[0].PackageCode + '/5');
            });
          } else {
            setUMessage(items['Message']);
            cursorFocus(document.getElementById('signinEmail'));
          }
        })
        .catch((error) => {
          console.log('Error: ', error);
          alert('Error: API /afcl/Account/checkpassword ', error)
        });
    }
  };

  // Login

  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [needRegist, setNeedRegist] = useState(false);

  const getGGUser = (ggUser) => {
    setGGUser(ggUser);
    props.getGGUser(ggUser);
  };

  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
    props.getFBUser(fbUser);
  };

  const getNeedRegist = (needRegist) => {
    setNeedRegist(needRegist);
  };

  useEffect(() => {
    if (needRegist) {
      navigate('/signup');
    }
  }, [needRegist]);

  // Login with Google
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  useEffect(() => {
    if (currentStep != 5 && myCart.length > 0) {
      navigate('/ticket/' + myCart[0].PackageCode + '/' + currentStep);
    }
  }, [currentStep]);

  const setUserCode = (userCode) => {
    props.setUserCode(userCode);
  };

  const onNumerPWKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key != 'Backspace') {
      event.preventDefault();
    }
  };

  return (
    <div className="page page-steps" style={{ minHeight: 'calc(100vh - 183px)' }}>
      {/* {itemGroup.length > 0 && (
        <header className="sticky-top">
          <StepBar itemGroup={itemGroup} currentStep={currentStep} setCurrentStep={setCurrentStep}/>
        </header>
      )} */}
      <div className="main ">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 py-5" style={{ margin: '0 auto' }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                {/* Start form lefr */}

                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12">
                  <div className="border-right-separator py-5">
                    <h2 className="font-30 text-uppercase mb-1 lh-1">{t('guest_checkout')}</h2>
                    <p className="color-grey mb-4">{t('proceed_checkout')}</p>

                    <form>
                      <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                        <input type="text" className="form-control" minLength="3" name="booking_guest_fname" placeholder={t('firstname') + '*'} onChange={(e) => (guestInfo.fName = e.target.value)} />
                        <span className="text-danger">{t(requireFname)}</span>
                      </div>

                      <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                        <input type="text" className="form-control" minLength="3" name="booking_guest_lname" placeholder={t('lastname') + '*'} onChange={(e) => (guestInfo.lName = e.target.value)} />
                        <span className="text-danger">{t(requireLname)}</span>
                      </div>

                      {/* <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                        <input
                          maxLength="20"
                          type="text"
                          id="idCard"
                          className="form-control"
                          pattern="[0-9]*"
                          title="ID Should contain numbers only"
                          name="booking_guest_identifier"
                          placeholder={t('id_number') + '*'}
                          onChange={(e) => (guestInfo.idCardNo = e.target.value)}
                        />
                        <span className="text-danger">{t(requireID)}</span>
                      </div> */}

                      <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                        <input type="email" id="email" className="form-control" name="booking_guest_email" placeholder={t('email') + '*'} onChange={(e) => (guestInfo.email = e.target.value)} />
                        <span className="text-danger">{t(requireEmail)}</span>
                      </div>

                      <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-3 pb-3 pb-lg-0">
                            <select className="form-control" id="telCode" defaultValue={guestInfo.telCode} onChange={(e) => (guestInfo.telCode = e.target.value)}>
                              <option key="0" value="0">
                                -
                              </option>
                              {countryPhone.map((e, key) => {
                                return (
                                  <option key={e.CountryId} value={e.CountryId} selected={guestInfo.telCode == e.CountryId}>
                                    {e.PhoneCode}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="col-9">
                            <input
                              type="tel"
                              id="phoneNo"
                              className="form-control"
                              title="Mobile Number should contain numbers only"
                              name="booking_guest_phone"
                              placeholder={t('phone_number') + '*'}
                              maxLength="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => (guestInfo.phoneNo = e.target.value)}
                            />
                          </div>
                          <span className="text-danger">{t(requireTel)}</span>
                        </div>
                        <span className="text-danger">{gMessage}</span>
                      </div>

                      <div className="mb-3 row col-xl-11 col-lg-11 col-md-12 col-sm-12 align-items-center">
                        <div className="mb-3 col-xl-5 col-lg-5 col-md-12 col-sm-12 pb-3 pb-lg-0">
                          <p className="color-grey font-10 lh-1 mb-1">{'*' + t('indicate_required')}</p>
                          <p className="color-grey font-10 lh-1 mb-0">{t('real_email')}</p>
                        </div>

                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-3">
                          <button type="button" className="btn btn-blue" onClick={confirmGuest}>
                            {t('continue_guest')}
                          </button>
                        </div>
                      </div>

                      <div className="d-none d-lg-block">
                        <hr />
                        <h6 className="font-20 weight-800 mb-0">{t('need_help')}</h6>
                        <p className="font-14 weight-600">
                          {t('send_us')}
                          {/* <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&online@columbiapicturesaquaverse.com.com&body=I would like learn more about check out . Could you please send me more details at the earliest?"> */}
                          {/* <a href="mailto:online@columbiapicturesaquaverse.com&subject=test subject&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest?" target="_blank"> */}
                          <a href="mailto:online@columbiapicturesaquaverse.com?subject=Guest checkout inquiry letter&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest? %0D%0A Thank you for your prompt response. %0A%0A Yours sincerely," target="_blank">
                            {/* <a href="mailto:online@columbiapicturesaquaverse.com"> */}
                            {t('enquiry')}
                          </a>
                          &nbsp;{t('or_callus')}
                          <a href="tel:+6633004999">+66(0)33 004 999</a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>

                {/* End form lefr */}

                <div className="d-block d-lg-none">
                  <div className="mb-3 row align-items-center">
                    <div className="col-lg-6 relative">
                      <hr />
                      <span className="orbig bg-white ">or</span>
                    </div>
                  </div>
                </div>

                {/* Start form right */}
                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12">
                  <div className="align-items-stretch py-5">
                    <div className="ms-auto returncustomer">
                      <h2 className="font-30 text-uppercase mb-1 lh-1">{t('returning_customer')}</h2>
                      <p className="color-grey mb-4">{t('welcome_back')}</p>
                      <div className="mb-3 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <input id="signinEmail" type="email" className="form-control" name="email" placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
                      </div>

                      <div className="mb-3 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <input
                          type="password"
                          onKeyPress={onNumerPWKeyDown}
                          onChange={(e) => setPassword(e.target.value.replace(/\D/g, ''))}
                          className="form-control"
                          placeholder={t('password')}
                          maxLength={6}
                          inputMode="numeric"
                          pattern="[0-9]*"
                        />
                        <span className="text-danger">{t(uMessage)}</span>
                      </div>

                      <div className="mb-3 col-xl-9 col-lg-9 col-md-12 col-sm-12 align-items-center">
                        <a href="#">
                          <button type="button" className="btn btn-blue mb-2" onClick={checkpassword}>
                            {t('signin')}
                          </button>
                        </a>
                        <div style={{ display: 'flex' }}>
                          <p className="mb-0" style={{ width: '50%' }}>
                            <a href={baseLink + routePath + '/forget'} className="font-10 weight-600">
                              {t('forgot_password')}
                            </a>
                          </p>
                          <p className="mb-0" style={{ width: '50%', textAlign: 'right' }} >
                            <a href='#' className="font-10 weight-600" onClick={() => getTerms(language)}>
                              {t('account_create')}
                            </a>
                          </p>
                          <Modal show={open} className="col-12 signinpage popupterm">
                            <div style={{ textAlign: 'right' }}>
                              <Close className="popup-close" onClick={onCloseModal} />
                            </div>
                            <div className="logosignin">
                              <img src={logo} id="logosignin" />
                            </div>

                            <h5 className="text-center mb-5">{subject}</h5>
                            <Markup content={" " + terms} />
                            <div className="text-center mb-5">
                              <input type="checkbox" className="form-check-input" id="vehicle1" name="vehicle1" onChange={(e) => setAgree(e.target.checked)} />
                              <label hntmlFor="vehicle1" class="form-check-label" style={{ padding: '.2rem 0 0 1rem' }}>
                                <u>{t('i_agree')}</u>
                              </label>
                            </div>
                            <div className="col-5" style={{ margin: '0 auto', paddingTop: '1em' }}>
                              <Button type="button" className="btn btn-link btn-db text-capitalize" onClick={onConfirm} disabled={!agree}>
                                {t('confirm')}
                              </Button>
                            </div>
                          </Modal>
                        </div>

                      </div>

                      <div className="mb-3 col-xl-9 col-lg-9 col-md-12 col-sm-12 row align-items-center">
                        <div className="relative">
                          <hr />
                          <span className="or bg-white">{t('or')}</span>
                        </div>
                      </div>

                      <div className="mb-3 row align-items-center">
                        <div className="col-lg-9 col-md-12">
                          {/* <div className="mb-3 gustloginFB">
                            <FBLogin language={language} fbtext={t('facebook')} getFBUser={getFBUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />
                          </div> */}
                          <div className="mb-5 gustloginGG">
                            <GGLogin language={language} ggtext={t('google')} getGGUser={getGGUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End form right */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuestCheckout;
