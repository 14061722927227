import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

//components
import ReactLoading from "../../component/Loading";
import {
  UPLOAD_SLIP,
  VIEW_SLIP,
  UPDATE_SLIP,
  DELETE_SLIP,
  SLIP_PAYMENT_TYPE,
} from "../../services/BASE_API";
import { apiLink, apiLink_ecom } from "../../constants/DataPublic";
import { Markup } from "interweave";
import { ReactComponent as UploadImage } from "../../component/icons/upload_image.svg";
import { ReactComponent as Upload } from "../../component/icons/upload.svg";
import { ReactComponent as View } from "../../component/icons/view.svg";
import errorImage from "../../component/img/notfound.png";
import { Button, Modal } from "react-bootstrap";

import ModalSuccess from "../../component/Modal/ModalSuccess";
import ModalViewPicture from "../../component/Modal/ModalViewPicture";
import { UploadSlip } from "../../component/Modal/UploadSlip";
import Backdrop from "@mui/material/Backdrop";
import { ConfirmModal } from "../../component/Modal/ConfirmModal";
import ModalFailed from "../../component/Modal/ModalFailed";

const ViewSlip = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [selectedBooking, setSelectBooking] = useState();
  const [loading, setLoading] = useState(true);

  const [orderInfo, setOrderInfo] = useState();
  const [uploading, setUpload] = useState(false);

  const [imageURLs, setImageURLs] = useState(""); // For Send To View Modal
  const [base64Image, setImageBase64] = useState("");

  const [openModal, setOpenModal] = useState(false); //Success Modal
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [modalIsOpen, setModalIsOpen] = useState(false); //View Picture Modal
  const [openUploadModal, setOpenUploadModal] = useState(false); //Upload Slip Modal
  const [openConfirmModal, setOpenConfirmModal] = useState(false); //Confirm Delete Modal
  const [slipInfo, setSlipInfo] = useState(); //Store Select Slip
  const [id, setId] = useState(""); //Set img id for delete
  const [aleart, setAleart] = useState("");
  const [isUpdate, setisUpdate] = useState(false);
  const [enableUpload, setEnableUpload] = useState(false);
  const [paymentType, setPaymentType] = useState([]); // For Payment Type [Transferbanking, ...
  const [selectPayment, setSelectPayment] = useState("");

  const [imageError, setImageError] = useState(false);

  let user = JSON.parse(sessionStorage.getItem("UserLogin"));

  const { t, i18n } = useTranslation();

  useEffect(async () => {
    // console.log("Image", imageURLs);
    setSelectBooking(state.selectedBooking);
  }, []);

  useEffect(() => {
    if (selectedBooking != null) {
      callService(selectedBooking);

      if(user.isStaff){
        getPaymentType();
      }
    }
  }, [selectedBooking]);

  useEffect(() => {
    if (slipInfo != null) {
      setOpenUploadModal(true);
    }
  }, [slipInfo]);

  useEffect(() => {
    if (openUploadModal) {
      setSlipInfo({
        TotalAmount: orderInfo.TotalAmount.toFixed(2),
        TotalPaid: orderInfo.TotalPaid.toFixed(2),
      });
    }
  }, [openUploadModal]);

  useEffect(()=>{
    if(base64Image != ""){
      setEnableUpload(true);
    }else{
      setEnableUpload(false);
    }
  },[base64Image])

  // useEffect(() => {
  //   console.log("Payment Type", paymentType);
  // }, [paymentType]);

  const setModalColse = () => {
    setOpenModal(false);
  };

  const backToOrderPage = () => {
    setOpenModal(false);
    navigate("/order");
  };

  const setClosePictureModal = () => {
    setModalIsOpen(false);
  };

  const setCloseUploadModal = () => {
    setOpenUploadModal(false);
    setisUpdate(false);
  };

  const setCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
  };

  async function callService(id) {
    setImageError(false);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + VIEW_SLIP + "?OrderId=" + id, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          var item = data["Result"];
          // console.log(item.TagHtml);
          if (item == null) {
            alert("Not Found");
            navigate("/order");
          }
          setOrderInfo(item);
          setLoading(false);
          return true;
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + VIEW_SLIP, error);
          return false;
        });
    }, 1000);
  }

  async function onImageChange(e) {
    // const reader = new FileReader();
    // reader.readAsDataURL(...e.target.files);
    // reader.onload = async () => {
    //   const base64String = reader.result;
    //   setImageBase64(base64String);
    // };

    if (!e.target.files || e.target.files.length === 0) {
      setImageBase64("");
      return;
    }
  
    const reader = new FileReader();
    const file = e.target.files[0]; // Access the first file
  
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const base64String = reader.result;
      setImageBase64(base64String);
    };
  }

  async function callUploadService(id, amount, remark) {
    if (base64Image == null || base64Image == "") {
      return false;
    }
    setUpload(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify({
        OrderId: id,
        Picture: base64Image,
        Amount: amount,
        Remark: remark,
      }),
    };
    console.log("API Request", requestOptions.body);
    setTimeout(() => {
      fetch(apiLink_ecom + UPLOAD_SLIP, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data["Success"]) {
            let items = data["Result"];
            console.log(UPLOAD_SLIP, items);
            setCloseUploadModal();
            if (user.isStaff) {
              callUpdateService(items.Id, amount, user.staffId, selectPayment.value);
            } else {
              callService(selectedBooking);
              //setImageURLs(items.Picture);
              setUpload(false);
              setOpenModal(true);
              setAleart("upload_success");
            }
            return true;
          } else {
            setCloseUploadModal();
            setUpload(false);
            setOpenFailedModal(true);
            setAleart("upload_failed");
            return true;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Error: " + UPLOAD_SLIP, error);
          return false;
        });
    }, 1000);
  }

  async function callUpdateService(id, amount, staffId, paymentType) {
    setUpload(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify({
        Id: id,
        Amount: amount,
        StaffId: staffId,
        ChannelTypeCode: paymentType,
      }),
    };
    console.log("API Request", requestOptions);
    setTimeout(() => {
      fetch(apiLink_ecom + UPDATE_SLIP, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data["Success"]) {
            let items = data["Result"];
            console.log(UPLOAD_SLIP, items);
            setCloseUploadModal();
            callService(selectedBooking);
            //setImageURLs(items.Picture);
            setUpload(false);
            setOpenModal(true);
            setAleart("update_success");
            callService(selectedBooking);
            return true;
          } else {
            setCloseUploadModal();
            setUpload(false);
            setOpenFailedModal(true);
            // setAleart("update_failed");
            setAleart(data["Message"]);
            return true;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Error: " + UPDATE_SLIP, error);
          return false;
        });
    }, 1000);
  }

  async function callDeleteService(id) {
    setUpload(true);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + DELETE_SLIP + "?OrderId=" + id, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data["Success"]) {
            var item = data["Result"];
            // console.log(item.TagHtml);
            if (item == null) {
              alert("Not Found");
              navigate("/order");
            }
            callService(selectedBooking);
            // setOrderInfo(item);
            setUpload(false);
            setOpenModal(true);
            setImageBase64("");
            setAleart("remove_success");
          } else {
            setUpload(false);
            setOpenFailedModal(true);
            setAleart("remove_failed");
          }
          return true;
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + DELETE_SLIP, error);
          return false;
        });
    }, 1000);
  }

  async function getPaymentType() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + SLIP_PAYMENT_TYPE, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data["Success"]) {
            var item = data["Result"];
            console.log(item);
            item.map((item) => {
              let payment= {
                value: item.ChannelTypeCode,
                label: item.ChannelTypeName,
              }
              setPaymentType((paymentTypeList) => [...paymentTypeList, payment]);
            });
          } else {
            alert(data["Message"]);
          }
          return true;
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + SLIP_PAYMENT_TYPE, error);
          return false;
        });
    }, 1000);
  }

  // Remove Slip
  const onConfirm = () => {
    if (id != "") {
      callDeleteService(id);
      setCloseConfirmModal();
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {loading == true ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      ) : (
        <div className="page page-steps">
          <div class="d-flex justify-content-center height-full">
            <div class="pt done" style={{ width: "80%", marginTop: "5%" }}>
              <div className="order-details-table">
                {orderInfo && <Markup content={" " + orderInfo.TagHtml} />}
              </div>

              <div className="upload-slip" style={{ margin: "auto" }}>
                <div className="imageupload-head">{t("transfer_slip")}</div>
                {uploading == false ? (
                  <div
                    className="image-grid-container"
                    style={
                      orderInfo.SlipList.length <= 3
                        ? { justifyContent: "center" }
                        : {}
                    }
                  >
                    {orderInfo.SlipList.length == 0 ? (
                      <label className="upload-button">
                        <UploadImage
                          className="upload-image"
                          style={
                            orderInfo.TotalAmount - orderInfo.TotalPaid == 0
                              ? { cursor: "auto" }
                              : { cursor: "pointer" }
                          }
                          onClick={() => {
                            if (
                              orderInfo.TotalAmount - orderInfo.TotalPaid !=
                              0
                            ) {
                              setOpenUploadModal(true);
                            }
                          }}
                        />
                      </label>
                    ) : (
                      orderInfo &&
                      orderInfo.SlipList.map((img, index) => {
                        return (
                          <div className="image-grid-item">
                            <div
                              className="imageupload-body"
                              // style={
                              //   orderInfo.SlipList.length = 3
                              //     ? { justifyContent: "center" }
                              //     : {}
                              // }
                            >
                              <div
                                className="row"
                                style={{ flexWrap: "nowrap" }}
                              >
                                <div className="col">
                                  <div className="row">
                                    <div className="col">
                                      <div className="imageupload-footer">
                                        {orderInfo.SlipList.length > 0 && (
                                          <>
                                            {user.isStaff && (
                                              <>
                                                <div
                                                  style={{ width: "10px" }}
                                                ></div>
                                                <Button
                                                  className="btn-view-order"
                                                  style={{
                                                    backgroundColor: "#0e315c",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    setSlipInfo({
                                                      Slip: img,
                                                      TotalAmount:
                                                        orderInfo.TotalAmount,
                                                      TotalPaid:
                                                        orderInfo.TotalPaid,
                                                    });
                                                    setisUpdate(true);
                                                  }}
                                                  // hidden ={img.IsFinish}
                                                  // disabled={
                                                  //   img.IsFinish
                                                  //   // orderInfo.SlipList.length ==
                                                  //   //   0 || imageError
                                                  //   //   ? true
                                                  //   //   : false
                                                  // }
                                                >
                                                  <span
                                                    style={{
                                                      color: "white",
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {t("update_payment_status")}
                                                  </span>
                                                </Button>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div
                                      className="col"
                                      style={{ position: "relative" }}
                                    >
                                      {orderInfo.SlipList.length > 0 &&
                                        !user.isStaff && (
                                          <button
                                            type="button"
                                            class="imageupload-head-button btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            disabled={img.IsFinish}
                                            hidden={img.IsFinish}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                              setOpenConfirmModal(true);
                                              setId(img.Id);
                                            }}
                                          ></button>
                                        )}
                                      <img
                                        className="slip-image"
                                        key={img.Id}
                                        // width="260"
                                        height="420"
                                        src={img.SlipImage}
                                        onError={(e) => {
                                          e.target.src = errorImage;
                                          e.target.style =
                                            "padding: 10px; margin: 16px";
                                          //setImageError(true);
                                        }}
                                        onClick={() => {
                                          setImageURLs(img.SlipImage);
                                          setModalIsOpen(true);
                                          console.log(
                                            "Length",
                                            orderInfo.SlipList.length
                                          );
                                        }}
                                        style={{ cursor: "zoom-in" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                ) : (
                  <Backdrop
                    sx={{ color: "#fff", zIndex: "1" }}
                    style={{ zIndex: 1100 }}
                    open
                  >
                    <div
                      class="spinner-border"
                      role="status"
                      aria-hidden="true"
                      style={{
                        color: "#0e315c",
                        width: "3rem",
                        height: "3rem",
                      }}
                    ></div>
                  </Backdrop>
                )}
                <div className="upload-slip-footer">
                  <Button
                    className="btn_upload_order"
                    style={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      width: "fit-content",
                      borderColor: "#0e315c",
                    }}
                    disabled={
                      orderInfo.TotalAmount - orderInfo.TotalPaid <= 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      setOpenUploadModal(true);
                    }}
                  >
                    <Upload />
                    <span>{t("upload")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {imageURLs != null && (
            <ModalViewPicture
              open={modalIsOpen}
              image={imageURLs}
              setClose={setClosePictureModal}
            />
          )}

          <ModalSuccess
            open={openModal}
            body={t(aleart)}
            setClose={setModalColse}
            //back={backToOrderPage}
            back={setModalColse}
          />

          <ModalFailed
            open={openFailedModal}
            body={t(aleart)}
            setClose={setCloseFailedModal}
            //back={backToOrderPage}
            back={setCloseFailedModal}
          />

          {loading != true && openUploadModal &&(
            <UploadSlip
              orderId={selectedBooking}
              open={openUploadModal}
              setClose={setCloseUploadModal}
              onImageChange={onImageChange}
              enableUpload={enableUpload}
              callServices={callUploadService}
              slipInfo={slipInfo}
              callUpdateServices={callUpdateService}
              isUpdate={isUpdate}
              paymentType={paymentType}
              selectPayment={selectPayment}
              setSelectPayment={setSelectPayment}
            />
          )}

          <ConfirmModal
            open={openConfirmModal}
            setClose={setCloseConfirmModal}
            message={t("confirm_remove_slip")}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </div>
  );
};

export default ViewSlip;
