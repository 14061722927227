import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import React, { useEffect, useState } from "react";

import {
  horizontalCardDiscount,
  verticalCardDetail,
  verticalCardDiscount,
  verticalCardStart,
} from "../../constants/DataPublic";
import { PackageCard } from "./PackageCard";
import { PackageCardBuyFlip } from "./PackageCardBuyFlip";
import { PackageCardD } from "./PackageCardD";
import { PackageCardH } from "./PackageCardH";
import { t } from "i18next";

export const PackageType = (props) => {
  const { packageType, language } = props;
  const { packageList } = props;
  const { index } = props;
  const { locationList, timeList, cabanaList } = props;

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);

  const [cart, setCart] = useState([]);
  const [typeStyle, setTypeStyle] = useState("section-2");
  const [slideShow, setSlideShow] = useState([]);

  useEffect(() => {
    if (packageList.length > 0) {
      if (
        verticalCardStart.indexOf(packageType.PackageTypeCode) > -1 ||
        verticalCardDiscount.indexOf(packageType.PackageTypeCode) > -1
      ) {
        if (packageList.length > 3) {
          setSlideShow([3, 3, 2]);
        } else setSlideShow([packageList.length, 3, 1]);
        setTypeStyle("section-hottest");
      } else if (verticalCardDetail.indexOf(packageType.PackageTypeCode) > -1) {
        // Extra
        if (packageList.length > 4) {
          setSlideShow([4, 3, 1]);
        } else setSlideShow([packageList.length, 3, 1]);
        setTypeStyle("section-3");
      } else if (
        horizontalCardDiscount.indexOf(packageType.PackageTypeCode) > -1
      ) {
        // Add-Ons
        setSlideShow([2, 2, 1]);
        setTypeStyle("section-3");
      } else {
        if (packageList.length > 3) {
          setSlideShow([3, 3, 2]);
        } else setSlideShow([packageList.length, 3, 1]);
        setTypeStyle("section-1");
      }

      if (myCart !== null) {
        setCart(myCart);
      }
    }
    // console.log('packageList', packageList)
  }, [packageList]);

  const settings = {
    className: "center",
    centermode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideShow[0],
    rows: slideShow[2],
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideShow[1],
          rows: slideShow[2],
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          rows: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const onAddToCart = (item, isEdit) => {
    let route = "Landing";
    sessionStorage.setItem("AgentRoute", JSON.stringify(route));

    myCart = sessionStorage.getItem("MyCart");
    myCart = JSON.parse(myCart);

    console.log("BeforeAddCart, ", myCart);

    let aIndex = -1;

    if (myCart != null) {
      aIndex = myCart.findIndex((e) => {
        return e.PackageCode === item.PackageCode;
      });
    } else {
      myCart = [];
    }
    console.log("aIndex", aIndex);
    if (aIndex === -1) {
      // เพิ่ม Cart
      if (item.Qty > 0) {
        myCart = [...myCart, item];
        setCart(myCart);
      }
    } else {
      // แก้ไข Cart
      let preQty = myCart[aIndex].Qty;
      // let newQty = preQty + 1;
      let newQty = preQty + item.Qty;
      console.log("แก้ไข cart", item);

      const editCart = myCart.map((e) => {
        if (
          e.PackageCode === item.PackageCode &&
          e.ItemCode === item.ItemCode
        ) {
          return {
            ...e,
            Qty: newQty,
            VisitDate: item.VisitDate,
            VisitLocation: item.VisitLocation,
            VisitTime: item.VisitTime,
            status: "normal",
          };
        }
        return e;
      });
      console.log("setCart >>>>>", editCart);
      sessionStorage.setItem("MyCart", JSON.stringify(editCart));
      setCart(editCart);
    }

    // }
  };

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem("MyCart", JSON.stringify(cart));
    // console.log("Mycart >>>>>", cart);
  }, [cart]);

  const getPackageSubTypeCode = (subTypeCode) => {
    props.getPackageSubTypeCode(subTypeCode);
  };
  return (
    <div className={`section ${typeStyle} py-5`}>
      <div className="container">
        {index == 0 ? (
          <h2 className="big-title mb-1 text-center">
            {packageType.PackageTypeName}
          </h2>
        ) : (
          <h2
            className="display-5 mb-1 text-center"
            style={{ fontWeight: "700" }}
          >
            {packageType.PackageTypeName}
          </h2>
        )}

        <p className="text-center mb-4 font-13" style={{ fontWeight: "bold" }}>
          {packageType.PackageTypeDescription}
        </p>
        {/* <p className="text-center mb-4 color-grey font-11">{t('ticket_expire')}</p> */}
        <div className="hottest-wrapper">
          <div className="row justify-content-center" id="slider_super_saver">
            {/* Slid Header */}
            {
              packageList.length > 0 &&
                // <Slider {...settings}>
                //   {
                packageList.map((e, index) => {
                  if (verticalCardStart.indexOf(packageType.PackageTypeCode) > -1 || verticalCardDiscount.indexOf(packageType.PackageTypeCode) > -1) {
                    if (index < 5) {
                      let picSize = index < 2 ? "landingSmall" : "landingLarge";
                      let sort = e.PackageItemLists.sort((a, b) =>
                        a.ItemType > b.ItemType ? -1 : 1
                      );
                      return (
                        <>
                          {e.VisitingDateItemCode == null &&
                          e.IsTransportationPackage == false ? (
                            <PackageCard
                              index={index}
                              isflip={true}
                              key={index}
                              packageCard={e}
                              pictureSize={picSize}
                              packageList={sort}
                              onAddToCart={onAddToCart}
                            />
                          ) : (
                            <PackageCardBuyFlip
                              index={index}
                              isflip={true}
                              key={index}
                              packageCard={e}
                              pictureSize={picSize}
                              packageList={sort}
                              onAddToCart={onAddToCart}
                              locationList={locationList}
                              timeList={timeList}
                              cabanaList={cabanaList}
                            />
                          )}
                        </>
                      );
                    }
                  } else if (horizontalCardDiscount.indexOf(packageType.PackageTypeCode) > -1 ) {
                    return; //<PackageCardH key={index} packageCard={e} onAddToCart={onAddToCart}/>
                  } else if (verticalCardDetail.indexOf(packageType.PackageTypeCode) > -1) {
                    if (index < 5) {
                      return; // <PackageCardD key={index} packageCard={e} onAddToCart={onAddToCart} getPackageSubTypeCode={getPackageSubTypeCode}/>
                    }
                  } else {
                    if (index < 5) {
                      let picSize = index < 2 ? "landingSmall" : "landingLarge";
                      let sort = e.PackageItemLists.sort((a, b) =>
                        a.ItemType > b.ItemType ? -1 : 1
                      );
                      return (
                        <>
                          {e.VisitingDateItemCode == null &&
                          e.IsTransportationPackage == false ? (
                            <PackageCard
                              index={index}
                              isflip={true}
                              key={index}
                              packageCard={e}
                              pictureSize={picSize}
                              PackageItemlength={e.PackageItemLists.length}
                              packageList={sort}
                              onAddToCart={onAddToCart}
                            />
                          ) : (
                            <PackageCardBuyFlip
                              index={index}
                              isflip={true}
                              key={index}
                              packageCard={e}
                              pictureSize={picSize}
                              packageList={sort}
                              onAddToCart={onAddToCart}
                              locationList={locationList}
                              timeList={timeList}
                              cabanaList={cabanaList}
                              language={language}
                            />
                          )}
                        </>
                      );
                    }
                  }
                })
              //     }
              // </Slider>
            }
            {/* Slid Header */}
          </div>
        </div>
      </div>
    </div>
  );
};
