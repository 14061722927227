import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiLink_ecom } from "../../constants/DataPublic";

import { SubTicket } from "./SubTicket";
import { SubTicketItem } from "./SubTicketItem";

import Pass from "../../component/img/pass.png";

import TermIcon from "../../component/img/term.png";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";

import SS1 from "../../component/img/ss-1.jpg";
import { useGlobalState } from "../../global";

import { TIME_LOOKUP, LOCATION_LOOKUP } from "../../services/BASE_API";

const Step1 = (props) => {
  const { packageTicket } = props;
  const { step } = props;
  const { currentStep } = props;
  const { packageCode } = props;
  const { cartUpdate, cabanaList, language, cart,fromBuyNow,onUpdateVisitDateAll } = props;
  const [packageSelected, setPackageSelected] = useState({});
  const [availableCabana, setAvailableCabana] = useState(null);

  const [availableCabanaLimit, setAvailableCabanaLimit] = useGlobalState("availableCabanaLimit");

  const [location, setLocation] = useState(null);
  const [timeList, setTimeList] = useState(null);

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);

  useEffect(() => {
    if (step == "1") {
      var dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "checkoutStep",
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: "" },
          },
        },
      });
      console.log("dataLayer step", dataLayer, step);
    }

    console.log("Step1: ", packageCode, currentStep, packageTicket);
    if (packageTicket.length > 0) {
      let sPackage = packageTicket.filter(
        (e) => e.PackageCode === packageCode
      )[0];
      setPackageSelected(sPackage);
    }
  }, [packageTicket]);

  const onAddToCart = (itemCart, isEdit) => {
    props.onAddToCart(itemCart, isEdit);
  };

  // ภาษา
  const { t } = useTranslation();

  

  useEffect(()=>{
    console.log("availableCabanaLimit >> " + availableCabanaLimit);
  },[availableCabana])

  useEffect(()=>{
    if(location == null){
      CallServiceGetLocation();
    }
    if(timeList == null){
      CallServiceGetTime();
    }
  },[])

  async function CallServiceGetLocation() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept-Language": language },
    };
    await fetch(apiLink_ecom + LOCATION_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setLocation(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + LOCATION_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  async function CallServiceGetTime() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", "Accept-Language": language },
    };
    await fetch(apiLink_ecom + TIME_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setTimeList(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + LOCATION_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  return (
    // <h1>xxx</h1>
    <div className="col-main">
      <div className="right-wrapper scrollable">
        {Object.keys(packageTicket).length > 0 && (
          <div className="box box-main mb-5">
            {packageSelected != null &&
              Object.keys(packageSelected).length > 0 &&
              !packageSelected.IsSaleByItem && (
                <SubTicket
                  flip={true}
                  itemList={packageSelected}
                  currentStep={currentStep}
                  cartUpdate={cartUpdate}
                  onAddToCart={onAddToCart}
                  location={location}
                  timeList={timeList}
                  cabanaList={cabanaList}
                  cart={cart}
                  fromBuyNow={fromBuyNow}
                  onUpdateVisitDateAll={onUpdateVisitDateAll}
                  language={language}
                />
              )}
            {packageSelected != null &&
              Object.keys(packageSelected).length > 0 &&
              packageSelected.IsSaleByItem && (
                <SubTicketItem
                  itemList={packageSelected}
                  currentStep={currentStep}
                  cartUpdate={cartUpdate}
                  onAddToCart={onAddToCart}
                  cart={cart}
                  language={language}
                />
              )}

            {packageTicket.map((e, index) => {
              let selectedPackageCode = "";
              if (packageSelected != null) {
                selectedPackageCode = packageSelected.PackageCode;
              }
              if (!e.IsSaleByItem && e.PackageCode !== selectedPackageCode) {
                return (
                  <SubTicket
                    key={index}
                    flip={true}
                    currentStep={currentStep}
                    cartUpdate={cartUpdate}
                    itemList={e}
                    onAddToCart={onAddToCart}
                    availableCabana={availableCabana}
                    onAvailableCabana={setAvailableCabana}
                    location={location}
                    timeList={timeList}
                    cabanaList={cabanaList}
                    cart={cart}
                    fromBuyNow={fromBuyNow}
                    onUpdateVisitDateAll={onUpdateVisitDateAll}
                    language={language}
                  />
                );
              } else if (
                e.IsSaleByItem &&
                e.PackageCode !== selectedPackageCode
              ) {
                return (
                  <SubTicketItem
                    key={index}
                    currentStep={currentStep}
                    cartUpdate={cartUpdate}
                    itemList={e}
                    onAddToCart={onAddToCart}
                    cart={cart}
                    language={language}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default Step1;
