import "../component/css/landindcss.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { LazyImage } from "react-lazy-images";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { ImageLoader } from 'react-loading-image'
import { ImageLoader } from "react-image-and-background-image-fade";
import Daypass from "../component/Homepage/Daypass";
import { PackageType } from "../component/Homepage/PackageType";
import {
  apiLink,
  apiLink_ecom,
  baseLink,
  packageTypeOther,
} from "../constants/DataPublic";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga";

// ReactGA.event({
//   category: 'Cart',
//   action: 'Add to cart'
// });

import sliderImg from "../component/img/slider-right2.png";
import engpc from "../component/video/engpc.mp4";
import thpc from "../component/video/thpc.mp4";
import engmobile from "../component/video/engmobile.mp4";
import thmobile from "../component/video/thmobile.mp4";
import { useGlobalState } from "../global";
import { ChangePasswordFirstLogin } from "../component/Modal/ChangePasswordFirstLogin";
import {
  GET_PACKAGE_BY_CONTRACT,
  TIME_LOOKUP,
  LOCATION_LOOKUP,
} from "../services/BASE_API";
import Agent from "../component/img/Agent_Banner.jpg";
import Agent_Mobile from "../component/img/Agen_IPAD.jpg";

//Component
const LandingPage = (props) => {
  const { language, setActiveTab } = props;
  const [routePath, setRoutePath] = useGlobalState("route");
  const [packageType, setPackageType] = useState([]);
  const [allPackage, setAllPackage] = useState([]);
  const [banner, setBanner] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [hardLink1, setHardlink1] = useState({});
  const [hardLink2, setHardlink2] = useState({});
  const [bannerUrl, setBannerUrl] = useState({});
  const [packageTicket, setPackageTicket] = useState([]);
  const [pacBanner, setPacbanner] = useState({});
  const [cart, setCart] = useState([]);

  const [locationList, setLocation] = useState(null);
  const [timeList, setTimeList] = useState(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const [cabanaList, setCabanaList] = useState(null);

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false
        },
      },
    ],
  };

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem("MyCart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    // getPackageTicket();

    //-------------- Wait API Send Correct Package ------------//
    if (user != null && language != null) {
      getAllPackage();
      CallServiceGetLocation();
      CallServiceGetTime();
    }
  }, [language]);

  useEffect(async () => {
    let pk = packageTicket.find(
      (x) => x.PackageCode == hardLink1.ButtonLinkUrl
    );
    setPacbanner(pk);
    // console.log('pk', pk)
  }, [packageTicket, hardLink1]);

  async function getBanner() {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    let path = routePath != "" ? "?prefix=" + routePath.replace("/", "") : "";
    const res = await fetch(
      apiLink_ecom + "/afcl/Package/Banner" + path,
      requestOptions
    );
    res
      .json()
      .then((res) => {
        let sortBanner = res["Result"]["PackageBanner"].sort((a, b) =>
          a.SeqNo > b.SeqNo ? 1 : -1
        );
        setBanner(sortBanner[0].PackageBannerButtons);
        setBannerUrl(sortBanner[0]);
        setHardlink1(sortBanner[0].PackageBannerButtons[0]);
        setHardlink2(sortBanner[0].PackageBannerButtons[1]);

        // console.log('banner', sortBanner[0].PackageBannerButtons);
        // console.log('sortBanner[0]', sortBanner[0]);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Banner ', error);
      });
  }

  const getPackageTicket = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    let path = routePath != "" ? "?prefix=" + routePath.replace("/", "") : "";
    const res = await fetch(
      apiLink_ecom + "/afcl/Package/Ticket" + path,
      requestOptions
    );
    res
      .json()
      .then((res) => {
        let packageTypeList = res["Result"]["PackageSteps"];
        // setItemGroup(packageTypeList)
        setPackageTicket(res["Result"]["Packages"]);
        // console.log('TES', res['Result']['Packages']);
        getBanner();
      })
      .catch((error) => {
        console.log(error);

        // alert('Error: API /afcl/Package/Ticket/ ' , error)
      });
  };

  async function getAllPackage() {
    props.setActiveTab("");
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    let path = "?contractId=" + user.userId;
    const res = await fetch(
      apiLink_ecom + GET_PACKAGE_BY_CONTRACT + path,
      requestOptions
    );
    res
      .json()
      .then((res) => {
        // console.log('Package,', res['Result']);
        setAllPackage(res["Result"].Packages);
        const sort = res["Result"].PackageTypes.sort((a, b) =>
          a.SeqNo > b.SeqNo ? 1 : -1
        );
        setPackageType(sort);
        initCabanaList(res["Result"].Packages);
        // console.log('res[', res['Result'].PackageTypes);
        // console.log('res[', res['Result'].Packages);
        // console.log('sort', sort);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package ', error);
      });
  }

  const initCabanaList = (allPackage) => {
    let newCabanaList = [];
    allPackage.forEach((item) => {
      if (item.VisitingDateItemCode != null) {
        if (!newCabanaList.includes(item.VisitingDateItemCode)) {
          newCabanaList.push(item.VisitingDateItemCode);
        }
      }
    });
    setCabanaList(newCabanaList);
  };

  function LazyBackground({ src }) {
    const [source, setSource] = useState("preload.jpg");

    useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = () => setSource(src);
    }, [src]);

    return <div style={{ backgroundImage: `url(${source})` }}></div>;
  }

  const onClickBanner = (packageCode) => {
    let pk = packageTicket.find((x) => x.PackageCode == packageCode);
    if (pk != null) {
      onAddToCart(
        {
          PackageCode: pk.PackageCode,
          PackageName: pk.PackageName,
          ItemCode: "",
          ItemName: "",
          Qty: 1,
          Tax: 0,
          ItemPrice: pk.PackagePrice - pk.DiscountPrice,
          ItemDiscount: pk.DiscountPrice,
          PriceName: pk.PriceName,
          PriceUnit: "",
          TotalPrice: pk.PackagePrice - pk.DiscountPrice,
          VisitDate: null,
          VisitTime: "",
          VisitLocation: "",
          step: 1,
          status: "normal",
        },
        false
      );

      // console.log('Is data')
    } else {
      console.log("not found");
    }
  };

  const onAddToCart = (item, isEdit) => {
    myCart = sessionStorage.getItem("MyCart");
    myCart = JSON.parse(myCart);

    console.log("BeforeAddCart, ", myCart);

    let aIndex = -1;

    if (myCart != null) {
      aIndex = myCart.findIndex((e) => {
        return e.PackageCode === item.PackageCode;
      });
    } else {
      myCart = [];
    }

    if (aIndex === -1) {
      // เพิ่ม Cart
      if (item.Qty > 0) {
        myCart = [...myCart, item];
        setCart(myCart);
      }
    } else {
      // แก้ไข Cart
      let preQty = myCart[aIndex].Qty;
      let newQty = preQty + 1;

      const editCart = myCart.map((e) => {
        if (
          e.PackageCode === item.PackageCode &&
          e.ItemCode === item.ItemCode
        ) {
          return {
            ...e,
            Qty: newQty,
            VisitDate: item.VisitDate,
            VisitLocation: item.VisitLocation,
            VisitTime: item.VisitTime,
            status: "normal",
          };
        }
        return e;
      });
      setCart(editCart);
    }
  };

  const getPackageSubTypeCode = (subTypeCode) => {
    // console.log("PackagePage ", subTypeCode);
    props.getPackageSubTypeCode(subTypeCode);
  };

  const onImageLoad = () => {
    setLoaded(true);
  };

  const { t } = useTranslation();

  async function CallServiceGetLocation() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
    };
    await fetch(apiLink_ecom + LOCATION_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setLocation(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + LOCATION_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  async function CallServiceGetTime() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
    };
    await fetch(apiLink_ecom + TIME_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setTimeList(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + LOCATION_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  return (
    <div className="page homepage">
      {/* Slide Banner */}
      {/* <div className="section section-video"> */}
      {/* <div className="wrapper slidervideo" > */}
      <ImageLoader
        // src={"https://ohhsome.agency/buytickets/img/bg-slider.jpg"}
        src={Agent}
        transitionTime="0s"
      >
        {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
          <>
            {shouldShowLoader && !hasFailed && (
              <div
                className="banner-loading"
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "450px",
                }}
              >
                <h2 style={{ color: "rgb(112 112 112)" }}> Loading ... </h2>
              </div>
            )}

            {hasFailed && (
              <div className="AwesomeoLoader__failed">Loading Fail :(</div>
            )}

            {hasLoaded && (
              <>
                {/* <div className='banner' style={{ background: 'url(https://ohhsome.agency/buytickets/img/bg-slider.jpg) no-repeat', display: 'flex', position: 'relative' }}> */}
                {/* <BrowserView>
                  <div className='banner-video' style={{ position: 'relative' }}>
                    {language == 'en' ?  
                      <video src={engpc} autoPlay loop muted style={{ width: '100%', height: '100%' }}></video>
                     : 
                      <video src={thpc} autoPlay loop muted style={{ width: '100%', height: '100%' }}></video>
                    }
                    
                    <div className='button-linkf-video'>
                      <a href={baseLink + '/ticket/' + hardLink2.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink2.ButtonLinkUrl)}>
                        <button className="btn btn-darkblue-video">{hardLink1.ButtonDesc}</button>
                      </a>
                    </div>
                    <div className='button-links-video'>
                      <a href={baseLink + '/ticket/' + hardLink2.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink2.ButtonLinkUrl)}>
                        <button className="btn btn-slider-video">{hardLink2.ButtonDesc}</button>
                      </a>
                    </div>
                  </div>
                </BrowserView> */}
                {isDesktopOrLaptop && (
                  <div
                    className="banner-video"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={Agent}
                      style={{ width: "100%", height: "720px" }}
                    />
                    {/* {language == "en" ? (
                      <video
                        src={engpc}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{ width: "100%", height: "100%" }}
                      ></video>
                    ) : (
                      <video
                        src={thpc}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{ width: "100%", height: "100%" }}
                      ></video>
                    )} */}
                    {/* {pacBanner ? (
                      <>
                        <div className="button-linkf-video">
                          <a
                            href={
                              baseLink +
                              routePath +
                              "/ticket/" +
                              hardLink1.ButtonLinkUrl +
                              "/1"
                            }
                            onClick={() =>
                              onClickBanner(hardLink1.ButtonLinkUrl)
                            }
                          >
                            <button className="btn btn-darkblue-video">
                              {hardLink1.ButtonDesc}
                            </button>
                          </a>
                        </div>

                        <div className="button-links-video">
                          <a
                            href={
                              baseLink +
                              routePath +
                              "/ticket/" +
                              hardLink1.ButtonLinkUrl +
                              "/1"
                            }
                            onClick={() =>
                              onClickBanner(hardLink1.ButtonLinkUrl)
                            }
                          >
                            <button className="btn btn-slider-video">
                              {hardLink1.ButtonDesc}
                            </button>
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="button-linkf-video">
                          <a>
                            <button className="btn btn-darkblue-video">
                              {hardLink1.ButtonDesc}
                            </button>
                          </a>
                        </div>

                        <div className="button-links-video">
                          <a>
                            <button className="btn btn-slider-video">
                              {hardLink1.ButtonDesc}
                            </button>
                          </a>
                        </div>
                      </>
                    )} */}
                  </div>
                )}
                {isTabletOrMobile && (
                  <div
                    className="banner-video"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={Agent_Mobile}
                      style={{ width: "100%", height: "720px" }}
                    />
                    {/* {language == "en" ? (
                      // <div dangerouslySetInnerHTML={{
                      //   __html:`<video loop muted autoplay playinline>
                      //   <source src="engmobile" type="video/mp4"
                      //   </video>`
                      // }}>

                      // </div>
                      <video
                        src={engmobile}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{ width: "100%", height: "100%" }}
                      ></video>
                    ) : (
                      <video
                        src={thmobile}
                        autoPlay
                        loop
                        muted
                        playsInline
                        style={{ width: "100%", height: "100%" }}
                      ></video>
                    )}
                    <div className="button-linkf-video">
                      <a
                        href={
                          baseLink +
                          routePath +
                          "/ticket/" +
                          hardLink1.ButtonLinkUrl +
                          "/1"
                        }
                        onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}
                      >
                        <button className="btn btn-darkblue-video">
                          {hardLink1.ButtonDesc}
                        </button>
                      </a>
                    </div>
                    <div className="button-links-video">
                      <a
                        href={
                          baseLink +
                          routePath +
                          "/ticket/" +
                          hardLink1.ButtonLinkUrl +
                          "/1"
                        }
                        onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}
                      >
                        <button className="btn btn-slider-video">
                          {hardLink1.ButtonDesc}
                        </button>
                      </a>
                    </div> */}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </ImageLoader>

      {/* Slide Packages */}
      {packageType.map((e, index) => {
        if (e.PackageTypeCode !== packageTypeOther) {
          const packageList = allPackage.filter(
            (p) => p.PackageTypeCode === e.PackageTypeCode
          );
          // console.log("Send PackageList, ", packageList);
          return (
            <PackageType
              index={index}
              key={index}
              packageType={e}
              packageList={packageList}
              getPackageSubTypeCode={getPackageSubTypeCode}
              locationList={locationList}
              timeList={timeList}
              cabanaList={cabanaList}
              language={language}
            />
          );
        }
      })}

      {allPackage.length > 0 && (
        <Daypass
          packageList={allPackage.filter(
            (p) => p.PackageTypeCode === packageTypeOther
          )}
        />
      )}

      {user && (
        <div
          className="section section-5 pb-5 text-center text-lg-start"
          style={{ marginTop: "-3rem" }}
        >
          <div className="container">
            <p className="weight-500 font-20">
              {t("Checkoutmore")}
              <br className="d-block d-lg-none" />
              <a href="#">
                <button
                  type="button"
                  className="btn btn-black btn-nf ms-lg-2 mt-3 mt-lg-0"
                >
                  {t("explore")}
                </button>
              </a>
            </p>
          </div>
        </div>
      )}
      {false && <ChangePasswordFirstLogin open={true} />}
    </div>
  );
};

export default LandingPage;
