import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LOCATION_LOOKUP, TIME_LOOKUP } from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";
import { ReactComponent as Close } from "../img/close.svg";
import { useGlobalState } from "../../global";
import moment from "moment";

const CartList = (props) => {
  const [availableCabanaLimit, setAvailableCabanaLimit] = useGlobalState(
    "availableCabanaLimit"
  );
  const { t, i18n } = useTranslation();

  const [cabanaVisitingDate, setCabanaVisitingDate] = useState(
    sessionStorage.getItem("CabanaVisitingDate")
  );

  const [visitingDateCabana, setVisitingDateCabana] =
    useGlobalState("visitingDateCabana");

  const [cabanaAllotment, setCabanaAllotment] = useState(
    JSON.parse(sessionStorage.getItem("CabanaAllotment"))
  );

  const { cartItem } = props;
  const { currentStep } = props;
  const { checkLastItem } = props;
  const { avaliableCabana, onSetAvaliableCabana, onUpdateVisitDateAll } = props;

  const [itemQty, setItemQty] = useState(cartItem.Qty);
  const [itemVisitingDate, setItemVisitingDate] = useState(null);

  const [removePackageCode, setRemovePackageCode] = useState("");
  const [removeItemCode, setRemoveItemCode] = useState("");
  const [removeStep, setRemoveStep] = useState("5");

  const [showConfirm, setShowConfirm] = useState(false);
  const [location, setLocation] = useState(null);
  const [time, setTime] = useState(null);
  const [initFirstLoad, setInitFirstLoad] = useState(true);

  const navigate = useNavigate();

  useEffect(async () => {
    // console.log("itemVisitingDate", itemVisitingDate)
    // if (location == null || time == null) {
    if (
      initFirstLoad &&
      cartItem.VisitLocation != "" &&
      cartItem.VisitLocation != null &&
      cartItem.VisitTime != "" &&
      cartItem.VisitTime != null &&
      location == null &&
      time == null
    ) {
      // console.log("initFirstLoad(cartlist) >> ", initFirstLoad);
      setInitFirstLoad(false);
      await CallServiceGetLocation();
      await CallServiceGetTime();
    }
    // }
    // if (time == null) {

    // }
  },[]);

  useEffect(() => {
    setItemQty(cartItem.Qty);
    // console.log("cartItem", cartItem);
    setCabanaAllotment(JSON.parse(sessionStorage.getItem("CabanaAllotment")));
    setCabanaVisitingDate(sessionStorage.getItem("CabanaVisitingDate"));
  }, [cartItem]);

  const addToCart = (item, isEdit) => {
    props.onAddToCart(item, isEdit);
  };

  // useEffect(async()=>{
  //   console.log("location >> ", location);
  //   if (location == null) {
  //     await CallServiceGetLocation();
  //   }
  // },[location])

  // useEffect(async()=>{
  //   console.log("time >> ", time);
  //   if (time == null) {
  //     await CallServiceGetTime();
  //   }
  // },[time])

  async function CallServiceGetLocation() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(apiLink_ecom + LOCATION_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          let test = [];
          item.map((x) => {
            test.push(x);
          });
          setLocation(test);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + LOCATION_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  async function CallServiceGetTime() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(apiLink_ecom + TIME_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("c", data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setTime(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + TIME_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  const getLocationName = (locationId) => {
    if (location != null) {
      const locationList = location.filter((x) => x.LocationId == locationId);
      if (locationList.length > 0) {
        return locationList[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getTimeName = (timeId) => {
    if (time != null) {
      const timeList = time.filter((x) => x.TimeId == timeId);
      if (timeList.length > 0) {
        return timeList[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const countRatePrice = (newQty) => {
    console.log("itemQty", itemQty);
    const rate = cartItem.RatePackage.find(
      (item) =>
        (newQty != null ? newQty : itemQty === "" ? 1 : itemQty) >=
          item.QtyFrom &&
        (newQty != null ? newQty : itemQty === "" ? 1 : itemQty) <= item.QtyTo
    );
    return rate != undefined
      ? rate.Price
      : cartItem.BasePrice - cartItem.ItemDiscount;
  };

  useEffect(() => {
    if (
      cabanaVisitingDate != null &&
      cartItem.VisitDate != "" &&
      cartItem.VisitDate != null
    ) {
      // setItemVisitingDate(cabanaVisitingDate);
      setVisitingDateCabana(cabanaVisitingDate);
    }
  }, [cabanaVisitingDate]);

  useEffect(() => {
    if (visitingDateCabana != null) {
      setItemVisitingDate(visitingDateCabana);
    }
  }, [visitingDateCabana]);

  const getAvailableCabana = () => {
    if (avaliableCabana != null) {
      if (avaliableCabana.length > 0) {
        const data = avaliableCabana.filter(
          (x) => x.ItemCode === cartItem.ItemCodeCabana
        );
        console.log("data", data);
        if (data.length > 0) {
          return data[0].Available;
        } else {
          return "";
        }
      } else {
        return "null";
      }
    } else {
      return "null";
    }
  };

  const checkAvailableCabana = () => {
    if (avaliableCabana != null && avaliableCabana != undefined) {
      const availableBalance = avaliableCabana.filter(
        (x) => x.ItemCode === cartItem.ItemCodeCabana
      );
      if (availableBalance.length > 0) {
        return !availableBalance[0].Status;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onNumerPWKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
      // console.log("event", event);
    }
  };

  const FormatString = (amount)=>{
    amount = amount.toString();
    const resultPrice = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g,"$1,");
    return resultPrice;
  };

  return (
    <div className="row" id="index">
      <div className="col-6">
        <p className="font-14 weight-700 mb-0">
          {cartItem.PackageName !== ""
            ? cartItem.PackageName
            : cartItem.ItemName}
        </p>
        <div className="font-12 weight-400 color-grey mb-0">
          <div
            className="input-group ms-auto-cart mt-2"
            style={{ width: "100%" }}
          >
            <span className="input-group-btn">
              <button
                type="button"
                className="btn  btn-number minus"
                data-type="minus"
                data-field="quant[1]"
                onClick={() => {
                  console.log("availableCabanaLimit>>" + availableCabanaLimit);
                  if (itemQty > 1) {
                    let newQty = itemQty - 1;
                    setItemQty((itemQty) => newQty);
                    console.log("Price", countRatePrice(newQty));
                    addToCart(
                      {
                        PackageCode: cartItem.PackageCode,
                        PackageName: cartItem.PackageName,
                        ItemCode: cartItem.ItemCode,
                        ItemName: cartItem.ItemName,
                        Qty: newQty,
                        Tax: 0,
                        ItemPrice: countRatePrice(newQty),
                        ItemDiscount: cartItem.ItemDiscount,
                        PriceName: cartItem.PriceName,
                        PriceUnit: cartItem.PriceUnit,
                        TotalPrice: newQty * countRatePrice(newQty),
                        VisitDate:
                          cartItem.VisitDate != null ? itemVisitingDate : null,
                        VisitTime: cartItem.VisitTime,
                        VisitLocation: cartItem.VisitLocation,
                        VisitTimeName: cartItem.VisitTimeName,
                        VisitLocationName: cartItem.VisitLocationName,
                        ItemCodeCabana: cartItem.ItemCodeCabana,
                        step: cartItem.step,
                        status: "normal",
                      },
                      true
                    );
                    props.setCartUpdate({
                      PackageCode: cartItem.PackageCode,
                      ItemCode: cartItem.ItemCode,
                      Qty: newQty,
                      TotalPrice: newQty * countRatePrice(newQty),
                      step: cartItem.step,
                    });
                    //   }
                  } else {
                    setRemovePackageCode(cartItem.PackageCode);
                    setRemoveItemCode(cartItem.ItemCode);
                    setRemoveStep(cartItem.step);
                    setShowConfirm(true);
                  }
                }}
              >
                <span className="material-icons">remove</span>
              </button>
            </span>
            <input
              type="text"
              name="quant[1]"
              className="input-text-qty"
              value={itemQty && FormatString(itemQty)}
              min={0}
              autoComplete="off"
              style={{
                borderColor: checkAvailableCabana() == true ? "red" : "#ced4da",
                color: checkAvailableCabana() == true ? "red" : "#000",
              }}
              onKeyPress={onNumerPWKeyDown}
              maxLength={4}
              onChange={(e) => {
                let qty = parseInt(
                  e.target.value != undefined ? e.target.value.replace(",","") : "1"
                );
                // console.log(qty);
                // console.log(isNaN(qty));
                if (isNaN(qty)) {
                  setItemQty(e.target.value);
                } else {
                  if (qty > 0) {
                    setItemQty(qty);
                    addToCart(
                      {
                        PackageCode: cartItem.PackageCode,
                        PackageName: cartItem.PackageName,
                        ItemCode: cartItem.ItemCode,
                        ItemName: cartItem.ItemName,
                        Qty: qty,
                        Tax: 0,
                        ItemPrice: countRatePrice(qty),
                        ItemDiscount: cartItem.ItemDiscount,
                        PriceName: cartItem.PriceName,
                        PriceUnit: cartItem.PriceUnit,
                        TotalPrice: qty * countRatePrice(qty),
                        VisitDate:
                          cartItem.VisitDate != null ? itemVisitingDate : null,
                        VisitTime: cartItem.VisitTime,
                        VisitLocation: cartItem.VisitLocation,
                        VisitTimeName: cartItem.VisitTimeName,
                        VisitLocationName: cartItem.VisitLocationName,
                        ItemCodeCabana: cartItem.ItemCodeCabana,
                        step: cartItem.step,
                        status: "normal",
                      },
                      true
                    );
                    props.setCartUpdate({
                      PackageCode: cartItem.PackageCode,
                      ItemCode: cartItem.ItemCode,
                      Qty: qty,
                      TotalPrice: qty * countRatePrice(qty),
                      step: cartItem.step,
                    });
                  } else {
                    setItemQty(1);
                    setRemovePackageCode(cartItem.PackageCode);
                    setRemoveItemCode(cartItem.ItemCode);
                    setRemoveStep(cartItem.step);
                    setShowConfirm(true);
                  }
                }
              }}
              onBlur={(e) => {
                let qty = parseInt(
                  e.target.value != undefined ? e.target.value.replace(",","") : "1"
                );
                if (isNaN(qty)) {
                  // setItemQty(1);
                  qty = 1;
                }
                setItemQty(qty);
                addToCart(
                  {
                    PackageCode: cartItem.PackageCode,
                    PackageName: cartItem.PackageName,
                    ItemCode: cartItem.ItemCode,
                    ItemName: cartItem.ItemName,
                    Qty: qty,
                    Tax: 0,
                    ItemPrice: countRatePrice(qty),
                    ItemDiscount: cartItem.ItemDiscount,
                    PriceName: cartItem.PriceName,
                    PriceUnit: cartItem.PriceUnit,
                    TotalPrice: qty * countRatePrice(qty),
                    VisitDate:
                      cartItem.VisitDate != null ? itemVisitingDate : null,
                    VisitTime: cartItem.VisitTime,
                    VisitLocation: cartItem.VisitLocation,
                    VisitTimeName: cartItem.VisitTimeName,
                    VisitLocationName: cartItem.VisitLocationName,
                    ItemCodeCabana: cartItem.ItemCodeCabana,
                    step: cartItem.step,
                    status: "normal",
                  },
                  true
                );
                props.setCartUpdate({
                  PackageCode: cartItem.PackageCode,
                  ItemCode: cartItem.ItemCode,
                  Qty: qty,
                  TotalPrice: qty * countRatePrice(qty),
                  step: cartItem.step,
                });
              }}
              // disabled
            />
            <span className="input-group-btn">
              <button
                type="button"
                className="btn btn-number plus"
                data-type="plus"
                data-field="quant[1]"
                onClick={() => {
                  console.log("availableCabanaLimit>>" + availableCabanaLimit);
                  // if (itemQty < availableCabanaLimit) {
                  let newQty = itemQty + 1;
                  setItemQty((itemQty) => newQty);
                  addToCart(
                    {
                      PackageCode: cartItem.PackageCode,
                      PackageName: cartItem.PackageName,
                      ItemCode: cartItem.ItemCode,
                      ItemName: cartItem.ItemName,
                      Qty: newQty,
                      Tax: 0,
                      ItemPrice: countRatePrice(newQty),
                      ItemDiscount: cartItem.ItemDiscount,
                      PriceName: cartItem.PriceName,
                      PriceUnit: cartItem.PriceUnit,
                      TotalPrice: newQty * countRatePrice(newQty),
                      VisitDate:
                        cartItem.VisitDate != null ? itemVisitingDate : null,
                      VisitTime: cartItem.VisitTime,
                      VisitLocation: cartItem.VisitLocation,
                      VisitTimeName: cartItem.VisitTimeName,
                      VisitLocationName: cartItem.VisitLocationName,
                      ItemCodeCabana: cartItem.ItemCodeCabana,
                      step: cartItem.step,
                      status: "normal",
                    },
                    true
                  );
                  props.setCartUpdate({
                    PackageCode: cartItem.PackageCode,
                    ItemCode: cartItem.ItemCode,
                    Qty: newQty,
                    TotalPrice: newQty * countRatePrice(newQty),
                    step: cartItem.step,
                  });
                  // }
                }}
              >
                <span className="material-icons">add</span>
              </button>
            </span>
          </div>
          {checkAvailableCabana() == true && (
            <p
              className="font-10 weight-700 mb-0"
              style={{ color: "red", marginTop: "5px" }}
            >
              {t("cabana_avaliable")} = {getAvailableCabana()}
            </p>
          )}
        </div>
      </div>
      <div className="col-6 text-end">
        {/* <p className="font-18 weight-700 mb-0">
          {(cartItem.ItemPrice * itemQty).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {t("thb")}
        </p> */}
        <p className="font-18 weight-700 mb-0">
          {(countRatePrice() * itemQty).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {t("thb")}
        </p>
        <span>
          <button
            type="button"
            className="btn-delete-mycart"
            onClick={(e) => {
              setRemovePackageCode(cartItem.PackageCode);
              setRemoveItemCode(cartItem.ItemCode);
              setRemoveStep(cartItem.step);
              setShowConfirm(true);
            }}
          >
            <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
          </button>
        </span>
      </div>
      <p
        className="font-12 weight-400 color-grey mb-0"
        style={{ marginTop: "5px" }}
      >
        {(cartItem.ItemCode === ""
          ? cartItem.PriceUnit == null
            ? ""
            : cartItem.PriceUnit
          : cartItem.ItemName) +
          (cartItem.VisitDate != "" && cartItem.VisitDate != null
            ? t("visitdate") + " : " + moment(cartItem.VisitDate).format("DD/MM/YYYY")
            : "")}
      </p>
      {cartItem.VisitLocationName != null ? (
        <p
          className="font-12 weight-400 color-grey mb-0"
          style={{ marginTop: "5px" }}
        >
          {t("location")} : {cartItem.VisitLocationName}
        </p>
      ) : (
        <>
          {cartItem.VisitLocation != "" &&
            cartItem.VisitLocation != null &&
            location != null && (
              <p
                className="font-12 weight-400 color-grey mb-0"
                style={{ marginTop: "5px" }}
              >
                {t("location")} : {getLocationName(cartItem.VisitLocation).LocationName}
              </p>
            )}
        </>
      )}
      {cartItem.VisitTimeName != null ? (
        <p
          className="font-12 weight-400 color-grey mb-0"
          style={{ marginTop: "5px" }}
        >
          {t("time")} : {cartItem.VisitTimeName}
        </p>
      ) : (
        <>
          {cartItem.VisitTime != "" &&
            cartItem.VisitTime != null &&
            time != null && (
              <p
                className="font-12 weight-400 color-grey mb-0"
                style={{ marginTop: "5px" }}
              >
                {t("time")} : {getTimeName(cartItem.VisitTime).TimeName}
              </p>
            )}
        </>
      )}
      <div className="col-12">
        <hr />
      </div>
      <Modal show={showConfirm} className="col-12 delete-Item">
        <Modal.Header>
          <button className="btn-close" onClick={() => setShowConfirm(false)} />
        </Modal.Header>
        {checkLastItem ? (
          <h5 className="text-center py-4">{t("confirm_delete_package")}</h5>
        ) : (
          <h5 className="text-center py-4">{t("confirm_delete_cart")}</h5>
        )}

        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-blue mb-3"
              type="button"
              onClick={() => {
                props.onRemoveCart(removePackageCode, removeItemCode);
                // if (currentStep != 5){
                navigate("/ticket/" + removePackageCode + "/" + currentStep);
                window.location.reload();
                // }
                onSetAvaliableCabana(null);
                setShowConfirm(false);
              }}
            >
              {t("confirm")}
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              onClick={() => setShowConfirm(false)}
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CartList;
