import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useRegister from "../../customHook/useRegister";
import { apiLink, apiLink_ecom } from "../../constants/DataPublic";

import { ReactComponent as Close } from "../img/close.svg";
import { useGlobalState } from "../../global";
import { useMediaQuery } from "react-responsive";
import { ConfirmChangeVisitDate } from "../Modal/ConfirmChangeVisitDate";
import { ReactComponent as Edit } from "../icons/edit.svg";
import { ReactComponent as Save } from "../icons/save.svg";

// Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  TextField,
} from "@mui/material";
import "moment/locale/th";
import moment from "moment";
import { red } from "@mui/material/colors";

const Checkout = (props) => {
  let {
    country,
    province,
    countryPhone,
    getCountry,
    getProvince,
    getCountryPhone,
  } = useRegister();

  const { cart } = props;
  const { userInfo } = props;
  const { language, onUpdateVisitDateAll } = props;
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [edit, setEdit] = useState(true);
  const [editContact, setEditContact] = useState(false);
  const navigate = useNavigate();

  const [routePath, setRoutePath] = useGlobalState("route");
  const [requireFname, setRequireFname] = useState("");
  const [requireLname, setRequireLname] = useState("");
  const [requireID, setRequireID] = useState("");
  const [requireEmail, setRequireEmail] = useState("");
  const [requireConfirmEmail, setRequireConfirmEmail] = useState("");
  const [requireTel, setRequireTel] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [invalidTime, setInvalidTime] = useState("");
  const [invalidDate, setinvalidDate] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [canPlaceOrder, setCanPlaceOrder] = useGlobalState("canPlaceOrder");
  const [visitingDate, setVisitingDate] = useGlobalState("visitingDate");
  const [openVisitDateModal, setOpenVisitDateModal] = useState(false);

  const [visitingDateCabana, setVisitingDateCabana] =
    useGlobalState("visitingDateCabana");
  //const [cabanaVisitingDate, setCabanaVisitingDate] = useState(null);
  const [visitDate, setVisitDate] = useState(null);

  const closeVisitDateModal = () => {
    setOpenVisitDateModal(false);
    setGuest({ ...guestInfo, ["arrivalDate"]: visitingDateCabana });
  };

  let guestInSessions = JSON.parse(sessionStorage.getItem("GuestInfo"));
  let cabanaVisitingDate = sessionStorage.getItem("CabanaVisitingDate");

  // Create a new date object for tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate());

  const [guestInfo, setGuest] = useState(
    guestInSessions == null
      ? {
          arrivalDate:
            visitingDate == null
              ? cabanaVisitingDate != null &&
                cabanaVisitingDate !== "" &&
                cabanaVisitingDate != "null"
                ? cabanaVisitingDate
                : null
              : visitingDate == null || visitingDate === ""
              ? null
              : moment(visitingDate).format("YYYY-MM-DD"),
          arrivalTime: "09:00",
          groupName: "",
          fName: "",
          lName: "",
          email: "",
          confirmEmail: "",
          phoneNo: "",
          telCode: 66,
          countryId: 66,
          note: "",
        }
      : guestInSessions
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 767px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  const { t, i18n } = useTranslation();

  if (guestInSessions == null) {
    console.log("GuestInfo", guestInfo);
    guestInSessions = guestInfo;
  }

  useEffect(() => {
    if (!edit && !editContact) {
      setCanPlaceOrder(true);
    }
  }, [edit, editContact]);

  useEffect(() => {
    if (
      userInfo == null ||
      Object.keys(userInfo).length === 0 ||
      (userInfo.type === "user" && !userInfo.isLogin)
    ) {
      // ถ้าไม่ได้ login หรือ continue as guest
      console.log("user null", userInfo);
      sessionStorage.removeItem("UserLogin");
      navigate("/guestCheckout");
    } else if (cart == null || cart.length === 0) {
      console.log("Cart 0");
      navigate("/");
    } else {
      userInfo.paymentMethod = "";
      userInfo.paymentUrl = "";

      sessionStorage.setItem("UserLogin", JSON.stringify(userInfo));
      // saveCart();
      //getUserInfo();
      getCountryPhone();
    }
    console.log("guestInfo >> ", guestInfo);
  }, []);

  useEffect(() => {
    console.log("CABANA", visitingDate);
    if (visitingDate != null) {
      setGuest({ ...guestInfo, ["arrivalDate"]: visitingDate });
    }
  }, [visitingDate]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [language]);

  const saveCart = async (e) => {
    if (userInfo !== null && (userInfo.isLogin || userInfo.type === "guest")) {
      let orderItem = [];
      cart.map((e) => {
        orderItem.push({
          PackageCode: e.PackageCode,
          ItemCode: e.ItemCode,
          Qty: e.Qty,
          // VisitDate: e.VisitDate === null ? null : e.VisitDate.substr(5, 2) + '/' + e.VisitDate.substr(8, 2) + '/' + e.VisitDate.substr(0, 4),
          VisitDate: e.VisitDate === null ? null : e.VisitDate,
          VisitTime: e.VisitTime,
          VisitLocation: e.VisitLocation,
        });
      });

      let bodytext = {
        ConfirmationNo: userInfo.confirmationNo,
        ChannelCode: userInfo.channelCode,
        UserId: userInfo.userId,
        UserCode: userInfo.userCode,
        PromotionCode: "",
        OrderItems: orderItem,
        Prefix: routePath.replace("/", ""),
      };

      // Email: userInfo.email,
      //   FirstName: userInfo.fName,
      //   LastName: userInfo.lName,
      //   PhoneNo: userInfo.phoneNo,
      //   IDCardNo: userInfo.idCardNo,
      //   telCode: userInfo.telCode,
      //   DeviceType:"",
      //   PushKey:"",
      //   DeviceKey:"",

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept-language": language,
        },
        body: JSON.stringify(bodytext),
      };

      await fetch(apiLink_ecom + "/afcl/Package/SaveOrder/V2", requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          let pk = items["Result"]["InvalidPk"].filter((a) => {
            return cart.find((b) => {
              return a.PackageCode === b.PackageCode;
            });
          });
          if (pk.length > 0) {
            setShowAlert(true);
          } else {
            if (items["Success"]) {
              console.log("Success: ", items["Success"]);
              userInfo.confirmationNo = items["Result"].ConfirmationNo;
              sessionStorage.setItem("UserLogin", JSON.stringify(userInfo));
              // getUserInfo(items["Result"].ConfirmationNo);
              getCountry();
              getCountryPhone();
            } else if (items["fail"]) {
              alert("Promo invalid");
            }
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert('Error: API /afcl/Package/SaveOrder ', error)
        });
    } else {
      navigate("/signin");
    }
  };

  // const getUserInfo = async (confirmationNo) => {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "accept-language": language,
  //     },
  //     // body: JSON.stringify({
  //     //   ConfirmationNo: confirmationNo,
  //     //   UserCode: userInfo.userCode,
  //     // }),
  //   };

  //   await fetch(
  //     apiLink_ecom +
  //       "/afcl/Package/CheckOutV2/" +
  //       userInfo.userCode +
  //       "?confirmationNo=" +
  //       confirmationNo,
  //     requestOptions
  //   )
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (data) {
  //       const items = data;

  //       userInfo.fName = items["Result"]["UserInformation"].FirstName;
  //       userInfo.lName = items["Result"]["UserInformation"].LastName;
  //       userInfo.email = items["Result"]["UserInformation"].Email;
  //       userInfo.phoneNo = items["Result"]["UserInformation"].PhoneNo;
  //       userInfo.idCardNo = items["Result"]["UserInformation"].IDCardNo;
  //       userInfo.country = items["Result"]["UserInformation"].Country;
  //       userInfo.province = items["Result"]["UserInformation"].Province;
  //       userInfo.countryId = items["Result"]["UserInformation"].CountryId;
  //       userInfo.provinceId = items["Result"]["UserInformation"].ProvinceId;
  //       userInfo.PhoneCode = items["Result"]["UserInformation"].PhoneCode;

  //       if (userInfo.countryId == null) {
  //         userInfo.telCode = 66;
  //       }

  //       sessionStorage.setItem("UserLogin", JSON.stringify(userInfo));

  //       if (userInfo.countryId != null) {
  //         getProvince(userInfo.countryId);
  //       }

  //       getCountry();
  //       getCountryPhone();

  //       items["Result"]["PaymentMethods"].sort((a, b) => (a > b ? 1 : -1));

  //       setPaymentMethod(items["Result"]["PaymentMethods"]);

  //       console.log("userInfo.telCode", items);
  //       console.log("userInfouserInfo.countryId", userInfo);
  //     })
  //     .catch((error) => {
  //       console.log("Error: ", error);
  //       // alert('Error: API /afcl/Package/CheckOut ', error)
  //     });
  // };

  const checkInfo = (e) => {
    console.log(guestInfo);
    e.preventDefault();
    if (
      guestInfo.arrivalDate === "" ||
      guestInfo.arrivalDate === "null" ||
      guestInfo.arrivalDate === null
    ) {
      setinvalidDate("warn_invalid_date");
    } else {
      setinvalidDate("");
    }

    const dateNow = moment(new Date()).format("YYYY-MM-DDT").toString();
    const str = dateNow + guestInfo.arrivalTime;
    const date = new Date(str);
    let invalidTime = date.getHours() < 9 || date.getHours() >= 18;
    if (invalidTime) {
      setInvalidTime("warn_arrival_time");
    }

    if (guestInfo.fName === "") {
      setRequireFname("warn_firstname");
    } else setRequireFname("");

    if (guestInfo.lName === "") {
      setRequireLname("warn_lastname");
    } else setRequireLname("");

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const validMail = regex.test(guestInfo.email);
    if (guestInfo.email === "") {
      setRequireEmail("warn_email");
    } else if (!validMail) {
      setRequireEmail("invalid_email");
    } else setRequireEmail("");

    if (guestInfo.confirmEmail === "") {
      setRequireConfirmEmail("warn_email");
    }

    const match = guestInfo.email !== guestInfo.confirmEmail ? false : true;
    if (validMail && !match) {
      setRequireConfirmEmail("email_notmatch");
    } else if (guestInfo.confirmEmail !== "") {
      setRequireConfirmEmail("");
    }

    if (guestInfo.phoneNo === "") {
      setRequireTel("warn_phone");
    } else setRequireTel("");

    // && userInfo.idCardNo
    if (
      guestInfo.arrivalDate !== "" &&
      guestInfo.arrivalDate != "null" &&
      guestInfo.arrivalDate != null &&
      guestInfo.fName != "" &&
      guestInfo.lName != "" &&
      guestInfo.email != "" &&
      validMail &&
      match &&
      guestInfo.phoneNo != "" &&
      !invalidTime
    ) {
      // updateVisitor();
      setEdit(false);

      guestInfo.arrivalDate = moment(guestInfo.arrivalDate).format(
        "YYYY-MM-DD"
      );
      guestInfo.confirmEmail = "";
      sessionStorage.setItem("GuestInfo", JSON.stringify(guestInfo));
      setGuest({ ...guestInfo, ["confirmEmail"]: "" });
    }
  };

  const checkContactInfo = (e) => {
    e.preventDefault();

    sessionStorage.setItem("UserLogin", JSON.stringify(userInfo));

    // && userInfo.idCardNo
    if (
      userInfo.fName != "" &&
      userInfo.email != ""
      // &&userInfo.phoneNo != ""
    ) {
      // updateVisitor();
      setEditContact(false);
      if (edit === false && editContact === false) {
        setCanPlaceOrder(true);
      } else {
        setCanPlaceOrder(false);
      }
    }
  };

  const updateVisitor = async () => {
    setEdit(false);
    console.log("UpdateVisitor :", userInfo);
    sessionStorage.setItem("UserLogin", JSON.stringify(userInfo));
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept-language": language,
      },
      body: JSON.stringify({
        UserId: userInfo.userId,
        UserCode: userInfo.userCode,
        PhoneNo: userInfo.phoneNo,
        Password: "",
        CountryId: userInfo.countryId,
        ProvinceId: userInfo.provinceId,
        HasSubscribeOffer: false,
        FirstName: userInfo.fName,
        LastName: userInfo.lName,
        Email: userInfo.email,
        IDCardNo: userInfo.idCardNo,
      }),
    };

    await fetch(
      apiLink_ecom + "/afcl/Account/visitor/update/profile",
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log("UpdateVisitor result :", items);
        if (items["Success"] === true) {
          console.log("UpdateVisitor Success = true");
          setErrMessage("");
          // getUserInfo(userInfo.confirmationNo);
        } else {
          console.log("UpdateVisitor Success = false");
          setErrMessage(items["Message"]);
          setEdit(true);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert('Error: API /afcl/Account/visitor/update/profile ', error)
      });
  };

  /** Register Success. **/
  const [showPopup, setShowPopup] = useState(false);
  const CloseSignupSuccess = () => setShowPopup(false);
  const ShowSignupSuccess = () => setShowPopup(true);

  const handleFormChange = (e) => {
    setGuest({ ...guestInfo, [e.target.name]: e.target.value });
    // console.log(guestInfo);
  };

  function disableCopyPaste(event) {
    event.preventDefault();
  }

  const onAddToCart = (itemCart) => {
    props.setCart(itemCart);
  };

  const onCheckVisitDate = (e) => {
    console.log("e>>", e);
    setVisitDate(e);
    let listItemVisitDate = cart.filter((x) => x.VisitDate != null);
    if (listItemVisitDate.length > 0) {
      // handleFormChange(e);
      setGuest({ ...guestInfo, ["arrivalDate"]: e });
      setOpenVisitDateModal(true);
    } else {
      //handleFormChange(e);
      setGuest({ ...guestInfo, ["arrivalDate"]: e });
    }
  };

  const onConfirmChangeVisitDate = () => {
    console.log("visitDate", visitDate);
    let date = moment(visitDate);
    setVisitingDateCabana(visitDate);
    sessionStorage.setItem("CabanaVisitingDate", date);
    const editCart = cart.map((e) => {
      if (e.VisitDate != null) {
        return {
          ...e,
          VisitDate: date.format("YYYY-MM-DD"),
          status: "normal",
        };
      }
      return e;
    });
    console.log("editCart", editCart);
    onAddToCart(editCart);
    setOpenVisitDateModal(false);
    // handleFormChange(visitDate);
    setGuest({ ...guestInfo, ["arrivalDate"]: visitDate });
    //window.location.reload();
  };

  const onGetDateSelectVisitDate = () => {
    // let dateNow = new Date();
    // let dateSelect = new Date(dateNow);
    // dateSelect.setDate(dateNow.getDate() + 1);
    let dateSelect = moment();
    return dateSelect;
  };

  const today = moment();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0E315C",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.invalid .MuiOutlinedInput-notchedOutline": {
              borderColor: red[500],
            },
          },
        },
      },
    },
  });

  return (
    <div className="col-main">
      <div className="info-wrapper py-4 px-3 px-lg-0">
        <div className="row mb-4">
          <div className="col-auto">
            <h2 className="text-uppercase lh-1 mb-0">
              {t("guest_information")}*
            </h2>
          </div>
          <div className="col-1">
            {!edit && (
              <div
                className="edit-info font-12 text-capitalize weight-500"
                onClick={() => {
                  setEdit(true);
                  setCanPlaceOrder(false);
                }}
              >
                <Edit width={16} height={16} />
                <p>{t("edit")}</p>
              </div>
            )}
            {edit && (
              <div
                className="save-info font-12 text-capitalize weight-500"
                onClick={checkInfo}
              >
                <Save width={16} height={16} />
                <p>{t("save")}</p>
              </div>
            )}
          </div>
        </div>

        {edit && (
          <form>
            <div className="row">
              <span className="text-danger">{errMessage}</span>
              <div className="form-group col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("arrival_date")}
                </label>
                {/* <input
                  id="startDate"
                  className={`form-control`}
                  type="date"
                  min={tomorrow.toISOString().split("T")[0]}
                  name="arrivalDate"
                  defaultValue={guestInfo.arrivalDate}
                  onChange={(e) => {
                    const pickDate = e.target.value;
                    if (pickDate < new Date().toISOString().split("T")[0]) {
                      let datePicker = document.getElementById("startDate");
                      guestInfo.arrivalDate = "";
                      datePicker.focus();
                    } else {
                      onCheckVisitDate(e);
                      // handleFormChange(e);
                    }
                  }}
                  value={guestInfo.arrivalDate}
                /> */}
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale={i18n.language}
                    dateFormats={"DD/MM/YYYY"}
                  >
                    <DatePicker
                      id="startDate"
                      name="arrivalDate"
                      defaultValue={today}
                      // style={{
                      //   borderColor:
                      //     requiredVisitDate == true ? "red" : "#ced4da",
                      // }}
                      className={invalidDate ? "invalid" : ""}
                      type="date"
                      minDate={onGetDateSelectVisitDate()} //new Date().toISOString().split("T")[0]
                      value={
                        guestInfo.arrivalDate === null ||
                        guestInfo.arrivalDate === ""
                          ? null
                          : moment(guestInfo.arrivalDate)
                      }
                      onChange={(value) => {
                        const pickDate = value.get().toLocaleString();
                        if (pickDate < new Date().toISOString().split("T")[0]) {
                          let datePicker = document.getElementById("startDate");
                          guestInfo.arrivalDate = "";
                          datePicker.focus();
                        } else {
                          onCheckVisitDate(value);
                          // handleFormChange(e);
                        }
                      }}
                      renderInput={(params) => (
                        <input
                          {...params.inputProps}
                          className={invalidDate ? "" : "invalid"}
                        />
                      )}
                      format={"DD/MM/YYYY"}
                      dayOfWeekFormatter={(day) => `${day}`}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
                <span className="text-danger">{t(invalidDate)}</span>
              </div>

              <div className="form-group col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("arrival_time")}
                </label>
                <input
                  id="startDate"
                  className={`form-control`}
                  type="time"
                  // min={new Date().toISOString().split("T")[0]}
                  defaultValue={
                    guestInSessions.arrivalTime == null
                      ? "09:00"
                      : guestInSessions.arrivalTime
                  }
                  min="08:00"
                  max="18:00"
                  name="arrivalTime"
                  required
                  onChange={handleFormChange}
                />
                <span className="text-danger">{t(invalidTime)}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("group_name_organize")}
                </label>
                <input
                  type="text"
                  id="groupName"
                  name="groupName"
                  className="form-control"
                  onChange={handleFormChange}
                  maxLength={50}
                  defaultValue={guestInSessions.groupName}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("firstname")}*
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="fName"
                  className={
                    requireFname == "" ? "form-control" : "form-control danger"
                  }
                  maxLength={50}
                  onChange={handleFormChange}
                  defaultValue={guestInSessions.fName}
                />
                <span className="text-danger">{t(requireFname)}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("lastname")}*
                </label>
                <input
                  type="text"
                  className={
                    requireLname == "" ? "form-control" : "form-control danger"
                  }
                  placeholder=""
                  name="lName"
                  maxLength={50}
                  onChange={handleFormChange}
                  defaultValue={guestInSessions.lName}
                />
                <span className="text-danger">{t(requireLname)}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("email")}*
                </label>
                <input
                  type="email"
                  className={
                    requireEmail == "" ? "form-control" : "form-control danger"
                  }
                  placeholder=""
                  name="email"
                  maxLength={100}
                  onChange={handleFormChange}
                  defaultValue={guestInSessions.email}
                  // disabled={userInfo.type === 'user' || userInfo.isLogin}
                />
                <span className="text-danger">{t(requireEmail)}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("confirm_email")}*
                </label>
                <input
                  type="email"
                  className={
                    requireConfirmEmail == ""
                      ? "form-control"
                      : "form-control danger"
                  }
                  placeholder=""
                  name="confirmEmail"
                  onChange={handleFormChange}
                  autoComplete="off"
                  onPaste={disableCopyPaste}
                />
                <span className="text-danger">{t(requireConfirmEmail)}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("phone_number")}*
                </label>

                <div className="row">
                  <div className="col-3 pb-3 pb-lg-0">
                    <select
                      className="form-control"
                      id="telCode"
                      name="telCode"
                      // defaultValue={userInfo.type === 'guest' ? userInfo.telCode : userInfo.countryId}
                      onChange={(e) => {
                        // userInfo.type === 'guest' ? (userInfo.telCode = e.target.value) : (userInfo.countryId = e.target.value);
                        handleFormChange(e);
                        getProvince(e.target.value);
                        console.log("userInfo", userInfo);
                      }}
                    >
                      <option key="0" value="0">
                        -
                      </option>
                      {countryPhone.map((e, key) => {
                        return (
                          <option
                            key={e.CountryId}
                            value={e.CountryId}
                            selected={guestInfo.telCode == e.CountryId}
                            defaultChecked={e.CountryId == 66}
                          >
                            {e.PhoneCode}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-9">
                    <input
                      type="Tel"
                      className={
                        requireTel == ""
                          ? "form-control"
                          : "form-control danger"
                      }
                      placeholder=""
                      defaultValue={guestInfo.phoneNo}
                      maxLength="10"
                      name="phoneNo"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={handleFormChange}
                      onPaste={disableCopyPaste}
                    />
                  </div>
                </div>
                <span className="text-danger">{t(requireTel)}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("note")}
                </label>
                <textarea
                  className="form-control"
                  name="note"
                  rows={4}
                  cols={20}
                  maxLength={100}
                  onChange={handleFormChange}
                  defaultValue={guestInfo.note}
                />
              </div>
            </div>
          </form>
        )}

        {!edit && (
          <>
            <div className="row">
              <span className="text-danger">{errMessage}</span>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("arrival_date")}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.arrivalDate
                    ? moment(guestInfo.arrivalDate).format("DD-MM-YYYY")
                    : "-"}
                </p>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("arrival_time")}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.arrivalTime ? guestInfo.arrivalTime : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("group_name_organize")}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.groupName ? guestInfo.groupName : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("firstname")}{" "}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.fName ? guestInfo.fName : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("lastname")}{" "}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.lName ? guestInfo.lName : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("email")}{" "}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.email ? guestInfo.email : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("confirm_email")}
                </p>
                <p className="weight-700 mb-0">
                  {!!guestInfo.email ? guestInfo.email : "-"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("phone_number")}
                </p>
                <p className="weight-700 mb-0">
                  {guestInfo.PhoneCode != undefined && guestInfo.PhoneCode != ""
                    ? "(+" + guestInfo.PhoneCode + ")"
                    : ""}
                  {guestInfo.phoneNo}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("note")}{" "}
                </p>
                <p className="weight-700 mb-0 text-break">
                  {!!guestInfo.note ? guestInfo.note : "-"}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Contact Information */}
      <div className="info-wrapper py-4 px-3 px-lg-0">
        <div className="row mb-4">
          <div className="col-auto">
            <h2 className="text-uppercase lh-1 mb-0">
              {t("contact_information")}
            </h2>
          </div>
          <div className="col-1">
            {!editContact && (
              <div
                className="edit-info font-12 text-capitalize weight-500"
                onClick={() => {
                  setEditContact(true);
                  setCanPlaceOrder(false);
                }}
              >
                <Edit width={16} height={16} />
                <p>{t("edit")}</p>
              </div>
            )}
            {editContact && (
              <div
                className="save-info font-12 text-capitalize weight-500"
                onClick={checkContactInfo}
              >
                <Save width={16} height={16} />
                <p>{t("save")}</p>
              </div>
            )}
          </div>
        </div>

        {editContact && (
          <form>
            <div className="row">
              <span className="text-danger">{errMessage}</span>

              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("contact_person")}
                </label>
                <input
                  type="text"
                  id="groupName"
                  className="form-control"
                  defaultValue={userInfo.fName}
                  maxLength={50}
                  onChange={(e) => (userInfo.fName = e.target.value)}
                  disabled={userInfo.type === "user" || userInfo.isLogin}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("contact_email")}
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  defaultValue={userInfo.email}
                  onChange={(e) => (userInfo.email = e.target.value)}
                  disabled={userInfo.type === "user" || userInfo.isLogin}
                />
                {/* <span className="text-danger">{t(requireEmail)}</span> */}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("contact_number")}
                </label>

                <div className="row">
                  <div className="col-3 pb-3 pb-lg-0">
                    <select
                      className="form-control"
                      id="telCode"
                      defaultValue={
                        userInfo.type === "guest"
                          ? userInfo.telCode
                          : userInfo.countryId
                      }
                      onChange={(e) => {
                        userInfo.type === "guest"
                          ? (userInfo.telCode = e.target.value)
                          : (userInfo.countryId = e.target.value);
                        userInfo.countryId = e.target.value;
                        getProvince(e.target.value);
                        console.log("userInfo", userInfo);
                      }}
                      disabled={userInfo.type === "user" || userInfo.isLogin}
                    >
                      <option key="0" value="0">
                        -
                      </option>
                      {countryPhone.map((e, key) => {
                        return (
                          <option
                            key={e.CountryId}
                            value={e.CountryId}
                            selected={66 == e.CountryId}
                          >
                            {e.PhoneCode}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-9">
                    <input
                      type="Tel"
                      className="form-control"
                      placeholder=""
                      defaultValue={userInfo.phoneNo}
                      maxLength="10"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        userInfo.phoneNo = e.target.value;
                      }}
                      disabled={userInfo.type === "user" || userInfo.isLogin}
                    />
                  </div>
                </div>
                {/* <span className="text-danger">{t(requireTel)}</span> */}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <label className="font-12 checkout-form-label">
                  {t("note")} :
                </label>
                <textarea
                  className="form-control"
                  name="note"
                  rows={userInfo.isStaff ? 1 : 4}
                  cols={20}
                  maxLength={100}
                  defaultValue={userInfo.note}
                  onChange={(e) => {
                    userInfo.note = e.target.value;
                  }}
                />
              </div>
            </div>
            {userInfo.isStaff && (
              <>
                <div className="row">
                  <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label className="font-12 checkout-form-label">
                      {t("note1")}
                    </label>
                    <textarea
                      className="form-control"
                      name="note1"
                      rows={1}
                      cols={20}
                      maxLength={100}
                      defaultValue={userInfo.note1}
                      onChange={(e) => {
                        userInfo.note1 = e.target.value;
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label className="font-12 checkout-form-label">
                      {t("note2")}
                    </label>
                    <textarea
                      className="form-control"
                      name="note1"
                      rows={1}
                      cols={20}
                      maxLength={100}
                      defaultValue={userInfo.note2}
                      onChange={(e) => {
                        userInfo.note2 = e.target.value;
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label className="font-12 checkout-form-label">
                      {t("note3")}
                    </label>
                    <textarea
                      className="form-control"
                      name="note3"
                      rows={1}
                      cols={20}
                      maxLength={100}
                      defaultValue={userInfo.note3}
                      onChange={(e) => {
                        userInfo.note3 = e.target.value;
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label className="font-12 checkout-form-label">
                      {t("note4")}
                    </label>
                    <textarea
                      className="form-control"
                      name="note4"
                      rows={1}
                      cols={20}
                      maxLength={100}
                      defaultValue={userInfo.note4}
                      onChange={(e) => {
                        userInfo.note4 = e.target.value;
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </form>
        )}

        {!editContact && (
          <>
            <div className="row">
              <span className="text-danger">{errMessage}</span>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("contact_person")}
                </p>
                <p className="weight-700 mb-0 ">{userInfo.fName}</p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("contact_email")}
                </p>
                <p className="weight-700 mb-0">
                  {!!userInfo.email ? userInfo.email : "-"}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("contact_number")}
                </p>
                <p className="weight-700 mb-0 ">
                  {userInfo.PhoneCode != undefined && userInfo.phoneNo != ""
                    ? "(+" + userInfo.phoneNo + ")"
                    : ""}
                  {userInfo.phoneNo}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-3">
                <p className="color-grey font-12 mb-0 checkout-label">
                  {t("note")}{" "}
                </p>
                <p className="weight-700 mb-0 text-break">
                  {!!userInfo.note ? userInfo.note : "-"}
                </p>
              </div>
            </div>
            {userInfo.isStaff && (
              <>
                <div className="row mb-3">
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-3">
                    <p className="color-grey font-12 mb-0 checkout-label">
                      {t("note1")}{" "}
                    </p>
                    <p className="weight-700 mb-0 text-break">
                      {!!userInfo.note1 ? userInfo.note1 : "-"}
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-3">
                    <p className="color-grey font-12 mb-0 checkout-label">
                      {t("note2")}{" "}
                    </p>
                    <p className="weight-700 mb-0 text-break">
                      {!!userInfo.note2 ? userInfo.note2 : "-"}
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-3">
                    <p className="color-grey font-12 mb-0 checkout-label">
                      {t("note3")}{" "}
                    </p>
                    <p className="weight-700 mb-0 text-break">
                      {!!userInfo.note3 ? userInfo.note3 : "-"}
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 mb-3">
                    <p className="color-grey font-12 mb-0 checkout-label">
                      {t("note4")}{" "}
                    </p>
                    <p className="weight-700 mb-0 text-break">
                      {!!userInfo.note4 ? userInfo.note4 : "-"}
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Modal show={showAlert} className="col-12 cart-placeorder">
        <a href="#" className="close">
          <Close className="popup-close" onClick={() => setShowAlert(false)} />
        </a>
        <h5 className="text-center text-danger py-5">{t("package_invalid")}</h5>
      </Modal>
      <ConfirmChangeVisitDate
        open={openVisitDateModal}
        setClose={closeVisitDateModal}
        onConfirm={onConfirmChangeVisitDate}
      />
    </div>
  );
};
export default Checkout;
