import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';

import { useTranslation } from "react-i18next";

//Image
import logo from "../component/img/logo-xl.png";
import { ReactComponent as Lock } from "../component/img/lock.svg";
import { ReactComponent as Refresh } from "../component/img/refresh.svg";
import { baseLink, apiLink, apiLink_ecom } from '../constants/DataPublic';
import { useGlobalState } from '../global'

const Passcode = (props) => {

    const navigate = useNavigate();
    const [routePath, setRoutePath] = useGlobalState('route');
    const {language} = props
    const [userName, setUserName] = useState("")

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(()=>{
        if (user != null && user.isLogin){
            navigate("/")
        } else if (user != null && !user.isLogin){
            setUserName(user.fName)
        } else {
            navigate("/signin")
        }

    },[])

    const [passcode, setPasscode] = useState([]);
    const [PinStyle, setPinStyle] = useState(["defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad"]);
    const [message,setMessage] = useState("")

    const checkPinPass = (num) => {
        if (passcode.length < 6){
            setPasscode([...passcode, num])
            PinStyle[passcode.length] = "showPinpad"
        }
    }

    const ResetPinpass = () => {
        setPasscode([])
        setPinStyle(["defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad"])
        setMessage("")
    }

    useEffect(() => {
        if (passcode.length ===6){
            checkPasscode()
        }
    }, [passcode])

    async function checkPasscode(){
        let passcodeStr = ""
        passcode.forEach(element => {
            passcodeStr = passcodeStr + element
        });

        const requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json", "accept-language": language},
            body: JSON.stringify({
                "UserId": user.userId,
                "Passcode": passcodeStr,
                "PushKey":"",
                "DeviceType": "",
                "DeviceKey": ""
            })
        };
        await fetch(apiLink_ecom+'/afcl/Account/checkpasscode', requestOptions)
        .then((response) => { return response.json();})
        .then((data) => {
            const items = data;
            if (items['Success']){
                user.isLogin = true
                user.channelCode = items['Result']['ChannelCode']
                props.setUserCode(items['Result']['User'].UserCode)
                sessionStorage.setItem('UserLogin',JSON.stringify(user))
                setMessage("")
                navigate('/')
            } else {
                ResetPinpass()
                setMessage(items['Message'])
            }
        })
        .catch(error => {
            console.log("Error: ", error)
            // alert('Error: API /afcl/Account/checkpasscode ', error)
            return false
        });
      }


    // ภาษา
    const { t } = useTranslation();
 
    return (
        <div className="page page-steps">
            <div className="section py-5">
                <div className="container">
                    <div className="row">
                        <div className="logosignin">
                            <img src={logo} alt="" id="logosignin" />
                        </div>

                        <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius" style={{ margin: "0 auto" }}>

                            <center>                   
                                <div className="mb-3"><Lock/></div>
                                <div className="mb-5 weight-500">{t('hi_fill_passcode', {userName})}</div>

                                <div className="col-8">
                                    <div className="row" style={{justifyContent: "center", alignItems: "center"}}>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[0]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[1]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[2]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[3]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[4]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[5]}></div>
                                        </div>
                                    </div>
                                </div>
                                
                                <span className="text-danger mb-3">{message}</span>
                            </center>

                            <center className="py-5">
                                
                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("1")}><span className="box-passcode">1</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("2")}><span className="box-passcode">2</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("3")}><span className="box-passcode">3</span></button>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("4")}><span className="box-passcode">4</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("5")}><span className="box-passcode">5</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("6")}><span className="box-passcode">6</span></button>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("7")}><span className="box-passcode">7</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("8")}><span className="box-passcode">8</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("9")}><span className="box-passcode">9</span></button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("0")}><span className="box-passcode">0</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={ResetPinpass}><Refresh className="size-refresh" /></button>
                                    </div>
                                </div>
                                    
                            </center>

                            <div className="row">
                                <div className="col-6 text-left weight-500">
                                    <a href={baseLink+ routePath +"/forget"} className="link-back">{t('forgot_passcode')}</a>
                                </div>

                                <div className="col-6 text-right weight-500">
                                    <a href={baseLink+ routePath +"/changepw"} className="link-back">{t('change_passcode')}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Passcode
