import { useState } from "react";
import {useNavigate} from 'react-router-dom';

import { apiLink, apiLink_ecom } from '../constants/DataPublic'

const showTerm = () => {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const [agree, setAgree] = useState(false)

  const onOpenModal = () => {
    setOpen(true)
  };

  const onCloseModal = () => {
    setOpen(false)
  };

  const onConfirm = () =>{
    setOpen(false)
    navigate('/signup')
  }

  ////////////////////
  const [subject, setSubject] = useState("");
  const [terms, setTerms] = useState("");

  async function getTerms(lang) {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": lang }
    };
    const res = await fetch(
      apiLink_ecom+"/afcl/Account/agreement", requestOptions
    );
    res
      .json()
      .then((res) => {
        setSubject(res["Result"].EmailTemplateSubject);
        setTerms(res["Result"].EmailTemplateBody);
        onOpenModal();
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Account/agreement ', error)
      });
  }

  return {open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm}
}

export default showTerm