import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import errorImage from "../../component/img/notfound.png";
import Select from "react-select";

import { ReactComponent as UploadImage } from "../../component/icons/upload_image.svg";

export const UploadSlip = (props) => {
  const {
    orderId,
    open,
    setClose,
    onImageChange,
    enableUpload,
    callServices,
    slipInfo,
    callUpdateServices,
    isUpdate,
    paymentType,
    selectPayment,
    setSelectPayment
  } = props;

  const { t } = useTranslation();

  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [amountValidate, setAmountvalidate] = useState("");
  const [enable, setEnable] = useState(false);
  const [image, setImage] = useState("");
  const [slipAmount, setSlipAmount] = useState(0);


  const handleAmountChange = (event) => {
    let value = event.target.value;
    setAmount(value);
  };

  const onFormat = (e) => {
    let value = parseAmount(amount != "" ? amount : "0");
    value = FormatAmount(value);
    validate(value);
    setAmount(value);
  };

  const FormatAmount = (amount) => {
    const resultPrice = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  const parseAmount = (amount) => {
    let price = amount;
    const checkPrice = amount.split(",");
    if (checkPrice.length > 0) {
      price = amount.replace(",", "");
    }
    const v1 = parseFloat(price);
    return v1;
  };

  const onPricePWKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
    }
  };

  const handleRemarkChange = (event) => {
    let value = event.target.value;
    setRemark(value);
  };

  const handleOnClose = () => {
    setClose();
    setAmount("");
    setRemark("");
  };

  const user = JSON.parse(sessionStorage.getItem("UserLogin"));

  useEffect(() => {
    if (user.isStaff && slipInfo != null) {
      if (slipInfo.Slip != null) {
        setRemark(slipInfo.Slip.Remark);
        setAmount(FormatAmount(slipInfo.Slip.Amount));
        setSlipAmount(slipInfo.Slip.Amount); // Fix Update Slip Maximum Amount
        setImage(slipInfo.Slip);
      }
      if (paymentType.length > 0) {
        setSelectPayment(paymentType[0]);
      }
    }
  }, [slipInfo]);

  // Validate every amount change
  useEffect(() => {
    if (amount != "") {
      if (slipInfo != null) {
        validate(amount != "" ? amount : "0");
      }
    }
  }, [amount]);

  useEffect(() => {
    setAmountvalidate("");
  }, [open]);

  useEffect(() => {
    if (slipInfo != null && amount != "") {
      validate(amount != "" ? amount : "0");
    }
  }, [enableUpload]);

  const validate = (amount) => {
    let value = parseAmount(amount != "" ? amount : "0");
    var max = (
      slipAmount +
      (slipInfo.TotalAmount - slipInfo.TotalPaid)
    ).toFixed(2); // Fix Update Slip Maximum Amount
    console.log("Maximum", max);

    if (value > max) {
      setAmountvalidate("slip_amount_err");
      setEnable(false);
      return false;
    }

    if (isUpdate && value > 0) {
      setAmountvalidate("");
      setEnable(true);
      return true;
    } else if (value !== 0) {
      setAmountvalidate("");
      if (enableUpload) {
        setEnable(true);
        return true;
      } else {
        setEnable(false);
        return false;
      }
    } else if (selectPayment != "") {
      setEnable(true)
    } else {
      setAmountvalidate("warn_amount");
      setEnable(false);
      return false;
    }
  };

  const onSelectPayment = (event) => {
    setSelectPayment(event);
  };

  const selectStyle = {
    option: (provided, state) => ({
      ...provided,
      color: "#000000",
      // backgroundColor: "#d7f8ff",
      // borderColor: "#d7f8ff",
      ':hover': {
        backgroundColor: "#d7f8ff",
      },
      backgroundColor: state.isSelected ? "#d7f8ff" : "white",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      // color: "#d7f8ff",
      // ':active': {
      //   backgroundColor:
      //     state.isSelected
      //       ? "#d7f8ff"
      //       : "white"
      // },
      ':hover': {
        backgroundColor: "white",
      },
      ':focus': {
        border: "none",
      }
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #ced4da" : "1px solid #ced4da",
      // This line disable the blue border
      boxShadow: 'none'
    })
  };

  return (
    <Modal
      show={open}
      onHide={handleOnClose}
      backdrop="static"
      keyboard={false}
      className="upload-slip-dialog"
    >
      <ModalHeader closeButton>
        <span>
          {isUpdate ? t("update_status_slip") : t("upload_payment_slip")}
        </span>
        {/* <button className="btn-modal-close" onClick={setClose}>
          <i class="fa fa-times"></i>
        </button> */}
      </ModalHeader>
      <ModalBody>
        <form>
          <div class="upload-slip-form">
            <div className="row mb-3">
              <div className="col-4 m-auto">
                <label for="exampleInput1" class="form-label m-0">
                  {t("transfered_amount")}
                </label>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className={
                    amountValidate == ""
                      ? "form-control"
                      : "form-control danger"
                  }
                  id="exampleInput1"
                  value={amount}
                  onChange={handleAmountChange}
                  onKeyPress={onPricePWKeyDown}
                  onBlur={onFormat}
                  autoComplete="false"
                  aria-describedby="amountHelp"
                  required={true}
                />
              </div>
              <div className="col m-auto">{t("baht")}</div>
            </div>
            {amountValidate && (
              <div className="row mb-3">
                <div className="col-4 m-auto"></div>
                <div className="col-6">
                  <div id="inputHelp" class="form-text mr-0">
                    <span style={{ color: "red" }}>{t(amountValidate)}</span>
                  </div>
                </div>
                <div className="col m-auto"></div>
              </div>
            )}
            {user.isStaff && (
              <div className="row mb-3">
                <div className="col-4 m-auto">
                  <label for="exampleInput2" class="form-label m-0">
                    {t("select_payment")}
                  </label>
                </div>
                <div className="col-6">
                  <Select
                    className="select-payment"
                    placeholder={t("warn_payment")}
                    value={selectPayment}
                    onChange={onSelectPayment}
                    options={paymentType}
                    styles={selectStyle}
                  />
                </div>
                <div className="col m-auto"></div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col-4">
                <label for="exampleInput2" class="form-label m-0">
                  {t("remark")}
                </label>
              </div>
              <div className="col-6">
                <textarea
                  rows="2"
                  cols="50"
                  type="text"
                  class="form-control"
                  id="exampleInput2"
                  placeholder={t("remark_tips")}
                  value={remark}
                  onChange={handleRemarkChange}
                  disabled={isUpdate ? true : false}
                  required
                />
              </div>
              <div className="col mt-0 auto"></div>
            </div>
            <div className="row mb-3">
              <div className={slipInfo == null ? "col-4 m-auto" : "col-4"}>
                <label for="exampleInput1" class="form-label m-0">
                  {t("bank_slip")}
                </label>
              </div>
              <div className="col-6">
                {!isUpdate && (
                  <input
                    type="file"
                    accept="image/*"
                    maxLength="1048576" //max file size 1 mb
                    class="form-control"
                    id="exampleInput1"
                    aria-describedby="inputHelp"
                    // value=""
                    onChange={onImageChange}
                  />
                )}
                {isUpdate && image && (
                  <img
                    className="slip-image"
                    key={image.Id}
                    // width="100%"
                    // height="260"
                    src={image.SlipImage}
                    onError={(e) => {
                      e.target.src = errorImage;
                      e.target.style = "padding: 10px; margin: 16px";
                      //setImageError(true);
                    }}
                    onClick={() => { }}
                  />
                )}
              </div>
              <div className="col m-auto"></div>
            </div>
            {!isUpdate && (
              <div className="row mb-3">
                <div className="col-4 m-auto"></div>
                <div className="col-6">
                  <div id="inputHelp" class="form-text mr-0">
                    {t("upload_desc")}
                    <br />
                    {t("upload_file_desc")}
                  </div>
                </div>
                <div className="col m-auto"></div>
              </div>
            )}
          </div>
          <div className="upload-warning">
            {!isUpdate ? t("upload_warning") : t("update_warning")}
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn-upload-order"
          style={{
            backgroundColor: "#0e315c",
            cursor: "pointer",
            maxWidth: "50%",
            borderColor: "#0e315c",
          }}
          onClick={async () => {
            if (isUpdate) {
              if (orderId != null && amount != null) {
                if (validate(amount)) {
                  await callUpdateServices(image.Id, amount, user.staffId, selectPayment.value);
                }
              }
            } else {
              if (orderId != null && amount != null && remark != null) {
                await callServices(orderId, amount, remark);
                setAmount("");
                setRemark("");
              }
            }
          }}
          disabled={!enable}
        >
          <span
            style={{
              color: "white",
              textTransform: "capitalize",
            }}
          >
            {isUpdate ? t("update") : t("upload")}
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
