import React, { useCallback, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

//Image
import logo from "../component/img/logo-xl.png";
import PicPasscode from "../component/img/Passcodechanged.png";
import { ReactComponent as Close } from "../component/img/close.svg";
import { ReactComponent as Passcode } from "../component/img/Passcode.svg";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

//css
import "../component/css/style.css";
import "../component/css/custom.css";
import { baseLink, apiLink, apiLink_ecom } from "../constants/DataPublic";
import { useGlobalState } from "../global";
import {
  CHANGE_PASSWORD_AGENT,
  CHANGE_PASSWORD_STAFF,
} from "../services/BASE_API";
import Backdrop from "@mui/material/Backdrop";
import imgInfo from "../component/img/info.png";

const ChangePass = (props) => {
  const { language } = props;
  const navigate = useNavigate();
  const [routePath, setRoutePath] = useGlobalState("route");
  const [canChange, setCanChange] = useState(false);
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [newPassMessage, setNewPassMessage] = useState("");
  const [confirmPassMessage, setConfirmPassMessage] = useState("");
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserLogin"))
  );
  const [loading, setLoading] = useState(false);
  // let user = sessionStorage.getItem("UserLogin");
  // user = JSON.parse(user);
  console.log(user);

  useEffect(() => {
    if (user != null) {
      console.log("Change Passcode");
    } else {
      navigate("/signin");
    }
  }, []);

  const onNumerPWKeyDown = (event) => {
    if (user.isStaff) {
      if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
        event.preventDefault();
        console.log("event", event);
      }
    }
  };

  const checkPwd = () => {
    let pwdCheck = 0;
    let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    validateRegex.forEach((regex, i) => {
      if (new RegExp(regex).test(NewpasswordInput)) {
        pwdCheck += 1;
      }
    });
    if (pwdCheck == 4) {
      console.log("Pass");
      setNewPassMessage("");
      setConfirmPassMessage("");
      changePasscode();
    } else {
      console.log("Not Pass");
      setNewPassMessage("format_password");
    }
  };

  const [OldpasswordType, setOldPasswordType] = useState("password");
  const [OldpasswordInput, setOldPasswordInput] = useState("");

  const toggleOldPassword = () => {
    if (OldpasswordType === "password") {
      setOldPasswordType("number");
      return;
    }
    setOldPasswordType("password");
  };

  const [NewpasswordType, setNewPasswordType] = useState("password");
  const [NewpasswordInput, setNewPasswordInput] = useState("");

  const toggleNewPassword = () => {
    if (NewpasswordType === "password") {
      setNewPasswordType("number");
      return;
    }
    setNewPasswordType("password");
  };

  const [CFpasswordType, setCFPasswordType] = useState("password");
  const [CFpasswordInput, setCFPasswordInput] = useState("");

  const routePathAgent = "/agent";
  const routePathStaff = "/staff";

  const [routePathSigin, setRoutePathSigin] = useState("");

  const toggleCFPassword = () => {
    if (CFpasswordType === "password") {
      setCFPasswordType("number");
      return;
    }
    setCFPasswordType("password");
  };
  //
  useEffect(() => {
    // if (OldpasswordInput.length === 6) {
    //   checkPasscode();
    // } else {
    //   setCanChange(false);
    // }
    if (OldpasswordInput.length >= 8) {
      setCanChange(true);
    } else {
      setCanChange(false);
    }
  }, [OldpasswordInput]);

  useEffect(() => {
    if (NewpasswordInput.length >= 8 && CFpasswordInput.length >= 8) {
      setActiveConfirm(true);
      setMessage("");
    } else {
      setMessage("");
      setActiveConfirm(false);
    }
  }, [NewpasswordInput, CFpasswordInput]);

  // async function checkPasscode() {
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "accept-language": language,
  //     },
  //     body: JSON.stringify({
  //       UserId: user.userId,
  //       Passcode: OldpasswordInput,
  //       PushKey: "",
  //       DeviceType: "",
  //       DeviceKey: "",
  //     }),
  //   };
  //   console.log(requestOptions);
  //   await fetch(apiLink_ecom + "/afcl/Account/checkpasscode", requestOptions)
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (data) {
  //       const items = data;
  //       console.log(items);
  //       if (items["Success"] === true) {
  //         console.log("Check Success = true");
  //         setCanChange(true);
  //         setMessage("");
  //       } else {
  //         console.log("Check Success = false");
  //         setCanChange(false);
  //         setMessage(items["Message"]);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Error: ", error);
  //       // alert('Error: API /afcl/Account/checkpasscode ', error)
  //       setCanChange(false);
  //     });
  // }

  async function changePasscode() {
    if (NewpasswordInput !== CFpasswordInput) {
      console.log("New Password Not Match!!");
      setConfirmPassMessage("password_notmatch");
      setNewPassMessage("password_notmatch");
    } else {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept-language": language,
        },
        body: JSON.stringify(
          user.isStaff
            ? {
                UserId: user.staffId,
                OldPassword: OldpasswordInput,
                NewPassword: NewpasswordInput,
              }
            : {
                ContractId: user.userId,
                OldPassword: OldpasswordInput,
                NewPassword: NewpasswordInput,
              }
        ),
      };
      console.log(requestOptions);
      await fetch(
        user.isStaff
          ? apiLink + CHANGE_PASSWORD_STAFF
          : apiLink + CHANGE_PASSWORD_AGENT,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          console.log(items);
          setLoading(false);
          if (items["Success"] === true) {
            console.log("Change Success = true");
            if (user.isStaff) {
              setRoutePathSigin(routePathStaff);
            } else {
              setRoutePathSigin(routePathAgent);
            }
            sessionStorage.clear();
            setShowPopupChangePass(true);
          } else {
            // setMessage("password_not_valid");
            setMessage(items["Message"]);
            console.log("Change Success = false");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          setLoading(false);
          // alert('Error: API /afcl/Account/changepasscode/save ', error)
        });
    }
  }

  /** Send Email Forget **/
  const [showPopupChangePass, setShowPopupChangePass] = useState(false);
  const CloseShowPopupChangePass = () => setShowPopupChangePass(false);

  // ภาษา
  const { t } = useTranslation();

  return (
    <div>
      <div className="page page-steps">
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="logosignin">
                <img src={logo} alt="" id="logosignin" />
              </div>
              <div
                className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius"
                style={{ margin: "0 auto" }}
              >
                <from>
                  <div className="mb-3">
                    <h2 className="main-title mb-3">{t("change_password")}</h2>
                    {/* <p>{t("passcode_number")}</p> */}

                    <div className="py-1">
                      <div className="mb-3">
                        <span style={{ fontSize: "18px" }}>{t("pwd_req")}</span>
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-color">
                          {t("old_password")}*{" "}
                        </label>
                        {OldpasswordType != "password" && (
                          <div className="input-group boxInputPass mb-3">
                            {/* <input
                              type="number"
                              onKeyDown={onNumerPWKeyDown}
                              onChange={(e) =>asd
                                  e.target.value.replace(/\D/g, "")
                                )
                              }
                              value={OldpasswordInput}
                              className="form-control boxborderPass"
                              placeholder={t("old_passcode")}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                              }}
                              maxlength={6}
                            /> */}
                            <input
                              type="text"
                              // onKeyDown={onNumerPWKeyDown}
                              onChange={(e) =>
                                setOldPasswordInput(e.target.value)
                              }
                              value={OldpasswordInput}
                              className={message == "" ? "form-control boxborderPass" : "form-control border danger"}
                              placeholder={t("old_password")}
                              // maxLength={user.isStaff ? 6 : 20}
                              maxLength={16}
                            />
                            <div className="input-group-prepend boxborderPass">
                              <span onClick={toggleOldPassword}>
                                <FiEyeOff />
                              </span>
                            </div>
                          </div>
                        )}
                        {OldpasswordType === "password" && (
                          <div className="input-group boxInputPass mb-3">
                            {/* <input
                              type="password"
                              onKeyPress={onNumerPWKeyDown}
                              onChange={(e) =>
                                setOldPasswordInput(
                                  e.target.value.replace(/\D/g, "")
                                )
                              }
                              value={OldpasswordInput}
                              className="form-control boxborderPass"
                              placeholder={t("old_passcode")}
                              maxLength={6}
                              inputMode="numeric"
                              pattern="[0-9]*"
                            /> */}
                            <input
                              type="password"
                              // onKeyPress={onNumerPWKeyDown}
                              onChange={(e) =>
                                setOldPasswordInput(e.target.value)
                              }
                              value={OldpasswordInput}
                              className={message == "" ? "form-control boxborderPass" : "form-control border danger"}
                              placeholder={t("old_password")}
                              // maxLength={user.isStaff ? 6 : 20}
                              maxLength={16}
                            />
                            <div className="input-group-prepend boxborderPass">
                              <span onClick={toggleOldPassword}>
                                <FiEye />
                              </span>
                            </div>
                          </div>
                        )}
                        <span className="text-danger">{t(message)}</span>
                      </div>

                      <div className="mb-3">
                        <label className="form-label text-color">
                          {t("new_password")}*{" "}
                        </label>
                        {NewpasswordType != "password" && (
                          <div className="input-group boxInputPass mb-3">
                            {/* <input
                              type="number"
                              onKeyDown={onNumerPWKeyDown}
                              onChange={(e) =>
                                setNewPasswordInput(
                                  e.target.value.replace(/\D/g, "")
                                )
                              }
                              value={NewpasswordInput}
                              className="form-control boxborderPass"
                              placeholder={t("new_passcode")}
                              disabled={!canChange}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                              }}
                              maxlength={6}
                            /> */}
                            <input
                              type="text"
                              // onKeyPress={onNumerPWKeyDown}
                              onChange={(e) =>
                                setNewPasswordInput(e.target.value)
                              }
                              value={NewpasswordInput}
                              className={newPassMessage == "" ? "form-control boxborderPass" : "form-control border danger"}
                              placeholder={t("new_password")}
                              disabled={!canChange}
                              // maxLength={user.isStaff ? 6 : 20}
                              maxLength={16}
                            />
                            <div className="input-group-prepend boxborderPass">
                              <span onClick={toggleNewPassword}>
                                <FiEyeOff />
                              </span>
                            </div>
                          </div>
                        )}
                        {NewpasswordType === "password" && (
                          <div className="input-group boxInputPass mb-3">
                            {/* <input
                              type="password"
                              onKeyDown={onNumerPWKeyDown}
                              onChange={(e) =>
                                setNewPasswordInput(
                                  e.target.value.replace(/\D/g, "")
                                )
                              }
                              value={NewpasswordInput}
                              className="form-control boxborderPass"
                              placeholder={t("new_passcode")}
                              maxLength={6}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              disabled={!canChange}
                            /> */}
                            <input
                              type="password"
                              // onKeyPress={onNumerPWKeyDown}
                              onChange={(e) =>
                                setNewPasswordInput(e.target.value)
                              }
                              value={NewpasswordInput}
                              className={newPassMessage == "" ? "form-control boxborderPass" : "form-control border danger"}
                              placeholder={t("new_password")}
                              disabled={!canChange}
                              // maxLength={user.isStaff ? 6 : 20}
                              maxLength={16}
                            />
                            <div className="input-group-prepend boxborderPass">
                              <span onClick={toggleNewPassword}>
                                <FiEye />
                              </span>
                            </div>
                          </div>
                        )}
                        <span className="text-danger">{t(newPassMessage)}</span>
                      </div>

                      <div className="mb-3">
                        <label className="form-label text-color">
                          {t("comfirm_password")}*{" "}
                        </label>
                        {CFpasswordType != "password" && (
                          <div className="input-group boxInputPass mb-3">
                            {/* .replace(/^0+/g, "") */}
                            {/* <input
                              type="number"
                              onKeyDown={onNumerPWKeyDown}
                              onChange={(e) =>
                                setCFPasswordInput(
                                  e.target.value.replace(/[^0-9]/gi, "") || ""
                                )
                              }
                              value={CFpasswordInput}
                              className="form-control boxborderPass"
                              placeholder={t("confirm_new_passcode")}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              disabled={!canChange}
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                              }}
                              maxlength={6}
                            /> */}
                            <input
                              type="text"
                              // onKeyPress={onNumerPWKeyDown}
                              onChange={(e) =>
                                setCFPasswordInput(e.target.value)
                              }
                              value={CFpasswordInput}
                              className={confirmPassMessage == "" ? "form-control boxborderPass" : "form-control border danger"}
                              placeholder={t("comfirm_password")}
                              disabled={!canChange}
                              // maxLength={user.isStaff ? 6 : 20}
                              maxLength={16}
                              style={confirmPassMessage != "" ? {
                                borderColor: "red !important"
                              } : {borderColor:"inherit"}}
                            />
                            <div className="input-group-prepend boxborderPass">
                              <span onClick={toggleCFPassword}>
                                <FiEyeOff />
                              </span>
                            </div>
                          </div>
                        )}
                        {CFpasswordType === "password" && (
                          <div className="input-group boxInputPass mb-3">
                            {/* .replace(/^0+/g, "") */}
                            {/* <input
                              type="password"
                              onKeyDown={onNumerPWKeyDown}
                              onChange={(e) =>
                                setCFPasswordInput(
                                  e.target.value.replace(/[^0-9]/gi, "") || ""
                                )
                              }
                              value={CFpasswordInput}
                              className="form-control boxborderPass"
                              placeholder={t("confirm_new_passcode")}
                              maxLength={6}
                              inputMode="numeric"
                              pattern="[0-9]*"
                              disabled={!canChange}
                            /> */}
                            <input
                              type="password"
                              // onKeyPress={onNumerPWKeyDown}
                              onChange={(e) =>
                                setCFPasswordInput(e.target.value)
                              }
                              value={CFpasswordInput}
                              className={confirmPassMessage == "" ? "form-control boxborderPass" : "form-control border danger"}
                              placeholder={t("comfirm_password")}
                              disabled={!canChange}
                              // maxLength={user.isStaff ? 6 : 20}
                              maxLength={16}
                              style={confirmPassMessage != "" ? {
                                borderColor: "red !important"
                              } : {borderColor:"inherit"}}
                            />
                            <div className="input-group-prepend boxborderPass">
                              <span onClick={toggleCFPassword}>
                                <FiEye />
                              </span>
                            </div>
                          </div>
                        )}
                        <span className="text-danger">
                          {t(confirmPassMessage)}
                        </span>
                      </div>
                      {/* <div className="mb-3">
                        <img
                          src={imgInfo}
                          style={{ width: "20px"}}
                        ></img>
                        <span style={{marginLeft:"10px"}}>{t("pwd_req_1")}</span>
                      </div>
                      <div className="mb-3">
                        <img
                          src={imgInfo}
                          style={{ width: "20px"}}
                        ></img>
                        <span style={{marginLeft:"10px"}}>{t("pwd_req_2")}</span>
                      </div>
                      <div className="mb-3">
                        <img
                          src={imgInfo}
                          style={{ width: "20px"}}
                        ></img>
                        <span style={{marginLeft:"10px"}}>{t("pwd_req_3")}</span> 
                      </div>
                      <div className="mb-3">
                        <img
                          src={imgInfo}
                          style={{ width: "20px"}}
                        ></img>
                        <span style={{marginLeft:"10px"}}>{t("pwd_req_4")}</span> 
                      </div>
                      <div className="mb-3">
                        <img
                          src={imgInfo}
                          style={{ width: "20px"}}
                        ></img>
                        <span style={{marginLeft:"10px"}}>{t("pwd_req_5")}</span> 
                      </div> */}
                    </div>
                  </div>

                  <div className="mb-3">
                    <Button
                      type="button"
                      className="btn btn-link btn-db text-capitalize"
                      // onClick={changePasscode}
                      onClick={checkPwd}
                      disabled={!activeConfirm}
                    >
                      {t("confirm")}
                    </Button>
                    <Modal
                      show={showPopupChangePass}
                      className="col-12 popup-changepw"
                    >
                      <a
                        href={baseLink + routePath + routePathSigin}
                        className="close"
                      >
                        <Close
                          className="popup-close"
                          onClick={CloseShowPopupChangePass}
                        />
                      </a>
                      <div className="logosignin">
                        {" "}
                        <img src={logo} className="logo-popup" />
                      </div>
                      <div className="text-center mb-3">
                        <img src={PicPasscode} className="img-forgetpopup" />
                      </div>
                      <p className="text-center text-popup-title">
                        {/* {t("passcode_changed")} */}
                        {t("password_changed")}
                      </p>
                      <div className="text-center">
                        <a
                          href={baseLink + routePath + routePathSigin}
                          className="link-lb"
                        >
                          {t("back_to_signin")}
                        </a>
                      </div>
                    </Modal>
                  </div>
                </from>
                <div className="text-center weight-500">
                  <a
                    href="#"
                    onClick={(e) => navigate("/")}
                    className="link-lb"
                  >
                    {t("back")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
    </div>
  );
};

export default ChangePass;
