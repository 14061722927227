import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";

import logo from "./img/logo.png";
import logoxl from "./img/logo-xl.png";
import PicProfile from "../component/img/picuser2.png";
import { ReactComponent as User } from "./img/user.svg";
import { ReactComponent as Cart } from "./img/cart.svg";
import { ReactComponent as Hamburger } from "./img/hamburger.svg";
import { baseLink, apiLink, apiLink_ecom } from "../constants/DataPublic";
import { useGlobalState } from "../global";
import {ReactComponent as Agent} from "./icons/Agent.svg";
import {ReactComponent as Staff} from "./icons/Staff.svg";

import userDefaultImg from "./img/user.svg";

import "./css/style.css";
import { func } from "prop-types";
import ModalFailed from "./Modal/ModalFailed";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";

const languages = [
  {
    code: "en",
    name: "ENG",
    list: "ENGLISH",
    country_code: "gb",
  },
  {
    code: "th",
    name: "TH",
    list: "THAI",
    country_code: "th",
  },
];

const Navbar = (props) => {
  const { cartItems, userLogin, activeTab, setActiveTab } = props;

  const [routePath, setRoutePath] = useGlobalState("route");
  const [inactiveCam, setInactiveCam] = useGlobalState("inactiveCam");
  const [loadCampaign, setLoadCampaign] = useGlobalState("loadCampaign");
  const [login, setLogin] = useState(false);
  const [toLink, setToLink] = useState("#");
  const [cItems, setCItems] = useState("");
  const [ticket, setTicket] = useState();
  const [cart, setCart] = useState([]);

  const [collapsed, setCollapsed] = useState(true);

  const [failedModal, setOpenFailedModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const navigate = useNavigate();

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);

  useEffect(() => {
    // console.log("Navbar, ", myCart);
    if (myCart != null && myCart.length > 0) {
      setCItems(myCart.length.toString());
    }
  }, [myCart]);

  useEffect(() => {
    // console.log("Navbar CartItems = ", cartItems);
    if (cartItems > 0 && cartItems < 100) {
      setCItems(cartItems);
    } else if (cartItems > 100) {
      setCItems("99+");
    } else if (myCart != null && myCart.length > 0) {
      setCItems(myCart.length.toString());
    } else {
      setCItems("");
    }
  }, [cartItems, myCart]);

  useEffect(() => {
    user = JSON.parse(sessionStorage.getItem("UserLogin"));
    if (user == null) {
      setLogin(false);
    } else {
      setLogin(true);
    }
  }, [userLogin, user]);

  const showUserLogin = () => {
    user = sessionStorage.getItem("UserLogin");
    user = JSON.parse(user);

    if (user != null) {
      setLogin(user.isLogin);

      if (user.isLogin) {
        setToLink(
          baseLink === "" ? routePath + "/#" : baseLink + routePath + "#"
        );
      } else {
        setToLink(baseLink + routePath + "/signin");
      }
    } else {
      setLogin(false);
      setToLink(baseLink + routePath + "/signin");
    }

    
  };

  async function setLogout(e) {
    e.preventDefault();

    let user = sessionStorage.getItem("UserLogin");
    user = JSON.parse(user);

    let headers = {
      UserId: user.userId,
      Email: user.email,
      DeviceKey: "",
    };
    //console.log(headers);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(headers),
    };
    await fetch(apiLink + "/afcl/Account/logout", requestOptions)
      .then(function (response) {
        return response;
      })
      .then(function (data) {
        const items = data;

        if (items["ok"]) {
          // sessionStorage.removeItem("UserLogin");
          // sessionStorage.removeItem("MyCart");
          // sessionStorage.removeItem("PaymentRoute");
          // sessionStorage.removeItem("GuestInfo");
          // sessionStorage.removeItem("AgentRoute");
          // sessionStorage.removeItem("CabanaVisitingDate");
          // sessionStorage.removeItem("Route");
          sessionStorage.clear(); // Remove All In Session
          setLogin(false);
          setToLink(baseLink + routePath + "/signin");
          setCItems("");
          props.setUserCode("");
          props.userLogout(true);
          props.setUserLogin(false);
          // console.log("Log Out successful");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    window.location.href = "/";
  }

  const goToCart = () => {
    myCart = sessionStorage.getItem("MyCart");
    myCart = JSON.parse(myCart);
    // console.log("goToCart", myCart === null ? 0 : myCart.length);
    if (myCart != null && myCart.length > 0) {
      console.log("navigate");
      // navigate("/ticket/" + myCart[0].PackageCode + "/5");
      navigate("/ticket/" + myCart[0].PackageCode + "/1");
    }else{
      // setModalMessage("cart_empty");
      // setOpenFailedModal(true);
      toast(t("cart_empty"), {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const onGetticket = async (item, isEdit) => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": currentLanguageCode },
    };
    // let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink_ecom + "/afcl/Package/Ticket", requestOptions);
    res
      .json()
      .then((res) => {
        let tic = res["Result"];
        setTicket(res["Result"]);
        let aIndex;
        if (tic != null) {
          aIndex = tic.Packages.find((e) => {
            return e.PackageCode === "AWPK001";
          });
        }
        let netP;
        if (aIndex.IsDiscount) {
          netP = aIndex.PackagePrice - aIndex.DiscountPrice;
        } else {
          netP = aIndex.PackagePrice;
        }

        let data = {
          PackageCode: aIndex.PackageCode,
          PackageName: aIndex.PackageName,
          ItemCode: "",
          ItemName: "",
          Qty: 1,
          Tax: 0,
          ItemPrice: netP,
          ItemDiscount: aIndex.IsDiscount ? aIndex.DiscountPrice : 0,
          PriceName: aIndex.PriceName,
          PriceUnit: "",
          TotalPrice: netP,
          VisitDate: null,
          VisitTime: "",
          VisitLocation: "",
          step: aIndex.StepId,
          status: "normal",
        };
        // console.log("aIndex", aIndex);
        onAddToCart(data, false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onAddToCart = async (item, isEdit) => {
    myCart = sessionStorage.getItem("MyCart");
    myCart = JSON.parse(myCart);

    // console.log("BeforeAddCart, ", myCart);
    let aIndex = -1;

    if (myCart != null) {
      aIndex = myCart.findIndex((e) => {
        return e.PackageCode === item.PackageCode;
      });
    } else {
      myCart = [];
    }

    if (aIndex === -1) {
      if (item.Qty > 0) {
        myCart = [...myCart, item];
        setCart(myCart);
        window.location.href = baseLink + "ticket/AGPK001/1";
      }
    } else {
      let preQty = myCart[aIndex].Qty;
      let newQty = preQty + 1;

      const editCart = myCart.map((e) => {
        if (
          e.PackageCode === item.PackageCode &&
          e.ItemCode === item.ItemCode
        ) {
          return {
            ...e,
            Qty: newQty,
            VisitDate: item.VisitDate,
            VisitLocation: item.VisitLocation,
            VisitTime: item.VisitTime,
            status: "normal",
          };
        }
        return e;
      });
      setCart(editCart);
      window.location.href = baseLink + "ticket/AWPK001/1";
    }
  };

  const handleCollapsedClick = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem("MyCart", JSON.stringify(cart));
  }, [cart]);

  const currentLanguageCode = cookies.get("i18next") || "th";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    props.getLanguage(currentLanguageCode);
  }, [currentLanguageCode]);

  const closeFailedModal = ()=>{
    setOpenFailedModal(false);
  }

  return (
    <div style={{ pointerEvents: inactiveCam ? "none" : "inherit" }}>
      {/* Mobile */}
      <nav className="fixed-top d-block d-lg-none ">
        <div className="container-fluid navi">
          <div className="row justify-content-between align-items-center">
            <div
              className="col-4 "
              style={{ pointerEvents: loadCampaign ? "inherit" : "none" }}
            >
              <a
                className="navbar-brand"
                href={baseLink + routePath == "" ? "/" : baseLink + routePath}
              >
                <img src={logo} alt="" className="logo" />
              </a>
            </div>
            <div className="col-8 text-end">
              <div className="row navmain">
                <div className="col-3 p-0">
                  <div className="col-nav border-right border-left">
                    <div className="wrapper text-center ">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {currentLanguage.name}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {languages.map(
                            ({ code, name, list, country_code }) => (
                              <li key={country_code}>
                                <a
                                  href="#"
                                  className={classNames("dropdown-item", {
                                    disabled: currentLanguageCode === code,
                                  })}
                                  onClick={() => {
                                    i18next.changeLanguage(code);
                                  }}
                                >
                                  <span
                                    style={{
                                      opacity:
                                        currentLanguageCode === code ? 0.5 : 1,
                                    }}
                                  ></span>
                                  {list}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-3 p-0">
                  <div className="col-nav border-right">
                    <div className="wrapper ">
                      <div className="dropdown companymenu">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onMouseOver={showUserLogin}
                        >
                          <span className="mb-0">
                            <User className="icon" />
                          </span>
                        </button>
                        {user == null ? (
                          <ul
                          className="dropdown-menu login-submenu"
                          aria-labelledby="dropdownMenuButton2"
                        >
                          <li className="li-submenu-agent">

                            <a
                              href="#"
                              className={classNames("dropdown-item icon-dropdown")}
                              onClick={() => {
                                if (!login) navigate("/agent");
                              }}
                            >
                              <div className="row" style={{flexWrap:"nowrap", justifyContent:"center"}}>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  <Agent className="login-agent-icon" width={30} height={30} />
                                </div>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  {t("agent")}
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="li-submenu-staff">
                            <a
                              href="#"
                              className={classNames("dropdown-item icon-dropdown")}
                              onClick={() => {
                                if (!login) navigate("/staff");
                              }}
                            >
                               <div className="row" style={{flexWrap:"nowrap", justifyContent:"center"}}>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  <Staff className="login-agent-icon" width={20} height={30} stroke={"none"} />
                                </div>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  {t("staff")}
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                        ) : (
                          <ul
                            className="dropdown-menu text-center py-4 px-3"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <li>
                              <img src={logo} className="logo-popup" />
                            </li>
                            <li className="my-3">
                              <img
                                src={
                                  user.photo === "" ? PicProfile : user.photo
                                }
                                alt=""
                                className="profilepic-navber"
                              />
                            </li>
                            <li className="mb-4">
                              {user.isStaff ? user.staffFName + " " + user.staffLName : user.fName + " " + user.lName}
                            </li>
                            <li className="mb-2">
                              <a href={baseLink + routePath + "/changepw"}>
                                <Button className="btn-changepw text-capitalize btn-light btn-shadow">
                                  {t("change_password")}
                                </Button>
                              </a>
                            </li>
                            <li>
                              <Button
                                className="btn-logout text-capitalize btn-success"
                                onClick={setLogout}
                              >
                                {t("logout")}
                              </Button>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 p-0">
                  <div className="col-nav border-right">
                    <div className="wrapper ">
                      <div className="mb-0 open-cart" onClick={goToCart}>
                        <Cart className="icon" />
                      </div>
                      <div>
                        {cItems != "" && (
                          <span className="icon-button-badge">{cItems}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 p-0 bg-black color-white">
                  <div className="col-nav">
                    <div className="wrapper ">
                      <a
                        href="#"
                        className="mb-0"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo03"
                        aria-controls="navbarTogglerDemo03"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <Hamburger className="icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            collapsed == true
              ? "collapse navbar-collapse"
              : "collapse navbar-collapse hide"
          }
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">
            <li className="nav-item">
              <div className="col-nav">
                <div className="wrapper ">
                  {/* ticket/AWPK001/1 */}
                  <a
                    className={
                      activeTab == "ordered"
                        ? "mb-0 ordered active"
                        : "mb-0 ordered"
                    }
                    onClick={() => {
                      if (user != null) {
                        navigate("/order");
                        handleCollapsedClick();
                      }else{
                        setModalMessage("please_login");
                        setOpenFailedModal(true);
                      }
                    }}
                  >
                    {t("ordered")}
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="col-nav">
                <div
                  className="wrapper "
                  style={{ pointerEvents: loadCampaign ? "context" : "none" }}
                >
                  {/* ticket/AWPK001/1 */}
                  <a
                    className={
                      activeTab == "ticket"
                        ? "mb-0 ticket active"
                        : "mb-0 ticket"
                    }
                    onClick={() => {
                      if (user != null) {
                        navigate("/ticket/AGPK001/1");
                        handleCollapsedClick();
                        sessionStorage.removeItem("AgentRoute");
                      }else{
                        setModalMessage("please_login");
                        setOpenFailedModal(true);
                      }
                    }}
                  >
                    {t("tickets")}
                  </a>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <div className="col-nav">
                <div className="wrapper text-center ">
                  <p className="mb-1 font-8 color-grey">{t("opening_hours")}</p>
                  <p className="mb-0 font-14 weight-700">{t("time_opening")}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {/* Mobile */}

      {/* PC */}
      <nav className="navbar navbar-expand-lg fixed-top d-none d-lg-block">
        <div className="container-fluid ps-4">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="col-4 "
            style={{ pointerEvents: loadCampaign ? "inherit" : "none" }}
          >
            <a
              className="navbar-brand"
              href={baseLink + routePath == "" ? "/" : baseLink + routePath}
            >
              <img src={logo} alt="" className="logo" />
            </a>
          </div>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navmain">
              <li className="nav-item">
                <div className="col-nav p-0">
                  <div
                    className="wrapper "
                    style={{ pointerEvents: loadCampaign ? "inherit" : "context-menu", cursor: !!user ? "pointer" : "context-menu" }}
                  >
                    <a
                      className={
                        activeTab == "ordered"
                          ? "mb-0 ordered active"
                          : "mb-0 ordered "
                      }
                      onClick={() => {
                        if (user != null) {
                          setActiveTab("ordered");
                          navigate("/order");
                        }else{
                          setModalMessage("please_login");
                          setOpenFailedModal(true);
                        }
                      }}
                    // style={{ backgroundColor: "#77e7ff" }}
                    >
                      {t("ordered")}
                    </a>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="col-nav p-0">
                  <div
                    className="wrapper "
                    style={{ pointerEvents: loadCampaign ? "inherit" : "context-menu", cursor: !!user ? "pointer" : "context-menu" }}
                  >
                    <a
                      className={
                        activeTab == "ticket"
                          ? "mb-0 ticket active "
                          : "mb-0 ticket "
                      }
                      onClick={() => {
                        if (user != null) {
                          setActiveTab("ticket");
                          navigate("/ticket/AGPK001/1");
                          sessionStorage.removeItem("AgentRoute");
                        }else{
                          setModalMessage("please_login");
                          setOpenFailedModal(true);
                        }
                      }}
                    // style={{ backgroundColor: "#77e7ff" }}
                    >
                      {t("tickets")}
                    </a>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="col-nav border-right">
                  <div className="wrapper text-center ">
                    <p className="mb-1 font-8 color-grey">
                      {t("opening_hours")}
                    </p>
                    <p className="mb-0 font-14 weight-700">
                      {t("time_opening")}
                    </p>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="col-nav col-3 p-0 border-right border-left">
                    <div className="wrapper text-center ">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {currentLanguage.name}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {languages.map(
                            ({ code, name, list, country_code }) => (
                              <li key={country_code}>
                                <a
                                  href="#"
                                  className={classNames("dropdown-item", {
                                    disabled: currentLanguageCode === code,
                                  })}
                                  onClick={() => {
                                    i18next.changeLanguage(code);
                                  }}
                                >
                                  <span
                                    style={{
                                      opacity:
                                        currentLanguageCode === code ? 0.5 : 1,
                                    }}
                                  ></span>
                                  {list}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="col-nav border-right">
                  <div className="wrapper">
                    <div className="dropdown companymenuli">
                      {/* <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onMouseOver={showUserLogin}
                      > */}
                        <a style={{pointerEvents:"inherit"}} className="mb-0" href="#" onMouseOver={showUserLogin}>
                          <User className="icon" />
                        </a>
                      {/* </button> */}
                      {user == null ? (
                        <ul
                          className="dropdown-menu login-submenu"
                          aria-labelledby="dropdownMenuButton2"
                        >
                          <li className="li-submenu-agent">

                            <a
                              href="#"
                              className={classNames("dropdown-item icon-dropdown")}
                              onClick={() => {
                                if (!login) navigate("/agent");
                              }}
                            >
                              <div className="row" style={{flexWrap:"nowrap", justifyContent:"center"}}>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  <Agent className="login-agent-icon" width={30} height={30} />
                                </div>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  {t("agent")}
                                </div>
                              </div>
                            </a>
                          </li>
                          <li className="li-submenu-staff">
                            <a
                              href="#"
                              className={classNames("dropdown-item icon-dropdown")}
                              onClick={() => {
                                if (!login) navigate("/staff");
                              }}
                            >
                               <div className="row" style={{flexWrap:"nowrap", justifyContent:"center"}}>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  <Staff className="login-agent-icon" width={20} height={30} stroke={"none"} />
                                </div>
                                <div className="col-6" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                  {t("staff")}
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      ) : (
                        <ul
                          className="dropdown-menu box-logout submenu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li>
                            <img
                              src={logoxl}
                              className="logo-popup"
                              style={{ width: "55%" }}
                            />
                          </li>
                          <li className="box-text-signin">
                            <img
                              src={user.photo === "" ? PicProfile : user.photo}
                              onError={() => (user.photo = userDefaultImg)}
                              className="logo profilepic"
                            />
                          </li>
                          <li className="box-text-signin">
                            {user.isStaff ? user.staffFName + " " + user.staffLName : user.fName + " " + user.lName}
                          </li>
                          <li className="mb-3">
                            <a href={baseLink + "/changepw"}>
                              <Button className="text-capitalize btn-light btn-shadow">
                                {t("change_password")}
                              </Button>
                            </a>
                          </li>
                          <li>
                            <Button
                              className="text-capitalize btn-success"
                              onClick={setLogout}
                            >
                              {t("logout")}
                            </Button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <div className="col-nav border- p-0">
                  <div className="wrapper ">
                    <span
                      className="mb-0 p-3 open-cart"
                      style={{ cursor: "pointer" }}
                      onClick={goToCart}
                    >
                      <Cart className="icon" />
                      {cItems != "" && (
                        <span className="icon-button-badge">{cItems}</span>
                      )}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* PC */}

      <ModalFailed
        open={failedModal}
        body={t(modalMessage)}
        setClose={closeFailedModal}
        back={closeFailedModal}
      />

      <ToastContainer
        position="top-right"
        autoClose={1000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default Navbar;
