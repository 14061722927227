import React, { useEffect, useState } from "react";
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { ImageLoader } from "react-image-and-background-image-fade";
import Product from "../../component/img/product-sample.jpg";
import Pass from "../../component/img/pass.png";
import TermIcon from "../../component/img/info.png";
import noImg from "../../component/img/no_image.png";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import { useMediaQuery } from "react-responsive";
import { packageTypeTicket } from "../../constants/DataPublic";
import { useGlobalState } from "../../global";

import { apiLink_ecom } from "../../constants/DataPublic";
import { CHECK_ALLOTMENT, LOCATION_LOOKUP } from "../../services/BASE_API";
import Backdrop from "@mui/material/Backdrop";
import ModalSuccess from "../../component/Modal/ModalSuccess";
import ModalFailed from "../../component/Modal/ModalFailed";
import { Form } from "react-bootstrap";
import { ConfirmChangeVisitDate } from "../Modal/ConfirmChangeVisitDate";
import { Visibility } from "@material-ui/icons";

// Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  TextField,
} from "@mui/material";
import moment from "moment";
import 'moment/locale/th'
import { red } from "@mui/material/colors";

export const AddSubTicket = (props) => {
  const { isAgent } = props;
  const { available } = props;
  const { currentStep } = props;
  const { picturePath } = props;
  const { packageTypeTicket } = props;
  const { itemList } = props;
  const { itemQty } = props;
  const { netPrice } = props;
  const { selectedI } = props;
  const { subTotalI } = props;
  const { editI } = props;
  const { selectedDate } = props;
  const { requiredDate } = props;
  const { selectedTime } = props;
  const { requiredTime } = props;
  const { requiredLocation } = props;
  const { selectedLocation } = props;
  const {
    location,
    timeList,
    cabanaList,
    cart,
    fromBuyNow,
    onUpdateVisitDateAll,
    language
  } = props;

  const [routePath, setRoutePath] = useGlobalState("route");
  const [availableCabanaLimit, setAvailableCabanaLimit] = useGlobalState(
    "availableCabanaLimit"
  );
  const [visitingDate, setVisitingDate] = useGlobalState("visitingDate");
  const [visitingDateCabana, setVisitingDateCabana] =
    useGlobalState("visitingDateCabana");
  const [myCart, setMyCart] = useState(
    JSON.parse(sessionStorage.getItem("MyCart"))
  );

  const [cabanaAllotment, setCabanaAllotment] = useState(
    JSON.parse(sessionStorage.getItem("CabanaAllotment"))
  );

  const [cabanaVisitingDate, setCabanaVisitingDate] = useState(
    sessionStorage.getItem("CabanaVisitingDate")
  );

  const [availableCabana, setAvailableCabana] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false); //Success Modal
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [openChangeVisitDate, setOpenChangeVisitDate] = useState(false); //Change VisitDate Modal
  const [aleart, setAleart] = useState("");
  const [showErrorAllotment, setShowErrorAllotment] = useState(false);
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [selectedTimeName, setSelectedTimeName] = useState(null);
  const [requiredVisitDate, setRequiredVisitDate] = useState(false);
  const [requiredLocationed, setRequiredLocationed] = useState(false);
  const [requiredTimed, setRequiredTimed] = useState(false);
  const [loadingCanabaAllotment, setLoadingCanabaAllotment] = useState(false);

  useEffect(()=>{
    moment.locale(i18n.language);
  }, [language])

  const setCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
    // if (myCart.length > 0) {
    //   // sessionStorage.setItem("MyCart",JSON.stringify([]))
    //   window.location.reload();
    // }
  };

  const setCloseChangeVisitDate = () => {
    setOpenChangeVisitDate(false);
  };

  const onComfirmChangeVisitDate = () => {
    setOpenChangeVisitDate(false);
    updateMyCart();
    if (itemQty > 0) setSelectedI("hidden");
    window.location.reload();
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 991px)",
  });

  const setItemQty = (item) => {
    props.setItemQty(item);
  };

  const addToCart = (item, isEdit) => {
    // console.log("item", item);
    props.addToCart(item, isEdit);
  };

  const setSelectedI = (item) => {
    props.setSelectedI(item);
  };

  const setRequiredDate = (item) => {
    props.setRequiredDate(item);
  };

  const setSelectedDate = async (date, ItemCode) => {
    console.log("Locate :", moment.locale())
    // console.log("DATE", date);
    setVisitingDate(moment(date).format("YYYY-MM-DD"));
    let convertDate = moment(date).format("YYYY-MM-DD");
    // console.log("DATED", convertDate);
    if (date != visitingDate) {
      await CallServicePostCheckAllotment(convertDate, ItemCode);
      // console.log(date);
    }
  };

  // const setRequiredTime = (item) => {
  //   props.setRequiredTime(item);
  // };

  const setSelectedTime = (item) => {
    const time = timeList.filter((x) => x.TimeId == item);
    // console.log("time", time);
    if (time.length > 0) {
      setSelectedTimeName(time[0].TimeName);
    } else {
      setSelectedTimeName(null);
    }
    props.setSelectedTime(item);
  };

  const setSelectedLocation = (item) => {
    const lo = location.filter((x) => x.LocationId == item);
    // console.log("location", lo);
    if (lo.length > 0) {
      setSelectedLocationName(lo[0].LocationName);
    } else {
      setSelectedLocationName(null);
    }
    props.setSelectedLocation(item);
  };

  const { t, i18n } = useTranslation();

  async function CallServicePostCheckAllotment(date, ItemCode) {
    setLoading(true);
    setLoadingCanabaAllotment(true);
    // cabanaList
    let allotmentList = [];
    // const listAllCabana = cabanaList != null ? cabanaList : cabanaListAll;
    // console.log("cabanaList", cabanaList);
    if (cabanaList != null) {
      if (ItemCode != null) {
        cabanaList.map((item) => {
          if (availableCabanaLimit != null) {
            if (item === ItemCode) {
              let allotment = {
                ItemCode: item,
                Qty: itemQty,
              };
              allotmentList.push(allotment);
            } else {
              let qty = 1;
              availableCabanaLimit.map((data) => {
                if (data.ItemCode === item) {
                  let allotment = data.AvailableMax - data.AvailableBalance;
                  if (allotment > 0) {
                    qty = allotment;
                  }
                }
              });
              let allotment = {
                ItemCode: item,
                Qty: qty,
              };
              allotmentList.push(allotment);
            }
          } else {
            if (item == ItemCode) {
              let allotment = {
                ItemCode: item,
                Qty: itemQty,
              };
              allotmentList.push(allotment);
            } else {
              let allotment = {
                ItemCode: item,
                Qty: 1,
              };
              allotmentList.push(allotment);
            }
          }
        });
      } else {
        cabanaList.map((item) => {
          let allotment = {
            ItemCode: item,
            Qty: 1,
          };
          allotmentList.push(allotment);
        });
      }
    } else {
      setLoading(false);
      setLoadingCanabaAllotment(false);
    }
    if (allotmentList.length > 0) {
      let body = {
        ArrivalDate: date,
        AllotmentItems: allotmentList,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18n.language,
        },
        body: JSON.stringify(body),
      };
      await fetch(apiLink_ecom + CHECK_ALLOTMENT, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          setLoading(false);
          setLoadingCanabaAllotment(false);
          if (data["Success"]) {
            const item = data["Result"];
            console.log("availableCabanaLimit:", availableCabanaLimit);
            const statusFail = item.filter(
              (x) =>
                x.Status == false &&
                x.ItemCode === itemList.VisitingDateItemCode
            );
            // if(statusFail.length > 0){

            // } else {
            changeSetAllotmentCabana(item);
            // }
          } else {
            setOpenFailedModal(true);
            setAleart(data["Message"]);
            // setAvailableCabanaLimit(null);
            // sessionStorage.setItem("CabanaAllotment", null);
            // sessionStorage.setItem("MyCart", JSON.stringify([]));
            // setItemQty(1);
          }
          props.setSelectedDate(date);
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + CHECK_ALLOTMENT, error);
          setLoading(false);
          setLoadingCanabaAllotment(false);
          return false;
        });
    } else {
      setLoading(false);
      setLoadingCanabaAllotment(false);
    }
  }

  const onSetCabanaAllotment = (dataAllotment) => {
    if (cabanaAllotment != null) {
      if (cabanaAllotment.length > 0) {
        if (cabanaAllotment.ItemCode === dataAllotment.ItemCode) {
          const list = {
            ItemCode: dataAllotment.ItemCode,
            AvailableBalance: dataAllotment.AvailableBalance,
            VisitingDate: dataAllotment.VisitingDate,
            AvailableMax: dataAllotment.AvailableMax,
          };
          // setCabanaAllotment(list);
          sessionStorage.setItem("CabanaAllotment", JSON.stringify(list));
          //console.log("CabanaAllotment");
        } else {
          // setCabanaAllotment(dataAllotment);
          sessionStorage.setItem(
            "CabanaAllotment",
            JSON.stringify(dataAllotment)
          );
          //console.log("CabanaAllotment");
        }
      } else {
        // setCabanaAllotment(dataAllotment);
        sessionStorage.setItem(
          "CabanaAllotment",
          JSON.stringify(dataAllotment)
        );
        //console.log("CabanaAllotment");
      }
    } else {
      // setCabanaAllotment(dataAllotment);
      sessionStorage.setItem("CabanaAllotment", JSON.stringify(dataAllotment));
      console.log("CabanaAllotment");
    }
  };

  const changeSetAllotmentCabana = (allotmentList) => {
    let listAvailableCabana = [];
    allotmentList.map((item) => {
      const list = {
        ItemCode: item.ItemCode,
        AvailableBalance: item.Available,
        VisitingDate: item.ArrivalDate.split("T")[0],
        AvailableMax: item.Available,
      };
      listAvailableCabana.push(list);
    });
    setAvailableCabanaLimit(listAvailableCabana);
    onSetCabanaAllotment(listAvailableCabana);
  };

  const onChangeQtyPlus = (qty) => {
    if (availableCabanaLimit != null) {
      let listAvailableCabana = [];
      availableCabanaLimit.map((item) => {
        if (item.ItemCode === itemList.VisitingDateItemCode) {
          if (qty > item.AvailableBalance) {
            setShowErrorAllotment(true);
          } else {
            setShowErrorAllotment(false);
          }
          const newList = {
            ItemCode: item.ItemCode,
            AvailableBalance: item.AvailableBalance,
            VisitingDate: item.VisitingDate,
            AvailableMax: item.AvailableMax,
          };
          listAvailableCabana.push(newList);
          setItemQty(qty);
        } else {
          const list = {
            ItemCode: item.ItemCode,
            AvailableBalance: item.AvailableBalance,
            VisitingDate: item.VisitingDate,
            AvailableMax: item.AvailableMax,
          };
          listAvailableCabana.push(list);
        }
      });
      setAvailableCabanaLimit(listAvailableCabana);
    }
  };

  const onChangeQtyMinu = (qty) => {
    if (availableCabanaLimit != null) {
      let listAvailableCabana = [];
      availableCabanaLimit.map((item) => {
        if (item.ItemCode === itemList.VisitingDateItemCode) {
          if (qty > item.AvailableBalance) {
            setShowErrorAllotment(true);
          } else {
            setShowErrorAllotment(false);
          }
          const newList = {
            ItemCode: item.ItemCode,
            AvailableBalance: item.AvailableBalance,
            VisitingDate: item.VisitingDate,
            AvailableMax: item.AvailableMax,
          };
          listAvailableCabana.push(newList);
          setItemQty(qty);
        } else {
          const list = {
            ItemCode: item.ItemCode,
            AvailableBalance: item.AvailableBalance,
            VisitingDate: item.VisitingDate,
            AvailableMax: item.AvailableMax,
          };
          listAvailableCabana.push(list);
        }
      });
      setAvailableCabanaLimit(listAvailableCabana);
    }
  };

  const checkAvailable = () => {
    if (itemList.VisitingDateItemCode != null) {
      if (availableCabanaLimit != null) {
        let disable = false;
        availableCabanaLimit.map((item) => {
          if (item.ItemCode === itemList.VisitingDateItemCode) {
            if (
              (item.AvailableBalance == 0 && itemQty == 1) ||
              showErrorAllotment
            ) {
              disable = true;
            }
          }
        });
        return disable;
      } else {
        return true;
      }
    } else if (itemList.IsTransportationPackage == true) {
      if (
        visitingDate != null &&
        selectedTime != null &&
        selectedTime != "" &&
        selectedLocation != null &&
        selectedLocation != ""
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    console.log("useEffect >> ", availableCabanaLimit);
    if (availableCabanaLimit != null) {
      availableCabanaLimit.map((item) => {
        if (item.ItemCode === itemList.VisitingDateItemCode) {
          if (!selectedI) {
            if (item.AvailableBalance < itemQty) {
              setShowErrorAllotment(true);
            } else {
              setShowErrorAllotment(false);
            }
          }
        }
        props.setSelectedDate(item.VisitingDate);
      });
    } else {
      setItemQty(1);
    }
  }, [availableCabanaLimit]);

  useEffect(async () => {
    console.log("visitingDate (useEffect) >> ", visitingDate);
    if (visitingDate != null) {
      // props.setSelectedDate(visitingDate);
      setSelectedDate(visitingDate, itemList.PackageCode);
      if (cart.length != 0) {
        sessionStorage.setItem("CabanaVisitingDate", visitingDate);
      }
      var cabanaAllot = JSON.parse(sessionStorage.getItem("CabanaAllotment"));
      console.log("cabanaAllot >> ", cabanaAllot);
      console.log("cabanaAllotment >> ", cabanaAllotment);
      console.log("fromBuyNow >> ", fromBuyNow);
      // console.log("cabanaAllotment == null && checkAllotmentFail == false && fromBuyNow == false >> ",cabanaAllotment == null && checkAllotmentFail == false && fromBuyNow == false);
      if (cabanaAllot == null && fromBuyNow == true && loadingCanabaAllotment == false) {
        console.log("CallServicePostCheckAllotment >> visitingDate");
        await setLoadingCanabaAllotment(true);
        await CallServicePostCheckAllotment(
          visitingDate,
          itemList.VisitingDateItemCode
        );
      }
    }
  }, [visitingDate]);

  const checkUpdateMyCart = () => {
    if (itemList.VisitingDateItemCode != null) {
      if (visitingDate != null) {
        setRequiredVisitDate(false);
        if (cart.length > 0) {
          console.log("visitingDate", visitingDate);
          let dateMatch = cart.filter(
            (x) => moment(x.VisitDate).format("YYYY-MM-DD") !== moment(visitingDate).format("YYYY-MM-DD") && x.VisitDate != null
          );
          console.log("dateMatch length", dateMatch.length);
          if (dateMatch.length > 0) {
            setOpenChangeVisitDate(true);
          } else {
            sessionStorage.setItem("CabanaVisitingDate", visitingDate);
            addMyCart();
            if (itemQty > 0) setSelectedI("hidden");
          }
        } else {
          sessionStorage.setItem("CabanaVisitingDate", visitingDate);
          addMyCart();
          if (itemQty > 0) setSelectedI("hidden");
        }
      } else {
        // error
        // let datePicker = document.getElementById("startDate");
        // datePicker.focus();
        setRequiredVisitDate(true);
      }
    } else if (itemList.IsTransportationPackage == true) {
      if (
        visitingDate != null &&
        selectedTime != null &&
        selectedTime != "" &&
        selectedLocation != null &&
        selectedLocation != ""
      ) {
        if (cart.length > 0) {
          // console.log("visitingDate", visitingDate);
          let dateMatch = cart.filter(
            (x) => moment(x.VisitDate).format("YYYY-MM-DD") !== moment(visitingDate).format("YYYY-MM-DD") && x.VisitDate != null
          );
          // console.log("dateMatch length", dateMatch.length);
          if (dateMatch.length > 0) {
            setOpenChangeVisitDate(true);
          } else {
            sessionStorage.setItem("CabanaVisitingDate", visitingDate);
            addMyCart();
            if (itemQty > 0) setSelectedI("hidden");
          }
        } else {
          sessionStorage.setItem("CabanaVisitingDate", visitingDate);
          addMyCart();
          if (itemQty > 0) setSelectedI("hidden");
        }
      } else {
        if (visitingDate == null) {
          setRequiredVisitDate(true);
        }
        if (selectedTime == null || selectedTime == "") {
          setRequiredTimed(true);
        }
        if (selectedLocation == null || selectedLocation == "") {
          setRequiredLocationed(true);
        }
      }
    } else {
      addMyCart();
      if (itemQty > 0) setSelectedI("hidden");
    }
  };

  const addMyCart = () => {
    // console.log("selectedTimeName", selectedTimeName);
    addToCart(
      {
        PackageCode: itemList.PackageCode,
        PackageName: itemList.PackageName,
        ItemCode: "",
        ItemName: "",
        Qty: itemQty,
        Tax: 0,
        ItemPrice: netPrice,
        ItemDiscount: itemList.IsDiscount ? itemList.DiscountPrice : 0,
        PriceName: itemList.PriceName,
        PriceUnit: "",
        TotalPrice: itemQty * netPrice,
        ItemCodeCabana: itemList.VisitingDateItemCode,
        VisitDate:
          itemList.VisitingDateItemCode != null ||
          itemList.IsTransportationPackage != false
            ? selectedDate === ""
              ? null
              : selectedDate
            : null,
        VisitTime: selectedTime,
        VisitLocation: selectedLocation,
        VisitTimeName: selectedTimeName,
        VisitLocationName: selectedLocationName,
        RatePackage: itemList.ContractVenueRatePackage,
        BasePrice: itemList.PackagePrice,
        step: currentStep,
        status: "normal",
      },
      editI
    );
  };

  const updateMyCart = async () => {
    setVisitingDateCabana(visitingDate);
    sessionStorage.setItem("CabanaVisitingDate", visitingDate);
    // addMyCart();
    await onUpdateVisitDateAll(visitingDate, {
      PackageCode: itemList.PackageCode,
      PackageName: itemList.PackageName,
      ItemCode: "",
      ItemName: "",
      Qty: itemQty,
      Tax: 0,
      ItemPrice: netPrice,
      ItemDiscount: itemList.IsDiscount ? itemList.DiscountPrice : 0,
      PriceName: itemList.PriceName,
      PriceUnit: "",
      TotalPrice: itemQty * netPrice,
      ItemCodeCabana: itemList.VisitingDateItemCode,
      VisitDate:
        itemList.VisitingDateItemCode != null ||
        itemList.IsTransportationPackage != false
          ? selectedDate === ""
            ? null
            : selectedDate
          : null,
      VisitTime: selectedTime,
      VisitLocation: selectedLocation,
      VisitTimeName: selectedTimeName,
      VisitLocationName: selectedLocationName,
      RatePackage: itemList.ContractVenueRatePackage,
      BasePrice: itemList.PackagePrice,
      step: currentStep,
      status: "normal",
    });
  };

  useEffect(() => {
    // console.log("selectedI >> ", selectedI);
    if (!selectedI) {
      // console.log("visitingDate >> ", visitingDate);
      if (visitingDate == null) {
        // console.log("cart >> ", cart);
        if (cart != null) {
          cart.map((item) => {
            // console.log("itemList.PackageCode >> ", itemList.PackageCode);
            // console.log("item.PackageCode >> ", item.PackageCode);
            console.log(
              "itemList.PackageCode === item.PackageCode >> ",
              itemList.PackageCode === item.PackageCode
            );
            if (itemList.PackageCode === item.PackageCode) {
              // console.log("setVisitingDate >> ", item.VisitDate);
              if (item.VisitDate != null) {
                setVisitingDate(item.VisitDate);
              }
            }
          });
        } else {
          // console.log("visitingDate: null");
          setAvailableCabanaLimit(null);
        }
      }
    } else {
      if (visitingDate == null) {
        // console.log("visitingDate: null");
        setAvailableCabanaLimit(null);
      }
    }
    // console.log("cabanaAllotment: ",cabanaAllotment.length);
    if (availableCabanaLimit == null && cabanaAllotment != null) {
      if (cabanaAllotment.length != undefined) {
        setAvailableCabanaLimit(cabanaAllotment);
      }
    }
  }, []);

  const checkPackageInMyCart = () => {
    if (cart != null && cart.length > 0) {
      const inCart = cart.filter(
        (x) =>
          x.PackageCode === itemList.PackageCode ||
          x.ItemCode === itemList.ItemCode
      );
      if (inCart.length > 0) {
        return "hidden";
      } else {
        // setItemQty(1);
        return "";
      }
    } else {
      return "";
    }
  };

  const onNumerPWKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
      // console.log("event", event);
    }
  };

  const onGetDateSelectVisitDate = () => {
    // let dateNow = new Date();
    // let dateSelect = new Date(dateNow);
    // dateSelect.setDate(dateNow.getDate());
    let dateSelect = moment();
    return dateSelect;
  };

  const FormatString = (amount)=>{
    amount = amount.toString();
    const resultPrice = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g,"$1,");
    return resultPrice;
  };

  const today = moment();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0E315C",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.invalid .MuiOutlinedInput-notchedOutline": {
              borderColor: red[500],
            },
          },
        },
      },
    },
  });

  return (
    <>
      {/** To Flip */}
      <div className="col-lg-6 ">
        <img
          src={picturePath}
          className="img-fluid w-100 d-block d-lg-none mb-3"
        />
        <div className="box box-border mb-0">
          <div className="box-body ">
            <div className="row">
              {/* <div className="col-7 desc">
                {itemList.IsAddonDiscount && (
                  <div className="d-none d-lg-block">
                    <span className="bubble mb-3">
                      3rd Ticket at
                      <br />
                      50% Discount
                    </span>
                  </div>
                )}
                <div className="d-block d-lg-none">
                  <p>
                  </p>
                </div>
              </div> */}
              {/* {itemList.PackageTypeCode === packageTypeTicket && ( */}
              {!isDesktopOrLaptop && (
                <div className="col-6 desc">
                  <p className="font-14 mb-2">
                    <strong>{t("overview")} :</strong>
                  </p>
                  {itemList.PackageItemLists.map((e, i) => {
                    return (
                      <p>
                        <Markup
                          key={i}
                          content={
                            e.ItemType == "Ticket"
                              ? "● " + e.Qty + " " + e.ItemName
                              : "+ " + e.Qty + " " + e.ItemName
                          }
                        />
                      </p>
                    );
                  })}
                  {/* <Markup content={itemList.PackageDescription} /> */}
                </div>
              )}

              {/* // )} */}
              {itemList.IsDiscount && (
                <div className="col text-end">
                  <p className="font-20 weight-700 color-orange mb-0 lh-1">
                    {netPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {t("thb")}
                  </p>
                  {/* <p className="font-20 weight-700 color-orange mb-0 lh-1">
                    {countRatePrice().Price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {t("thb")}
                  </p> */}
                  <p className="oldPrice">
                    {itemList.PackagePrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {t("thb")}
                  </p>
                  {itemList.IsAddonDiscount && (
                    <div className="d-block d-lg-none">
                      <span className="bubble mb-3">
                        3rd Ticket at
                        <br />
                        50% Discount
                      </span>
                    </div>
                  )}
                </div>
              )}
              {!itemList.IsDiscount && (
                <div className="col-5 text-end">
                  <p className="font-20 weight-700 mb-3 lh-1">
                    {netPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {t("thb")}
                  </p>
                </div>
              )}
              <div className="col-12">
                <p className="color-orange font-10 mb-0 weight-700">
                  <em
                    dangerouslySetInnerHTML={{
                      __html: itemList.PackageDescription,
                    }}
                  ></em>
                  {/* <em>{t('ticket_expire')} </em> */}
                  {/* <em>{itemList.PackageExpireMessage}</em> */}
                </p>
              </div>
              <div className="col-12">
                <hr className="mt-2 mb-3" />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-6 desc">
                <p className="font-14 mb-0">{t("quantity")}</p>
              </div>
              <div className="col-6 text-end">
                <div className="input-group ms-auto" style={{ width: "100%" }}>
                  <span className="input-group-btn">
                    <button
                      type="button"
                      className={`btn  btn-number minus ${checkPackageInMyCart()}`}
                      data-type="minus"
                      data-field="quant[1]"
                      onClick={(e) => {
                        if (itemList.VisitingDateItemCode != null) {
                          if (availableCabanaLimit != null) {
                            if (itemQty > 1) {
                              onChangeQtyMinu(itemQty - 1);
                            } else {
                              setItemQty(1);
                            }
                          } else {
                            if (itemQty > 1)
                              setItemQty((itemQty) => itemQty - 1);
                            else setItemQty(1);
                          }
                        } else {
                          if (itemQty > 1) setItemQty((itemQty) => itemQty - 1);
                          else setItemQty(1);
                        }
                      }}
                    >
                      <span className="material-icons">remove</span>
                    </button>
                  </span>
                  <input
                    type="text"
                    name="quant[1]"
                    // className="form-control input-number"
                    className="input-text-qty"
                    autoComplete="off"
                    // style={{ marginLeft: "-3px" }}
                    value={itemQty && FormatString(itemQty)}
                    defaultValue={1}
                    min={1}
                    maxLength={4}
                    onKeyPress={onNumerPWKeyDown}
                    onChange={(e) => {
                      let qty = parseInt(
                        e.target.value != undefined ? e.target.value.replace(",","") : "1"
                      );
                      // console.log(qty);
                      // console.log(isNaN(qty));
                      if (isNaN(qty)) {
                        setItemQty(e.target.value);
                      } else {
                        if (itemList.VisitingDateItemCode != null) {
                          if (availableCabanaLimit != null) {
                            onChangeQtyPlus(qty);
                          } else {
                            setItemQty(qty);
                          }
                        } else {
                          setItemQty(qty);
                        }
                      }
                    }}
                    onBlur={(e) => {
                      let qty = parseInt(
                        e.target.value != undefined ? e.target.value.replace(",","") : "1"
                      );
                      if (isNaN(qty)) {
                        // setItemQty(1);
                        qty = 1;
                      } else {
                        if (qty <= 0) {
                          qty = 1;
                        }
                      }
                      if (itemList.VisitingDateItemCode != null) {
                        if (availableCabanaLimit != null) {
                          onChangeQtyPlus(qty);
                        } else {
                          setItemQty(qty);
                        }
                      } else {
                        setItemQty(qty);
                      }
                    }}
                    disabled={checkPackageInMyCart()}
                  />
                  <span className="input-group-btn">
                    <button
                      type="button"
                      className={`btn btn-number plus ${checkPackageInMyCart()}`}
                      data-type="plus"
                      data-field="quant[1]"
                      onClick={(e) => {
                        if (itemList.VisitingDateItemCode != null) {
                          if (availableCabanaLimit != null) {
                            onChangeQtyPlus(itemQty + 1);
                          } else {
                            setItemQty(itemQty + 1);
                          }
                        } else {
                          setItemQty(itemQty + 1);
                        }
                      }}
                    >
                      <span className="material-icons">add</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-3">
          {isAgent && (
            <>
              {itemList.VisitingDateItemCode == null &&
              itemList.IsTransportationPackage == false ? (
                <></>
              ) : (
                <>
                  <div className="card-header d-flex justify-content-center">
                    <h5 className="text-center mb-0">
                      {/* {itemList.IsTransportationPackage
                        ? "TRANSPOTATION"
                        : itemList.PackageName}{" "} */}
                        {itemList.PackageName}
                    </h5>
                  </div>
                  <div class="container">
                    <div class="row pt-2">
                      <div class="col-4 p-0 ">
                        <p
                          className="p-0 d-flex justify-content-center align-items-center  h-100"
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {t("visitdate")}
                        </p>
                      </div>
                      <div class="col-8">
                        <ThemeProvider theme={theme}>
                          <CssBaseline />
                          <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            dateFormats={"DD/MM/YYYY"}
                            adapterLocale={i18n.language}
                          >
                            <DatePicker
                              id="startDate"
                              defaultValue={today}
                              // style={{
                              //   borderColor:
                              //     requiredVisitDate == true ? "red" : "#ced4da",
                              // }}
                              className={requiredVisitDate ? "invalid" : ""}
                              format={"DD/MM/YYYY"}
                              dayOfWeekFormatter={(day) => `${day}`}
                              type="date"
                              minDate={onGetDateSelectVisitDate()} //new Date().toISOString().split("T")[0]
                              value={
                                visitingDate == null
                                  ? visitingDate
                                  : moment(visitingDate)
                              }
                              disabled={checkPackageInMyCart()}
                              onChange={(value) => {
                                setRequiredVisitDate(false);
                                const pickDate = value.get().toLocaleString();
                                if (
                                  pickDate <
                                  new Date().toISOString().split("T")[0]
                                ) {
                                  let datePicker =
                                    document.getElementById("startDate");
                                  setSelectedDate("");
                                  datePicker.focus();
                                } else {
                                  setSelectedDate(
                                    value,
                                    itemList.VisitingDateItemCode
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <input
                                  {...params.inputProps}
                                  className={requiredVisitDate ? "" : "invalid"}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </ThemeProvider>
                      </div>
                    </div>
                    {itemList.IsTransportationPackage ? (
                      <>
                        <div class="row pt-2">
                          <div class="col-4 p-0 ">
                            <p
                              className="p-0 d-flex justify-content-center align-items-center  h-100"
                              style={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              {t("location")}
                            </p>
                          </div>
                          <div class="col-8">
                            <Form.Select
                              id="transportation_schedule"
                              value={selectedLocation}
                              className={`form-control ${requiredLocation}`}
                              style={{
                                borderColor:
                                  requiredLocationed == true
                                    ? "red"
                                    : "#ced4da",
                              }}
                              onChange={(e) => {
                                setRequiredLocationed(false);
                                setSelectedLocation(e.target.value);
                              }}
                              name="item_time"
                              disabled={checkPackageInMyCart()}
                            >
                              <option key="blankChoice" hidden value>
                                {t("please_select")}
                              </option>
                              {location != null ? (
                                location.map((row, index) => (
                                  <option value={row.LocationId}>
                                    {row.LocationName}
                                  </option>
                                ))
                              ) : (
                                <></>
                              )}
                            </Form.Select>
                          </div>
                        </div>
                        <div class="row pt-2">
                          <div class="col-4 p-0 ">
                            <p
                              className="p-0 d-flex justify-content-center align-items-center  h-100"
                              style={{ fontSize: "14px", fontWeight: "bold" }}
                            >
                              {t("time")}
                            </p>
                          </div>
                          <div class="col-8">
                            <Form.Select
                              id="transportation_schedule"
                              value={selectedTime}
                              className={`form-control ${requiredLocation}`}
                              onChange={(e) => {
                                setRequiredTimed(false);
                                setSelectedTime(e.target.value);
                              }}
                              style={{
                                borderColor:
                                  requiredTimed == true ? "red" : "#ced4da",
                              }}
                              name="item_time"
                              disabled={checkPackageInMyCart()}
                            >
                              <option key="blankChoice" hidden value>
                                {t("please_select")}
                              </option>
                              {timeList != null ? (
                                timeList.map((row, index) => (
                                  <option value={row.TimeId}>
                                    {row.TimeName}
                                  </option>
                                ))
                              ) : (
                                <></>
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {visitingDate != null &&
                          availableCabanaLimit != null && (
                            <>
                              {availableCabanaLimit.map((item) =>
                                item.ItemCode ===
                                itemList.VisitingDateItemCode ? (
                                  <>
                                    <div class="row pt-2">
                                      <div class="col-4 p-0 ">
                                        <p
                                          className="p-0 d-flex justify-content-center align-items-center  h-100"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: "#008936",
                                          }}
                                        >
                                          {t("available")}
                                        </p>
                                      </div>
                                      <div class="col-8">
                                        <p
                                          className="p-0 d-flex align-items-center h-100"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: "#008936",
                                          }}
                                        >
                                          {item.AvailableBalance}
                                        </p>
                                      </div>
                                    </div>
                                    {showErrorAllotment && (
                                      <div class="row pt-2">
                                        <div class="col-4 p-0 ">
                                          <p
                                            className="p-0 d-flex justify-content-center align-items-center  h-100"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                              color: "#008936",
                                            }}
                                          ></p>
                                        </div>
                                        <div class="col-8">
                                          <p
                                            className="p-0 d-flex align-items-center h-100"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                              color: "#DB2020",
                                            }}
                                          >
                                            {t("service_not_enough")}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )
                              )}
                            </>
                          )}
                      </>
                    )}
                  </div>
                </>
              )}
              {/* {itemList.VisitingDateItemCode == null &&
                itemList.IsTransportationPackage == false && <></>} */}
            </>
          )}

          {!isAgent && (
            <div className="row align-items-center">
              <div className="col-6 pe-0">
                <p className="font-14 weight-700 mb-0">{t("subtotal")}</p>
              </div>
              <div className="col-6 text-end ps-0">
                <p className="font-24 weight-900 color-orange mb-0">
                  {subTotalI.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  <span className="font-18">{t("thb")}</span>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="d-block d-lg-none mb-4">
          {/* {routePath == '/16two' ? (
            <>
              <p className="d-flex font-14 mb-2 weight-600 text-decoration-underline" >
                <a href="https://columbiapicturesaquaverse.com/terms-and-conditions/" target="_blank">
                  <img src={TermIcon} className="TermIcon" />
                  {t('terms_conditions')}{' '}
                </a>
              </p>
            </>
          ) : (
            <> */}
          <p
            className="d-flex font-14 mb-2 weight-600 text-decoration-underline"
            data-bs-toggle="collapse"
            href={"#term" + itemList.PackageCode}
            role="button"
            aria-expanded="false"
            aria-controls="term1"
          >
            <img src={TermIcon} className="TermIcon" />
            {t("terms_conditions")}{" "}
          </p>
          {/* </>
          )} */}

          <div className="collapse" id={"term" + itemList.PackageCode}>
            <div className="card card-body text-left">
              <Markup content={itemList.PackageTermAndCondition} />
            </div>
          </div>
        </div>
        {checkPackageInMyCart() === "" && (
          <div className="col-12 d-">
            <button
              className="btn btn-main"
              style={{ backgroundColor: "#0E315C", color: "#ffffff" }}
              type="button"
              onClick={() => {
                // onUpdateAvailableCabanaLimit();
                checkUpdateMyCart();
              }}
              // disabled={checkAvailable() ? true : false}
            >
              {t(isAgent ? "select" : "addtocart")}
            </button>
          </div>
        )}
        {checkPackageInMyCart() === "hidden" && (
          <span className="btn">
            <Verified className="img- me-3" />
            {t("addtocart")}{" "}
          </span>
        )}
      </div>

      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
      <ModalSuccess
        open={openSuccessModal}
        body={t(aleart)}
        setClose={setCloseSuccessModal}
        //back={backToOrderPage}
        back={setCloseSuccessModal}
      />

      <ModalFailed
        open={openFailedModal}
        body={t(aleart)}
        setClose={setCloseFailedModal}
        //back={backToOrderPage}
        back={setCloseFailedModal}
      />

      <ConfirmChangeVisitDate
        open={openChangeVisitDate}
        setClose={setCloseChangeVisitDate}
        onConfirm={onComfirmChangeVisitDate}
      />
    </>
  );
};
