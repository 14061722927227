import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import imgFailed from "../img/alert.png";

export const ContactWarningModal = (props) => {
  const { open, setClose } = props;
  const { t } = useTranslation();

  const onCloseModal = () => {
    setClose(false);
  };

  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      className="cancelbooking-dialog"
    >
      <ModalHeader closeButton className="filter-header">
        <p>{t("warning")}</p>
      </ModalHeader>
      <ModalBody>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={imgFailed}
            style={{ width: "140px", marginBottom: "50px" }}
          ></img>
        </div>
        <div className="layout-msg-confirm">
          <p>{t("aleart_please_contact_staff")}</p>
        </div>
      </ModalBody>
      <ModalFooter className="footer-order-detail-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-warning-close"
            onClick={(e) => onCloseModal()}
          >
            {t("close")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
