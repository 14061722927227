import React, { useEffect, useRef, useState } from 'react';
import pending from '../component/img/paymentpending.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { apiLink, apiLink_ecom } from "../constants/DataPublic";
import { useMediaQuery } from 'react-responsive'
import { OMISE_INQUIRY } from '../services/BASE_API';

//Component
const PaymentProcessing = (props) => {
  const { language, getCartItem } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 990px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let paymentRoute = JSON.parse(sessionStorage.getItem('PaymentRoute'));

  let guest = JSON.parse(sessionStorage.getItem("GuestInfo"));
  // let confirmNo = "";
  // let guestCode = "";
  // let guestEmail = "";

  const [confirmNo, setConfirmNo] = useState("");
  const [guestCode, setGuestCode] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (paymentRoute != null) {
      setConfirmNo(paymentRoute.confirmationNo);
      setGuestCode(paymentRoute.guestCode);
      setGuestEmail(paymentRoute.guestEmail);
      setTotalPrice(paymentRoute.totalPrice);
    } else {
      setConfirmNo(user.confirmationNo);
      setGuestCode(user.guestCode);
      setGuestEmail(guest.email);
      setTotalPrice(user.totalPrice);
    }
  }, [])

  useEffect(() => {
    if ((confirmNo != "", guestCode != "")) {

      const interval = setInterval(async () => {
        await checkStatus();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [confirmNo]);

  const checkStatus = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language, 'Content-Type': 'application/json', },
    };
    const response = await fetch(apiLink_ecom + OMISE_INQUIRY + '?ConfirmationNo=' + confirmNo + '&UserCode=' + guestCode, requestOptions).
      then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data["Success"]) {
          const items = data["Result"];
          if (items['ResponseCode'] === "successful") {
            sessionStorage.removeItem("MyCart")
            navigate("/done", { state: { confirmationNo: confirmNo, TagHtml: items["TagHtml"], OrderId: items["OrderId"], GuestEmail: guestEmail } });
            sessionStorage.removeItem("GuestInfo");
          } else if (items['ResponseCode'] === "failed") {
            navigate("/paymentfail", { state: { ResponseDetail: items["ResponseDetail"], } });
          } else if (items['ResponseCode'] === "expired") {
          } else if (items['ResponseCode'] === "pending") {
          } else if (items['ResponseCode'] === "reversed") {
          } else {
            console.log("Error")
          }
        } else {
          console.log("bad request", data["Result"])
        }
      });
    sessionStorage.removeItem("GuestInfo");
  }

  const goHome = () => {
    navigate("/")
  }

  return (
    <>
      {confirmNo != "" && (
        <div
          className="page homepage pb-5"
          style={{
            minHeight: isTabletOrMobile ? "auto" : "calc(100vh - 183px)",
          }}
        >
          <div
            class="card mt-5"
            style={{
              width: isTabletOrMobile ? "80%" : "50%",
              margin: "auto",
            }}
          >
            <div class="card-body">
              <h2
                className="lh-1"
                style={{
                  width: "100%",
                  height: "100%",
                  fontWeight: "700",
                  fontSize: "18px",
                  color: "#0e315c",
                }}
              >
                {t("smart_finder")}
              </h2>
              <h2
                className="lh-1"
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {user.InvoiceNumber}
              </h2>
              <h2
                className="lh-1"
                style={{
                  width: "100%",
                  height: "100%",
                  fontWeight: "700",
                  fontSize: "18px",
                  color: "#0e315c",
                }}
              >
                {totalPrice.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {t("thb")}
              </h2>
            </div>
          </div>
          <div
            style={{
              height: "60%",
              display: "flex",
              justifyContent: "center",
              marginTop: isTabletOrMobile ? "5rem" : "10rem",
              alignItems: "center",
            }}
          >
            <div
              class="spinner-border"
              role="status"
              aria-hidden="true"
              style={{
                color: "#0e315c",
                width: "3rem",
                height: "3rem",
              }}
            ></div>
          </div>
          <div
            style={{
              height: "15%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <h5
              style={{
                fontWeight: "800",
                fontSize: "18px",
                width: "70%",
                textAlign: "center",
              }}
            >
              {t("please_wait")}
            </h5>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentProcessing;




