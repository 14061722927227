import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import useRegister from '../customHook/useRegister';

import logo from "../component/img/logo-xl.png";
import PicSuccess from "../component/img/popup-success.png";
import { ReactComponent as Lock } from "../component/img/lock.svg";
import { ReactComponent as Refresh } from "../component/img/refresh.svg";
import { ReactComponent as Close } from "../component/img/close.svg";
import { baseLink } from '../constants/DataPublic';
import { useGlobalState } from '../global'

const SetPasscode = (props) => {

    const navigate = useNavigate();
    const [userName, setUserName] = useState("")
    const [routePath, setRoutePath] = useGlobalState('route');
    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    // Signup info
    const { userSignup } = props

    useEffect(() => {
        if (user != null && user.isLogin) {
            navigate("/")
        } else if (Object.keys(userSignup).length > 0) {
            setUserName(userSignup.fName)
        } else {
            navigate("/signup")
        }
    }, [])

    const [passcode, setPasscode] = useState([]);
    const [PinStyle, setPinStyle] = useState(["defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad"]);
    const [message, setMessage] = useState("")

    // Check User Register
    let { userInfo, saveSignup } = useRegister();
    userInfo = { ...userSignup }

    const checkPinPass = (num) => {
        if (passcode.length < 6) {
            setPasscode([...passcode, num])
            PinStyle[passcode.length] = "showPinpad"
        }
    }

    const ResetPinpass = () => {
        setPasscode([])
        setPinStyle(["defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad", "defaultPinpad"])
        setMessage("")
    }

    useEffect(() => {
        if (passcode.length === 6) {
            submitRegister()
        }
    }, [passcode])

    const submitRegister = async (e) => {
        let passcodeStr = ""
        passcode.forEach(element => {
            passcodeStr = passcodeStr + element
        });

        if (passcodeStr.length === 6) {
            userInfo.passcode = passcodeStr
            await saveSignup(userInfo)
            let user = sessionStorage.getItem('UserLogin')
            user = JSON.parse(user)
            if (user != null) {
                setShowSignupSuccess(true)
            }
        }
    }

    /** Register Success. **/
    const [showPopupSignupSuccess, setShowSignupSuccess] = useState(false);
    const CloseSignupSuccess = () => setShowSignupSuccess(false);

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="page page-steps">
            <div className="section py-5">
                <div className="container">
                    <div className="row">
                        <div className="logosignin">
                            <img src={logo} alt="" id="logosignin" />
                        </div>

                        <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius" style={{ margin: "0 auto" }}>

                            <center>
                                <div className="mb-3"><Lock /></div>
                                <div className="mb-5 weight-500">{t('hi_fill_passcode', { userName })}</div>
                                <div className="col-8">
                                    <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[0]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[1]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[2]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[3]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[4]}></div>
                                        </div>
                                        <div className="col-2 PinpadPadding">
                                            <div className={PinStyle[5]}></div>
                                        </div>
                                    </div>
                                </div>

                                <span className="text-danger mb-3">{message}</span>
                            </center>

                            <center className="py-5">

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("1")}><span className="box-passcode">1</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("2")}><span className="box-passcode">2</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("3")}><span className="box-passcode">3</span></button>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("4")}><span className="box-passcode">4</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("5")}><span className="box-passcode">5</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("6")}><span className="box-passcode">6</span></button>
                                    </div>
                                </div>

                                <div className="row mb-5">
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("7")}><span className="box-passcode">7</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("8")}><span className="box-passcode">8</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("9")}><span className="box-passcode">9</span></button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={() => checkPinPass("0")}><span className="box-passcode">0</span></button>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn-none" onClick={ResetPinpass}><Refresh className="size-refresh" /></button>
                                    </div>
                                </div>

                            </center>
                            <Modal show={showPopupSignupSuccess} className="col-12 popup-setpw">
                                <a href={baseLink + routePath} className="close"><Close className="popup-close" onClick={CloseSignupSuccess} /></a>
                                <div className="logosignin"><img src={logo} alt="" className="logo-popup" /></div>
                                <div className="text-center mb-4">
                                    <img src={PicSuccess} alt="" className="img-popup" />
                                </div>
                                <p className="text-center text-db">{t('register_success')}</p>
                            </Modal>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetPasscode