const STAFF_LOGIN = "/afcl/Account/staff/login";
const AGENT_LOGIN = "/afcl/Account/Agent/login";

const AGENT_GROUP_BOOKING = "/agent/GetGroupBooking";
const AGENT_TODAY_ARRIVAL = "/agent/GetAgentTodayArrival";
const AGENT_PAID = "/agent/GetAgentPaid";
const AGENT_UNPAID = "/agent/GetAgentUnPaid";
const AGENT_CANCEL_BOOKING = "/agent/GetAgentCancelBooking";
const FILTER = "/agent/SearchBooking";
const GET_ORDER_INFO = "/agent/GetOrderInfo";
const AGENT_SEND_EMAIL = "/agent/SendEmail";
const STAFF_SEND_EMAIL = "/staff/SendEmail";
const GET_AGENT = "/afcl/Account/Agent";
const AGENT_GET_CART = "/afcl/Package/Agent/GetCart/";

const STAFF_GET_GROUP_BOOKING = "/staff/GetGroupBooking";
const STAFF_GET_TODAY_ARRIVAL = "/staff/GetAgentTodayArrival";
const STAFF_GET_PAID = "/staff/GetAgentPaid";
const STAFF_GET_UNPAID = "/staff/GetAgentUnPaid";
const STAFF_GET_CANCEL_BOOKING = "/staff/GetAgentCancelBooking";
const STAFF_FILTER = "/staff/SearchBooking";

const STAFF_CANCEL_BOOKING = "/staff/CancelBooking";
const SAVE_CART = "/afcl/Package/Agent/SaveCart";
const GET_CART ="/afcl/Package/Agent/GetCart/";

// Print
const PRINT_PERFORMA_INVOICE = "/agent/PrintPerformaInvoice";
const PRINT_BOOKING_FORM = "/staff/PrintBookingForm";
const PRINT_BOOKING_REPORT = "/staff/PrintBookingReport";
const PRINT_FOR_GUEST = "/agent/PrintBooking";

const SAVE_ORDER_V2 = "/afcl/Package/Agent/SaveOrder";
const VERIFY_PROMOTION_CODE = "/afcl/Package/VerifyPromotionCode/";
const ITEM_LOOKUP = "/api/v1/Item/Lookup";
const ADD_BOOKING_ITEM = "/staff/AddBookingItems";
const DELETE_BOOKING_ITEM = "/staff/DeleteBookingItem";
const UPDATE_BOOKING_ORDER = "/staff/UpdateBooking";
const GET_PACKAGE_BY_CONTRACT = "/afcl/Package/GetPackagesByContractId";
const CHANGE_PASSWORD_AGENT = "/afcl/Account/agent/changepassword";
const RESET_PASSWORD_AGENT = "/afcl/Account/agent/resetpassword";
const CHANGE_PASSWORD_STAFF = "/afcl/Account/staff/changepassword";
const RESET_PASSWORD_STAFF = "/afcl/Account/staff/resetpassword";
// const CHANGE_PASSWORD_STAFF = "/afcl/Account/changepasscode/save";
const FIND_USER = "/afcl/Account/finduser";
const FORGOT_PASSWORD = "/afcl/Account/forgotpassword";
const PAYMENT_HISTORY = "/agent/GetPaymentHistory";
const CHECK_ALLOTMENT = "/afcl/Package/Agent/CheckAllotment";
const LOCATION_LOOKUP = "/afcl/Package/Transportation/LocationLookup";
const TIME_LOOKUP = "/afcl/Package/Transportation/TimeLookup";
const GET_ITEM_BY_CONTRACT = "/staff/GetItemsByContract";
const CHECK_UPDATE_ALLOTMENT = "/afcl/Package/Agent/CheckUpdateAllotment";
const REMOVE_CART = "/afcl/Package/Agent/RemoveCart";

//Slip
const VIEW_SLIP = "/agent/ViewSlip";
const UPLOAD_SLIP = "/agent/UploadSlip";
const UPDATE_SLIP = "/staff/UpdateBookingSlip";
const DELETE_SLIP = "/agent/DeleteBookingSlip";

//Payment
const PAY_CITY_LEADGER = "/afcl/Package/PayCityLeadger";
const SAVE_CITY_LEADGER = "/afcl/Package/ConfirmCityLeadger";
const SAVE_BOOKING = "/afcl/Package/staff/SaveBookingAndPrint";
const SAVE_BOOKING_PRINT ="/afcl/Package/staff/SaveBookingAndPrint";

const GET_OMISE ="/afcl/Omise";
const PAY_OMISE = "/afcl/Package/PayOmise";
const OMISE_CREDIT_CARD = "/afcl/Package/Omise/CreditCard";
const OMISE_IBANKING = "/afcl/Package/Omise/InternetBanking";
const OMISE_MOBILE_BANKING = "/afcl/Package/Omise/MobileBanking";
const OMISE_INQUIRY = "/afcl/Package/Omise/Inquiry";
const LINKS_PAY = "/afcl/Package/Omise/LinksPay";
const CONFIRM_AMOUNT = "/afcl/Package/Agent/GetConfirmAmount";
const SLIP_PAYMENT_TYPE = "/staff/UpdateBookingSlip/Payments";

const EMAIL_CONFIRMATION = "/EN/api/v1/Account/email-confirmed";

export {
  STAFF_LOGIN,
  AGENT_LOGIN,
  AGENT_GROUP_BOOKING,
  AGENT_TODAY_ARRIVAL,
  AGENT_PAID,
  AGENT_UNPAID,
  AGENT_CANCEL_BOOKING,
  GET_ORDER_INFO,
  VIEW_SLIP,
  UPLOAD_SLIP,
  AGENT_SEND_EMAIL,
  PRINT_PERFORMA_INVOICE,
  SAVE_ORDER_V2,
  VERIFY_PROMOTION_CODE,
  PRINT_BOOKING_FORM,
  PRINT_BOOKING_REPORT,
  STAFF_SEND_EMAIL,
  GET_AGENT,
  ITEM_LOOKUP,
  ADD_BOOKING_ITEM,
  DELETE_BOOKING_ITEM,
  UPDATE_BOOKING_ORDER,
  UPDATE_SLIP,
  DELETE_SLIP,
  GET_PACKAGE_BY_CONTRACT,
  FILTER,
  STAFF_CANCEL_BOOKING,
  CHANGE_PASSWORD_AGENT,
  CHANGE_PASSWORD_STAFF,
  RESET_PASSWORD_AGENT,
  FIND_USER,
  FORGOT_PASSWORD,
  PAYMENT_HISTORY,
  PAY_CITY_LEADGER,
  SAVE_CITY_LEADGER,
  SAVE_BOOKING,
  PAY_OMISE,
  OMISE_CREDIT_CARD,
  OMISE_IBANKING,
  OMISE_MOBILE_BANKING,
  CHECK_ALLOTMENT,
  OMISE_INQUIRY,
  LOCATION_LOOKUP,
  TIME_LOOKUP,
  GET_ITEM_BY_CONTRACT,
  LINKS_PAY,
  STAFF_GET_GROUP_BOOKING,
  STAFF_GET_CANCEL_BOOKING,
  STAFF_GET_TODAY_ARRIVAL,
  STAFF_GET_PAID,
  STAFF_GET_UNPAID,
  STAFF_FILTER,
  SAVE_CART,
  AGENT_GET_CART,
  CONFIRM_AMOUNT,
  GET_OMISE,
  SAVE_BOOKING_PRINT,
  GET_CART,
  CHECK_UPDATE_ALLOTMENT,
  PRINT_FOR_GUEST,
  REMOVE_CART,
  RESET_PASSWORD_STAFF,
  SLIP_PAYMENT_TYPE,
  EMAIL_CONFIRMATION
};
