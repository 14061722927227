import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

//Image
import logo from '../component/img/logo-xl.png';
import EmailConfirm from '../component/img/email.PNG';
import { ReactComponent as Close } from '../component/img/close.svg';

//css
import '../component/css/style.css';
import '../component/css/custom.css';

import useLogin from '../customHook/useLogin';
import { baseLink, apiLink, apiLink_ecom } from '../constants/DataPublic';
import { useGlobalState } from '../global'

const Forget = (props) => {
  const { language } = props;
  const navigate = useNavigate();
  const [editEmail, setEditEmail] = useState(true);
  const [routePath, setRoutePath] = useGlobalState('route');
  
  let user = sessionStorage.getItem('UserLogin');
  user = JSON.parse(user);

  useEffect(() => {
    console.log('User', user);
    if (user == null) {
      setEmail('');
      setEditEmail(true);
    } else if (user != null && user.isLogin) {
      navigate('/');
    } else if (user.email != null) {
      setEmail(user.email);
      setEditEmail(false);
    }
  }, []);

  // Login with Email
  let { result, message, setEmail, setResult, submitForgot } = useLogin({ lang: language });

  useEffect(() => {
    if (result) {
      console.log('Show Popup');
      forgotPasscode();
    }
  }, [result]);

  // Forgot Password
  async function forgotPasscode() {
    let user = sessionStorage.getItem('UserLogin');
    user = JSON.parse(user);
    console.log(user);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept-language': language },
      body: JSON.stringify({
        UserId: user.userId,
        Email: user.email,
        DeviceKey: '',
      }),
    };
    console.log(requestOptions);
    await fetch(apiLink_ecom + '/afcl/Account/forgotpassword', requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        if (items['Success'] === true) {
          console.log('Forgot Success = true');
          sessionStorage.removeItem('UserLogin');
          setShowPopupSendEmail(true);
        } else {
          console.log('Forgot Success = false');
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Account/forgotpassword ', error)
        setResult(false);
      });
  }

  useEffect(() => {
    if (showPopupSendEmail) {
      sessionStorage.clear();
    }
  }, [showPopupSendEmail]);

  /** Send Email Forget **/
  const [showPopupSendEmail, setShowPopupSendEmail] = useState(false);
  const CloseShowPopupSendEmail = () => setShowPopupSendEmail(false);
  const ShowShowPopupSendEmail = () => setShowPopupSendEmail(true);

  // ภาษา
  const { t } = useTranslation();

  return (
    <div>
      <div className="page box-mh">
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="logosignin">
                <img src={logo} id="logosignin" />
              </div>
              <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius" style={{ margin: '0 auto' }}>
                <from>
                  <div className="mb-3">
                    <h2 className="main-title mb-3">{t('forgot_your_passcode')}</h2>
                    <span className="weight-500">{t('please_enter_email')}</span>
                    <p className="text-color ">{t('new_passcode_generate')}</p>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-color ">
                      {' '}
                      {t('email')}*{' '}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      defaultValue={user != null ? user.email : ''}
                      disabled={!editEmail}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="text-danger">{t(message)}</span>
                  </div>

                  <div className="mb-3">
                    <Button type="button" className="btn btn-link btn-db text-capitalize" onClick={submitForgot}>
                      {t('confirm')}
                    </Button>
                    <Modal show={showPopupSendEmail} className="col-12 popup-forget">
                      <a href={baseLink + routePath + '/signin'} className="close">
                        <Close className="popup-close" onClick={CloseShowPopupSendEmail} />
                      </a>
                      <div className="logosignin">
                        {' '}
                        <img src={logo} className="logo-popup" />
                      </div>
                      <div className="text-center mb-4">
                        <img src={EmailConfirm} className="img-forgetpopup" />
                      </div>
                      <h5 className="text-center text-popup-title">{t('send_email_completed')}</h5>
                      <div className="text-center">
                        <a href={baseLink + routePath + '/signin'} className="link-lb">
                          {t('back_to_signin')}
                        </a>
                      </div>
                    </Modal>
                  </div>
                </from>
                <div className="text-center">
                  <a href={baseLink + routePath + '/signin'} className="link-lb">
                    {t('back_to_signin')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );


};

export default Forget;
