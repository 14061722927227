import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import errorAllotment from "../img/error_allotment.png";
import moment from "moment/moment";

export const AllotmentErrorModal = (props) => {
  const { openModal, closeModal, listAllotmentRes } = props;
  const { t } = useTranslation();

  console.log("listAllotmentRes",listAllotmentRes);

  const onCloseModal = () => {
    closeModal(false);
  };

  function dateFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YY").toString();
  }

  const listTest = [
    {
      cabanaName: "Deluxe Cabana",
      available: 20,
    },
    {
      cabanaName: "Super Deluxe Cabana",
      available: 15,
    },
    {
      cabanaName: "VIP Cabana",
      available: 10,
    },
  ];

  return (
    <Modal
      show={openModal}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="modal-show-allotment"
    >
      <ModalHeader closeButton />
      <ModalBody>
        <div className="modal-title">{t('check_cabana')}</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={errorAllotment}
            style={{
              width: "200px",
              marginBottom: "0px 20px",
              marginTop: "20px",
            }}
          ></img>
        </div>
        <div
          style={{
            margin: "20px",
            border: "1px solid #b9b9b9",
            borderRadius: "5px",
            boxShadow: "0px 0px 2px #e2e2e2",
            padding: "10px 35px",
          }}
        >
          <p style={{ color: "#0e315c", fontWeight: "600" }}>
            {t("arrival_date")} : {listAllotmentRes != null ? dateFormat(listAllotmentRes[0].ArrivalDate.split("T")[0]) : ""}
          </p>
          {listAllotmentRes != null && (
            <div class="container">
              {listAllotmentRes.map((item, index) => (
                <div class="row">
                  <div class="col-8">
                    <p style={{ color: "#0e315c", fontWeight: "600" }}>
                      {index+1}. {item.ItemName}
                    </p>
                  </div>
                  <div class="col-4">
                    <p style={{ color: "#34995C", fontWeight: "600" }}>
                      {t('available')} : {item.Available}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <p style={{margin:"0px 40px", color:"red", fontSize:"18px", fontWeight:"600"}}>{t("cabana_not_enough")}</p>
      </ModalBody>
      <ModalFooter className="footer-order-detail-modal">
        <Button
          type="button"
          className="btn-close-show-allot-modal"
          onClick={(e) => onCloseModal()}
        >
          {t("close")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
