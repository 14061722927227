import React, {useState} from "react";
import {GoogleLogin} from "react-google-login";
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { apiLink,clientId } from '../constants/DataPublic'
import useLogin from '../customHook/useLogin';

import { ReactComponent as Google } from "../component/img/btn-google.svg";

import "../component/css/style.css";
import "../component/css/custom.css";

const GGLogin = (props) => {

  const { t } = useTranslation();

  const navigate = useNavigate();
  let {getUserCart} = useLogin({lang: ''})
  const [needRegist, setNeedRegist] = useState(false)
  const {language} = props
  const {ggtext} = props
  
  const handleFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res);
  };

  const handleLogin = async (res) => {
    let ggUser = res.profileObj;
    props.getGGUser(ggUser)

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        "Provider":"Google",
        "ProviderKey":ggUser.googleId,
        "Email": ggUser.email,
        "PushKey":"",
        "DeviceType":"",
        "DeviceKey":""
       }),
    };
    await fetch(apiLink+"/afcl/Account/external/checklogin",requestOptions)
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          const items = data;
          if(items["Result"].NeedRegister){
            setNeedRegist(true)
          } else {
            props.setUserCode(items["Result"].User.UserCode)
            let userLogin = {
              fName: items["Result"].User.FirstName,
              lName: items["Result"].User.LastName,
              email: items["Result"].User.Email,
              userId: items["Result"].User.UserId,
              userCode: items["Result"].User.UserCode,
              photo: items["Result"].User.UserPicture,
              isLogin: true,
              channelCode: items["Result"].ChannelCode
            }
            sessionStorage.setItem('UserLogin',JSON.stringify(userLogin))
            navigate("/")
          }
          props.getNeedRegist(items["Result"].NeedRegister)
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert('Error: API /afcl/Account/external/checklogin ', error)
        });
  }; 

  return (
    <div>
      <div id="signInButton">
          <GoogleLogin
            clientId={clientId}
            render={renderProps => (
                <button onClick={renderProps.onClick} className="btn btn-link btnloginsocial btnloginsocial-guest">
                  <span className="picloginsocial"><Google/></span><span className="textloginsocial">{t(ggtext)}</span>
                </button>
                )}
            onSuccess={handleLogin}
            onFailure={handleFailure}
            cookiePolicy={"single_host_origin"}
            isSignedIn={false}
          />
      </div>
    </div>
  );
};

export default GGLogin;
