import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GET_AGENT } from "../../services/BASE_API";
import Backdrop from "@mui/material/Backdrop";
import { apiLink_ecom } from "../../constants/DataPublic";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

//Image
import logo from "../../component/icons/Agent-color.svg";

const SelectAgent = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { open, handleClose, setSelectedAgent } = props;

  const [loading, setLoading] = useState(false);
  const [agentUser, setAgentUser] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [agent, setAgent] = useState("");

  let user = JSON.parse(sessionStorage.getItem("UserLogin"));

  useEffect(() => {
    if (user.isStaff) {
      callGetAgentService();
    }
  }, []);

  useEffect(() => {
    if (selectedOption != null) {
      if (agentUser != null) {
        let agent = agentUser.find((x) => x.ContractId == selectedOption);
        setAgent(agent);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (agentUser != null) {
      setSelectedOption(user.userCode);
    }
  }, [agentUser]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const submitAgent = () => {
    if (agent != null) {
      user.email = agent.Email;
      user.userId = agent.ContractId;
      user.fName = agent.ContractName;
      user.userCode = agent.ContractId;
      user.isCityLeadger = agent.IsCityLeadger;
      user.phoneNo = agent.Officetel.trim();
      user.confirmationNo = "";
      user.InvoiceNumber = "";
      sessionStorage.setItem("UserLogin", JSON.stringify(user));
      sessionStorage.setItem("MyCart", JSON.stringify([]));
      sessionStorage.removeItem("GuestInfo");
      setSelectedAgent();
      handleClose();
      //   navigate("/ticket/AWPK001/1");
    }
  };

  const onCloseModal = () => {
    handleClose();
    setSelectedAgent();
  };

  const backToOrderPage = () => {
    navigate("/order");
  };

  async function callGetAgentService() {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + GET_AGENT, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          // console.log(data);
          setAgentUser(data["Result"]);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert("Error: " + apiLink_ecom, GET_AGENT,  error);
          setLoading(false);
          return false;
        });
    }, 1000);
  }

  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      className="select-agent"
    >
      <Modal.Header>
        <button className="btn-close" onClick={backToOrderPage}>
          {/* <i class="fa fa-times"></i> */}
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="content-header" style={{ textAlign: "center" }}>
            <img src={logo} style={{ width: "30%", margin: "5%" }} />
            <h2 className="main-title mb-3">{t("select_agent")}</h2>
          </div>
          {agentUser != null && (
            <>
              <div
                className="label mb-2"
                style={{ color: "#0E315C", marginLeft: "50px", fontWeight:"300" }}
              >
                {t("agents")} *
              </div>
              <Form.Select value={selectedOption} onChange={handleSelectChange}>
                {agentUser != null ? (
                  agentUser.map((row, index) => (
                    <option value={row.ContractId}>{row.ContractName}</option>
                  ))
                ) : (
                  <></>
                )}
              </Form.Select>
            </>
          )}
        </div>
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: "1" }}
            style={{ zIndex: 1100 }}
            open
          >
            <div
              class="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
            ></div>
          </Backdrop>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn_confirm"
          disabled={agent == null || agent == ""}
          onClick={submitAgent}
        >
          {t("confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SelectAgent;
