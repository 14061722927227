import React, { useState, useEffect } from 'react';
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";

//Image
import DayPass from "../../component/img/day-pass.png";
import ExtremePass from "../../component/img/extreme-pass.png";
import { baseLink, dayPassPackageCode, extremePassPackageCode } from '../../constants/DataPublic';
import { useGlobalState } from '../../global'

const Daypass = (props) => {

  const {packageList} = props

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let Promotion = sessionStorage.getItem('Promo');
  Promotion = JSON.parse(Promotion);

  const [routePath, setRoutePath] = useGlobalState('route');
  const [cart, setCart] = useState([])

  useEffect(() => {
    setCart(myCart)
  }, [])

  const addToCart = (item, isEdit) =>{
    let aIndex = cart.findIndex(e => {return e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode})

    if ( aIndex === -1 ) {
        // เพิ่ม Cart
        if (item.Qty > 0){
            setCart((cart) => {
                return [...cart, item]
            })
        }
    } else {
        // แก้ไข Cart
        let preQty = cart[aIndex].Qty
        let newQty = preQty+1
        
        console.log("Before edit cart : ", cart, newQty);
        let editCart = cart
        editCart[aIndex].Qty = cart[aIndex].Qty + 1
        console.log("EditCart, ", editCart);
        setCart(editCart)
        sessionStorage.setItem('MyCart', JSON.stringify(editCart))
    }
  }

  useEffect(() => {
    sessionStorage.setItem('MyCart', JSON.stringify(cart))
    Promotion = Promotion === null ? { discountPromo: '', promoCode: '', havePro: false, packageType:'' } : Promotion;
    sessionStorage.setItem('Promo', JSON.stringify(Promotion));
    console.log("scheckk nulll Daypasss",myCart)
  }, [cart])

  // ภาษา
  const { t } = useTranslation();

  return (
 	<div className="section section-6">
    <div className="container-fluid">
      <div className="row">
        { 
          packageList.map ((e,index) => {
            if (e.PackageCode === dayPassPackageCode)
            {
              return (
                <div className="col-lg-6 bg-grey p-0">
                  <div className="pass-wrapper">
                    <div className="mb-5">
                      <h2 className="secondary-title mb-3 weight-900 text-left">{e.PackageName}</h2>
                      <p className="weight-500 font- text-desc ">{e.PackageDescription}</p>
                    </div>

                    <div className="row align-items-">

                      <div className="col-lg-6">
                        <div className="text-center text-lg-start">
                          <img src={DayPass} className="mb-5" />
                        </div>
                        <p className="weight-500 font-10 color-grey">* To qualify for Thai Residents rate - Thai citizenship &amp; Thai Residency (Expats with work permits, "O" type visas, Student Visas, 
                          Diplomatic Visas are eligible) Please note Tourist Visas are not applicable.</p>
                        <p className="weight-500 font-10 color-grey" /><p className="weight-500 font-10 color-grey">Children under the age of 3 years old (35 months) are admitted free of charge when accompanied by an adult.</p>
                      </div>

                      <div className="col-lg-1" />
                        <div className="col-lg-5">
                          <div className="boxprice-wrapper">

                          { e.PackageItemLists.map((i,index) => {
                            return (
                                <div className="boxprice border">
                                  <div className="row align-items-center justify-content-between">
                                    <div className="col-6">	
                                      <p className="weight-900 font-12 mb-0">{i.ItemName}</p>
                                    </div>
                                    <div className="col-6 text-end">	
                                      <p className="font-18 weight-900 mb-0">{i.ItemPrice.toLocaleString()} {t('thb')}</p>
                                    </div>
                                  </div>
                                </div>
                            )
                          })}
                            <hr className="my-0" />

                            <div className="row align-items-center justify-content-between pt-3">
                              <div className="col text-end">	
                                <a href={baseLink + routePath +"/ticket/"+e.PackageCode+"/1"}><button type="button" className="btn btn-main mt-auto mx-auto align-self-start "
                                    onClick={() => {
                                      if (!e.IsSaleByItem){
                                        e.PackageItemLists.map((i,index) => {
                                          let netPrice = i.IsDiscount ? i.ItemPrice - i.DiscountPrice : i.ItemPrice
                                          console.log("AddCart DayPass: ", i);
                                            addToCart(
                                              {
                                                  PackageCode: e.PackageCode,
                                                  PackageName: e.PackageName,
                                                  ItemCode: "",
                                                  ItemName: "",
                                                  Qty: 1,
                                                  Tax: 0,
                                                  ItemPrice: netPrice,
                                                  ItemDiscount: i.IsDiscount ? i.DiscountPrice : 0,
                                                  PriceName: i.PriceName,
                                                  PriceUnit: '',
                                                  TotalPrice: netPrice,
                                                  VisitDate: null,
                                                  VisitTime: '',
                                                  VisitLocation: '',
                                                  step: 1,
                                                  status: 'normal'}, false
                                              ) 
                                    
                                        })
                                      }
                                    }}
                                  >
                                  {t('buy_now')}
                                </button></a>	
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>

                </div>
              )
            } else if (e.PackageCode === extremePassPackageCode){
              return(
                <div className="col-lg-6 bg-black p-0 color-white">
                  <div className="pass-wrapper extreme">

                    <div className="mb-5">
                      <h2 className="secondary-title mb-3 weight-900 text-left color-main">{e.PackageName}</h2>
                      <p className="weight-500 text-desc">{e.PackageDescription}</p>
                    </div>

                    <div className="row">

                      <div className="col-lg-6 text-center text-lg-start">
                        <img src={ExtremePass} className="mb-5 mb-lg-0" />
                      </div>

                      <div className="col-lg-1" />

                        <div className="col-lg-5">
                          
                          <div className="boxprice-wrapper">
                          { e.PackageItemLists.map((i,index) => {
                            return (
                              <div className="boxprice border">
                                <div className="row align-items-center justify-content-between">
                                  <div className="col-6">	
                                    <p className="weight-900 font-12 mb-0">{i.ItemName}</p>
                                  </div>
                                  <div className="col-6 text-end">	
                                    <p className="font-18 weight-900 mb-0">{i.ItemPrice.toLocaleString()} {i.PriceName}</p>
                                  </div>
                                </div>
                              </div>
                            )
                            })
                          }
                            
                            <hr className="my-0" />
                            
                            <div className="row align-items-center justify-content-between pt-3">
                              <div className="col text-end">	
                              <a href={baseLink+ routePath +"/ticket/"+e.PackageCode+"/1"}><button type="button" className="btn btn-main mt-auto mx-auto align-self-start ">{t('discover')}</button>	</a>
                              </div>
                            </div>

                          </div>

                        </div>
                    </div>

                  </div>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  </div>

  )
}

export default Daypass;