import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { apiLink, apiLink_ecom } from "../constants/DataPublic";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive'
import { OMISE_IBANKING } from "../services/BASE_API";
import ModalFailed from "../component/Modal/ModalFailed";

const PaymentTruewallet = (props) => {
    const { language } = props

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 561px)' })

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    let paymentRoute = JSON.parse(sessionStorage.getItem('PaymentRoute'));

    const { t } = useTranslation();
    const navigate = useNavigate()

    const [phoneNo, setPhoneNo] = useState("")
    const [loading, setLoading] = useState(false)
    const [reqiurePhone, setRequirePhone] = useState('')

    const [confirmNo, setConfirmNo] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [contractId, setContractId] = useState(0);
    const [guestCode, setGuestCode] = useState("");
    const [openFailedModal, setOpenFailedModal] = useState(false);
    const [aleart, setAleart] = useState("");

    useEffect(() => {
        if(paymentRoute == null){
            setConfirmNo(user.confirmationNo);
            setTotalPrice(user.totalPrice);
            setContractId(user.userCode);
            setGuestCode(user.guestCode);
        }else{
            setConfirmNo(paymentRoute.confirmationNo);
            setTotalPrice(paymentRoute.totalPrice);
            setContractId(paymentRoute.userCode);
            setGuestCode(paymentRoute.guestCode);
        }
        setTimeout(() => {
            setLoading(true)
        }, 500)
    }, [])

    const handleSubmit = async () => {

        let valid = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNo)

        if (phoneNo !== '' && valid) {
            setLoading(false)
            const requestOptions = {
                method: "POST",
                headers: { "accept-language": language, 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    "ConfirmationNo": confirmNo,
                    // "UserCode": user.userCode,
                    "UserCode": guestCode,
                    "paymentType": 'truemoney',
                    "PhoneNo": phoneNo,
                    "InvoiceNumber": user.InvoiceNumber,
                    "Source": "Agent",
                    "Amount": totalPrice
                }),
            };
            console.log(requestOptions.body);
            // ​/afcl​/Package​/Omise​/InternetBanking
            await fetch(apiLink_ecom + OMISE_IBANKING, requestOptions).
                then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    if (data["Success"]) {
                        const items = data["Result"];
                        setLoading(true)
                        window.location.href = items["WebPaymentUrl"];
                    } else {
                        setLoading(true)
                        console.log("bad request", data["Result"])
                        setAleart(data["Message"] == null ? 'update_failed' : data["Message"]);
                        setOpenFailedModal(true);
                    }
                })
        } else {
            handleChange()
        }
    }

    const handleChange = (e) => {
        console.log("phoneNo", phoneNo)
        if (!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phoneNo)) {
            let word = phoneNo !== '' ? 'wrong_mobile_pattern' : 'require_mobile'
            setRequirePhone(word)
        } else {
            setRequirePhone('')
        }
    }

    useEffect(() => {
        if (phoneNo !== '') {
            const timeOutId = setTimeout(() => handleChange(), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [phoneNo]);


    const cancel = () => {
        navigate('/paymentOmise')
    }
    
    const setCloseFailedModal = ()=>{
        setOpenFailedModal(false);
    }
    return (
        <div className="page homepage" style={{ height: '720px' }}>
            {!loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: "1" }}
                    style={{ zIndex: 1100 }}
                    open
                >
                    <div
                        class="spinner-border"
                        role="status"
                        aria-hidden="true"
                        style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
                    ></div>
                </Backdrop>
            )}
            <div className="section pt-5">
                <div className="container d-flex justify-content-center">
                    <div class="col-10 col-md-8 col-lg-6" style={{ paddingBottom: '20px' }}>
                        <div id="credit_card" class="card text-center">
                            <div class="card-body">
                                <div className="row" style={{ height: '45px' }}>
                                    <div className="col-6 h-100">
                                        <img src="https://cdn.omise.co/assets/screenshots/series-b/omise-logo.jpg" alt="omise" width={100} />
                                    </div>
                                    <div className="col-6 h-100">
                                        <img src="https://i0.wp.com/www.9tana.com/wp-content/uploads/2019/06/truewallet.jpg?fit=600%2C290&ssl=1&resize=1280%2C720" alt="omise" width={100} />
                                    </div>
                                </div>
                                <hr />
                                <h2 className="font-30 lh-1 mb-5" style={{
                                    width: '100%',
                                    height: '100%',
                                    color: '#0e315c'
                                }}>
                                    True Money Wallet
                                </h2>
                                <p class="card-text">{t('enter_your_mobile_number')}</p>
                                <div className="col-8 mb-5" style={{ margin: 'auto' }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="expiration_month"
                                        placeholder={t('telephone_no')}
                                        maxLength="10"
                                        style={{ fontWeight: 'normal', border: reqiurePhone !== '' ? '1px solid #ca4d4d' : '' }}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => { setPhoneNo(e.target.value); }}
                                    />
                                    <span className="text-danger text-left">{t(reqiurePhone)}</span>
                                </div>
                                <div className="row col-12 col-sm-8 mb-3" style={{ margin: 'auto' }}>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-blue" style={{ fontSize: isTabletOrMobile ? '11px' : '' }} onClick={handleSubmit}>
                                            {t('confirm')}
                                        </button>
                                    </div>
                                    <div className="col-6">
                                        <button type="button" className="btn btn-blue-reverse" style={{ fontSize: isTabletOrMobile ? '11px' : '' }} onClick={cancel}>
                                            {t('cancel')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalFailed
                open={openFailedModal}
                body={aleart}
                setClose={setCloseFailedModal}
                back={setCloseFailedModal}
            />
        </div>
    )
}

export default PaymentTruewallet;