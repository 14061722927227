import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export const ConfirmModal = (props) => {
  const { open, setClose, message, onConfirm } = props;
  const { t } = useTranslation();

  const onCloseModal = () => {
    setClose(false);
  };

  const onClickConfirm = () => {
    onConfirm();
  };
  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="modal-prarmeter-report"
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody>
        <div className="layout-msg-confirm">{message}</div>
      </ModalBody>
      <ModalFooter className="footer-order-detail-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-payment-modal"
            // style={{pointerEvents:"none"}}
            onClick={(e) => onClickConfirm()}
          >
            {t("confirm")}
          </Button>
        </div>
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-payment-modal-cancel"
            // style={{pointerEvents:"none"}}
            onClick={(e) => onCloseModal()}
          >
            {t("cancel")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
