import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../component/icons/logo_agent.svg'
import { Button } from 'react-bootstrap'
import cookies from "js-cookie";
import classNames from "classnames";
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import { EMAIL_CONFIRMATION } from '../services/BASE_API';
import { apiLink } from '../constants/DataPublic';
import { Backdrop } from '@mui/material';
import ModalFailed from '../component/Modal/ModalFailed';

export default function EmailConfirmation(props) {
    const languages = [
        {
            code: "en",
            name: "ENG",
            list: "ENGLISH",
            country_code: "gb",
        },
        {
            code: "th",
            name: "TH",
            list: "THAI",
            country_code: "th",
        },
    ];

    const params = new URLSearchParams(window.location.search);

    const currentLanguageCode = cookies.get("i18next") || "th";
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()

    const [loadingApi, setLoadingApi] = useState(false);
    const [token, setToken] = useState(null);
    const [openFailedModal, setOpenFailedModal] = useState(false);
    const [aleart, setAleart] = useState("");

    useEffect(() => {
        let _token = params.get('token');
        setToken(_token);
        // console.log("TOKEN UseEffect", _token);
    }, [])

    useEffect(() => {
        props.getLanguage(currentLanguageCode);
    }, [currentLanguageCode]);

    const sendEmailConfirm = async (token) => {
        let payload = JSON.stringify({
            "Token": token,
            "RequestId": ""
        });

        const requestOptions = {
            method: "PATCH",
            headers: { "accept-language": i18n.language, 'Content-Type': 'application/json', },
            body: payload
        };
        setLoadingApi(true);
        await fetch(apiLink + EMAIL_CONFIRMATION, requestOptions)
            .then(function (response) {
                console.log(response);
                setLoadingApi(false);
                if (response.ok && (response.status === 200 || response.status === 202)) {
                    window.location.href = "/agent";
                } else {
                    setAleart(response.statusText);
                    setOpenFailedModal(true);
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
                setAleart(error);
                setOpenFailedModal(true);
            });
    }

    const handleOnClick = async () => {
        // console.log("TOKEN", token);
        if (token != null && token != "") {
            sendEmailConfirm(token);
        }
    }

    const setCloseFailedModal = () => {
        setOpenFailedModal(false);
    }

    return (
        <>
            {loadingApi && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: "1" }}
                    style={{ zIndex: 1100 }}
                    open
                >
                    <div
                        class="spinner-border"
                        role="status"
                        aria-hidden="true"
                        style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
                    ></div>
                </Backdrop>
            )}
            <div className='text-end'>
                <div style={{ position: "absolute", top: "8px", right: "20px" }}>
                    <div className="wrapper text-end" style={{ width: "50px" }}>
                        <div className="dropdown">
                            <button
                                className="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ fontSize: "12px", fontWeight: "normal" }}
                            >
                                {currentLanguage.name}
                            </button>
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                {languages.map(
                                    ({ code, name, list, country_code }) => (
                                        <li key={country_code}>
                                            <a
                                                href="#"
                                                className={classNames("dropdown-item", {
                                                    disabled: currentLanguageCode === code,
                                                })}
                                                onClick={() => {
                                                    i18next.changeLanguage(code);
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        opacity:
                                                            currentLanguageCode === code ? 0.5 : 1,
                                                    }}
                                                ></span>
                                                {list}
                                            </a>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container text-center m-auto'>
                <div className='emailconfirm-container'>
                    <div className='card-container'>
                        <Logo className='logo-email-confirm mb-3' />
                        {/* <img src={logo} alt="" className="mb-5" style={{width:"400px"}}/> */}
                        <div class="card" style={{ height: "100%" }}>
                            <div class="card-header">
                                <div className='verify-header'>
                                    {t("email_confirm")}
                                </div>
                            </div>
                            <div class="card-body">
                                <div className='m-5 text-start'>{t("please_confirm_email")}</div>
                                <div className='row m-0' style={{ width: "100%", padding: "0px 30px 30px" }}>
                                    <div className='col'>
                                        <Button className='btn btn-verify-main' onClick={handleOnClick}>{t("confirm")}</Button>
                                    </div>
                                    <div className='col'>
                                        <Button className='btn btn-verify-secondary' onClick={() => {
                                            window.location.href = "/";
                                        }}>{t("cancel")}</Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ModalFailed
                open={openFailedModal}
                body={aleart}
                setClose={setCloseFailedModal}
                back={setCloseFailedModal}
            />
        </>

    )
}
