import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import errorAllotment from "../img/error_allotment.png";
import moment from "moment/moment";
import { Form } from "react-bootstrap";

export const SelectDetailTransportModal = (props) => {
  const { openModal, closeModal, onSelect, visitdate, listLocation, listTime, selectItem } =
    props;
  const { t } = useTranslation();
  const [selectLocation, setSelectLocation] = useState(null);
  const [selectTime, setSelectTime] = useState(null);

  const [requiredLocationed, setRequiredLocationed] = useState(false);
  const [requiredTimed, setRequiredTimed] = useState(false);

  const onCloseModal = () => {
    closeModal(false);
  };

  const onSelectTransport = () => {
    if (selectLocation != "" && selectLocation != null && selectTime != null && selectTime != "") {
      onSelect(selectLocation, selectTime);
    } else {
      if (selectLocation === "" || selectLocation == null) {
        setRequiredLocationed(true);
      }
      if (selectTime == null || selectTime === "") {
        setRequiredTimed(true);
      }
    }

  };

  function dateFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YY").toString();
  }

  const listTest = [
    {
      cabanaName: "Deluxe Cabana",
      available: 20,
    },
    {
      cabanaName: "Super Deluxe Cabana",
      available: 15,
    },
    {
      cabanaName: "VIP Cabana",
      available: 10,
    },
  ];

  return (
    <Modal
      show={openModal}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="modal-show-allotment"
    >
      <ModalHeader closeButton />
      <ModalBody>
        <div className="modal-title">{selectItem.PackageName}</div>
        <div style={{ paddingTop: "30px" }}>
          <div className="row mb-2 pb-3">
            <div className="col-3 m-auto">
              <p className="mb-0" style={{ textAlign: "end" }}>
                {t("visitdate")}
              </p>
            </div>
            <div className="col-8">
              <input
                id="startDate"
                className={`form-control`}
                type="date"
                style={{ width: "60%" }}
                value={visitdate}
                disabled={true}
              />
            </div>
          </div>
          <div className="row mb-2 pb-3">
            <div className="col-3 m-auto">
              <p className="mb-0" style={{ textAlign: "end" }}>
                {t("location")}
              </p>
            </div>
            <div className="col-8">
              <Form.Select
                id="transportation_schedule"
                value={selectLocation}
                className={`form-control`}
                style={{
                  width: "60%",
                  borderColor: requiredLocationed == true ? "red" : "#ced4da",
                }}
                onChange={(e) => {
                  setRequiredLocationed(false);
                  setSelectLocation(e.target.value);
                }}
                name="item_time"
              >
                <option key="blankChoice" hidden value>
                  {t("please_select")}
                </option>
                {listLocation != null ? (
                  listLocation.map((row, index) => (
                    <option value={row.LocationId}>{row.LocationName}</option>
                  ))
                ) : (
                  <></>
                )}
              </Form.Select>
            </div>
          </div>
          <div className="row mb-2 pb-3">
            <div className="col-3 m-auto">
              <p className="mb-0" style={{ textAlign: "end" }}>
                {t("time")}
              </p>
            </div>
            <div className="col-8">
              <Form.Select
                id="transportation_schedule"
                value={selectTime}
                style={{
                  width: "60%",
                  borderColor: requiredTimed == true ? "red" : "#ced4da",
                }}
                className={`form-control`}
                onChange={(e) => {
                  setRequiredTimed(false);
                  setSelectTime(e.target.value);
                }}
                name="item_time"
              // disabled={checkPackageInMyCart()}
              >
                <option key="blankChoice" hidden value>
                  {t("please_select")}
                </option>
                {listTime != null ? (
                  listTime.map((row, index) => (
                    <option value={row.TimeId}>{row.TimeName}</option>
                  ))
                ) : (
                  <></>
                )}
              </Form.Select>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="footer-order-detail-modal">
        <Button
          type="button"
          className="btn-close-show-allot-modal"
          style={{ backgroundColor: "#0e315c" }}
          onClick={(e) => onSelectTransport()}
        >
          {t("select")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
