import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../global";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { baseLink, apiLink, apiLink_ecom } from "../../constants/DataPublic";
import {
  CHANGE_PASSWORD_AGENT,
  CHANGE_PASSWORD_STAFF,
} from "../../services/BASE_API";
import { padding } from "@mui/system";

export const ChangePasswordFirstLogin = (props) => {
  const { language, open, setClose } = props;
  const { t } = useTranslation();
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserLogin"))
  );
  const navigate = useNavigate();
  const [routePath, setRoutePath] = useGlobalState("route");
  const [canChange, setCanChange] = useState(false);
  const [activeConfirm, setActiveConfirm] = useState(false);
  const [message, setMessage] = useState("");

  const [OldpasswordType, setOldPasswordType] = useState("password");
  const [OldpasswordInput, setOldPasswordInput] = useState("");

  const toggleOldPassword = () => {
    if (OldpasswordType === "password") {
      setOldPasswordType("number");
      return;
    }
    setOldPasswordType("password");
  };

  const [NewpasswordType, setNewPasswordType] = useState("password");
  const [NewpasswordInput, setNewPasswordInput] = useState("");

  const toggleNewPassword = () => {
    if (NewpasswordType === "password") {
      setNewPasswordType("number");
      return;
    }
    setNewPasswordType("password");
  };

  const [CFpasswordType, setCFPasswordType] = useState("password");
  const [CFpasswordInput, setCFPasswordInput] = useState("");

  const routePathAgent = "/agent";
  const routePathStaff = "/staff";

  const [routePathSigin, setRoutePathSigin] = useState("");

  const toggleCFPassword = () => {
    if (CFpasswordType === "password") {
      setCFPasswordType("number");
      return;
    }
    setCFPasswordType("password");
  };

  useEffect(() => {
    // if (OldpasswordInput.length === 6) {
    //   checkPasscode();
    // } else {
    //   setCanChange(false);
    // }
    if (OldpasswordInput.length >= 6) {
      setCanChange(true);
    } else {
      setCanChange(false);
    }
  }, [OldpasswordInput]);

  useEffect(() => {
    if (NewpasswordInput.length >= 6 && CFpasswordInput.length >= 6) {
      setActiveConfirm(true);
    } else {
      setMessage("");
      setActiveConfirm(false);
    }
  }, [NewpasswordInput, CFpasswordInput]);

  const onNumerPWKeyDown = (event) => {
    if (user.isStaff) {
      if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
        event.preventDefault();
        console.log("event", event);
      }
    }
  };

  const handleCloseModal = () => {
    setClose(false);
  };

  async function changePasscode() {
    if (NewpasswordInput !== CFpasswordInput) {
      console.log("New Password Not Match!!");
      setMessage("passcode_notmatch");
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept-language": language,
        },
        body: JSON.stringify(
          user.isStaff
            ? {
                UserId: user.staffId,
                OldPassCode: OldpasswordInput,
                NewPassCode: NewpasswordInput,
              }
            : {
                ContractId: user.userId,
                OldPassword: OldpasswordInput,
                NewPassword: NewpasswordInput,
              }
        ),
      };
      console.log(requestOptions);
      await fetch(
        user.isStaff
          ? apiLink + CHANGE_PASSWORD_STAFF
          : apiLink_ecom + CHANGE_PASSWORD_AGENT,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          console.log(items);
          if (items["Success"] === true) {
            console.log("Change Success = true");
            if (user.isStaff) {
              setRoutePathSigin(routePathStaff);
            } else {
              setRoutePathSigin(routePathAgent);
            }
            sessionStorage.clear();
            setShowPopupChangePass(true);
          } else {
            console.log("Change Success = false");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert('Error: API /afcl/Account/changepasscode/save ', error)
        });
    }
  }
  const [showPopupChangePass, setShowPopupChangePass] = useState(false);
  const CloseShowPopupChangePass = () => setShowPopupChangePass(false);

  return (
    <Modal
      show={open}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="change-password-modal"
    >
      <Modal.Body>
        {/* <div
          className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius"
          style={{ margin: "0 auto" }}
        > */}
        <div className="body-change-password-modal">
          <from>
            <div className="mb-3">
              <h2 className="main-title mb-3">{t("change_password")}</h2>
              <div className="py-5">
                <div className="mb-3">
                  <label className="form-label text-color">
                    {t("old_password")}*{" "}
                  </label>
                  {OldpasswordType != "password" && (
                    <div className="input-group boxInputPass mb-3">
                      <input
                        type="text"
                        onKeyDown={onNumerPWKeyDown}
                        onChange={(e) => setOldPasswordInput(e.target.value)}
                        value={OldpasswordInput}
                        className="form-control boxborderPass"
                        placeholder={t("old_password")}
                        maxLength={user.isStaff ? 6 : 100}
                      />
                      <div className="input-group-prepend boxborderPass">
                        <span onClick={toggleOldPassword}>
                          <FiEyeOff />
                        </span>
                      </div>
                    </div>
                  )}
                  {OldpasswordType === "password" && (
                    <div className="input-group boxInputPass mb-3">
                      <input
                        type="password"
                        onKeyPress={onNumerPWKeyDown}
                        onChange={(e) => setOldPasswordInput(e.target.value)}
                        value={OldpasswordInput}
                        className="form-control boxborderPass"
                        placeholder={t("old_password")}
                        maxLength={user.isStaff ? 6 : 100}
                      />
                      <div className="input-group-prepend boxborderPass">
                        <span onClick={toggleOldPassword}>
                          <FiEye />
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label text-color">
                    {t("new_password")}*{" "}
                  </label>
                  {NewpasswordType != "password" && (
                    <div className="input-group boxInputPass mb-3">
                      <input
                        type="text"
                        onKeyPress={onNumerPWKeyDown}
                        onChange={(e) => setNewPasswordInput(e.target.value)}
                        value={NewpasswordInput}
                        className="form-control boxborderPass"
                        placeholder={t("new_password")}
                        disabled={!canChange}
                        maxLength={user.isStaff ? 6 : 100}
                      />
                      <div className="input-group-prepend boxborderPass">
                        <span onClick={toggleNewPassword}>
                          <FiEyeOff />
                        </span>
                      </div>
                    </div>
                  )}
                  {NewpasswordType === "password" && (
                    <div className="input-group boxInputPass mb-3">
                      <input
                        type="password"
                        onChange={(e) => setNewPasswordInput(e.target.value)}
                        value={NewpasswordInput}
                        className="form-control boxborderPass"
                        placeholder={t("new_password")}
                        disabled={!canChange}
                        maxLength={user.isStaff ? 6 : 100}
                      />
                      <div className="input-group-prepend boxborderPass">
                        <span onClick={toggleNewPassword}>
                          <FiEye />
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label text-color">
                    {t("comfirm_password")}*{" "}
                  </label>
                  {CFpasswordType != "password" && (
                    <div className="input-group boxInputPass mb-3">
                      <input
                        type="text"
                        onChange={(e) => setCFPasswordInput(e.target.value)}
                        value={CFpasswordInput}
                        className="form-control boxborderPass"
                        placeholder={t("comfirm_password")}
                        disabled={!canChange}
                        maxLength={user.isStaff ? 6 : 100}
                      />
                      <div className="input-group-prepend boxborderPass">
                        <span onClick={toggleCFPassword}>
                          <FiEyeOff />
                        </span>
                      </div>
                    </div>
                  )}
                  {CFpasswordType === "password" && (
                    <div className="input-group boxInputPass mb-3">
                      <input
                        type="password"
                        onChange={(e) => setCFPasswordInput(e.target.value)}
                        value={CFpasswordInput}
                        className="form-control boxborderPass"
                        placeholder={t("comfirm_password")}
                        disabled={!canChange}
                        maxLength={user.isStaff ? 6 : 100}
                      />
                      <div className="input-group-prepend boxborderPass">
                        <span onClick={toggleCFPassword}>
                          <FiEye />
                        </span>
                      </div>
                    </div>
                  )}
                  <span className="text-danger">{t(message)}</span>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Button
                type="button"
                className="btn btn-link btn-db text-capitalize"
                onClick={changePasscode}
                disabled={!activeConfirm}
              >
                {t("confirm")}
              </Button>
            </div>
          </from>
        </div>

        {/* </div> */}
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
};
