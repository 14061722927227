import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Countdown, { zeroPad } from "react-countdown";
import { apiLink, apiLink_ecom } from "../constants/DataPublic";
import { ReactComponent as Close } from "../component/img/close.svg";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { useGlobalState } from '../global';

import { ReactComponent as CityLedger } from '../component/icons/city_ledger.svg';
import { ReactComponent as SaveBooking } from '../component/icons/save_book.svg';
import { GET_OMISE, OMISE_CREDIT_CARD, OMISE_IBANKING, OMISE_INQUIRY, OMISE_MOBILE_BANKING, PAY_CITY_LEADGER, PAY_OMISE, SAVE_BOOKING, SAVE_BOOKING_PRINT, SAVE_CITY_LEADGER } from "../services/BASE_API";
import ModalSuccess from './../component/Modal/ModalSuccess';
import ModalFailed from './../component/Modal/ModalFailed';
import moment from "moment/moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const PaymentOmise = (props) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 990px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    let paymentRoute = JSON.parse(sessionStorage.getItem('PaymentRoute'));

    let myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    let Promo = sessionStorage.getItem('Promo')
    Promo = JSON.parse(Promo)

    let paymentHis = sessionStorage.getItem('paymentHis')
    paymentHis = JSON.parse(paymentHis)

    let guest = JSON.parse(sessionStorage.getItem("GuestInfo"));

    const params = new URLSearchParams(window.location.search)

    const { state } = useLocation();

    const { language, setActiveTab, getCartItem } = props
    const [publicKey, setPublicKey] = useGlobalState('publicKey')
    const [webPaymentUrl, setWebPaymentUrl] = useState("")
    const [invoiceNo, setInvoiceNo] = useState("")
    const [total, setTotal] = useState(0)
    const [countryList, setCountryList] = useState([])
    const [qrExpired, setQrExpired] = useState(600000)
    const [paymentSuccess, setPaymentSuccess] = useState(null)
    const [showAlert, setShowAlert] = useState(false);
    const [showSelectmethod, setShowSelectMethod] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [paymentList, setPaymentList] = useState([]);
    const [paymentType, setPaymentType] = useState(null);
    const [expiryShow, setExpiryShow] = useState('');
    const [cooldown, setCooldown] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [currentPayment, setCurrentPayment] = useState({
        paymentName: "",
        index: 0,
        paymentType: "credit_card"
    });
    const [cardInfo, setCardInfo] = useState({
        name: '',
        number: '',
        security_code: '',
        expiration_month: '',
        expiration_year: '',
        country: 'TH'
    });

    const [requireName, setRequireName] = useState('');
    const [requireNumber, setRequireNumber] = useState('');
    const [requireSecurity, setRequireSecuriry] = useState('');
    const [requireExpireMonth, setRequireExpireMounth] = useState('');
    const [requirerExpireYear, setRequireExpireYear] = useState('');
    const [requireCountry, setRequireCountry] = useState('');
    const [requireOmise, setRequireOmise] = useState('');
    const [dueDate, setDueDate] = useState('');

    let [OmiseCard, setOmiseCard] = useState();
    let [Omise, setOmise] = useState();

    const [paymentCondition, setPaymentCondition] = useState(null);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openFailedModal, setOpenFailedModal] = useState(false);
    const [aleart, setAleart] = useState("");

    // let confirmNo = "";
    // let totalPrice = 0;
    // let contractId = 0;
    // let guestCode = "";

    const [confirmNo, setConfirmNo] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [contractId, setContractId] = useState(0);
    const [guestCode, setGuestCode] = useState("");
    const [guestEmail, setGuestEmail] = useState("");
    const [bookingStatus, setBookingStatus] = useState(false);


    const { t } = useTranslation();
    const navigate = useNavigate()

    useEffect(() => {
        if (
            (confirmNo != "" && totalPrice != 0 && contractId != 0, guestCode != "")
        ) {
            console.log("GUEST CODE", guestCode);
            let confirm = params.get("ConfirmationNo");
            let userCode = params.get("UserCode");
            if (!confirm && !userCode) {
                if (user.conditionPayment == 0) {
                    getCityLedger();
                } else {
                    getInvoiceNumber();
                }
            }
        }
    }, [confirmNo, totalPrice, contractId, guestCode]);

    useEffect(() => {
        //Bypass Payment 
        // console.log("Route",paymentRoute);
        // if(paymentRoute != null){
        //     user.totalPrice = paymentRoute.totalPrice;
        //     user.confirmationNo = paymentRoute.confirmationNo;
        // };

        if (paymentRoute == null) {
            // confirmNo = user.confirmationNo;
            // totalPrice = user.totalPrice;
            // contractId = user.userCode;
            // guestCode = user.guestCode;
            setConfirmNo(user.confirmationNo);
            setTotalPrice(user.totalPrice);
            setContractId(user.userCode);
            setGuestCode(user.guestCode);
            setGuestEmail(guest.email);
            setBookingStatus(false);

            setTotal(user.totalPrice);
        } else {
            // confirmNo = paymentRoute.confirmationNo;
            // totalPrice = paymentRoute.totalPrice;
            // contractId = paymentRoute.userCode;
            // guestCode = paymentRoute.guestCode;
            setConfirmNo(paymentRoute.confirmationNo);
            setTotalPrice(paymentRoute.totalPrice);
            setContractId(paymentRoute.userCode);
            setGuestCode(paymentRoute.guestCode);
            setGuestEmail(paymentRoute.guestEmail);

            setTotal(paymentRoute.totalPrice);

            // setBookingStatus(paymentRoute.bookingStatus); // to disable save booking button for staff
            setBookingStatus(true);
        }

        setActiveTab("");

        console.log("Payment: ", user);
        console.log("language: ", language);
        console.log("myCart: ", myCart);
        if ((myCart == null || myCart == undefined || myCart.length == 0 || user == null || user == undefined) && (paymentRoute == null)) {
            console.log("OMISE : PaymentRoute", paymentRoute);
            //navigate("/")
        } else {
            if (language) {
                let confirm = params.get('ConfirmationNo')
                let userCode = params.get('UserCode')
                if (user != null && user != undefined) {
                    // setTotal(totalPrice)
                    if (confirm && userCode) {
                        navigate('/paymentProcess')
                    } else {
                        HandleLoadScript()
                        // setTotal(user.totalPrice)
                        //By Pass CityLeadger
                        if (user.conditionPayment == 0) {
                            // getCityLedger();
                            setCurrentPayment({
                                paymentName: "City Ledger",
                                index: 6,
                                paymentType: "city_ledger"
                            });
                        } else {
                            // getInvoiceNumber();     
                        }
                        getOmisePayment();
                        getCountry();
                        console.log("getMobileOS: ", getMobileOS());
                    }
                }
            }
        }
    }, [language])

    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
            return "iOS"
        }
        return "Other"
    }


    useEffect(() => {
        if (currentPayment.paymentType === 'promptpay') {
            omiseCardHandleSuccess()
        }
    }, [currentPayment])

    const HandleLoadScript = () => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://cdn.omise.co/omise.js";
        script.async = true;
        script.onload = () => OmiseLoaded();
        document.body.appendChild(script);
    }

    const OmiseLoaded = async () => {
        setOmise(window.Omise)
        setOmiseCard(window.OmiseCard)
    }

    useEffect(() => {
        if (Omise && OmiseCard) {
            getPublicKey()
            console.log("publicKeypublicKey: ", publicKey);
            Omise.setPublicKey(publicKey)
            OmiseCard.configure({
                publicKey: publicKey,
                currency: 'thb',
                frameLabel: 'AFCL',
                submitLabel: 'Pay Now',
                buttonLabel: 'Pay with Omise',
            })
        }
    }, [publicKey, Omise, OmiseCard])

    const getPublicKey = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        // /afcl/Omise/PublicKey
        await fetch(apiLink_ecom + '/afcl/Omise/PublicKey', requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    setPublicKey(items["VariableValues"])

                } else {
                    console.log("bad request", data["Result"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
            });
    }



    const CreditCardConfigure = (e) => {
        OmiseCard.configure({
            defaultPaymentMethod: e == 'ewallet' ? 'alipay' : e,
            otherPaymentMethods: e == 'ewallet' ? ['truemoney', 'shopeepay', 'alipay', 'rabbit_linepay', 'googlepay'] : [],
        })
        OmiseCard.configureButton('#' + e)
        OmiseCard.attach()
    }

    const omiseCardHandle = async (e) => {
        OmiseCard.open({
            amount: total,
            phone_number: "",
            onCreateTokenSuccess: (token) => {
                console.log("nonce", token)
                console.log("OmiseCard", OmiseCard)
                omiseCardHandleSuccess(token, e);
            },
        })

    }

    const handlePayment = (e) => {
        // e.preventDefault()
        CreditCardConfigure(e)
        omiseCardHandle(e)
    }

    const getCountry = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        // /afcl/Package/CountryIsoLookup/{culture}
        await fetch(apiLink_ecom + '/afcl/Package/CountryIsoLookup/' + language, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    setCountryList(items)
                    console.log("Country", items)
                    // setPaymentList(items)
                } else {
                    console.log("bad request", data["Result"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
            });
    }


    const omiseCardHandleSuccess = async (nonce, type) => {
        setLoadingApi(true)
        if (paymentType === 'truemoney') {
            navigate('/paymentOmise/trueMoney')
        } else {
            console.log("omiseCardHandleSuccess", paymentType)
            console.log("Token", nonce)
            let path, valid, payload
            if (currentPayment.paymentType !== 'credit_card') {
                // /afcl/Package/Omise/InternetBanking
                path = OMISE_IBANKING;
                valid = paymentType || currentPayment.paymentType === 'promptpay' ? true : false
                payload = JSON.stringify({
                    "ConfirmationNo": confirmNo,
                    // "UserCode": user.userCode,
                    "UserCode": guestCode,
                    "paymentType": currentPayment.paymentType === 'promptpay' ? 'promptpay' : paymentType,
                    "PhoneNo": "",
                    "InvoiceNumber": user.InvoiceNumber,
                    "PlatformType": "",
                    "Source": "Agent",
                    "Amount": totalPrice,
                });
            }
            if (currentPayment.paymentType === 'credit_card') {
                valid = true
                // /afcl/Package/Omise/CreditCard
                path = OMISE_CREDIT_CARD
                payload = JSON.stringify({
                    "ConfirmationNo": confirmNo,
                    // "UserCode": user.userCode,
                    "UserCode": guestCode,
                    "CardToken": nonce,
                    "Amount": totalPrice,
                    "InvoiceNumber": user.InvoiceNumber,
                    "Source": "Agent"
                })
            }
            if (currentPayment.paymentType === 'mobile_banking') {
                valid = paymentType ? true : false
                // /afcl/Package/Omise/CreditCard
                path = OMISE_MOBILE_BANKING
                payload = JSON.stringify({
                    // "UserCode": user.userCode,
                    "UserCode": guestCode,
                    "ConfirmationNo": confirmNo,
                    "PaymentType": paymentType,
                    "InvoiceNumber": user.InvoiceNumber,
                    "PhoneNo": "",
                    "PlatformType": getMobileOS().toLocaleUpperCase(),
                    "Source": "Agent",
                    "Amount": totalPrice,
                })
            }

            if (valid) {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "accept-language": language,
                        "Content-Type": "application/json",
                    },
                    body: payload,
                };
                console.log("Payment Payload", requestOptions.body);
                await fetch(apiLink_ecom + path, requestOptions)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        if (data["Success"]) {
                            const items = data["Result"];
                            console.log("items['PaymentType']", items["PaymentType"]);
                            setLoadingApi(false);
                            if (items["PaymentType"] === "promptpay") {
                                setWebPaymentUrl(items["WebPaymentUrl"]);
                                setShowAlert(true);
                            } else {
                                window.location.href = items["WebPaymentUrl"];
                            }
                        } else {
                            setLoadingApi(false);
                            setOpenFailedModal(true);
                            setAleart(data["Message"]);
                            console.log("bad request", data["Result"]);
                        }
                    });
            } else {
                setShowSelectMethod(true);
                setLoadingApi(false);
            }
        }

    }


    const getOmisePayment = async () => {
        // /afcl/Omise
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        await fetch(apiLink_ecom + GET_OMISE + "?isStaff=" + (user.isStaff ? "true" : "false"), requestOptions)
            // await fetch(apiLink_ecom + GET_OMISE + "?isStaff="+ user.isStaff? true : false , requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    console.log(items);
                    setPaymentList(items)
                } else {
                    console.log("bad request", data["Result"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
            });
    }

    const getInvoiceNumber = async () => {
        // /afcl/Package/PayOmise
        const requestOptions = {
            method: "POST",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
            body: JSON.stringify({
                "ConfirmationNo": confirmNo,
                "ContractId": contractId,
                "Amount": totalPrice
            }),
        };
        await fetch(apiLink_ecom + PAY_OMISE, requestOptions).
            then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    user.InvoiceNumber = items["InvoiceNumber"];
                    // user.totalPrice = items["AgentTotalPrice"];
                    sessionStorage.setItem('UserLogin', JSON.stringify(user));
                    setInvoiceNo(items["InvoiceNumber"]);
                    setTotal(items["AgentTotalPrice"]);

                    setPaymentCondition({
                        ConditionName: items.ConditionName,
                        ConditionPercenPayment: items.ConditionPercenPayment
                    });
                    if (items.ConditionPercenPayment === 0) {
                        setTotal(items["AgentTotalPrice"]);
                        setCurrentPayment({
                            paymentName: "City Ledger",
                            index: 6,
                            paymentType: "city_ledger"
                        });
                    } else {

                    }
                } else {
                    // navigate("/ticket/" + myCart[0].PackageCode + "/5")
                    console.log("bad request", data["Result"])
                }
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (cardInfo.name === '') {
            setRequireName('warn_card_name');
        } else setRequireName('');

        if (cardInfo.number === '') {
            setRequireNumber('warn_card_number');
        } else setRequireNumber('');

        if (cardInfo.expiration_month === '') {
            setRequireExpireMounth('warn_card_expire_month');
        } else setRequireExpireMounth('');

        if (cardInfo.expiration_year === '') {
            setRequireExpireYear('warn_card_expire_year');
        } else setRequireExpireYear('');

        if (cardInfo.security_code === '') {
            setRequireSecuriry('warn_card_security');
        } else setRequireSecuriry('');

        if (cardInfo.country === '') {
            setRequireCountry('warn_card_country');
        } else setRequireCountry('');

        if (cardInfo.name != '' && cardInfo.number != '' && cardInfo.expiration_month != '' && cardInfo.expiration_year && cardInfo.security_code != '' && cardInfo.country != '') {
            try {
                setLoadingApi(true)
                // const token = await createTokenPromise('card', cardInfo);
                Omise.createToken("card", cardInfo,
                    function (statusCode, response) {
                        // omiseCardHandleSuccess(response['id'], 'card')
                        if (response['id'] != undefined) {
                            omiseCardHandleSuccess(response['id'], 'card')
                        } else {
                            setLoadingApi(false)
                            setAleart(response['message']);
                            setOpenFailedModal(true);
                        }
                        console.log(response)
                    });

            } catch (error) {
                setLoadingApi(false)
                setRequireOmise('**' + error.message)
                console.log("requireNumber: ", requireNumber);
                console.log("Error: ", error);
            }
        }
    };

    const handleBlur = (e) => {
        console.log("e", e)
        if (!e.target.value.includes("/") && e.target.value.length === 4) {
            const inputValue = e.target.value;
            const updatedValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
            cardInfo.expiration_month = inputValue.slice(0, 2)
            cardInfo.expiration_year = inputValue.slice(2)
            setExpiryShow(updatedValue);
        }

        if (e.target.value.includes("/")) {
            const inputValue = e.target.value;
            let expireyear = inputValue.slice(2)
            // const updatedValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
            cardInfo.expiration_month = inputValue.slice(0, 2)
            cardInfo.expiration_year = expireyear.replace("/", "")
            // setExpiryShow(updatedValue);
        }
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <span></span>
        } else {
            // Render a countdown
            return (
                <span>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const checkQrStatus = async (e) => {
        // /afcl/Package/PayOmise
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', }
        };
        await fetch(apiLink_ecom + OMISE_INQUIRY + '?ConfirmationNo=' + confirmNo + '&UserCode=' + guestCode, requestOptions).
            then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    if (items['ResponseCode'] === "successful") {
                        sessionStorage.removeItem("MyCart")
                        // navigate("/done", { state: { confirmationNo: user.confirmationNo, TagHtml: items["TagHtml"] } })
                        navigate("/done", { state: { confirmationNo: confirmNo, TagHtml: items["TagHtml"], OrderId: items["OrderId"], GuestEmail: guestEmail } });
                        sessionStorage.removeItem("GuestInfo");
                    } else if (items['ResponseCode'] === "failed") {
                        navigate("/paymentfail", { state: { ResponseDetail: items["ResponseDetail"], } })
                    } else if (items['ResponseCode'] === "expired") {

                    } else if (items['ResponseCode'] === "pending") {

                    } else if (items['ResponseCode'] === "reversed") {

                    } else {
                        console.log("error")
                    }
                } else {
                    console.log("bad request", data["Result"])
                }
            })
    }

    useEffect(() => {
        if (cooldown) {
            const interval = setInterval(() => {
                checkQrStatus()
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [cooldown])

    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowButton(true)
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [showAlert])

    const onCloseModal = () => {
        setCooldown(false)
        setShowButton(false)
        setShowAlert(false)
    }

    useEffect(() => {
        console.log("PaymentCondition", paymentCondition);
    }, paymentCondition)

    const getCityLedger = async () => {
        setLoadingApi(true);
        const requestOptions = {
            method: "POST",
            headers: {
                "accept-language": language,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ConfirmationNo: confirmNo,
                ContractId: contractId,
            }),
        };
        await fetch(apiLink_ecom + PAY_CITY_LEADGER, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setLoadingApi(false);
                if (data["Success"]) {
                    const items = data["Result"];
                    console.log(items);
                    setTotal(items.AgentTotalPrice);
                    setInvoiceNo(items.InvoiceNumber);
                    setDueDate(items.DueDate.split("T")[0]);
                    user.InvoiceNumber = items.InvoiceNumber;
                    user.totalPrice = items.AgentTotalPrice;
                    setTotalPrice(items.AgentTotalPrice);
                    sessionStorage.setItem("UserLogin", JSON.stringify(user));
                } else {
                    navigate("/ticket/" + myCart[0].PackageCode + "/5");
                    console.log("bad request", data["Result"]);
                }
            });
    };

    const confirmCityLeadger = async () => {
        setLoadingApi(true);
        const requestOptions = {
            method: "POST",
            headers: {
                "accept-language": language,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                InvoiceNumber: invoiceNo,
                Amount: total,
                StaffId: user.staffId != null ? user.staffId : 0
            }),
        };
        // console.log(requestOptions.body);
        await fetch(apiLink_ecom + SAVE_CITY_LEADGER, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setLoadingApi(false);
                if (data["Success"]) {
                    const items = data["Result"];
                    console.log(items);
                    // setAleart('update_success');
                    // setOpenSuccessModal(true);
                    sessionStorage.removeItem("MyCart");
                    // user.confirmationNo = "";
                    // user.InvoiceNumber = "";
                    user.totalPrice = 0;
                    user.fullAmount = 0;
                    user.note = "";
                    user.note1 = "";
                    user.note2 = "";
                    user.note3 = "";
                    user.note4 = "";
                    sessionStorage.setItem("UserLogin", JSON.stringify(user));
                    // navigate("/done", { state: { confirmationNo: user.confirmationNo, TagHtml: items["TagHtml"] } })
                    navigate("/done", { state: { confirmationNo: confirmNo, TagHtml: items["TagHtml"], OrderId: items["OrderId"], GuestEmail: guestEmail } });
                    sessionStorage.removeItem("GuestInfo");

                } else {
                    console.log("bad request", data["Result"]);
                    setAleart(data["Message"] == null ? 'update_failed' : data["Message"]);
                    setOpenFailedModal(true);
                }
            });
    };

    const confirmSaveBooking = async () => {
        setLoadingApi(true);
        const requestOptions = {
            method: "POST",
            headers: {
                "accept-language": language,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ConfirmationNo: confirmNo,
            }),
        };
        await fetch(apiLink_ecom + SAVE_BOOKING, requestOptions)
            .then(function (response) {
                // Get the filename from the Content-Disposition header
                const contentDisposition = response.headers.get(
                    "Content-Disposition"
                );
                const match =
                    contentDisposition && contentDisposition.match(/filename="(.+)"/);
                const originalFilename = match && match[1] ? match[1] : "";
                const date = new Date().toISOString().slice(0, 10).replace(/-/g, "_"); // Format current date as yyyy_mm_dd
                const filename = user.confirmationNo + originalFilename + "_" + date; // Concatenate the orderId, original filename, and current date
                return response.blob().then((blob) => ({ blob, filename }));
            })
            .then(function ({ blob, filename }) {
                // Use the filename as the title of the new tab
                const url = URL.createObjectURL(blob);
                const newtab = window.open(url, "_blank");
                const link = document.createElement("a");
                // link.href = url;
                // link.download = filename;
                link.click();
                sessionStorage.removeItem("MyCart");
                sessionStorage.removeItem("GuestInfo");

                user.confirmationNo = "";
                user.InvoiceNumber = "";
                user.totalPrice = 0;
                user.fullAmount = 0;
                user.note = "";
                user.note1 = "";
                user.note2 = "";
                user.note3 = "";
                user.note4 = "";
                sessionStorage.setItem("UserLogin", JSON.stringify(user));

                // navigate("/done", { state: { confirmationNo: user.confirmationNo, TagHtml: items["TagHtml"], OrderId: items["OrderId"], GuestEmail: guest.email } });
                getCartItem();
                navigate("/order");
            }).catch((error) => {
                setLoadingApi(false);
                console.log("Error: ", error);
                alert("Error: " + SAVE_BOOKING, error);
                return false;
            });
    };

    const setCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        sessionStorage.removeItem("MyCart");
        navigate('/');
        window.location.reload();
        // navigate("/done", { state: { confirmationNo: user.confirmationNo, TagHtml: "" } });
    }

    const setCloseFailedModal = () => {
        setOpenFailedModal(false);
    }

    const FormatAmount = (amount) => {
        const resultPrice = amount
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return resultPrice;
    };

    return (
        <div className="page homepage" style={{ minHeight: isTabletOrMobile ? 'auto' : 'calc(100vh - 183px)' }}>
            <div className="section">
                <div className="container">
                    {paymentList.length !== 0 && user != null ? (
                        ''
                    ) : (
                        <Backdrop
                            sx={{ color: "#fff", zIndex: "1" }}
                            style={{ zIndex: 1100 }}
                            open
                        >
                            <div
                                class="spinner-border"
                                role="status"
                                aria-hidden="true"
                                style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
                            ></div>
                        </Backdrop>
                    )}

                    {loadingApi && (
                        <Backdrop
                            sx={{ color: "#fff", zIndex: "1" }}
                            style={{ zIndex: 1100 }}
                            open
                        >
                            <div
                                class="spinner-border"
                                role="status"
                                aria-hidden="true"
                                style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
                            ></div>
                        </Backdrop>
                    )}

                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-11" style={{ margin: '0 auto' }}>
                        <div className="row mb-4">
                            <div className="col-lg-12">
                                <h2 className="font-30 mb-5 lh-1" style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#00BFEA'
                                }}>
                                    {t('checkout')}
                                </h2>
                            </div>
                            <div className="col-lg-12">
                                {paymentCondition && paymentCondition.ConditionPercenPayment != 0 && (
                                    <h5 className="font-16 lh-1" style={{
                                        fontWeight: 600,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        {/* { t("paid_from_amount").replace("{0}", (paymentCondition == null? "" : paymentCondition.ConditionName))} */}
                                        {user.conditionPayment}
                                    </h5>
                                )}
                                <h5 className="font-16 lh-1" style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {invoiceNo}
                                </h5>
                                <h5 className="font-16 mb-3 lh-1" style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {/* {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')} */}
                                    {total &&
                                        !user.isStaff ? (
                                        <>
                                            {/* {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')} */}
                                            {FormatAmount(total)} {t('thb')}
                                        </>
                                    ) : (
                                        <>
                                            {/* {user.fullAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')} */}
                                            {/* {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')} */}
                                            {FormatAmount(total)} {t('thb')}
                                        </>
                                    )
                                    }
                                </h5>
                            </div>
                        </div>
                        {paymentList.length !== 0 && (
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="font-30 text-uppercase mb-5 lh-1" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        {t('select_payment_omi') + '*'}
                                    </h2>
                                    <form>
                                        <div className="row d-flex">
                                            {paymentCondition && paymentCondition.ConditionPercenPayment !== 0 ? (paymentList.map((item, index) => {
                                                return (
                                                    <>
                                                        {getMobileOS().toLocaleLowerCase() === 'other' ? (
                                                            <>
                                                                {item.PaymentType !== 'mobile_banking' && (
                                                                    <a key={item.Id} href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                                        <div id="credit_card" className="card" style={{ cursor: 'pointer', background: index === currentPayment.index ? '#79E7FF' : '', textAlign: 'center' }}
                                                                            onClick={() => { setCurrentPayment({ paymentName: item.PaymentHeaderName, index: index, paymentType: item.PaymentType }); setPaymentType(0); }}
                                                                        // onClick={() => handlePayment('credit_card')}
                                                                        >
                                                                            <div className="card-body">
                                                                                <p className="card-text">
                                                                                    <img src={item.Picture} width={item.PaymentType === 'credit_card' ? 113 : item.PaymentType === 'internet_banking' || item.PaymentType === 'mobile_banking' ? 95 : item.PaymentType === 'promptpay' ? 60 : 70} />
                                                                                </p>
                                                                                <p className="card-text">{item.PaymentHeaderName}</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <a key={item.Id} href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                                    <div id="credit_card" className="card" style={{ cursor: 'pointer', background: index === currentPayment.index ? '#79E7FF' : '', textAlign: 'center', height: '100%' }}
                                                                        onClick={() => { setCurrentPayment({ paymentName: item.PaymentHeaderName, index: index, paymentType: item.PaymentType }); setPaymentType(0); }}
                                                                    // onClick={() => handlePayment('credit_card')}
                                                                    >
                                                                        <div className="card-body">
                                                                            <p className="card-text">
                                                                                <img src={item.Picture} width={item.PaymentType === 'credit_card' ? 113 : item.PaymentType === 'internet_banking' || item.PaymentType === 'mobile_banking' ? 95 : item.PaymentType === 'promptpay' ? 60 : 70} />
                                                                            </p>
                                                                            <p className="card-text">{item.PaymentHeaderName}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        )}

                                                    </>
                                                )
                                            })) : (user.isCityLeadger && paymentRoute === null && <>
                                                {/* For City ledger */}
                                                <a href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                    <div id="city_ledger" className="card" style={{ cursor: 'pointer', background: 6 === currentPayment.index ? '#79E7FF' : '', textAlign: 'center', height: '100%' }}
                                                        onClick={() => { setCurrentPayment({ paymentName: "City Ledger", index: 6, paymentType: "city_ledger" }); setPaymentType(0); }}
                                                    // onClick={() => handlePayment('credit_card')}
                                                    >
                                                        <div className="card-body">
                                                            <p className="card-text">
                                                                <CityLedger width={70} />
                                                            </p>
                                                            <p className="card-text">City Ledger</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </>
                                            )}
                                            {user.conditionPayment === 0 && paymentRoute != null && (
                                                <>
                                                    {/* For City ledger */}
                                                    <a href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                        <div id="city_ledger" className="card" style={{ cursor: 'pointer', background: 6 === currentPayment.index ? '#79E7FF' : '', textAlign: 'center', height: '100%' }}
                                                            onClick={() => { setCurrentPayment({ paymentName: "City Ledger", index: 6, paymentType: "city_ledger" }); setPaymentType(0); }}
                                                        // onClick={() => handlePayment('credit_card')}
                                                        >
                                                            <div className="card-body">
                                                                <p className="card-text">
                                                                    <CityLedger width={70} />
                                                                </p>
                                                                <p className="card-text">City Ledger</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            )}
                                            {user.isStaff && bookingStatus == false && (
                                                <>
                                                    {/* For Save Booking */}
                                                    <a href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                        <div id="booking" className="card" style={{ cursor: 'pointer', background: 7 === currentPayment.index ? '#79E7FF' : '', textAlign: 'center', height: '100%' }}
                                                            onClick={() => { setCurrentPayment({ paymentName: "Save Book & Print", index: 7, paymentType: "booking" }); setPaymentType(0); }}
                                                        // onClick={() => handlePayment('credit_card')}
                                                        >
                                                            <div className="card-body">
                                                                <p className="card-text">
                                                                    <SaveBooking width={70} />
                                                                </p>
                                                                <p className="card-text">Save Book & Print</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>

                                <div className="col-sm-6">

                                    {/* for Credit Card */}
                                    {currentPayment.paymentType === 'credit_card' && (
                                        <>
                                            <div id={currentPayment.paymentType} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="py-5" style={{ paddingLeft: isTabletOrMobile ? '0px' : '40px' }}>
                                                    {/* <h2 className="font-30 mb-1 lh-1">{t('credit_debit_card')}</h2> */}
                                                    <h2 className="font-30 mb-1 lh-1">Credit / Debit Card</h2>
                                                    <p className="color-grey mb-4">Secured by Omise</p>

                                                    <form>
                                                        <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                            <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('card_number')} *</p>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                maxLength="16"
                                                                name="cardInfo_number"
                                                                placeholder={t('card_number') + '*'}
                                                                style={{ border: requireNumber !== '' ? '1px solid #ca4d4d' : '' }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(e) => cardInfo.number = e.target.value} />
                                                            <span className="text-danger">{t(requireNumber)}</span>
                                                        </div>

                                                        <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                            <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('card_name')} *</p>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="cardInfo_name"
                                                                placeholder={t('card_name') + '*'}
                                                                style={{ border: requireName !== '' ? '1px solid #ca4d4d' : '' }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[a-zA-Z]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(e) => cardInfo.name = e.target.value} />
                                                            <span className="text-danger">{t(requireName)}</span>
                                                        </div>
                                                        <div className="mb-2 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('expiry_date')} *</p>
                                                                    <input
                                                                        value={expiryShow}
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="cardInfo_expiry"
                                                                        maxLength="5"
                                                                        placeholder={t('expiry_date') + '*'}
                                                                        style={{ border: requireExpireMonth !== '' ? '1px solid #ca4d4d' : '' }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => { setExpiryShow(e.target.value); handleBlur(e) }}
                                                                    />
                                                                    <span className="text-danger">{t(requireExpireMonth)}</span>
                                                                </div>

                                                                <div className="col-6">
                                                                    <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('security_code')} *</p>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        name="cardInfo_security_code"
                                                                        placeholder={t('***')}
                                                                        maxLength="3"
                                                                        style={{ border: requireSecurity !== '' ? '1px solid #ca4d4d' : '' }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        onChange={(e) => cardInfo.security_code = e.target.value}
                                                                    />
                                                                    <span className="text-danger">{t(requireSecurity)}</span>
                                                                </div>
                                                            </div>
                                                            <span className="text-danger"></span>
                                                        </div>
                                                        <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                            <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('country_region')} *</p>
                                                            <select
                                                                defaultValue={cardInfo.country}
                                                                className="form-control"
                                                                style={{ border: requireCountry !== '' ? '1px solid #ca4d4d' : '' }}
                                                                onChange={(e) => cardInfo.country = e.target.value}>
                                                                {countryList && countryList.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.IsoCode} selected={cardInfo.country == item.IsoCode}>
                                                                            {item.CountryName}
                                                                        </option>
                                                                    )
                                                                })}
                                                                {/* <option key="1" value="th" selected={cardInfo.country == 'th'}>
                                                                Thailand
                                                            </option>
                                                            <option key="2" value="sg" selected={cardInfo.country == 'sg'}>
                                                                Sigapore
                                                            </option>
                                                            <option key="3" value="jp" selected={cardInfo.country == 'jp'}>
                                                                Japan
                                                            </option> */}
                                                            </select>
                                                            <span className="text-danger">{t(requireCountry)}</span>
                                                        </div>
                                                        <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                            <span className="text-danger" style={{ fontWeight: '700' }}>{t(requireOmise)}</span>
                                                        </div>
                                                        <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12 ">
                                                            <button type="button" className="btn btn-blue" onClick={handleSubmit}>
                                                                {t('confirm')}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {/* City Ledger */}
                                    {currentPayment.paymentType === 'city_ledger' && (
                                        <>
                                            <div id={currentPayment.paymentType} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="py-5" style={{ paddingLeft: isTabletOrMobile ? '0px' : '40px' }}>
                                                    <h2 className="font-28 mb-4 lh-1 text-center">{currentPayment.paymentName}</h2>
                                                    <p className="mb-1 px-2 text-center" style={{ color: '#0E315C', fontWeight: '500' }}>{t('amount')}</p>
                                                    <h2 className="font-20 mb-1 lh-1 text-center">
                                                        {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                                    </h2>

                                                    <div className="mb-5 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('duedate')}</p>
                                                        {/* <input id="startDate" className="form-control" type="date" min={new Date().toISOString().split('T')[0]} value={dueDate} disabled={true} onChange={e => {
                                                            const pickDate = e.target.value
                                                            if (pickDate < new Date().toISOString().split('T')[0]) {
                                                                let datePicker = document.getElementById("startDate")
                                                                setDueDate("")
                                                                datePicker.focus()
                                                            } else {
                                                                setDueDate(e.target.value)
                                                            }
                                                        }} /> */}
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterMoment}
                                                            dateFormats={"DD/MM/YYYY"}
                                                            adapterLocale="th"
                                                        >
                                                            <DatePicker

                                                                id="startDate" className="form-control" type="date"
                                                                value={dueDate == "" ? null : moment(dueDate)}
                                                                sx={{
                                                                    "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                                        height: "36px",
                                                                        backgroundColor: "#F2F3F5",
                                                                        borderColor: "#E5E5E5",
                                                                        border: "none !important",
                                                                    },
                                                                }}
                                                                disabled={true}
                                                                format={"DD/MM/YYYY"}
                                                                dayOfWeekFormatter={(day) => `${day}`}
                                                            /></LocalizationProvider>
                                                    </div>

                                                    <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                                                        <button type="button" className="btn btn-blue"
                                                            onClick={async () => {
                                                                await confirmCityLeadger();
                                                            }}
                                                        >
                                                            {t('confirm_issueticket')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {/* Save Booking */}
                                    {currentPayment.paymentType === 'booking' && (
                                        <>
                                            <div id={currentPayment.paymentType} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="py-5" style={{ paddingLeft: isTabletOrMobile ? '0px' : '40px' }}>
                                                    <h2 className="font-28 mb-4 lh-1 text-center">{currentPayment.paymentName}</h2>
                                                    <p className="mb-1 px-2 text-center" style={{ color: '#0E315C', fontWeight: '500' }}>{t('amount')}</p>
                                                    <h2 className="font-20 mb-1 lh-1 text-center">
                                                        {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                                    </h2>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                                                        <button type="button" className="btn btn-blue"
                                                            onClick={() => { confirmSaveBooking() }}>
                                                            {t('confirm_print')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {/* choose bank pattern */}
                                    {currentPayment.paymentType !== 'credit_card' && currentPayment.paymentType !== 'city_ledger' && currentPayment.paymentType !== 'booking' && currentPayment.paymentType !== 'promptpay' && (
                                        <div id={currentPayment.paymentType} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="py-5" style={{ paddingLeft: isTabletOrMobile ? '0px' : '40px' }}>
                                                <h2 className="font-30 mb-1 lh-1">{currentPayment.paymentName}</h2>
                                                <p className="color-grey mb-4">{currentPayment.paymentType === 'internet_banking' ? t('select_your_bank') : t('select_your_ewallet')}</p>
                                                <form>
                                                    {paymentList[currentPayment.index].Details && paymentList[currentPayment.index].Details.map((val, index) => {
                                                        return (
                                                            <>
                                                                <div id={val.PaymentType} className="mb-3 row col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex card"
                                                                    onClick={() => { setPaymentType(val.PaymentType) }}
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        margin: '0 auto',
                                                                        height: '42px',
                                                                        cursor: 'pointer',
                                                                        background: val.PaymentType === paymentType ? '#79E7FF' : ''
                                                                    }}>
                                                                    <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2" style={{ margin: 'auto auto' }}>
                                                                        <img src={val.Picture} width={30}></img>
                                                                    </div>
                                                                    <div className="col-10 col-xl-10 col-lg-10 col-md-10 col-sm-10" style={{ margin: 'auto' }}>
                                                                        {val.PaymentDetailName}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                    <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ margin: 'auto' }}>
                                                        <button type="button" className="btn btn-blue" onClick={omiseCardHandleSuccess}>
                                                            {t('confirm')}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}
                    </div>

                    {currentPayment.paymentType === 'promptpay' && (
                        <>
                            {webPaymentUrl == "" && (
                                <Backdrop
                                    sx={{ color: "#fff", zIndex: "1" }}
                                    style={{ zIndex: 1100 }}
                                    open
                                >
                                    <div
                                        class="spinner-border"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
                                    ></div>
                                </Backdrop>
                            )}
                        </>
                    )}

                    {webPaymentUrl && (
                        <Modal show={showAlert} className="col-12 cart-placeorder">
                            <a href="#" className="close">
                                <Close className="popup-close" onClick={onCloseModal} />
                            </a>
                            <h2 className="font-30 mb-1 lh-1 text-center">{currentPayment.paymentName}</h2>
                            <p className="color-grey mb-4 text-center">{t('scan_qr_to_pay')}</p>
                            <h5 className="text-center">
                                <img src={webPaymentUrl} width={isTabletOrMobile ? 200 : 300} />
                                <div className="text-center py-3">
                                    {/* Remaining time:&nbsp; */}
                                    <Countdown
                                        date={Date.now() + qrExpired}
                                        renderer={renderer}
                                        onComplete={() => {
                                            window.location.reload();
                                        }}
                                    />
                                </div>
                                <h5 className="font-16 mt-3 lh-1" style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                </h5>
                                {showButton && (
                                    <div className="mt-4 col-8" style={{ margin: 'auto' }}>
                                        <button type="button" className="btn btn-blue" onClick={() => { setCooldown(true); checkQrStatus() }}>
                                            {t('check_payment_status')}
                                        </button>
                                    </div>
                                )}

                            </h5>
                        </Modal>
                    )}

                    <Modal show={showSelectmethod} className="col-12 cart-placeorder">
                        <a href="#" className="close">
                            <Close className="popup-close" onClick={() => setShowSelectMethod(false)} />
                        </a>
                        <h5 className="text-center text-danger">{t('warn_payment')}</h5>
                    </Modal>
                    <ModalSuccess
                        open={openSuccessModal}
                        body={aleart}
                        setClose={setCloseSuccessModal}
                        back={setCloseSuccessModal}
                    />
                    <ModalFailed
                        open={openFailedModal}
                        body={aleart}
                        setClose={setCloseFailedModal}
                        back={setCloseFailedModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentOmise;
