import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { AGENT_SEND_EMAIL, STAFF_SEND_EMAIL } from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";

import imgSuccess from "../img/popup-success.png";
import Backdrop from "@mui/material/Backdrop";
import { useTranslation } from "react-i18next";
const SendEmail = (props) => {
  const { order, open, setClose, disabled, setEmailStatus } = props;

  const [value, setValue] = useState(order[0].email);
  const [loading, setLoading] = useState(false);
  const [canSendEmail, setCanSendEmail] = useState(false);
  const [message, setMessage] = useState("");

  const {t} = useTranslation();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(()=>{
    emailValidation(value);
  },[value]);

  function emailValidation() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || value === "") {
      setMessage(t("warn_email"));
      setCanSendEmail(false);
      return false;
    } else if (regex.test(value) === false) {
      setMessage(t("invalid_email"));
      setCanSendEmail(false);
      return false;
    } else {
      setMessage("");
      setCanSendEmail(true);
      return true; 
    }
  }

  async function callService() {
    if(disabled){ // if disable = true? agent : staff
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "EN",
        },
        body: JSON.stringify({
          OrderId: order[0].id,
          GuestEmail: value,
        }),
      };
      console.log(requestOptions);
      setTimeout(() => {
        fetch(apiLink_ecom + AGENT_SEND_EMAIL, requestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            var item = data["Result"];
            console.log(data);
            if(data["Success"]){
              setEmailStatus("Success");
            }else{
              setEmailStatus("Failed");
            }

            if (item == null) {
              alert("Not Found");
            } else {
              if(!item){
                alert(data["Message"]);
              }
              setLoading(false);
              setClose();
            }
            return true;
          })
          .catch((error) => {
            console.log("Error: ", error);
            alert("Error: " + AGENT_SEND_EMAIL, error);
            return false;
          });
      }, 1000);
    }
    else{
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "EN",
        },
        body: JSON.stringify({
          OrderId: order[0].id,
          GuestEmail: value,
        }),
      };
      console.log("API Request", requestOptions);
      setTimeout(() => {
        fetch(apiLink_ecom + STAFF_SEND_EMAIL, requestOptions)
          .then(function (response) {
            return response.json();
          })
          .then(function (data) {
            var item = data["Result"];
            console.log(data);
            if(data["Success"]){
              setEmailStatus("Success");
            }else{
              setEmailStatus("Failed");
            }

            if (item == null) {
              alert("Not Found");
            } else {
              if(!item){
                alert(data["Message"]);
              }
              setLoading(false);
              setClose();
            }
            return true;
          })
          .catch((error) => {
            console.log("Error: ", error);
            alert("Error: " + AGENT_SEND_EMAIL, error);
            return false;
          });
      }, 1000);
      // //Close Modal
      // setLoading(false);
      // setClose();
    } 
  }

  return (
    <Modal
      show={open}
      onHide={setClose}
      backdrop="static"
      keyboard={false}
      className="sendemail-dialog"
    >
      <Modal.Header closeButton>
        <span>{t('email_status')}</span>
      </Modal.Header>
      <Modal.Body className="sendemail-dialog-body">
        <div className="sendemail-container">
          <form>
            <div class="sendemail-form mb-3">
              <label for="exampleInputEmail1" class="form-label">
              {t('enter_email')}
              </label>
              <input
                type="email"
                className={message == "" ? "form-control mb-3" : "form-control mb-3 danger"}
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={order[0].email == "" ? "" : value}
                maxLength={50}
                disabled={disabled}
                onChange={handleChange}
              />
              <span style={{ display: "flex", justifyContent: "start", color: "#DC0000", fontWeight:"normal" }}>{message}</span>
              <div class="mt-1" style={{color: "#3B84F2" }}>
                {t("email_help")}
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!loading ? (
          <Button onClick={callService} disabled={!canSendEmail}>{t('confirm')}</Button>
        ) : (
          <button
            class="btn btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></span>
            {t('sending')}...
          </button>
        )}
      </Modal.Footer>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
          )}
    </Modal>
  );
};

export default SendEmail;
