import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import { apiLink_ecom } from "../constants/DataPublic";
import { CONFIRM_AMOUNT } from "../services/BASE_API";

const ConfirmAmount = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, setActiveTab } = props;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 990px)" });

  const [radioState, setRadioState] = useState("0");
  const [buttonState, setButtonState] = useState(true);
  const [amount, setAmount] = useState(0);
  const [minimum, setMinimum] = useState(0);
  const [maximum, setMaximum] = useState(0);
  const [message, setMessage] = useState("");
  const [paymentCondition, setPaymentCondition] = useState("");

  let user = JSON.parse(sessionStorage.getItem("UserLogin"));
  let paymentRoute = JSON.parse(sessionStorage.getItem("PaymentRoute"));

  const payment = () => {
    let totalAmount = 0;
    let conditionPayment = "";
    switch (radioState) {
      case "0":
        totalAmount = maximum;
        conditionPayment = t("full_payment");
        break;
      case "1":
        totalAmount = minimum;
        conditionPayment = t("paid_from_minimum").replace(
          "{0}",
          paymentCondition
        );
        break;
      case "2":
        totalAmount = parseAmount(amount);
        conditionPayment = t("from_amount_payment").replace(
          "{0}",
          FormatAmount(totalAmount)
        );
        break;
    }
    if (paymentRoute != null) {
      paymentRoute.totalPrice = parseFloat(totalAmount);
    } else {
      user.totalPrice = parseFloat(totalAmount);
    }
    user.conditionPayment = conditionPayment;

    sessionStorage.setItem("UserLogin", JSON.stringify(user));
    sessionStorage.setItem("PaymentRoute", JSON.stringify(paymentRoute));
    navigate("/paymentOmise");
  };

  useEffect(() => {
    // console.log(state);
    setActiveTab("");
    setAmount(FormatAmount(minimum));
    if (paymentRoute === null) {
      getPaymentCondition(user.confirmationNo);
    } else {
      getPaymentCondition(paymentRoute.confirmationNo);
    }
  }, []);

  useEffect(() => {
    console.log(radioState);
    if (radioState !== "2") {
      if (minimum != 0 && maximum != 0) {
        console.log("AMOUNTT",minimum, maximum);
        setButtonState(true);
        setMessage("");
      }else{
        setButtonState(false);
      }
    } else {
      validate(parseAmount(amount));
    }
  }, [radioState, maximum, minimum]);

  const handelSelect = (e) => {
    let value = e.target.value;
    setRadioState(value);
  };

  const handleInputFocus = (event) => {
    event.target.setAttribute("autocomplete", "off");
  };

  const handleAmountChange = (event) => {
    setRadioState("2");
    let value = event.target.value;
    setAmount(value);
    validate(value);
  };

  const onFormat = async (e) => {
    let value = await parseAmount(amount != "" ? amount : "0");
    value = FormatAmount(value);
    setAmount(value);
  };

  const parseAmount = (amount) => {
    let price = amount;
    const checkPrice = amount.split(",");
    if (checkPrice.length > 0) {
      price = amount.replace(",", "");
    }
    const v1 = parseFloat(price);
    return v1;
  };

  const onPricePWKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
    }
  };

  const FormatAmount = (amount) => {
    const resultPrice = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  const validate = (amount) => {
    var min = parseAmount(minimum.toString()).toFixed(2);
    var max = parseAmount(maximum.toString()).toFixed(2);
    amount = parseAmount(amount.toString());
    if(amount == NaN){
      amount = 0;
    }
    if (amount >= 0) {
      if (amount < min) {
        setMessage("minimum_warning");
        setButtonState(false);
        console.log(amount, " ++ ", min);
        console.log(amount);
      } else if (amount > max) {
        setMessage("maximum_warning");
        setButtonState(false);
        console.log(amount, " ++ ", max);
      } else {
        console.log(amount);
        setMessage("");
        setButtonState(true);
      }
    } else {
      setButtonState(false);
    }
  };

  const getPaymentCondition = async (confirmationNo) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "accept-language": language,
        "Content-Type": "application/json",
      },
    };
    await fetch(
      apiLink_ecom + CONFIRM_AMOUNT + "?ConfirmationNo=" + confirmationNo,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data["Success"]) {
          const items = data["Result"];
          setMaximum(items.FullAmount);
          setMinimum(items.Minimum);
          setAmount(FormatAmount(items.Minimum));
          setPaymentCondition(items.ConditionName);
          console.log(paymentCondition);
          // if (user.isCityLeadger) {
          //   user.conditionPayment = 0;
          //   user.totalPrice = items.FullAmount;
          //   sessionStorage.setItem("UserLogin", JSON.stringify(user));
          //   navigate("/paymentOmise");
          // }

          if (paymentRoute != null) {
            if (items.ConditionPercenPayment === 0) {
              user.conditionPayment = 0;
              paymentRoute.totalPrice = items.FullAmount;
              sessionStorage.setItem("UserLogin", JSON.stringify(user));
              sessionStorage.setItem(
                "PaymentRoute",
                JSON.stringify(paymentRoute)
              );
              navigate("/paymentOmise");
            }
          } else {
            if (items.ConditionPercenPayment === 0) {
              user.conditionPayment = 0;
              user.totalPrice = items.FullAmount;
              sessionStorage.setItem("UserLogin", JSON.stringify(user));
              navigate("/paymentOmise");
            }
          }
        } else {
          console.log("bad request", data["Result"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert('Error: API ', user.paymentUrl , error)
      });
  };

  return (
    <div
      className="page homepage"
      style={{ minHeight: isTabletOrMobile ? "auto" : "calc(100vh - 183px)" }}
    >
      <div className="section">
        <div className="select-content">
          <div className="selected-amount-container">
            <div className="row mb-3">
              <div className="col">
                <p className="select-header">{t("select_amount")}</p>
              </div>
            </div>
            <div className="row mb-3" onClick={() => setRadioState("0")}>
              <div className="col">
                <div className="select-full">
                  <div className="row">
                    <div className="col-2">
                      <input
                        type="radio"
                        value={0}
                        checked={radioState === "0"}
                        onChange={handelSelect}
                        className="radio"
                      ></input>
                    </div>
                    <div className="col">
                      <p>{t("full_amount")}</p>
                    </div>
                    <div className="col-4 amount">
                      <p>{FormatAmount(maximum)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3" onClick={() => setRadioState("1")}>
              <div className="col">
                <div className="select-full">
                  <div className="row">
                    <div className="col-2">
                      <input
                        type="radio"
                        value={1}
                        checked={radioState === "1"}
                        onChange={handelSelect}
                        className="radio"
                      ></input>
                    </div>
                    <div className="col">
                      <p>{t("minimum")}</p>
                    </div>
                    <div className="col-4 amount">
                      <p>{FormatAmount(minimum)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4" onClick={() => setRadioState("2")}>
              <div className="col">
                <div className="select-full">
                  <div className="row mb-3">
                    <div className="col-2">
                      <input
                        type="radio"
                        value={2}
                        checked={radioState === "2"}
                        onChange={handelSelect}
                        className="radio"
                      ></input>
                    </div>
                    <div className="col-10">
                      <p>{t("enter_amount")}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col">
                      <input
                        type="text"
                        className={message == "" ? "form-control" : "form-control danger"}
                        id="inputAmount"
                        value={amount}
                        onChange={handleAmountChange}
                        onKeyPress={onPricePWKeyDown}
                        onBlur={onFormat}
                        onFocus={handleInputFocus}
                        autoComplete="false"
                        aria-describedby="amountHelp"
                      />
                      <span className="text-danger">{t(message)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                <button
                  className="btn btn-filter-confirm"
                  style={{
                    backgroundColor: "#0e315c",
                    cursor: "pointer",
                    maxWidth: "50%",
                    textTransform: "capitalize",
                    borderColor: "#0e315c",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    payment();
                  }}
                  disabled={!buttonState}
                >
                  {t("confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAmount;
