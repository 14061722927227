import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import { SelectItemModal } from "./SelectItemModal";
import { ConfirmModal } from "./ConfirmModal";

import {
  GET_ORDER_INFO,
  DELETE_BOOKING_ITEM,
  UPDATE_BOOKING_ORDER,
  GET_ORDER_INFO_STAFF,
  CHECK_ALLOTMENT,
  CHECK_UPDATE_ALLOTMENT,
} from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";
import { async } from "q";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ModalSuccess from "../../component/Modal/ModalSuccess";
import ModalFailed from "../../component/Modal/ModalFailed";
import Backdrop from "@mui/material/Backdrop";
import { AllotmentErrorModal } from "./AllotmentErrorModal";
import { ConfirmDeletePackNotFoundRate } from "./ConfirmDeletePackNotFoundRate";

export const OrderInfoModalStaff = (props) => {
  const navigate = useNavigate();
  const { open, setClose, dataOrderInfo, orderNo } = props;
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserLogin"))
  );
  const [init, setinit] = useState(true);
  const [OrderInfoData, setOrderInfoData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [listItem, setListItem] = useState(null);
  const [email, setEmail] = useState(null);
  const [openSelectItemModal, setOpenSelectItemModal] = React.useState(false);
  const [openComfirmModal, setOpenComfirmModal] = React.useState(false);
  const [indexDelete, setIndexDelete] = useState(null);
  const { t, i18n } = useTranslation();
  const [totalCharge, setTotalCharge] = useState("0.00");

  const [openSuccessModal, setOpenSuccessModal] = useState(false); //Success Modal
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [aleart, setAleart] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAllotErrorModal, setShowAllotErrorModal] = useState(false);
  const [listAllotment, setListAllotment] = useState(null);
  const [listAllotmentFail, setListAllotmentFail] = useState(null);
  const [errorFormatEmail, setErrorFormatEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [editButtonState, setEditButtonState] = useState(true);
  const [
    showConfirmDeletePackageRateNotFound,
    setshowConfirmDeletePackageRateNotFound,
  ] = useState(false);
  const [listDeletePackage, setListDeletePackage] = useState(null);
  const [statusBooking, setStatusBooking] = useState(null);

  // const [user, setUser] = useState(
  //   JSON.parse(sessionStorage.getItem("UserLogin"))
  // );

  const onCloseShowAllotErrorModal = () => {
    setShowAllotErrorModal(false);
  };

  useEffect(async () => {
    if (init == true) {
      loadOrderDetail();
      setinit(false);
    }
  });
  useEffect(async () => {
    if (refreshData) {
      setOpenSelectItemModal(false);
      await CallServiceGetOrderInfo(orderNo);
      setRefreshData(false);
    }
  }, [refreshData]);

  useEffect(() => {
    if (email != null) {
      onChangeData();
    }
  }, [email]);

  const loadOrderDetail = async () => {
    await CallServiceGetOrderInfo(orderNo);
  };

  const handleClose = () => {
    setClose(false);
  };

  useEffect(() => {
    if (listAllotmentFail) {
    } else {
    }
  }, [listAllotmentFail]);

  useEffect(() => {
    if (OrderInfoData != null) {
      var disable =
        OrderInfoData != null &&
        OrderInfoData.BookStatus === "T" &&
        !OrderInfoData.IsOpenBill
          ? false
          : true;
      setEditButtonState(disable);
    }
  }, [OrderInfoData]);

  useEffect(() => {
    console.log("editButtonState", editButtonState);
  }, [editButtonState]);

  function timeFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YY HH:mm").toString();
  }

  const handleCloseSelectItemModal = () => {
    setOpenSelectItemModal(false);
  };

  const handleOpenSelectItem = () => {
    setOpenSelectItemModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenComfirmModal(false);
  };

  const handleOpenConfirmModal = (index) => {
    setIndexDelete(index);
    setOpenComfirmModal(true);
  };

  const setCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
  };

  const setConfirmDelectPackage = () => {
    setshowConfirmDeletePackageRateNotFound(false);
    if (listDeletePackage != null) {
      console.log("listDeletePackage", listDeletePackage);
      listDeletePackage.map(async (item, index) => {
        console.log("listDeletePackage (OrderDetailId)", item.OrderDetailId);
        await ConfirmCallServiceDeleteBookingItem(item.OrderDetailId, (index == listDeletePackage.length - 1));
      });
    }
  };

  async function ConfirmCallServiceDeleteBookingItem(orderDetailId, isLastItem) {
    console.log("orderDetailId " + orderDetailId);
    setLoading(true);
    setinit(false);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(
      apiLink_ecom + DELETE_BOOKING_ITEM + "?OrderDetailId=" + orderDetailId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data");
        console.log(data);
        handleCloseConfirmModal();
        setLoading(false);
        if (data["Success"]) {
          // setOpenSuccessModal(true);
          // setAleart("delete_package_item_success");
        } else {
          // setOpenFailedModal(true);
          // setAleart("delete_package_item_fail");
        }
        if(isLastItem == true){
          CallServiceGetOrderInfo(orderNo);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + DELETE_BOOKING_ITEM, error);
        setLoading(false);
        handleClose();
        return false;
      });
  }

  async function CallServiceGetOrderInfo(orderId) {
    setLoading(true);
    console.log("orderId " + orderId);
    setinit(false);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(
      apiLink_ecom + GET_ORDER_INFO + "?OrderId=" + orderId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data");
        console.log(data["Result"]);
        if (data["Success"]) {
          setOrderInfoData(data["Result"]);
          setEmail(data["Result"].Email);
          setStatusBooking(data["Result"].BookStatus);
          setData(data["Result"].Details);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + GET_ORDER_INFO, error);
        handleClose();
        setLoading(false);
        return false;
      });
  }

  async function CallServicePostUpdateBooking(payload) {
    setLoading(true);
    setErrorFormatEmail(false);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    await fetch(apiLink_ecom + UPDATE_BOOKING_ORDER, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          setOpenSuccessModal(true);
          setAleart("update_booking_success");
          CallServiceGetOrderInfo(orderNo);
        } else {
          setOpenFailedModal(true);
          setAleart("update_booking_fail");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + UPDATE_BOOKING_ORDER, error);
        setLoading(false);
        return false;
      });
  }

  useEffect(() => {
    if (listItem != null) {
      onUpdateListAllotment(listItem);
      if (listItem != null && listItem.length > 0) {
        console.log("dateRateIsNull(listItem) >> ", listItem);
        let itemDelect = [];
        listItem.map((item)=>{
          if(item.ItemRates.length == 0){
            itemDelect.push(item);
          }
        });
        if (itemDelect.length > 0 && statusBooking === "T") {
          console.log("dateRateIsNull >> ", itemDelect);
          setListDeletePackage(itemDelect);
          setshowConfirmDeletePackageRateNotFound(true);
        }
      }
    }
  }, [listItem]);

  const onUpdateListAllotment = (listDetail) => {
    let listAllot = [];
    setListAllotmentFail(null);
    console.log("listDetail", listDetail);
    listDetail.map((data) => {
      if (data.CabanaItemCode != null) {
        let allotment = null;
        if (listAllotment != null) {
          console.log("listAllotment", listAllotment);
          allotment = listAllotment.filter(
            (x) => x.ItemCode === data.CabanaItemCode
          );
          console.log("allotment", allotment);
        }
        let qty = parseInt(data.qty);
        if (isNaN(qty)) {
          qty = 0;
        }
        listAllot.push({
          cabanaItemCode: data.CabanaItemCode,
          bookingTotal:
            allotment != null
              ? allotment.length > 0
                ? allotment[0].BookedQty
                : 0
              : 0,
          changeTotal: data.qty === "" ? 0 : qty,
        });
      }
    });
    console.log("listAllot before", listAllot);
    const totalByCabanaItemCode = listAllot.reduce((acc, item) => {
      if (!acc[item.cabanaItemCode]) {
        acc[item.cabanaItemCode] = {
          ItemCode: item.cabanaItemCode,
          BookedQty: item.bookingTotal,
          TotalQty: 0,
        };
      }
      acc[item.cabanaItemCode].TotalQty += item.changeTotal;
      return acc;
    }, {});
    console.log("totalByCabanaItemCode", totalByCabanaItemCode);
    const result = Object.values(totalByCabanaItemCode);
    console.log("listAllot after", result);
    setListAllotment(result);
  };

  async function CallServicePostCheckUpdateAllotment(payload) {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify(payload),
    };
    await fetch(apiLink_ecom + CHECK_UPDATE_ALLOTMENT, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          const result = data["Result"];
          let listFail = result.filter((x) => x.Status == false);
          if (listFail.length == 0) {
            onPackData();
          } else {
            setListAllotmentFail(listFail);
            setShowAllotErrorModal(true);
          }
        } else {
          setOpenFailedModal(true);
          setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + CHECK_UPDATE_ALLOTMENT, error);
        setLoading(false);
        return false;
      });
  }

  async function CallServiceDeleteBookingItem(orderDetailId) {
    console.log("orderDetailId " + orderDetailId);
    setLoading(true);
    setinit(false);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(
      apiLink_ecom + DELETE_BOOKING_ITEM + "?OrderDetailId=" + orderDetailId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data");
        console.log(data);
        handleCloseConfirmModal();
        setLoading(false);
        if (data["Success"]) {
          setOpenSuccessModal(true);
          setAleart("delete_package_item_success");
        } else {
          setOpenFailedModal(true);
          setAleart("delete_package_item_fail");
        }
        CallServiceGetOrderInfo(orderNo);
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + DELETE_BOOKING_ITEM, error);
        setLoading(false);
        handleClose();
        return false;
      });
  }

  const setData = async (data) => {
    console.log("OrderDetailTableStaff >> setData");
    const list = [];
    let listAllot = [];
    let TotalChargeAll = 0.0;
    data.map((data, index) => {
      let unit_price = data.unitPrice != null ? data.unitPrice : 0.0;
      // if(data.ItemRates != null){
      //   const rate = data.ItemRates.find(
      //     (item) => data.qty >= item.QtyFrom && data.qty <= item.QtyTo //(item.QtyTo == 0 ? Infinity : item.QtyTo)
      //   );
      //   if(rate != null){
      //     unit_price = rate.Price;
      //   }
      // }
      const total_amount = data.qty * unit_price;
      TotalChargeAll = TotalChargeAll + total_amount;
      list.push({
        OrderId: data.OrderId,
        OrderDetailId: data.OrderDetailId,
        PackageCode: data.PackageCode,
        PackageName: data.PackageName,
        ItemCode: data.ItemCode,
        ItemName: data.ItemName,
        qty: data.qty,
        unit: data.unit,
        unitPrice: FormatAmount(unit_price),
        TotalAmount: FormatAmount(total_amount),
        ItemRates: data.ItemRates,
        CabanaItemCode: data.CabanaItemCode,
        VisitDate: data.VisitDate,
        VisitLocation: data.VisitLocation,
        VisitTime: data.VisitTime,
        BasePrice: data.BasePrice,
      });
      if (data.CabanaItemCode != null) {
        listAllot.push({
          cabanaItemCode: data.CabanaItemCode,
          bookingTotal: data.qty,
          changeTotal: data.qty,
        });
      }
    });
    console.log("listAllot before", listAllot);
    const totalByCabanaItemCode = listAllot.reduce((acc, item) => {
      if (!acc[item.cabanaItemCode]) {
        acc[item.cabanaItemCode] = {
          ItemCode: item.cabanaItemCode,
          BookedQty: 0,
          TotalQty: 0,
        };
      }
      acc[item.cabanaItemCode].BookedQty += item.bookingTotal;
      acc[item.cabanaItemCode].TotalQty += item.changeTotal;
      return acc;
    }, {});
    console.log("totalByCabanaItemCode", totalByCabanaItemCode);
    const result = Object.values(totalByCabanaItemCode);
    console.log("listAllot after", result);
    await setListAllotment(result.length > 0 ? result : null);
    setTotalCharge(FormatAmount(TotalChargeAll));
    console.log("listDetail", list);
    setListItem(list);
  };

  const changeEmail = (value) => {
    setEmail(value);
  };

  const onChangeData = () => {
    let data = {
      OrderId: OrderInfoData.OrderId,
      GuestCode: OrderInfoData.GuestCode,
      PaidStatus: OrderInfoData.PaidStatus,
      BookStatus: OrderInfoData.BookStatus,
      SendEmail: OrderInfoData.SendEmail,
      Email: email,
      OrderNo: OrderInfoData.OrderNo,
      CustomerCode: OrderInfoData.CustomerCode,
      FirstName: OrderInfoData.FirstName,
      LastName: OrderInfoData.LastName,
      GroupName: OrderInfoData.GroupName,
      CountryId: OrderInfoData.CountryId,
      PhoneCode: OrderInfoData.PhoneCode,
      PhoneNumber: OrderInfoData.PhoneNumber,
      // TelNo: OrderInfoData.TelNo,
      ArrivalDate: OrderInfoData.ArrivalDate,
      ArrivalTime: OrderInfoData.ArrivalTime,
      Expire: OrderInfoData.Expire,
      TotalCharge: OrderInfoData.TotalCharge,
      TotalPaid: OrderInfoData.TotalPaid,
      Outstanding: OrderInfoData.Outstanding,
      SaleDate: OrderInfoData.SaleDate,
      ContractId: OrderInfoData.ContractId,
      Details: listItem,
      IsOpenBill: OrderInfoData.IsOpenBill,
    };
    // data.Email = value;
    console.log(data);
    setOrderInfoData(data);
  };

  const onCheckAllotment = () => {
    if (isValidEmail()) {
      if (listAllotment != null) {
        const payload = {
          ArrivalDate: OrderInfoData.ArrivalDate.split("T")[0],
          AllotmentItems: listAllotment,
        };
        CallServicePostCheckUpdateAllotment(payload);
      } else {
        onPackData();
      }
    } else {
      setErrorFormatEmail(true);
      if (email === "") {
        setErrorMessage(t("please_enter_your_email"));
      } else {
        setErrorMessage(t("invalid_email_format"));
      }
    }
  };

  const onPackData = () => {
    let detailList = [];
    listItem.map((item) => {
      const unit_price = parseAmount(
        item.unitPrice != undefined ? item.unitPrice + "" : "0.0"
      );
      let total_amount = item.qty * unit_price;
      detailList.push({
        PackageCode: item.PackageCode,
        ItemCode: item.ItemCode,
        Qty: parseInt(item.qty),
        Price: unit_price,
        VisitDate: item.VisitDate,
        VisitTime: item.VisitLocation,
        VisitLocation: item.VisitTime,
      });
    });
    let newPayload = {
      ConfirmationNo: OrderInfoData.OrderNo,
      GuestInfo: {
        Email: email,
      },
      ContractInfo: {
        ContractId: OrderInfoData.ContractId,
      },
      StaffInfo: {
        StaffId: user.staffId != null ? user.staffId : 0,
      },
      OrderItems: detailList,
    };
    let payload = {
      OrderId: OrderInfoData.OrderId,
      GuestCode: OrderInfoData.GuestCode,
      PaidStatus: OrderInfoData.PaidStatus,
      BookStatus: OrderInfoData.BookStatus,
      SendEmail: OrderInfoData.SendEmail,
      Email: email,
      OrderNo: OrderInfoData.OrderNo,
      CustomerCode: OrderInfoData.CustomerCode,
      FirstName: OrderInfoData.FirstName,
      LastName: OrderInfoData.LastName,
      GroupName: OrderInfoData.GroupName,
      CountryId: OrderInfoData.CountryId,
      PhoneCode: OrderInfoData.PhoneCode,
      PhoneNumber: OrderInfoData.PhoneNumber,
      // TelNo: OrderInfoData.TelNo,
      ArrivalDate: OrderInfoData.ArrivalDate,
      ArrivalTime: OrderInfoData.ArrivalTime,
      Expire: OrderInfoData.Expire,
      TotalCharge: OrderInfoData.TotalCharge,
      TotalPaid: OrderInfoData.TotalPaid,
      Outstanding: OrderInfoData.Outstanding,
      SaleDate: OrderInfoData.SaleDate,
      ContractId: OrderInfoData.ContractId,
      // PaymentHistory: OrderInfoData.PaymentHistory,
      Details: detailList,
    };
    //data.Email = value;
    console.log(newPayload);
    CallServicePostUpdateBooking(newPayload);
    //setOrderInfoData(data);
  };

  const changeQty = (position, qty) => {
    console.log("changeQty >> ", qty === "" ? 0 : qty);
    const list = [];
    let TotalChargeAll = 0.0;
    listItem.map((data, index) => {
      console.log(data.unitPrice);
      let unit_price = parseAmount(
        data.unitPrice != undefined ? data.unitPrice : "0.0"
      );
      let total_amount = (data.qty === "" ? 0 : data.qty) * unit_price;
      if (position == index) {
        if (data.ItemRates != null) {
          const rate = data.ItemRates.find(
            (item) => qty >= item.QtyFrom && qty <= item.QtyTo //(item.QtyTo == 0 ? Infinity : item.QtyTo)
          );
          console.log("rate", rate);
          if (rate != undefined) {
            unit_price = rate.Price;
          } else {
            unit_price = data.BasePrice;
          }
        }
        console.log("unit_price", unit_price);
        total_amount = (qty === "" ? 0 : qty) * unit_price;
        console.log(total_amount);
        list.push({
          OrderId: data.OrderId,
          OrderDetailId: data.OrderDetailId,
          PackageCode: data.PackageCode,
          PackageName: data.PackageName,
          ItemCode: data.ItemCode,
          ItemName: data.ItemName,
          qty: qty,
          unit: data.unit,
          unitPrice: FormatAmount(unit_price),
          TotalAmount: FormatAmount(total_amount),
          ItemRates: data.ItemRates,
          CabanaItemCode: data.CabanaItemCode,
          VisitDate: data.VisitDate,
          VisitLocation: data.VisitLocation,
          VisitTime: data.VisitTime,
          BasePrice: data.BasePrice,
        });
      } else {
        list.push(data);
      }
      TotalChargeAll = TotalChargeAll + total_amount;
      console.log("TotalChargeAll", TotalChargeAll);
    });
    setTotalCharge(FormatAmount(TotalChargeAll));
    console.log("TotalChargeAll", TotalChargeAll);
    setListItem(list);
    // if(listItem[position].CabanaItemCode != null){
    //   onUpdateListAllotment(listItem[position].CabanaItemCode);
    // }
  };

  const changeUnitPrice = (position, status) => {
    console.log("status => " + status);
    const unit_price = parseAmount(
      listItem[position].unitPrice != undefined
        ? listItem[position].unitPrice
        : "0.00"
    );

    const step = 1.0;
    var result = 0.0;
    console.log(unit_price);
    console.log(step);
    if (status === "p") {
      result = unit_price + step;
    } else if (status === "m") {
      result = unit_price - step;
    }
    console.log("result");
    console.log(result);
    const resultPrice = result;
    console.log("resultPrice");
    console.log(resultPrice);
    const list = [];
    let TotalChargeAll = 0.0;
    listItem.map((data, index) => {
      let unit_price =
        data.unitPrice != undefined ? parseAmount(data.unitPrice) : 0.0;
      console.log("unit_price", unit_price);
      let total_amount = data.qty * unit_price;
      if (position == index) {
        unit_price = resultPrice;
        console.log(unit_price);
        total_amount = data.qty * unit_price;
        TotalChargeAll = TotalChargeAll + total_amount;
        console.log(total_amount);
        list.push({
          OrderId: data.OrderId,
          OrderDetailId: data.OrderDetailId,
          PackageCode: data.PackageCode,
          PackageName: data.PackageName,
          ItemCode: data.ItemCode,
          ItemName: data.ItemName,
          qty: data.qty,
          unit: data.unit,
          unitPrice: FormatAmount(unit_price),
          TotalAmount: FormatAmount(total_amount),
          ItemRates: data.ItemRates,
          CabanaItemCode: data.CabanaItemCode,
          VisitDate: data.VisitDate,
          VisitLocation: data.VisitLocation,
          VisitTime: data.VisitTime,
          BasePrice: data.BasePrice,
        });
      } else {
        TotalChargeAll = TotalChargeAll + total_amount;
        list.push(data);
      }
    });
    console.log("TotalChargeAll", TotalChargeAll);
    setTotalCharge(FormatAmount(TotalChargeAll));
    setListItem(list);
  };

  const onChangeUnitPrice = (position, e) => {
    console.log(e.target.value);
    let unit_price = parseAmount(
      e.target.value !== "" ? e.target.value : "0.00"
    );
    const list = [];
    let TotalChargeAll = 0.0;
    listItem.map((data, index) => {
      if (position == index) {
        unit_price = parseAmount(
          e.target.value != "" ? e.target.value : "0.00"
        );
        let total_amount = data.qty * unit_price;
        TotalChargeAll = TotalChargeAll + total_amount;
        list.push({
          OrderId: data.OrderId,
          OrderDetailId: data.OrderDetailId,
          PackageCode: data.PackageCode,
          PackageName: data.PackageName,
          ItemCode: data.ItemCode,
          ItemName: data.ItemName,
          qty: data.qty,
          unit: data.unit,
          unitPrice: e.target.value,
          TotalAmount: FormatAmount(total_amount),
          ItemRates: data.ItemRates,
          CabanaItemCode: data.CabanaItemCode,
          VisitDate: data.VisitDate,
          VisitLocation: data.VisitLocation,
          VisitTime: data.VisitTime,
          BasePrice: data.BasePrice,
        });
      } else {
        unit_price = parseAmount(
          data.unitPrice != "" ? data.unitPrice : "0.00"
        );
        TotalChargeAll = TotalChargeAll + data.qty * unit_price;
        list.push(data);
      }
    });
    setTotalCharge(FormatAmount(TotalChargeAll));
    setListItem(list);
  };

  const onReseteUnitPrice = (position, price) => {
    let unit_price = parseAmount(price);
    const list = [];
    let TotalChargeAll = 0.0;
    listItem.map((data, index) => {
      if (position == index) {
        unit_price = parseAmount(price);
        let total_amount = data.qty * unit_price;
        TotalChargeAll = TotalChargeAll + total_amount;
        list.push({
          OrderId: data.OrderId,
          OrderDetailId: data.OrderDetailId,
          PackageCode: data.PackageCode,
          PackageName: data.PackageName,
          ItemCode: data.ItemCode,
          ItemName: data.ItemName,
          qty: data.qty,
          unit: data.unit,
          unitPrice: price,
          TotalAmount: FormatAmount(total_amount),
          ItemRates: data.ItemRates,
          CabanaItemCode: data.CabanaItemCode,
          VisitDate: data.VisitDate,
          VisitLocation: data.VisitLocation,
          VisitTime: data.VisitTime,
          BasePrice: data.BasePrice,
        });
      } else {
        unit_price = parseAmount(
          data.unitPrice != "" ? data.unitPrice : "0.00"
        );
        TotalChargeAll = TotalChargeAll + data.qty * unit_price;
        list.push(data);
      }
    });
    setTotalCharge(FormatAmount(TotalChargeAll));
    setListItem(list);
  };

  const onChangeFormatUnitPrice = (position, e) => {
    console.log("onChangeFormatUnitPrice");
    let resultPrice = parseAmount(
      listItem[position].unitPrice != "" ? listItem[position].unitPrice : "1.00"
    );
    const list = [];
    let TotalChargeAll = 0.0;
    listItem.map((data, index) => {
      if (position == index) {
        resultPrice = parseAmount(
          data.unitPrice != "" ? data.unitPrice : "1.00"
        );
        let total_amount = data.qty * resultPrice;
        TotalChargeAll = TotalChargeAll + data.qty * resultPrice;
        list.push({
          OrderId: data.OrderId,
          OrderDetailId: data.OrderDetailId,
          PackageCode: data.PackageCode,
          PackageName: data.PackageName,
          ItemCode: data.ItemCode,
          ItemName: data.ItemName,
          qty: data.qty,
          unit: data.unit,
          unitPrice: FormatAmount(resultPrice),
          TotalAmount: FormatAmount(total_amount),
          ItemRates: data.ItemRates,
          CabanaItemCode: data.CabanaItemCode,
          VisitDate: data.VisitDate,
          VisitLocation: data.VisitLocation,
          VisitTime: data.VisitTime,
          BasePrice: data.BasePrice,
        });
      } else {
        resultPrice = parseAmount(data.unitPrice);
        TotalChargeAll = TotalChargeAll + data.qty * resultPrice;
        list.push(data);
      }
    });
    setTotalCharge(FormatAmount(TotalChargeAll));
    setListItem(list);
  };

  const FormatAmount = (amount) => {
    const resultPrice = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  const FormatString = (amount) => {
    amount = amount.toString();
    const resultPrice = amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  const parseAmount = (amount) => {
    let price = amount;
    const checkPrice = amount.split(",");
    console.log("CheckPrice.length  => " + checkPrice.length);
    if (checkPrice.length > 0) {
      price = amount.replace(",", "");
    }
    const v1 = parseFloat(price);
    return v1;
  };

  const onDeleteRowData = (position) => {
    CallServiceDeleteBookingItem(listItem[indexDelete].OrderDetailId);
  };

  const preparePayment = () => {
    const payload = {
      confirmationNo: OrderInfoData.OrderNo,
      userCode: OrderInfoData.ContractId,
      totalPrice: OrderInfoData.TotalPaid,
      guestCode: OrderInfoData.CustomerCode,
      guestEmail: OrderInfoData.Email,
      route: "Booking",
      bookingStatus:
        OrderInfoData.BookStatus === "Y" || OrderInfoData.BookStatus === "C"
          ? true
          : false, // if tentaive set this to false to enable staff button
    };
    // const payload = {
    //   confirmationNo: OrderInfoData.OrderNo,
    //   oldConfirmationNo: user.confirmationNo == null ? "" : user.confirmationNo,
    //   userCode: user.userCode,
    //   totalPrice: OrderInfoData.TotalPaid,
    //   oldPrice: user.totalPrice == null ? 0 : user.totalPrice,
    //   route: "Booking",
    // };
    sessionStorage.setItem("PaymentRoute", JSON.stringify(payload));
    var route = JSON.parse(sessionStorage.getItem("PaymentRoute"));
    console.log(route);
    navigate("/confirm", {
      // state: {
      //   payload: payload,
      // },
    });
  };

  function dateTimeFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YYYY HH:mm:ss").toString();
  }

  function dateFormat(date) {
    //T00:00:00
    return moment(new Date(date)).format("DD/MM/YYYY").toString();
  }

  const getFailByPackage = (itemCodeCabana) => {
    if (listAllotmentFail != null) {
      const fail = listAllotmentFail.filter(
        (x) => x.ItemCode === itemCodeCabana
      );
      if (fail.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const onNumerPWKeyDown = (event) => {
    if (!/[0-9]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
      console.log("event", event);
    }
  };

  const onPricePWKeyDown = (event) => {
    if (!/[0-9.]/.test(event.key) && event.key != "Backspace") {
      event.preventDefault();
      console.log("event", event);
    }
  };

  function isValidEmail() {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="payment-history-modal"
    >
      <Modal.Header
        className="filter-header"
        style={{ margin: "10px 20px 10px 20px" }}
        closeButton
      >
        <p>{t("order_info")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="order-detail-body">
          <form class="row g-4">
            <div class="col-md-3">
              <label for="orderNo" class="form-label">
                {t("order_no")}
              </label>
              <input
                type="text"
                class="form-control"
                id="orderNo"
                value={OrderInfoData != null ? OrderInfoData.OrderNo : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="arrival" class="form-label">
                {t("arrival")}
              </label>
              <input
                type="text"
                class="form-control"
                id="arrival"
                value={
                  OrderInfoData != null
                    ? OrderInfoData.ArrivalDate != null
                      ? timeFormat(
                          OrderInfoData.ArrivalDate.split("T")[0] +
                            "T" +
                            OrderInfoData.ArrivalTime
                        )
                      : ""
                    : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="expire" class="form-label">
                {t("expire")}
              </label>
              <input
                type="text"
                class="form-control"
                id="expire"
                value={
                  OrderInfoData != null ? timeFormat(OrderInfoData.Expire) : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="groupName" class="form-label">
                {t("group_name")}
              </label>
              <input
                type="text"
                class="form-control"
                id="groupName"
                value={OrderInfoData != null ? OrderInfoData.GroupName : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="orderNo" class="form-label">
                {t("firstname")}
              </label>
              <input
                type="text"
                class="form-control"
                id="orderNo"
                value={OrderInfoData != null ? OrderInfoData.FirstName : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="arrival" class="form-label">
                {t("lastname")}
              </label>
              <input
                type="text"
                class="form-control"
                id="arrival"
                value={OrderInfoData != null ? OrderInfoData.LastName : ""}
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="email" class="form-label">
                {t("email")}
              </label>
              <input
                type="text"
                class="form-control"
                id="email"
                value={email != null ? email : ""}
                maxLength={50}
                onChange={(e) => changeEmail(e.target.value)}
                // disabled={
                //   !user.isStaff == false
                //     ? OrderInfoData != null
                //       ? OrderInfoData.BookStatus === "T"
                //         ? false
                //         : true
                //       : true
                //     : true
                // }
                disabled={editButtonState}
                style={{
                  color:
                    OrderInfoData != null
                      ? OrderInfoData.BookStatus === "T"
                        ? "#003483"
                        : "#828282"
                      : "#828282",
                  borderColor: errorFormatEmail == true ? "red" : "#ced4da",
                }}
              />
              {errorFormatEmail == true && (
                <label style={{ color: "red", fontWeight: "100" }}>
                  {errorMessage}
                </label>
              )}
            </div>
            <div class="col-md-3">
              <label for="groupName" class="form-label">
                {t("phone_no")}
              </label>
              <div class="row">
                <div class="col-8 col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    id="groupName"
                    value={OrderInfoData != null ? OrderInfoData.PhoneCode : ""}
                    disabled={true}
                  />
                </div>
                <div class="col-8 col-sm-8" style={{ paddingLeft: "0px" }}>
                  <input
                    type="text"
                    class="form-control"
                    id="groupName"
                    value={
                      OrderInfoData != null ? OrderInfoData.PhoneNumber : ""
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            {/* <div class="col-md-2">
              <label for="groupName" class="form-label">
                &#8195;
              </label>
              <input
                type="text"
                class="form-control"
                id="groupName"
                value={OrderInfoData != null ? OrderInfoData.TelNo : ""}
                disabled={true}
              />
            </div> */}
            <div class="col-md-3">
              <label for="totalpaid" class="form-label">
                {t("total_paid")}
              </label>
              <input
                type="text"
                class="form-control"
                id="totalpaid"
                value={
                  OrderInfoData != null
                    ? FormatAmount(OrderInfoData.TotalPaid)
                    : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="outstanding" class="form-label">
                {t("outstanding")}
              </label>
              <input
                type="text"
                class="form-control"
                id="outstanding"
                style={{ color: "#DC0000", fontWeight: "500" }}
                value={
                  OrderInfoData != null
                    ? FormatAmount(OrderInfoData.Outstanding)
                    : ""
                }
                disabled={true}
              />
            </div>
            <div class="col-md-3">
              <label for="totalcharge" class="form-label">
                {t("total_charge")}
              </label>
              <input
                type="text"
                class="form-control"
                id="totalcharge"
                value={
                  OrderInfoData != null
                    ? FormatAmount(OrderInfoData.TotalCharge)
                    : ""
                }
                disabled={true}
              />
            </div>
          </form>
          {OrderInfoData != null && (
            <div className="modal-table" style={{ marginTop: "20px" }}>
              <TableContainer sx={{ minHeight: 200 }}>
                <Table
                  sx={{ minWidth: 650, border: 0 }}
                  size="small"
                  aria-label="a dense table"
                  className="data-table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow className="tableHead">
                      <TableCell sx={{ minWidth: "50px" }}>
                        {t("number")}
                      </TableCell>
                      <TableCell sx={{ minWidth: "50px" }}>
                        <Button
                          className="btn-add-row"
                          onClick={() => handleOpenSelectItem()}
                          style={{
                            backgroundColor:
                              OrderInfoData.BookStatus === "T"
                                ? "#545454"
                                : "#BEBEBE",
                            color:
                              OrderInfoData.BookStatus === "T"
                                ? "#fff"
                                : "#000",
                          }}
                          disabled={
                            editButtonState
                            // OrderInfoData.BookStatus === "T" ? false : true
                          }
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </Button>
                      </TableCell>
                      <TableCell sx={{ minWidth: "130px" }}>
                        {t("code")}
                      </TableCell>
                      <TableCell sx={{ minWidth: "200px" }}>
                        {t("name")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ minWidth: "250px", maxWidth: "350px" }}
                      >
                        {t("qty")}
                      </TableCell>
                      {/* <TableCell sx={{ width: "15%", paddingLeft: "5%" }}>
                        Unit
                      </TableCell> */}
                      <TableCell
                        align="center"
                        sx={{ minWidth: "250px", maxWidth: "250px" }}
                      >
                        {t("unit_price")}
                      </TableCell>
                      <TableCell align="right" sx={{ minWidth: "150px" }}>
                        {t("total_amount")}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: "50px" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  {listItem && (
                    <TableBody>
                      {listItem.map((item, index) => (
                        <TableRow key={item.OrderId}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            {item.PackageCode != ""
                              ? item.PackageCode
                              : item.ItemCode}
                          </TableCell>
                          <TableCell>
                            {item.PackageName != ""
                              ? item.PackageName
                              : item.ItemName}
                          </TableCell>
                          <TableCell>
                            <div
                              className="row"
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <Button
                                className="btn-circle-plus-minus"
                                style={{
                                  backgroundColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "white"
                                      : "#BEBEBE",
                                  borderColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "#BFBFBF"
                                      : "#BEBEBE",
                                }}
                                onClick={() => {
                                  let qty = parseInt(
                                    item.qty != undefined ? item.qty : "1"
                                  );
                                  if (qty <= 1) {
                                    qty = 1;
                                  } else {
                                    qty = qty - 1;
                                  }
                                  changeQty(index, qty);
                                }}
                                disabled={
                                  editButtonState
                                  // OrderInfoData.BookStatus === "T"
                                  //   ? false
                                  //   : true
                                }
                              >
                                <i class="fa fa-minus" aria-hidden="true"></i>
                              </Button>
                              <div className="col">
                                <span class="deleteicon">
                                  <input
                                    type="text"
                                    className={`qty-order-info${
                                      getFailByPackage(item.CabanaItemCode) ==
                                      true
                                        ? "-Fail"
                                        : ""
                                    }`}
                                    style={{
                                      backgroundColor:
                                        OrderInfoData.BookStatus === "T"
                                          ? "#545454"
                                          : "#BEBEBE",
                                    }}
                                    id={"orderNo"}
                                    value={item.qty && FormatString(item.qty)}
                                    maxLength={4}
                                    onKeyPress={onNumerPWKeyDown}
                                    onChange={(e) => {
                                      let qty = parseInt(
                                        e.target.value != undefined
                                          ? e.target.value
                                          : "1"
                                      );
                                      console.log("qty >>>>>", qty);
                                      if (isNaN(qty)) {
                                        if (e.target.value === "") {
                                          console.log("qty >> ");
                                          changeQty(
                                            index,
                                            e.target.value.replace(",", "")
                                          );
                                        } else {
                                          console.log("qty >> not int");
                                          changeQty(index, 0);
                                        }
                                      } else {
                                        console.log("qty >> int");
                                        changeQty(
                                          index,
                                          parseInt(
                                            e.target.value.replace(",", "")
                                          )
                                        );
                                      }
                                    }}
                                    onBlur={(e) => {
                                      let qty = parseInt(
                                        e.target.value != undefined
                                          ? e.target.value.replace(",", "")
                                          : "1"
                                      );
                                      if (isNaN(qty)) {
                                        if (e.target.value === "") {
                                          console.log("qty >> ");
                                          changeQty(index, 1);
                                        } else {
                                          console.log("qty >> not int");
                                          changeQty(index, 1);
                                        }
                                      } else {
                                        console.log("qty >> int");
                                        if (qty == 0) qty = 1;
                                        changeQty(index, qty); //e.target.value.replace(",", "")
                                      }
                                      // changeQty(index, 1)
                                    }}
                                    disabled={
                                      editButtonState
                                      // OrderInfoData.BookStatus === "T"
                                      //   ? false
                                      //   : true
                                    }
                                    autoComplete="off"
                                  />
                                  <span
                                    style={
                                      editButtonState
                                        ? { display: "none" }
                                        : { display: "inherit" }
                                    }
                                    onClick={(e) => {
                                      // input.focus();
                                      let qtyFocus =
                                        document.getElementById("orderNo");
                                      changeQty(index, 1);
                                      qtyFocus.focus();
                                    }}
                                  >
                                    x
                                  </span>
                                </span>
                                {/* <input
                                  type="search"
                                  className={`input-icons${
                                    getFailByPackage(item.CabanaItemCode) ==
                                    true
                                      ? "-Fail"
                                      : ""
                                  }`}
                                  style={{
                                    backgroundColor:
                                      OrderInfoData.BookStatus === "T"
                                        ? "#545454"
                                        : "#BEBEBE",
                                  }}
                                  id="orderNo"
                                  value={item.qty}
                                  maxLength={4}
                                  onKeyPress={onNumerPWKeyDown}
                                  // style={{
                                  //   borderColor: getFailByPackage(item.CabanaItemCode) == true ? "red" : "#ced4da !important",
                                  //   color: getFailByPackage(item.CabanaItemCode) == true ? "red" : "#828282 !important",
                                  // }}
                                  onChange={(e) => {
                                    let qty = parseInt(
                                      e.target.value != undefined
                                        ? e.target.value
                                        : "1"
                                    );
                                    console.log(qty);
                                    if (isNaN(qty)) {
                                      if (e.target.value === "") {
                                        console.log("qty >> ");
                                        changeQty(index, e.target.value);
                                      } else {
                                        console.log("qty >> not int");
                                        changeQty(index, "");
                                      }
                                    } else {
                                      console.log("qty >> int");
                                      changeQty(index, e.target.value);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    let qty = parseInt(
                                      e.target.value != undefined
                                        ? e.target.value
                                        : "1"
                                    );
                                    if (isNaN(qty)) {
                                      if (e.target.value === "") {
                                        console.log("qty >> ");
                                        changeQty(index, 1);
                                      } else {
                                        console.log("qty >> not int");
                                        changeQty(index, 1);
                                      }
                                    } else {
                                      console.log("qty >> int");
                                      changeQty(index, e.target.value);
                                    }
                                    // changeQty(index, 1)
                                  }}
                                  disabled={
                                    OrderInfoData.BookStatus === "T"
                                      ? false
                                      : true
                                  }
                                /> */}
                              </div>
                              <Button
                                className="btn-circle-plus-minus"
                                style={{
                                  backgroundColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "white"
                                      : "#BEBEBE",
                                  borderColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "#BFBFBF"
                                      : "#BEBEBE",
                                }}
                                onClick={() => {
                                  let qty = parseInt(
                                    item.qty != undefined ? item.qty : "1"
                                  );
                                  changeQty(index, qty + 1);
                                }}
                                disabled={
                                  editButtonState
                                  // OrderInfoData.BookStatus === "T"
                                  //   ? false
                                  //   : true
                                }
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </Button>
                            </div>
                          </TableCell>
                          {/* <TableCell>{item.Unit}</TableCell> */}
                          <TableCell>
                            <div
                              className="row"
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <Button
                                className="btn-circle-plus-minus"
                                style={{
                                  backgroundColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "white"
                                      : "#BEBEBE",
                                  borderColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "#BFBFBF"
                                      : "#BEBEBE",
                                }}
                                onClick={() => changeUnitPrice(index, "m")}
                                disabled={
                                  editButtonState
                                  // OrderInfoData.BookStatus === "T"
                                  //   ? false
                                  //   : true
                                }
                              >
                                <i class="fa fa-minus" aria-hidden="true"></i>
                              </Button>
                              <div className="col">
                                <span class="deleteicon">
                                  <input
                                    type="text"
                                    className={`qty-order-info${
                                      getFailByPackage(item.CabanaItemCode) ==
                                      true
                                        ? "-Fail"
                                        : ""
                                    }`}
                                    style={{
                                      backgroundColor:
                                        OrderInfoData.BookStatus === "T"
                                          ? "#545454"
                                          : "#BEBEBE",
                                    }}
                                    id={"orderNo"}
                                    value={item.unitPrice}
                                    onKeyPress={onPricePWKeyDown}
                                    onChange={(e) =>
                                      onChangeUnitPrice(index, e)
                                    }
                                    onBlur={(e) =>
                                      onChangeFormatUnitPrice(index, e)
                                    }
                                    disabled={
                                      editButtonState
                                      // OrderInfoData.BookStatus === "T"
                                      //   ? false
                                      //   : true
                                    }
                                    autoComplete="off"
                                  />
                                  <span
                                    style={
                                      editButtonState
                                        ? { display: "none" }
                                        : { display: "inherit" }
                                    }
                                    onClick={(e) => {
                                      // input.focus();
                                      onReseteUnitPrice(index, "1.00");
                                    }}
                                  >
                                    x
                                  </span>
                                </span>
                                {/* <input
                                  type="search"
                                  className="input-icons"
                                  style={{
                                    backgroundColor:
                                      OrderInfoData.BookStatus === "T"
                                        ? "#545454"
                                        : "#BEBEBE",
                                  }}
                                  value={item.unitPrice}
                                  onKeyPress={onPricePWKeyDown}
                                  onChange={(e) => onChangeUnitPrice(index, e)}
                                  onBlur={(e) =>
                                    onChangeFormatUnitPrice(index, e)
                                  }
                                  disabled={
                                    OrderInfoData.BookStatus === "T"
                                      ? false
                                      : true
                                  }
                                /> */}
                              </div>
                              <Button
                                className="btn-circle-plus-minus"
                                style={{
                                  backgroundColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "white"
                                      : "#BEBEBE",
                                  borderColor:
                                    OrderInfoData.BookStatus === "T"
                                      ? "#BFBFBF"
                                      : "#BEBEBE",
                                }}
                                onClick={() => changeUnitPrice(index, "p")}
                                disabled={
                                  editButtonState
                                  // OrderInfoData.BookStatus === "T"
                                  //   ? false
                                  //   : true
                                }
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell sx={{ width: "20%", textAlign: "end" }}>
                            {item.TotalAmount}
                          </TableCell>
                          <TableCell width={1} align="right">
                            <Button
                              className="btn-delete-order-detail"
                              style={{
                                backgroundColor:
                                  OrderInfoData.BookStatus === "T"
                                    ? "#545454"
                                    : "#BEBEBE",
                              }}
                              onClick={() => handleOpenConfirmModal(index)}
                              disabled={
                                editButtonState
                                // OrderInfoData.BookStatus === "T" ? false : true
                              }
                            >
                              <i
                                class="fa fa-fw fa-trash-o"
                                aria-hidden="true"
                                style={{
                                  color:
                                    OrderInfoData.BookStatus === "T"
                                      ? "#fff"
                                      : "#000",
                                }}
                              ></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <div className="layout-total-charge">
          <div class="row justify-content-end">
            <div class="col-auto">
              <div className="title">{t("total_charge")}</div>
            </div>
            <div class="col-auto">
              <div className="detail">{totalCharge}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-select-item-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-update-payment-modal"
            onClick={(e) => {
              onCheckAllotment();
              //onPackData()
            }}
            style={{
              backgroundColor:
                OrderInfoData != null && OrderInfoData.BookStatus !== "T"
                  ? "#BEBEBE"
                  : "#79E7FF",
              color:
                OrderInfoData != null && OrderInfoData.BookStatus !== "T"
                  ? "#9E9E9E"
                  : "#000",
              borderColor:
                OrderInfoData != null && OrderInfoData.BookStatus !== "T"
                  ? "#BEBEBE"
                  : "#79E7FF",
            }}
            disabled={
              OrderInfoData != null &&
              OrderInfoData.BookStatus === "T" &&
              !OrderInfoData.IsOpenBill
                ? false
                : true
            }
          >
            {t("update")}
          </Button>
        </div>
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-payment-payment-modal"
            onClick={(e) => {
              preparePayment();
            }}
            // style={{
            //   backgroundColor:
            //     OrderInfoData != null &&
            //     (OrderInfoData.Outstanding === null ||
            //       OrderInfoData.Outstanding === 0)
            //       ? "#BEBEBE"
            //       : "#0e315c",
            //   color:
            //     OrderInfoData != null &&
            //     (OrderInfoData.Outstanding === null ||
            //       OrderInfoData.Outstanding === 0)
            //       ? "#9E9E9E"
            //       : "white",
            //   borderColor:
            //     OrderInfoData != null &&
            //     (OrderInfoData.Outstanding === null ||
            //       OrderInfoData.Outstanding === 0)
            //       ? "#BEBEBE"
            //       : "#0e315c",
            // }}
            disabled={
              OrderInfoData != null &&
              (OrderInfoData.IsOpenBill ||
                OrderInfoData.Outstanding === null ||
                OrderInfoData.Outstanding === 0 ||
                OrderInfoData.BookStatus === "C")
                ? true
                : false
            }
          >
            {t("payment")}
          </Button>
        </div>
      </Modal.Footer>
      {openSelectItemModal && (
        <SelectItemModal
          open={openSelectItemModal}
          setClose={handleCloseSelectItemModal}
          setRefreshData={setRefreshData}
          orderNo={orderNo}
          listDetailItem={listItem}
          arrivalDate={OrderInfoData.ArrivalDate}
          // dataItem={itemOrderList}
        />
      )}
      {openComfirmModal && (
        <ConfirmModal
          open={openComfirmModal}
          setClose={handleCloseConfirmModal}
          message={t("confirm_to_delete_item")}
          onConfirm={onDeleteRowData}
        />
      )}

      <ModalSuccess
        open={openSuccessModal}
        body={t(aleart)}
        setClose={setCloseSuccessModal}
        //back={backToOrderPage}
        back={setCloseSuccessModal}
      />

      <ModalFailed
        open={openFailedModal}
        body={t(aleart)}
        setClose={setCloseFailedModal}
        //back={backToOrderPage}
        back={setCloseFailedModal}
      />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
      <AllotmentErrorModal
        openModal={showAllotErrorModal}
        closeModal={onCloseShowAllotErrorModal}
        listAllotmentRes={listAllotmentFail}
      />
      <ConfirmDeletePackNotFoundRate
        open={showConfirmDeletePackageRateNotFound}
        onConfirm={setConfirmDelectPackage}
      />
    </Modal>
  );
};
