import { createGlobalState } from 'react-hooks-global-state';

const { useGlobalState } = createGlobalState({ 
    route: '' , 
    discountPromo:'', 
    promoCode: '', 
    havePro: false, 
    inactiveCam: false,
    disabledPromo: false,
    publicKey: '',
    loadCampaign: false,
    canPlaceOrder:true,
    availableCabanaLimit: null,
    visitingDate: null,
    visitingDateCabana: '',
});

export { useGlobalState}