import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import imgCancel from "../img/cancel-book.png";

export const CancelBookingModal = (props) => {
  const { orderNo, open, setClose, onConfirm } = props;
  const { t } = useTranslation();

  const onCloseModal = () => {
    setClose(false);
  };

  const onClickConfirm = () => {
    onConfirm();
  };
  return (
    <Modal
      show={open}
      onHide={onCloseModal}
      backdrop="static"
      keyboard={false}
      className="cancelbooking-dialog"
    >
      <ModalHeader closeButton></ModalHeader>
      <ModalBody className="cancelbooking-body">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md" style={{display:"flex", justifyContent:"center"}}>
                <img style={{display:"flex"}} src={imgCancel}></img>
              </div>
            </div>
            <div className="row">
              <div className="col-md mt-5">
                <p>{t("cancel_confirm").replace("{0}", orderNo)}</p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="filter-modal-footer">
        <Button
          className="btn-filter-confirm"
          style={{
            backgroundColor: "#0e315c",
            cursor: "pointer",
            maxWidth: "50%",
            textTransform: "capitalize",
            borderColor: "#0e315c",
          }}
          onClick={() => {
            onClickConfirm()
          }}
        >
          {t("confirm")}
        </Button>
        <Button
          className="btn-filter-reset"
          style={{
            backgroundColor: "white",
            cursor: "pointer",
            maxWidth: "50%",
            color: "#0e315c",
            textTransform: "capitalize",
            borderColor: "#0e315c",
          }}
          onClick={() => {
            onCloseModal()
          }}
        >
          {t("cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
