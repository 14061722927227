import React from 'react';
import BackToTop from "react-back-to-top-button";
import ArrowUp from "../img/up-arrow.png"


const BackToTopPackget = () => {
  return (
    <BackToTop showOnScrollDown showAt={50} speed={3000} easing="easeInOutQuint">
		<button className="BackToTop">
          <img src={ArrowUp} alt="" style={{width: "20px"}}/>
		</button>
	</BackToTop>
  )
}
export default BackToTopPackget;