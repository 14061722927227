import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { TableCell, colors } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import {
  GET_ORDER_INFO,
  ITEM_LOOKUP,
  ADD_BOOKING_ITEM,
  GET_ITEM_BY_CONTRACT,
  LOCATION_LOOKUP,
  TIME_LOOKUP,
} from "../../services/BASE_API";
import { apiLink_ecom } from "../../constants/DataPublic";
import { async } from "q";

import ModalSuccess from "../../component/Modal/ModalSuccess";
import ModalFailed from "../../component/Modal/ModalFailed";
import Backdrop from "@mui/material/Backdrop";
import { SelectDetailTransportModal } from "../Modal/SelectDetailTransportModal";
import { pageLimit } from "../../constants/DataPublic";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as CheckBorder } from "../icons/checkbox.svg";

export const SelectItemModal = (props) => {
  const {
    open,
    setClose,
    dataItem,
    setRefreshData,
    orderNo,
    listDetailItem,
    arrivalDate,
  } = props;
  const [data, setData] = useState(null);
  const [isCheckAll, setCheckAll] = useState(false);
  const [pageInfo, setPageInfo] = useState();
  const [recordStart, setRecordStart] = useState(0);
  const [recordEnd, setRecordEnd] = useState(0);
  const { t, i18n } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState("1");
  const [openSuccessModal, setOpenSuccessModal] = useState(false); //Success Modal
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [aleart, setAleart] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserLogin"))
  );
  const [openDetailTrasportModal, setOpenDetailTrasportModal] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [onClickCheckAll, setOnClickCheckAll] = useState(false);
  const [location, setLocation] = useState(null);
  const [time, setTime] = useState(null);

  const onCloseDetailTrasportModal = () => {
    setOpenDetailTrasportModal(false);
    setOnClickCheckAll(false);
  };

  const handleClose = () => {
    setClose(false);
  };

  const setCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    setRefreshData(true);
  };

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
  };

  useEffect(() => {
    console.log("keyword");
    console.log(keyword);
  }, [keyword]);

  const handlePage = async (page) => {
    // setLoading(true);
    // setTableBody();
    let currentPage = 1;

    if (page === "1") {
      currentPage = pageInfo.PageNumber + 1;
    } else {
      currentPage = pageInfo.PageNumber - 1;
    }
    setPage(currentPage);
    CallServiceGetItemLookUp(currentPage, keyword);
    // await CallService(value, currentPage);
    // setLoading(false);
  };

  useEffect(async () => {
    await CallServiceGetLocation();
    await CallServiceGetTime();
    await CallServiceGetItemLookUp("1", keyword);
  }, []);

  useEffect(() => {
    console.log(data);
    const lengthData = data == null ? 0 : data.length;
    let countIsCheck = 0;
    if (data != null) {
      data.map((item) => {
        if (item.isCheck) {
          countIsCheck = countIsCheck + 1;
        }
      });
    }
    if (lengthData > countIsCheck) {
      setCheckAll(false);
    } else {
      if (lengthData <= 0) {
        setCheckAll(false);
      } else {
        setCheckAll(true);
      }
    }
  }, [data]);

  const getLocationName = (locationId) => {
    if (location != null) {
      const locationList = location.filter((x) => x.LocationId == locationId);
      if (locationList.length > 0) {
        return locationList[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getTimeName = (timeId) => {
    if (time != null) {
      const timeList = time.filter((x) => x.TimeId == timeId);
      if (timeList.length > 0) {
        return timeList[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const onSelectDetailTransport = (location, time) => {
    const index = data.findIndex(
      (item) =>
        item.ItemCode === selectItem.ItemCode &&
        item.PackageCode === selectItem.PackageCode
    );
    console.log("index >> ", index);
    onCheckChange(index, location, time);
    if (onClickCheckAll) {
      // setCheckAll(!isCheckAll);
      setOnClickCheckAll(false);
    }
    setOpenDetailTrasportModal(false);
  };

  const onCheckIsTransport = (position) => {
    if (data[position].IsTransportationPackage == true) {
      if (!data[position].isCheck == true) {
        setSelectItem(data[position]);
        setOpenDetailTrasportModal(true);
      } else {
        setSelectItem(null);
        onCheckChange(position, "", "");
      }
    } else {
      setSelectItem(null);
      onCheckChange(position, "", "");
    }
  };

  const onCheckChange = (position, location, time) => {
    let newData = [];
    data.map((item, index) => {
      if (position == index) {
        newData.push({
          No: item.No,
          ItemCode: item.ItemCode,
          ItemName: item.ItemName,
          ItemQty: item.ItemQty,
          UnitName: item.UnitName,
          TotalAmount: item.TotalAmount,
          IsItem: item.IsItem,
          PackageCode: item.PackageCode,
          PackageName: item.PackageName,
          isCheck: !item.isCheck,
          isDisable: item.isDisable,
          location: location,
          time: time,
          IsTransportationPackage: item.IsTransportationPackage,
        });
      } else {
        newData.push(item);
      }
    });
    const lengthData = newData.length;
    let countIsCheck = 0;
    newData.map((item) => {
      if (item.isCheck) {
        countIsCheck = countIsCheck + 1;
      }
    });
    if (lengthData > countIsCheck) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
    setData(newData);
  };

  const onCheckAll = () => {
    if (!isCheckAll) {
      setOnClickCheckAll(true);
      const positionTransport = data.findIndex(
        (item) => item.IsTransportationPackage == true
      );
      let newData = [];
      data.map((item, index) => {
        if (positionTransport != index) {
          newData.push({
            No: item.No,
            ItemCode: item.ItemCode,
            ItemName: item.ItemName,
            ItemQty: item.ItemQty,
            UnitName: item.UnitName,
            TotalAmount: item.TotalAmount,
            IsItem: item.IsItem,
            PackageCode: item.PackageCode,
            PackageName: item.PackageName,
            isCheck: item.isDisable ? item.isDisable : !isCheckAll,
            isDisable: item.isDisable,
            location: item.location,
            time: item.time,
            IsTransportationPackage: item.IsTransportationPackage,
          });
        } else {
          setSelectItem(item);
          newData.push({
            No: item.No,
            ItemCode: item.ItemCode,
            ItemName: item.ItemName,
            ItemQty: item.ItemQty,
            UnitName: item.UnitName,
            TotalAmount: item.TotalAmount,
            IsItem: item.IsItem,
            PackageCode: item.PackageCode,
            PackageName: item.PackageName,
            isCheck: item.isDisable ? item.isDisable : isCheckAll,
            isDisable: item.isDisable,
            location: item.location,
            time: item.time,
            IsTransportationPackage: item.IsTransportationPackage,
          });
        }
      });
      setData(newData);
      const itemTransport = data.filter(
        (x) => x.IsTransportationPackage == true
      );
      if (itemTransport.length > 0) {
        // setSelectItem(itemTransport);
        itemTransport.forEach((element) => {
          if (!element.isDisable) {
            setOpenDetailTrasportModal(true);
          }
        });
      } else {
        setCheckAll(!isCheckAll);
      }
    } else {
      let newData = [];
      data.map((item, index) => {
        newData.push({
          No: item.No,
          ItemCode: item.ItemCode,
          ItemName: item.ItemName,
          ItemQty: item.ItemQty,
          UnitName: item.UnitName,
          TotalAmount: item.TotalAmount,
          IsItem: item.IsItem,
          PackageCode: item.PackageCode,
          PackageName: item.PackageName,
          isCheck: item.isDisable ? item.isDisable : !isCheckAll,
          isDisable: item.isDisable,
          location: "",
          time: "",
          IsTransportationPackage: item.IsTransportationPackage,
        });
      });
      setData(newData);
      setCheckAll(!isCheckAll);
    }
  };

  const checkboxStyle = makeStyles({
    checkbox: {
      "&.Mui-checked": {
        color: "#000000",
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      },
    },
  });

  const classes = checkboxStyle();

  const onConfirmSelectItem = () => {
    saveItemBooking();
    // setRefreshData(true);
  };

  async function CallServiceGetLocation() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(apiLink_ecom + LOCATION_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setLocation(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        // alert("Error: " + LOCATION_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  async function CallServiceGetTime() {
    // setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
    };
    await fetch(apiLink_ecom + TIME_LOOKUP, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        // setLoading(false);
        if (data["Success"]) {
          const item = data["Result"];
          setTime(item);
        } else {
          // setOpenFailedModal(true);
          // setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + TIME_LOOKUP, error);
        // setLoading(false);
        return false;
      });
  }

  async function CallServiceGetItemLookUp(page, keyword) {
    setLoading(true);
    console.log(keyword);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": i18n.language,
      },
      body: JSON.stringify({
        PageNumber: page,
        PageLimit: pageLimit,
        ContractId: user.userId,
        Keyword: keyword,
        ArrivalDate: arrivalDate,
      }),
    };
    await fetch(apiLink_ecom + GET_ITEM_BY_CONTRACT, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log("data");
        console.log(data);
        setPageInfo(data["PageInfo"]);
        setLoading(false);
        if (data["Data"] != null && data["Data"] != undefined) {
          let listItem = [];
          data["Data"].map((item, index) => {
            let isChecked = false;
            let location = "";
            let time = "";
            listDetailItem.map((itemDetail) => {
              // console.log("itemDetail",itemDetail);
              if (
                (item.IsItem ? item.ItemCode : item.PackageCode) ===
                (itemDetail.PackageCode != null
                  ? itemDetail.PackageCode
                  : itemDetail.ItemCode)
              ) {
                isChecked = true;
                // location = item.ItemCode;
                // time = item.ItemCode;
              }
            });
            listItem.push({
              No: index + 1,
              ItemCode: item.ItemCode,
              ItemName: item.ItemName,
              ItemQty: item.ItemQty,
              UnitName: item.UnitName,
              TotalAmount: item.ItemRates[0].UnitPrice,
              IsItem: item.IsItem,
              PackageCode: item.PackageCode,
              PackageName: item.PackageName,
              isCheck: isChecked,
              isDisable: isChecked,
              location: location,
              time: time,
              IsTransportationPackage: item.IsTransportationPackage,
            });
          });
          setData(listItem);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + ITEM_LOOKUP, error);
        setLoading(false);
        // handleClose();
        return false;
      });
  }

  useEffect(() => {
    setPageStart();
  }, [pageInfo]);

  function setPageStart() {
    if (pageInfo != null) {
      setRecordEnd(
        pageInfo.PageLimit * pageInfo.PageNumber > pageInfo.TotalRecords
          ? pageInfo.TotalRecords
          : pageInfo.PageLimit * pageInfo.PageNumber
      );
      if (pageInfo.TotalRecords === 0) {
        setRecordStart(0);
      } else {
        if (pageInfo.PageLimit > pageInfo.TotalRecords) {
          setRecordStart(pageInfo.PageNumber);
        } else {
          setRecordStart(
            pageInfo.PageLimit * pageInfo.PageNumber - pageInfo.PageLimit + 1
          );
        }
      }
    }
  }

  const saveItemBooking = async () => {
    let itemList = [];
    data.map((item) => {
      if (item.isCheck == true && item.isDisable == false) {
        itemList.push({
          PackageCode: item.PackageCode,
          ItemCode: item.ItemCode,
          Qty: item.ItemQty,
          VisitDate: arrivalDate,
          VisitTime: item.time,
          VisitLocation: item.location,
        });
      }
    });
    let payload = {
      OrderId: orderNo,
      OrderItems: itemList,
    };
    await CallServicePostAddBookingItem(payload);
  };

  async function CallServicePostAddBookingItem(payload) {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    await fetch(apiLink_ecom + ADD_BOOKING_ITEM, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          setRefreshData(true);
          // setOpenSuccessModal(true);
          // setAleart("save_item_success");
        } else {
          setOpenFailedModal(true);
          setAleart(data["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + ADD_BOOKING_ITEM, error);
        setLoading(false);
        return false;
      });
  }
  const Ref = useRef(null);

  const onChangeKeywork = (value) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      console.log(value);
      CallServiceGetItemLookUp(page, value);
      if (Ref.current) clearInterval(Ref.current);
    }, 1000);
    console.log("out count");
    setKeyword(value);
    Ref.current = id;
  };

  const CustomCheckboxIcon = (props) => (
    <div className="custom-checkbox">{props.children}</div>
  );

  const FormatAmount = (amount) => {
    const resultPrice = amount
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return resultPrice;
  };

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="payment-history-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-title">{t("select_item")}</div>
        <div class="col-md-3">
          <span class="deleteicon-nosubmit">
            <input
              type="text"
              className={`nosubmit`}
              placeholder={t("search")}
              value={keyword}
              onChange={(e) => onChangeKeywork(e.target.value)}
            />
            <span
              onClick={(e) => {
                onChangeKeywork("");
              }}
            >
              x
            </span>
          </span>
          {/* <input
            class="nosubmit"
            type="search"
            placeholder="Search..."
            onChange={(e) => onChangeKeywork(e.target.value)}
          /> */}
        </div>
        <div className="page-control">
          <div className="page-info">
            {pageInfo == null ? "" : recordStart + " - " + recordEnd}
          </div>
          <div className="page-info"> {t("of")} </div>
          <div className="page-info">
            {pageInfo == null ? "" : pageInfo.TotalRecords}
          </div>
          <div className="page-control-arrow">
            <button
              onClick={async () => {
                if (recordStart != 1) {
                  if (recordStart != 0) {
                    await handlePage("-1");
                  }
                }
              }}
            >
              <i class="fa fa-caret-left" aria-hidden="true"></i>
            </button>
            <button
              onClick={async () => {
                if (recordEnd != pageInfo.TotalRecords) {
                  await handlePage("1");
                }
              }}
            >
              <i class="fa fa-caret-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div className="order-detail-body">
          <TableContainer className="table-select-item">
            <Table
              sx={{ minWidth: 650, border: 0 }}
              size="small"
              aria-label="a dense table"
              className="data-table"
              stickyHeader
            >
              <TableHead sx={{ minHeight: 200 }}>
                <TableRow className="tableHead">
                  <TableCell width={10} align="center">
                    <Checkbox
                      id={0}
                      // className={classes.checkbox}
                      onChange={() => onCheckAll()}
                      checked={isCheckAll}
                      className="form-check-table"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "24px",
                          borderColor: "#A7A7A7",
                          color: "#A7A7A7",
                        },
                      }}
                      icon={
                        // <i
                        //   className="custom-checkbox-border fa fa-square-o"
                        //   aria-hidden="true"
                        // />
                        <i style={{ boxShadow: "0px -1px 4px rgb(0 0 0 / 10%)" }}>
                          <CheckBorder />
                        </i>
                      }
                      checkedIcon={
                        <CustomCheckboxIcon>
                          <Check width={"25px"} height={"25px"} />
                        </CustomCheckboxIcon>
                      }
                      classes={{ root: classes.root }}
                    />
                  </TableCell>
                  <TableCell align="left">{t("number")}</TableCell>
                  <TableCell align="left">{t("item_code")}</TableCell>
                  <TableCell align="left">{t("item_transaction")}</TableCell>
                  {/* <TableCell align="left">{t("unit")}</TableCell> */}
                  <TableCell align="right">{t("total_amount")}</TableCell>
                  <TableCell width="5%"></TableCell>
                </TableRow>
              </TableHead>
              {data && (
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        background: item.isDisable
                          ? "#d1d1d1"
                          : item.isCheck
                            ? "#79E7FF"
                            : "#fff",
                      }}
                    >
                      <TableCell width={10} align="center">
                        <Checkbox
                          id={index}
                          // className={classes.checkbox}
                          onChange={() => onCheckIsTransport(index)} //onCheckChange(index, "", "")
                          checked={item.isCheck}
                          disabled={item.isDisable}
                          className="form-check-table"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "36px",
                              borderColor: "#A7A7A7",
                              color: "#A7A7A7",
                            },
                          }}
                          icon={
                            <i style={{ boxShadow: "0px -1px 4px rgb(0 0 0 / 10%)" }}>
                              <CheckBorder />
                            </i>
                          }
                          checkedIcon={
                            <CustomCheckboxIcon>
                              <Check
                                width={"25px"}
                                height={"25px"}
                                className={
                                  item.isDisable
                                    ? "custom-checkbox-disable"
                                    : ""
                                }
                                style={{ margin: "1px" }}
                              />
                            </CustomCheckboxIcon>
                          }
                          classes={{ root: classes.root }}
                        />
                      </TableCell>
                      <TableCell align="left">{item.No}</TableCell>
                      <TableCell align="left">
                        {item.IsItem ? item.ItemCode : item.PackageCode}
                      </TableCell>
                      <TableCell align="left">
                        {item.IsItem ? item.ItemName : item.PackageName}
                        {getLocationName(item.location) != null && (
                          <p style={{ marginBottom: "0px", fontSize: "12px" }}>
                            {t("visitdate")} :{" "}
                            {arrivalDate != null
                              ? arrivalDate.split("T")[0]
                              : ""}
                            , {t("location")} :{" "}
                            {getLocationName(item.location) != null
                              ? getLocationName(item.location).LocationName
                              : ""}
                            , {t("time")} :{" "}
                            {getTimeName(item.time) != null
                              ? getTimeName(item.time).TimeName
                              : ""}
                          </p>
                        )}
                      </TableCell>
                      {/* <TableCell align="left">{item.UnitName}</TableCell> */}
                      <TableCell align="right" >{FormatAmount(item.TotalAmount)}</TableCell>
                      <TableCell width="5%"></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-select-item-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-select-item-modal"
            onClick={(e) => onConfirmSelectItem()}
          >
            {t("select")}
          </Button>
        </div>
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-cancel-select-item-modal"
            onClick={(e) => handleClose()}
          >
            {t("cancel")}
          </Button>
        </div>
      </Modal.Footer>
      <ModalSuccess
        open={openSuccessModal}
        body={t(aleart)}
        setClose={setCloseSuccessModal}
        //back={backToOrderPage}
        back={setCloseSuccessModal}
      />

      <ModalFailed
        open={openFailedModal}
        body={t(aleart)}
        setClose={setCloseFailedModal}
        //back={backToOrderPage}
        back={setCloseFailedModal}
      />
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
      {selectItem != null && (
        <SelectDetailTransportModal
          selectItem={selectItem}
          openModal={openDetailTrasportModal}
          closeModal={onCloseDetailTrasportModal}
          onSelect={onSelectDetailTransport}
          listLocation={location}
          listTime={time}
          visitdate={arrivalDate != null ? arrivalDate.split("T")[0] : ""}
        />
      )}
    </Modal>
  );
};
