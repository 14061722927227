import React, { useEffect, useState } from "react";
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { ImageLoader } from "react-image-and-background-image-fade";
import Product from "../../component/img/product-sample.jpg";
import Pass from "../../component/img/pass.png";
import TermIcon from "../../component/img/info.png";
import noImg from "../../component/img/no_image.png";
import { AddSubTicket } from "./AddSubTicket";
import { AddSubTicketFlip } from "./AddSubTicketFlip";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import { useMediaQuery } from "react-responsive";
import { useGlobalState } from "../../global";

import { packageTypeTicket } from "../../constants/DataPublic";

export const SubTicket = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { itemList } = props;
  const { currentStep } = props;
  const { cartUpdate } = props;
  const { isflip } = props;
  const { location,timeList } = props;
  const { availableCabana, onAvailableCabana,cabanaList, cart,fromBuyNow, onUpdateVisitDateAll, language } = props;

  let myCart = cart;
  const [routePath, setRoutePath] = useGlobalState("route");
  const [picturePath, setPicturePath] = useState(noImg);
  const [netPrice, setNetPrice] = useState(0);
  const [flip, setFlip] = useState(false);
  const [available, setAvailable] = useState(true);
  const [isAgent, setIsAgent] = useState(true);
  const [cartActive, setCartActive] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [itemQty, setItemQty] = useState(1);
  const [subTotalI, setSubTotalI] = useState(0);
  const [selectedI, setSelectedI] = useState("");
  const [editI, setEditI] = useState(false);
  const [requiredDate, setRequiredDate] = useState("");
  const [requiredTime, setRequiredTime] = useState("");
  const [requiredLocation, setRequiredLocation] = useState("");

  const countRatePrice = () => {
    const rate = itemList.ContractVenueRatePackage.find(
      (item) => itemQty >= item.QtyFrom && itemQty <= item.QtyTo //(item.QtyTo == 0 ? Infinity : item.QtyTo)
    );
    return rate;
  };

  useEffect(() => {
    if (countRatePrice()) {
      setNetPrice(countRatePrice().Price);
    } else {
      if (itemList.IsDiscount) {
        setNetPrice(itemList.PackagePrice - itemList.DiscountPrice);
      } else {
        setNetPrice(itemList.PackagePrice);
      }
    }
    // if (itemList.IsDiscount) {
    //   setNetPrice(itemList.PackagePrice - itemList.DiscountPrice);
    // } else {
    //   setNetPrice(itemList.PackagePrice);
    // }

    if (itemList.PackagePictureLists.length > 0) {
      let picture = itemList.PackagePictureLists.filter(
        (e) => e.PictureSize.toLowerCase() === "small"
      )[0];
      if (picture != null) setPicturePath(picture.PictureUrl);
      else setPicturePath(noImg);
    }

    if(myCart != null ){
      const pIndex = myCart.findIndex((e) => {
        return e.PackageCode === itemList.PackageCode;
      });
      
      if (pIndex !== -1) {
        // ถ้ามี Package อยู่ใน Cart
        setItemQty(myCart[pIndex].Qty);
        setSelectedDate(myCart[pIndex].VisitDate);
        setSelectedLocation(myCart[pIndex].VisitLocation)
        setSelectedTime(myCart[pIndex].VisitTime);
        // เช็คว่า Package อยู่ใน Mode Edit หรือไม่
        if (myCart[pIndex].status === "edit") {
          setSelectedI("");
          setEditI(true);
        } else setSelectedI("hidden");
      } else {
        // ถ้าไม่มี Package อยู่ใน Cart
        setItemQty(1);
        setSelectedI("");
        setEditI(false);
      }
    }

    //เงื่อนไข flip Card
    // itemList.PackageItemLists.forEach((e) => {
    //   if (e.IsVisitDate == true) {
    //     setFlip(true)
    //   }
    // })

    console.log("IS flip", itemList);
    console.log("routePathroutePath", routePath);
  }, [itemList]);

  useEffect(() => {
    if(myCart != null){
      myCart.forEach((e) => {
        if (e.VisitDate != null) {
          setSelectedDate(e.VisitDate);
        }
      });
    }
  }, [cartUpdate]);

  useEffect(() => {
    if (cartUpdate.PackageCode === itemList.PackageCode) {
      console.log("SubTicket CartUpdate", cartUpdate);
      setItemQty(cartUpdate.Qty);
    }
  }, [cartUpdate]);

  useEffect(() => {
    setSubTotalI(itemQty * netPrice);
    if (countRatePrice()) {
      setNetPrice(countRatePrice().Price);
    } else {
      if (itemList.IsDiscount) {
        setNetPrice(itemList.PackagePrice - itemList.DiscountPrice);
      } else {
        setNetPrice(itemList.PackagePrice);
      }
    }
    console.log(itemQty);
  }, [itemQty, netPrice]);

  const addToCart = (item, isEdit) => {
    props.onAddToCart(item, isEdit);
  };

  // ภาษา
  const { t } = useTranslation();

  return (
    <div className="box box-main mb-5">
      <div className="box-main-header">
        <div className="row align-items-center headsubticket">
          <div className="col-lg-6"></div>
          <div className="col-lg-6" style={{ height: "40px" }}>
            <h2 className="main-title" style={{ marginBottom: "12px" ,color:"#000"}}>
              {itemList.PackageName}
            </h2>
            <p
              className="font-12 weight-600 mb-0"
              style={{ paddingLeft: "0px" }}
            >
              <Markup content={itemList.PackageCondition} />
            </p>
          </div>
        </div>
        <div style={{ width: "49%", height: "225px", marginTop: "-80px" }}>
          <ImageLoader src={picturePath} transitionTime="0s">
            {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
              <>
                {shouldShowLoader && !hasFailed && (
                  <div
                    className="card-img-top square"
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h2 style={{ color: "rgb(112 112 112)", fontSize: "20px" }}>
                      {" "}
                      Loading ...{" "}
                    </h2>
                  </div>
                )}
                {hasFailed && (
                  <div className="AwesomeoLoader__failed">Loading Fail :(</div>
                )}
                {hasLoaded && (
                  <>
                    {/* { !picLoad && <h2>Loading ...</h2> }  */}
                    <img
                      src={picturePath}
                      className="product-sample d-none d-lg-block mb-3"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </>
                )}
              </>
            )}
          </ImageLoader>
        </div>
      </div>
      <div className="box-main-body">
        <div className="row ">
          <div className="col-lg-6">
            <div className="d-none d-lg-block">
              <div className="desc afterimage">
                <p className="font-14 mb-2">
                  <strong>{t("overview")}: </strong>
                </p>

                {itemList.PackageItemLists.map((e, i) => {
                  return (
                    <p>
                      <Markup
                        key={i}
                        content={
                          e.ItemType == "Ticket"
                            ? "● " + e.Qty + " " + e.ItemName
                            : "+ " + e.Qty + " " + e.ItemName
                        }
                      />
                    </p>
                  );
                })}
              </div>
              {/* <div className="pt-1">
                <p className="font-14 mb-2">
                  <img src={TermIcon} className="TermIcon" />
                  {t('terms_conditions')}{' '}
                </p>
                <Markup content={itemList.PackageTermAndCondition} />
              </div> */}
            </div>
          </div>

          <AddSubTicket
            isAgent={isAgent}
            available={available}
            currentStep={currentStep}
            picturePath={picturePath}
            packageTypeTicket={packageTypeTicket}
            itemList={itemList}
            itemQty={itemQty}
            netPrice={netPrice}
            selectedI={selectedI}
            subTotalI={subTotalI}
            editI={editI}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            selectedLocation={selectedLocation}
            requiredDate={requiredDate}
            requiredTime={requiredTime}
            requiredLocation={requiredLocation}
            setItemQty={setItemQty}
            addToCart={addToCart}
            setSelectedI={setSelectedI}
            setSelectedDate={setSelectedDate}
            setSelectedTime={setSelectedTime}
            setSelectedLocation={setSelectedLocation}
            setRequiredDate={setRequiredDate}
            setRequiredTime={setRequiredTime}
            setRequiredLocation={setRequiredLocation}
            onAvailableCabana={onAvailableCabana}
            availableCabana={availableCabana}
            location={location}
            timeList={timeList}
            cabanaList={cabanaList}
            cart={cart}
            fromBuyNow={fromBuyNow}
            onUpdateVisitDateAll={onUpdateVisitDateAll}
            language={language}
          />

          {/** To Flip */}
          {/* {routePath == '' ? (
            <>
              {!flip ? (
                <AddSubTicket
                  currentStep={currentStep}
                  picturePath={picturePath}
                  packageTypeTicket={packageTypeTicket}
                  itemList={itemList}
                  itemQty={itemQty}
                  netPrice={netPrice}
                  selectedI={selectedI}
                  subTotalI={subTotalI}
                  editI={editI}
                  setItemQty={setItemQty}
                  addToCart={addToCart}
                  setSelectedI={setSelectedI}
                />
              ) : (
                <>
                  <AddSubTicketFlip
                    currentStep={currentStep}
                    picturePath={picturePath}
                    packageTypeTicket={packageTypeTicket}
                    itemList={itemList}
                    itemQty={itemQty}
                    netPrice={netPrice}
                    selectedI={selectedI}
                    subTotalI={subTotalI}
                    editI={editI}
                    selectedDate={selectedDate}
                    selectedTime={selectedTime}
                    requiredDate={requiredDate}
                    requiredTime={requiredTime}
                    setItemQty={setItemQty}
                    addToCart={addToCart}
                    setSelectedI={setSelectedI}
                    setSelectedDate={setSelectedDate}
                    setRequiredDate={setRequiredDate}
                    setRequiredTime={setRequiredTime}
                  />
                </>
              )}
            </>
          ) : (
            <AddSubTicket
              currentStep={currentStep}
              picturePath={picturePath}
              packageTypeTicket={packageTypeTicket}
              itemList={itemList}
              itemQty={itemQty}
              netPrice={netPrice}
              selectedI={selectedI}
              subTotalI={subTotalI}
              editI={editI}
              setItemQty={setItemQty}
              addToCart={addToCart}
              setSelectedI={setSelectedI}
            />
          )} */}
        </div>
        {isDesktopOrLaptop && (
          <div className="row">
      
            <div className="col-lg-12">
              <div className="pt-1">
                {/* {routePath == '/16two' ? (
                  <>
                    <a href="https://columbiapicturesaquaverse.com/terms-and-conditions/" target="_blank">
                      <p className="font-14 mb-2">
                        <img src={TermIcon} className="TermIcon" />
                        {t('terms_conditions')}{' '}
                      </p>
                    </a>
                  </>
                ) : (
                  <> */}
                <p
                  className="d-flex font-14 mb-2 weight-600 text-decoration-underline"
                  data-bs-toggle="collapse"
                  href={"#term" + itemList.PackageCode}
                  role="button"
                  aria-expanded="false"
                  aria-controls="term1"
                >
                  <img src={TermIcon} className="TermIcon" />
                  {t("terms_conditions")}{" "}
                </p>
                <div className="collapse" id={"term" + itemList.PackageCode}>
                  <div className="card card-body text-left">
                    <Markup content={itemList.PackageTermAndCondition} />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )}
      </div>
    </div>
  );
};
