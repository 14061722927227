import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { apiLink, apiLink_ecom } from "../constants/DataPublic";
import { AGENT_LOGIN, GET_CART } from "../services/BASE_API";

const useLoginAgent = (props) => {
  const [userLogin, setUserLogin] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [buttonState, setButtonState] = useState(false);

  const [cartQTy, setCartQTy] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(()=>{
    if(email != "" || password != ""){
      setButtonState(true);
      emailValidation();
    }else{
      setButtonState(false);
    }
  },[email,password])

  useEffect(()=>{
    if(password != ""){
      passwordValidation();
    }
  },[password])

  function submitLogin(e) {
    e.preventDefault();
    if (emailValidation() && passwordValidation()) {
      // Validate email and Password
      findUser();
    }
  }

  function submitForgot(e) {
    e.preventDefault();
    if (emailValidation()) {
      // Validate email
      findUserForgot();
      if (result) {
        console.log("Result: ", result);
      }
    }
  }

  function emailValidation() {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || email === "") {
      setMessage(t("warn_email"));
      return false;
    } else if (regex.test(email) === false) {
      setMessage(t("invalid_email"));
      return false;
    } else {
      setMessage("");
      return true;
    }
  }

  function passwordValidation() {
    if (!password || password === "") {
      setPasswordValidate("require_password");
    } else {
      setPasswordValidate("");
      return true;
    }
    return false;
  }

  async function findUser() {
    // console.log(i18n.language);
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json", 
        "Accept-Language": i18n.language
      },
      body: JSON.stringify({ Email: email, Password: password }),
    };
    console.log("Find User " + requestOptions);
    // await fetch(apiLink + "/afcl/Account/finduser", requestOptions)
    await fetch(apiLink + AGENT_LOGIN, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        if (items["Success"] === true) {
         let userLogin = {
            fName: items["Result"].User.ContractName,
            lName: "",
            email: items["Result"].User.Email,
            userId: items["Result"].User.ContractId,
            userCode: items["Result"].User.ContractId,
            photo: items["Result"].User.UserPicture == null ? "" : items["Result"].User.UserPicture,
            isLogin: true,
            confirmationNo: "",
            idCardNo: "",
            phoneNo: items["Result"].User.Officetel == null ? "" : items["Result"].User.Officetel.trim(),
            type: "user",
            guestDone: false,
            isAgent: true,
            isStaff: false,
            channelCode: items["Result"].ChannelCode
              ? items["Result"].ChannelCode
              : "",
            guestCode: "",
            isCityLeadger: items["Result"].User.IsCityLeadger,
            countryId: items["Result"].User.CountryId
          };
          sessionStorage.setItem("UserLogin", JSON.stringify(userLogin));
          setUserLogin(userLogin);
          
          setLoading(false);
          setPasswordValidate("");
          // navigate("/");
          //props.handleUserLoginChange(true);
        } else {
          setLoading(false);
          setPasswordValidate(t(items["Message"]));
          // if(items["Result"].User.IsExpired == null){
          //   setPasswordValidate(t("email_or_password_not_valid"));
          // } else {
          //   if(items["Result"].User.IsExpired == true){
          //     setPasswordValidate(t("contract_expired"));
          //   }
          // }
          // setMessage(items["Message"]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: API", AGENT_LOGIN, error);
        return false;
      });
    return false;
  }

  async function findUserForgot() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Email: email }),
    };
    await fetch(apiLink_ecom + "/afcl/Account/finduser", requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log(items);
        if (items["Success"] === true) {
          userLogin = {
            fName: items["Result"].FirstName,
            lName: items["Result"].LastName,
            email: items["Result"].Email,
            userId: items["Result"].UserId,
            userCode: items["Result"].UserId,
            photo: items["Result"].UserPicture,
            isLogin: false,
            channelCode: "",
            confirmationNo: "",
            orderNo:"",
            idCardNo: "",
            phoneNo: "",
            type: "user",
            guestDone: false,
          };
          sessionStorage.setItem("UserLogin", JSON.stringify(userLogin));
          console.log("Success = true");
          setMessage("");
          setResult(true);
        } else {
          console.log("Success = false");
          setMessage(items["Message"]);
          setResult(false);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: API /afcl/Account/finduser ", error);
        setResult(false);
      });
  }

  async function getUserCart(userCode, language) {
    console.log("getUserCart", userCode);
    if (userCode != "" && userCode != null && userCode != undefined) {
      const requestOptions = {
        method: "GET",
        headers: { "accept-language": language },
      };
      await fetch(
        // apiLink_ecom + "/afcl/Package/Cart/CheckOut/V2/" + userCode,
        apiLink_ecom + GET_CART + userCode,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          console.log("getUserCart", items);
          if (items["Success"] === true) {
            // Cart ใหม่
            let myCart = sessionStorage.getItem("MyCart");
            myCart = JSON.parse(myCart);

            // Cart ที่ Save ไว้ก่อนหน้า
            let allCart = items["Result"]["Order"];

            let user = sessionStorage.getItem("UserLogin");
            user = JSON.parse(user);
            user.confirmationNo = allCart.ConfirmationNo;
            sessionStorage.setItem("UserLogin", JSON.stringify(user));

            // รวม Cart
            let saveCart = [];
          
            allCart.Packages.forEach((e) => {
              if (e.ItemCode === "") {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                        return c.PackageCode === e.PackageCode;
                      });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: "",
                    ItemName: "",
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: "",
                    TotalPrice: "",
                    VisitDate: e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: "normal",
                    VisitingDateItemCode: e.VisitingDateItemCode,
                    IsTransportationPackage: e.IsTransportationPackage,
                    RatePackage: e.RatePackage,
                    BasePrice: e.DiscountPrice
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              } else {
                let cIndex =
                  myCart === null || myCart.length === 0
                    ? -1
                    : myCart.findIndex((c) => {
                        return (
                          c.PackageCode === e.PackageCode &&
                          c.ItemCode === e.ItemCode
                        );
                      });
                if (cIndex === -1) {
                  saveCart.push({
                    PackageCode: e.PackageCode,
                    PackageName: e.PackageName,
                    ItemCode: e.ItemCode,
                    ItemName: e.ItemName,
                    Qty: e.Qty,
                    Tax: 0,
                    ItemPrice: e.PackagePrice,
                    ItemDiscount: e.IsDiscount ? e.DiscountPrice : 0,
                    PriceName: e.PriceName,
                    PriceUnit: e.PriceUnit,
                    TotalPrice: parseInt(e.Qty) * parseInt(e.ItemPrice),
                    VisitDate: e.VisitDate,
                    VisitTime: e.VisitTime,
                    VisitLocation: e.VisitLocation,
                    step: 1,
                    status: "normal",
                  });
                } else {
                  myCart[cIndex].Qty = myCart[cIndex].Qty + e.Qty;
                }
              }
            });
            myCart =
              myCart === null || myCart.length === 0
                ? [...saveCart]
                : [...myCart, ...saveCart];
            setCartQTy(myCart.length);
            sessionStorage.setItem("MyCart", JSON.stringify(myCart));
          } else {
            console.log("Success = false");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: API /afcl/Package/Cart/CheckOut/ ", error);
        });
    }
  }
  return {
    email,
    result,
    message,
    passwordValidate,
    userLogin,
    cartQTy,
    loading,
    buttonState,
    setEmail,
    setPassword,
    setResult,
    setMessage,
    emailValidation,
    submitLogin,
    submitForgot,
    getUserCart,
    setLoading
  };
};

export default useLoginAgent;
