import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { gapi } from "gapi-script";
import { Button, Modal, Form } from "react-bootstrap";
import "react-responsive-modal/styles.css";

//Image
import logo from "../component/icons/Staff.svg";
import agentLogo from "../component/icons/Agent-color.svg";

//css
import "../component/css/style.css";
import "../component/css/custom.css";

import showTerm from "../customHook/showTerm";
import { clientId } from "../constants/DataPublic";
import useLoginStaff from "../customHook/useLoginStaff";

import { baseLink, apiLink_ecom } from "../constants/DataPublic";
import { GET_AGENT } from "../services/BASE_API";
import { useGlobalState } from "../global";
import Backdrop from "@mui/material/Backdrop";

const SigninStaff = (props) => {
  const navigate = useNavigate();
  const [routePath, setRoutePath] = useGlobalState("route");
  const { language } = props;

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  useEffect(() => {
    if (user != null && user.isLogin && showModal == false) {
      navigate("/");
    }
  }, [user]);

  // Login with Email and Password
  let {
    message,
    passwordValidate,
    userLogin,
    showModal,
    loading,
    buttonState,
    email,
    setEmail,
    setPassword,
    submitLogin,
    setAgent,
    submitAgent,
    setLoading,
    emailValidation,
  } = useLoginStaff(props.handleUserLoginChange);

  // Show Terms & Conditions
  let {
    open,
    subject,
    terms,
    agree,
    setAgree,
    onOpenModal,
    onCloseModal,
    getTerms,
    onConfirm,
  } = showTerm();

  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [needRegist, setNeedRegist] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [agentUser, setAgentUser] = useState();

  useEffect(() => {
    if (userLogin != null) {
      props.onUserLoginChange(true);
    }
  }, [userLogin]);

  const handleShow = () => setModalIsOpen(true);
  const handleClose = () => {
    if (selectedOption != "") navigate("/");
    setModalIsOpen(false);
  };

  useEffect(() => {
    //console.log("Log modal ======= " + showModal);
    if (showModal) {
      handleShow();
      callGetAgentService();
    }
  }, [showModal]);

  // useEffect(() => {
  //   if (needRegist) {
  //     getTerms(language);
  //     setNeedRegist(false);
  //   }
  // }, [needRegist]);

  // Login with Google
  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: "",
  //     });
  //   }
  //   gapi.load("client:auth2", start);
  // }, []);

  // ภาษา
  const { t } = useTranslation();

  //กด Enter
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      submitLogin(event);
    }
  }
  const inputReference = React.createRef();
  const inputUserKeyDown = (event) => {
    if (event.key === 'Enter' && emailValidation()) {
      event.preventDefault();
      event.stopPropagation();
      inputReference.current.focus();
    }
  }

  function handleSelectChange(event) {
    setSelectedOption(event.target.value);
    // setAgent(event.target.value);
    // console.log("Agent Code",event.target.value);
  }

  async function callGetAgentService() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + GET_AGENT, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          // console.log(data);
          setAgentUser(data["Result"]);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + apiLink_ecom, error);
          setLoading(false);
          return false;
        });
    }, 1000);
  }

  useEffect(() => {
    if (agentUser != null && agentUser.length > 0) {
      setSelectedOption(agentUser[0].ContractId);
    }
  }, [agentUser]);

  useEffect(() => {
    if (selectedOption != null) {
      // console.log("AGENT Option" + selectedOption.toString());
      if (agentUser != null) {
        let agent = agentUser.find((x) => x.ContractId == selectedOption);
        setAgent(agent);
      }
    }
  }, [selectedOption]);

  return (
    <div className="page page-steps">
      <div className="section py-5">
        <div className="container">
          <div className="row">
            {/* <div style={{ marginTop: "15%" }}></div> */}
            <div
              className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius"
              style={{ margin: "0 auto" }}
            >
              <div className="mb-3">
                <div style={{ textAlign: "center" }}>
                  <img src={logo} style={{ width: "20%", margin: "5%" }} />
                  <h2 className="main-title mb-3">
                    {t("sign_in_to_your_account_staff")}
                  </h2>
                </div>

                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-color"
                >
                  {t("email")}*
                </label>
                <input
                  type="email"
                  className={message == "" ? "form-control" : "form-control danger"}
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={inputUserKeyDown}
                  required
                />
                {/* <span className="text-danger">{t(message)}</span> */}
                <span className="text-danger">{message}</span>

                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label text-color"
                >
                  {t("password")}*
                </label>
                <input
                  type="password"
                  className="form-control"
                  ref={inputReference}
                  id="exampleFormControlInput2"
                  placeholder=""
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                  required
                />
                <span className="text-danger">{t(passwordValidate)}</span>
              </div>
              <div className="mb-3">
                <Button
                  type="button"
                  className="btn btn-link btn-lb text-capitalize"
                  onClick={submitLogin}
                  disabled={!buttonState}
                >
                  {t("login")}
                </Button>
              </div>
              <div className="row">
                <div className="col-md-6 text-left">
                  {/* <a
                    style={{
                      color: "gray",
                      cursor: "context-menu",
                      textDecoration: "underline",
                    }}
                  >
                    {t("change_password")}
                  </a> */}
                </div>
                <div className="col-md-6 text-right">
                  {/* <a href={baseLink + routePath + "staff/resetpassword"}>{t("forgot_password")}</a> */}
                  <a
                    href=""
                    className="login-text-footer"
                    onClick={(e) =>
                      navigate("/staff/resetpassword", {
                        state: { email: email },
                      })
                    }
                  >
                    {t("forgot_password")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <Modal
          show={modalIsOpen}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="select-agent"
        >
          <Modal.Header>
            {/* <button className="btn-modal-close" onClick={handleClose}>
              <i class="fa fa-times"></i>
            </button> */}
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="content-header" style={{ textAlign: "center" }}>
                <img src={agentLogo} style={{ width: "30%", margin: "5%" }} />
                <h2 className="main-title mb-3">{t("select_agent")}</h2>
              </div>
              {agentUser != undefined && (
                <>
                  <div
                    className="label mb-2"
                    style={{
                      color: "#0E315C",
                      marginLeft: "50px",
                      fontWeight: "300",
                    }}
                  >
                    {t("agents")} *
                  </div>
                  <Form.Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    {agentUser != null ? (
                      agentUser.map((row, index) => (
                        <option value={row.ContractId}>
                          {row.ContractName}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Form.Select>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn_confirm" onClick={submitAgent} disabled = {selectedOption == ""}>
              {t("confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Loading Spinner */}
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: "1" }}
            style={{ zIndex: 1100 }}
            open
          >
            <div
              class="spinner-border"
              role="status"
              aria-hidden="true"
              style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
            ></div>
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default SigninStaff;
