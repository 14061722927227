import React, { useState, useEffect } from "react";
import {
  CloseButton,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { ReactComponent as View } from "../icons/view_black.svg";

// Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  TextField,
} from "@mui/material";
import "moment/locale/th";
import moment from "moment";
import { red } from "@mui/material/colors";

export const FilterModal = (props) => {
  const { open, setClose, service, data, setData, isTodayTab, language } = props;
  const { t, i18n } = useTranslation();

  const dateNow = new Date().toISOString().slice(0, 10);
  const defaultTime = "12:00";

  const [searchValue, setSerchValue] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [expireTime, setExpireTime] = useState("");

  const [selectPaid, setSelectPaid] = useState();
  const [selectBooking, setSelectBooking] = useState();
  const [selectEmail, setSelectEmail] = useState();

  const selectObject = {
    PaidStatus: [
      { value: 0, label: t("all") },
      { value: 1, label: t("paid") },
      { value: 2, label: t("partial_paid") },
      { value: 3, label: t("unpaid") },
    ],
    BookingStatus: [
      { value: 0, label: t("all") },
      { value: 1, label: t("tentative") },
      { value: 2, label: t("confirm") },
      { value: 3, label: t("cancel") },
    ],
    EmailStatus: [
      { value: 0, label: t("all") },
      { value: 1, label: t("done") },
      { value: 2, label: t("waiting") },
    ],
  };

  const [paidStatus, setPaidStatus] = useState(selectObject.PaidStatus);
  const [bookingStatus, setBookingStatus] = useState(
    selectObject.BookingStatus
  );
  const [emailStatus, setEmailStatus] = useState(selectObject.EmailStatus);

  useEffect(() => {
    setData({
      Keyword: "",
      VisitDate: isTodayTab ? dateNow : "", // if Tab === today arrival default date today and disable datepicker
      VisitTime: "",
      ExpiredDate: "",
      ExpiredTime: "",
      PaidStatus: "",
      BookingStatus: "",
      SendEmail: "",
    });
  }, []);

  useEffect(() => {
    setPaidStatus(selectObject.PaidStatus);
    setBookingStatus(selectObject.BookingStatus);
    setEmailStatus(selectObject.EmailStatus);

    console.log(selectPaid);
    // Reload the value prop when the language changes
    if (selectPaid != null) {
      const reloadedSelectPaid = selectPaid.map((option) => {
        const foundOption = selectObject.PaidStatus.find(
          (x) => x.value === option.value
        );
        return {
          ...option,
          label: t(foundOption.label),
        };
      });
      setSelectPaid(reloadedSelectPaid);
    }

    if (selectBooking != null) {
      const reloadedSelectBooking = selectBooking.map((option) => {
        const foundOption = selectObject.BookingStatus.find(
          (x) => x.value === option.value
        );
        return {
          ...option,
          label: t(foundOption.label),
        };
      });
      setSelectBooking(reloadedSelectBooking);
    }

    if (selectEmail != null) {
      const reloadedSelectEmail = selectEmail.map((option) => {
        const foundOption = selectObject.EmailStatus.find(
          (x) => x.value === option.value
        );
        return {
          ...option,
          label: t(foundOption.label),
        };
      });
      setSelectEmail(reloadedSelectEmail);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (isTodayTab) {
      setArrivalDate(dateNow); // if Tab === today arrival default date today and disable datepicker
    }else{
      setArrivalDate("");
    }
  }, [isTodayTab]);

  useEffect(() => {
    console.log("Filter : ", data);
  }, [data]);

  useEffect(()=>{
    moment.locale(i18n.language);
  }, [language])

  const onSearchValueChange = (e) => {
    setSerchValue(e.target.value);
    console.log("Value", e.target.value);
    setData({ ...data, ["Keyword"]: e.target.value });
  };

  const onArrivalDateChange = (e) => {
    let date = moment(e).format("YYYY-MM-DD");
    setArrivalDate(date);
    console.log("Arrival Date", date);
    setData({ ...data, ["VisitDate"]: date });
  };
  const onArrivalTimeChange = (e) => {
    setArrivalTime(e.target.value);
    console.log("Arrival Time", e.target.value);
    setData({ ...data, ["VisitTime"]: e.target.value });
  };
  const onExpireDateChange = (e) => {
    let date = moment(e).format("YYYY-MM-DD");
    setExpireDate(date);
    console.log("Expire Date", date);
    setData({ ...data, ["ExpiredDate"]: date });
  };
  const onExpireTimeChange = (e) => {
    setExpireTime(e.target.value);
    console.log("Expire Time", e.target.value);
    setData({ ...data, ["ExpiredTime"]: e.target.value });
  };

  const onSelectPaidChange = (e) => {
    console.log(e);
    if (e.length > 0) {
      let isAll = e.find((x) => x.value === 0);
      // console.log("Is All", isAll);
      if (isAll != null) {
        setPaidStatus([{ value: 0, label: t("all") }]);
        setSelectPaid([{ value: 0, label: t("all") }]);
        setData({ ...data, ["PaidStatus"]: "" });
      } else {
        if (e.length == selectObject.PaidStatus.length - 1) {
          setPaidStatus([{ value: 0, label: t("all") }]);
          setSelectPaid([{ value: 0, label: t("all") }]);
          setData({ ...data, ["PaidStatus"]: "" });
        } else {
          setSelectPaid(e);
          let value = "";
          e.map((x, index) => {
            value += x.value.toString() + (index === e.length - 1 ? "" : ",");
          });
          setData({ ...data, ["PaidStatus"]: value });
        }
      }
    } else {
      // retore default option value
      setPaidStatus(selectObject.PaidStatus);
      setData({ ...data, ["PaidStatus"]: "" });
      setSelectPaid(e);
    }
  };

  const onSelectBookingChange = (e) => {
    // console.log(e);
    if (e.length > 0) {
      let isAll = e.find((x) => x.value === 0);
      // console.log("Is All", isAll);
      if (isAll != null) {
        setBookingStatus([{ value: 0, label: t("all") }]);
        setSelectBooking([{ value: 0, label: t("all") }]);
        setData({ ...data, ["BookingStatus"]: "" });
      } else {
        if (e.length == selectObject.BookingStatus.length - 1) {
          setBookingStatus([{ value: 0, label: t("all") }]);
          setSelectBooking([{ value: 0, label: t("all") }]);
          setData({ ...data, ["BookingStatus"]: "" });
        } else {
          setSelectBooking(e);
          let value = "";
          e.map((x, index) => {
            value += x.value.toString() + (index === e.length - 1 ? "" : ",");
          });
          setData({ ...data, ["BookingStatus"]: value });
        }
      }
    } else {
      // retore default option value
      setBookingStatus(selectObject.BookingStatus);
      setData({ ...data, ["BookingStatus"]: "" });
      setSelectBooking(e);
    }
  };

  const onSelectEmailChange = (e) => {
    // console.log(e);
    if (e.length > 0) {
      let isAll = e.find((x) => x.value === 0);
      // console.log("Is All", isAll);
      if (isAll != null) {
        setEmailStatus([{ value: 0, label: t("all") }]);
        setSelectEmail([{ value: 0, label: t("all") }]);
        setData({ ...data, ["SendEmail"]: "" });
      } else {
        if (e.length == selectObject.EmailStatus.length - 1) {
          setEmailStatus([{ value: 0, label: t("all") }]);
          setSelectEmail([{ value: 0, label: t("all") }]);
          setData({ ...data, ["SendEmail"]: "" });
        } else {
          setSelectEmail(e);
          let value = "";
          e.map((x, index) => {
            value += x.value.toString() + (index === e.length - 1 ? "" : ",");
          });
          setData({ ...data, ["SendEmail"]: value });
        }
      }
    } else {
      // retore default option value
      setEmailStatus(selectObject.EmailStatus);
      setData({ ...data, ["SendEmail"]: "" });
      setSelectEmail(e);
    }
  };

  const onConfirm = () => {
    service("filter", 1);
  };

  const onReset = () => {
    // reset search and datetime
    setSerchValue("");
    setArrivalDate("");
    setArrivalTime("");
    setExpireDate("");
    setExpireTime("");

    // reset select dropdown value
    setSelectPaid([]);
    setSelectBooking([]);
    setSelectEmail([]);

    // retore default option value
    setPaidStatus(selectObject.PaidStatus);
    setBookingStatus(selectObject.BookingStatus);
    setEmailStatus(selectObject.EmailStatus);

    // reset filter data
    setData({
      Keyword: "",
      VisitDate: "",
      VisitTime: "",
      ExpiredDate: "",
      ExpiredTime: "",
      PaidStatus: "",
      BookingStatus: "",
      SendEmail: "",
    });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0E315C",
      },
    },
    // components: {
    //   MuiTextField: {
    //     styleOverrides: {
    //       root: {
    //         "&.invalid .MuiOutlinedInput-notchedOutline": {
    //           borderColor: red[500],
    //         },
    //       },
    //     },
    //   },
    // },
  });

  return (
    <div className="filter-dialog" style={{ right: open ? 0 : -600 }}>
      <ModalHeader className="filter-header">
        <p>{t("search_filter")}</p>
        <CloseButton onClick={setClose} />
      </ModalHeader>
      <ModalBody>
        <form>
          <div className="row mb-3">
            <label for="exampleInput2" class="filter-form-label form-label m-0">
              {t("search")}
            </label>
            <div className="input-container">
              <i>
                <View />
              </i>
              <input
                type="text"
                class="input-field"
                id="exampleInput2"
                maxLength={40}
                value={searchValue}
                onChange={onSearchValueChange}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 p-0">
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("arrival_date")}
              </label>
              {/* <input
                  type="date"
                  class="input-field"
                  id="exampleInput2"
                  value={arrivalDate}
                  onChange={onArrivalDateChange}
                  disabled={isTodayTab}
                  required
                /> */}
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale={i18n.language}
                  dateFormats={"DD/MM/YYYY"}
                >
                  <DatePicker
                    type="date"
                    class="input-field"
                    id="exampleInput2"
                    value={arrivalDate == "" ? null : moment(arrivalDate)}
                    onChange={onArrivalDateChange}
                    disabled={isTodayTab}
                    sx={{
                      "& .MuiInputBase-root.MuiOutlinedInput-root": {
                        height: "36px",
                      },
                    }}
                    format={"DD/MM/YYYY"}
                    dayOfWeekFormatter={(day) => `${day}`}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </div>
            <div className="col-3">
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("time")}
              </label>
              <div className="input-container">
                <input
                  type="time"
                  class="input-field"
                  id="exampleInput2"
                  value={arrivalTime}
                  onChange={onArrivalTimeChange}
                  required
                />
              </div>
            </div>
            <div className="col-3 pe-0" style={{width:"26%", paddingLeft:"10px"}}>
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("expire_date")}
              </label>
              {/* <div className="input-container">
                <input
                  type="date"
                  class="input-field"
                  id="exampleInput2"
                  value={expireDate}
                  onChange={onExpireDateChange}
                  required
                />
              </div> */}
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  dateFormats={"DD/MM/YYYY"}
                  adapterLocale={i18n.language}
                >
                  <DatePicker
                    type="date"
                    class="input-field"
                    id="exampleInput2"
                    value={expireDate == "" ? null : moment(expireDate)}
                    onChange={onExpireDateChange}
                    sx={{
                      "& .MuiInputBase-root.MuiOutlinedInput-root": {
                        height: "36px",
                      },
                    }}
                    format={"DD/MM/YYYY"}
                    dayOfWeekFormatter={(day) => `${day}`}
                  />
                </LocalizationProvider>
              </ThemeProvider>
            </div>
            <div className="col-3 ps-3 pe-0" style={{width:"24%"}}>
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("time")}
              </label>
              <div className="input-container">
                <input
                  type="time"
                  class="input-field"
                  id="exampleInput2"
                  value={expireTime}
                  onChange={onExpireTimeChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col ps-0" style={{paddingRight:"20px"}}>
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("paid_status")}
              </label>
              <Select
                placeholder={t("select_filter")}
                value={selectPaid}
                onChange={onSelectPaidChange}
                options={paidStatus}
                isMulti
              />
            </div>
            <div className="col ps-0 pe-0">
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("booking_status")}
              </label>
              <Select
                placeholder={t("select_filter")}
                value={selectBooking}
                onChange={onSelectBookingChange}
                options={bookingStatus}
                isMulti
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6 ps-0">
              <label
                for="exampleInput2"
                class="filter-form-label form-label m-0"
              >
                {t("email_status")}
              </label>
              <Select
                placeholder={t("select_filter")}
                value={selectEmail}
                onChange={onSelectEmailChange}
                options={emailStatus}
                isMulti
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="filter-modal-footer">
        <Button
          className="btn-filter-confirm"
          style={{
            backgroundColor: "#0e315c",
            cursor: "pointer",
            maxWidth: "25%",
            textTransform: "capitalize",
            borderColor: "#0e315c",
          }}
          onClick={() => {
            onConfirm();
            setClose();
          }}
        >
          {t("search")}
        </Button>
        <Button
          className="btn-filter-reset"
          style={{
            backgroundColor: "white",
            cursor: "pointer",
            maxWidth: "25%",
            color: "#0e315c",
            textTransform: "capitalize",
            borderColor: "#0e315c",
          }}
          onClick={() => {
            onReset();
          }}
        >
          {t("reset")}
        </Button>
      </ModalFooter>
    </div>
  );
};
