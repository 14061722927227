import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { apiLink_ecom, baseLink } from "../constants/DataPublic";
import { useLocation, useNavigate } from "react-router-dom";
import { Markup } from "interweave";
import { useMediaQuery } from "react-responsive";
import { AGENT_SEND_EMAIL, PRINT_BOOKING_FORM, PRINT_FOR_GUEST, SAVE_BOOKING_PRINT } from "../services/BASE_API";
import { Backdrop } from "@material-ui/core";
import ModalSuccess from "../component/Modal/ModalSuccess";
import ModalFailed from "../component/Modal/ModalFailed";
import { Alert } from "react-bootstrap";
import LinksImage from "../component/img/Links.png";

const Done = (props) => {
  const { language, getCartItem } = props;
  const { state } = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 990px)" });

  const [keepState, setKeepstate] = useState();
  const [loading, setLoading] = useState(false);

  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Done: ", user);
    console.log("confirmNo: ", state);
    setKeepstate(state);
    getCartItem(0);
    user.confirmationNo = "";
    user.note = "";
    user.note1 = "";
    user.note2 = "";
    user.note3 = "";
    user.note4 = "";
    sessionStorage.removeItem("CabanaVisitingDate");
    sessionStorage.setItem("UserLogin", JSON.stringify(user));
    sessionStorage.removeItem("CabanaVisitingDate");
    if(state === null){
      goHome();
    }
  }, []);

  const goHome = () => {
    user.confirmationNo = "";
    sessionStorage.setItem("UserLogin", JSON.stringify(user));
    navigate("/");
    window.location.reload();
    setKeepstate(null);
  };

  async function callPrintServices(apiPath, orderId, confirmNo) {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + apiPath + "?OrderId=" + orderId, requestOptions)
        .then(function (response) {
          setLoading(false);
          // Get the filename from the Content-Disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const match =
            contentDisposition && contentDisposition.match(/filename="(.+)"/);
          const originalFilename = match && match[1] ? match[1] : "";
          const date = new Date().toISOString().slice(0, 10).replace(/-/g, "_"); // Format current date as yyyy_mm_dd
          const filename = confirmNo + originalFilename + "_" + date; // Concatenate the orderId, original filename, and current date
          return response.blob().then((blob) => ({ blob, filename }));
        })
        .then(function ({ blob, filename }) {
          // Use the filename as the title of the new tab
          const url = URL.createObjectURL(blob);
          const newtab = window.open(url, "_blank");
          const link = document.createElement("a");
          // link.href = url;
          // link.download = filename;
          link.click();
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);
          alert("Error: " + apiPath, error);
          return false;
        });
    }, 1000);
  }

  // ภาษา
  const { t } = useTranslation();

  //Modal
  const [emailStatus, setEmailStatus] = useState("");
  const [successModal, setOpenSuccessModal] = useState(false);
  const [failedModal, setOpenFailedModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openSuccessModal = () => {
    setOpenSuccessModal(true);
  };
  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const openFailedModal = () => {
    setOpenFailedModal(true);
  };
  const closeFailedModal = () => {
    setOpenFailedModal(false);
  };

  useEffect(() => {
    if (emailStatus != "") {
      if (emailStatus == "Success") {
        setModalMessage("sendemail_success");
        openSuccessModal();
        setEmailStatus("");
      } else if (emailStatus == "Failed") {
        setModalMessage("sendemail_failed");
        openFailedModal();
        setEmailStatus("");
      } else {
        Alert(emailStatus);
        setEmailStatus("");
      }
    }
  }, [emailStatus]);

  return (
    <div className="page page-steps">
      <div class="col-lg-12 py-5">
        <div class="d-flex justify-content-center height-full">
          <div class="pt-5 done" style={{ width: "80%" }}>
            <h2 class="font-40 color-main mb-2 text-center">
              {t("you_all_set")}
            </h2>
            <p class="font-18 text-center" style={{ fontWeight: "600" }}>
              {t("thank_you")}
            </p>
            <p
              class="mb-5 text-center"
              style={{
                width: isTabletOrMobile ? "100%" : "50%",
                margin: "auto",
              }}
            >
              {t("junk_box")}
            </p>
            {/* <h5 class="font-20 mb-3 text-left" style={{ fontWeight: "500" }}>
              {t("your_booking_reference")} :{" "}
              {keepState && keepState.confirmationNo}
            </h5> */}
            <div>
              {keepState && <Markup content={" " + keepState.TagHtml} />}
            </div>
            <div className="row">
              <div className="col-xl-2 col-sm-2">
                <div style={{ width: "100%", margin: "auto" }}>
                  <button
                    class="btn btn-main btn-booking-done"
                    onClick={goHome}
                  >
                    {t("back")}
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4">
                <div style={{ width: "100%", margin: "auto" }}>
                  <button
                    class="btn btn-main btn-booking-done"
                    onClick={async () => {
                      await callPrintServices(
                        PRINT_BOOKING_FORM,
                        keepState.OrderId,
                        keepState.confirmationNo
                      );
                    }}
                  >
                    {t("print_booking_form")}
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-sm-4">
                <div style={{ width: "100%", margin: "auto" }}>
                  <button
                    class="btn btn-main btn-booking-done"
                    onClick={async () => {
                      await callPrintServices(
                        PRINT_FOR_GUEST,
                        keepState.OrderId,
                        keepState.confirmationNo
                      );
                    }}
                  >
                    {t("print_for_guest")}
                  </button>
                </div>
              </div>
            </div>
            <h2 class="font-20 mb-5"></h2>
          </div>
        </div>
        {/* <Markup content={" " + state.TagHtml} /> */}
      </div>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
      <ModalSuccess
        open={successModal}
        body={t(modalMessage)}
        setClose={closeSuccessModal}
        back={closeSuccessModal}
      />
      <ModalFailed
        open={failedModal}
        body={t(modalMessage)}
        setClose={closeFailedModal}
        back={closeFailedModal}
      />
    </div>
  );
};
export default Done;
