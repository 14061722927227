import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Markup } from "interweave";
import { gapi } from 'gapi-script';
import Button from "react-bootstrap/Button";
import { Modal } from 'react-bootstrap';
import "react-responsive-modal/styles.css";

//Image
import logo from "../component/img/Signin-Logo.png";
import { ReactComponent as Close } from "../component/img/close.svg";
import { ReactComponent as Vector } from "../component/img/vector.svg";

//css
import "../component/css/style.css";
import "../component/css/custom.css";

import useLoginAgent from '../customHook/useLoginAgent';
import showTerm from '../customHook/showTerm';
import { clientId } from '../constants/DataPublic'
import { useGlobalState } from "../global";
import { baseLink, apiLink, apiLink_ecom } from "../constants/DataPublic";
import { Backdrop } from "@material-ui/core";


const SigninAgent = (props) => {

  const navigate = useNavigate();
  const { language } = props
  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  const [routePath, setRoutePath] = useGlobalState("route");
  useEffect(() => {
    if (user != null && user.isLogin) {
      navigate("/")
    }
  }, [user])

  // Login with Email and Password
  let { message, passwordValidate, userLogin, loading, buttonState,email,  setEmail, setPassword, submitLogin, setLoading ,emailValidation} = useLoginAgent(props.handleUserLoginChange);

  // Show Terms & Conditions
  let { open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm } = showTerm();

  const [ggUser, setGGUser] = useState({})
  const [fbUser, setFBUser] = useState({})
  const [needRegist, setNeedRegist] = useState(false)

  useEffect(() => {
    if(userLogin != null){
      props.onUserLoginChange(true, userLogin.userId);
    }
  },[userLogin])

  useEffect(() => {
    if (needRegist) {
      getTerms(language)
      setNeedRegist(false)
    }
  }, [needRegist])

  // Login with Google
  // useEffect(() => {
  //   function start() {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: ""
  //     })
  //   }
  //   gapi.load('client:auth2', start);
  // }, []);

  // ภาษา
  const { t } = useTranslation();
  
  //กด Enter
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      submitLogin(event);
    }
  }
  const inputReference = React.createRef();
  const inputUserKeyDown = (event) => {
    if (event.key === 'Enter' && emailValidation()) {
      event.preventDefault();
      event.stopPropagation();
      inputReference.current.focus();
    }
  }

  return (
    <div>
      <div className="page page-steps">
        <div className="section py-5">
          <div className="container">
            <div className="row">
              {/* <div style={{ marginTop: "15%" }}></div> */}
              <div
                className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius"
                style={{ margin: "0 auto" }}
              >
                <div className="mb-3">
                  <div style={{ textAlign: "center" }}>
                    <img src={logo} style={{ width: "35%", margin: "5%" }} />
                    <h2 className="main-title mb-3">
                      {t("sign_in_to_your_account_agent")}
                    </h2>
                  </div>

                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label text-color"
                  >
                    {t("email")}*
                  </label>
                  <input
                    type="email"
                    className={(message == "") ? "form-control" : "form-control danger"}
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={inputUserKeyDown}
                    required
                  />
                  {/* <span className="text-danger">{t(message)}</span> */}
                  <span className="text-danger">{message}</span>

                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label text-color"
                  >
                    {t("password")}*
                  </label>
                  <input
                    type="password"
                    ref={inputReference}
                    className="form-control"
                    id="exampleFormControlInput2"
                    placeholder=""
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    required
                  />
                  <span className="text-danger">{t(passwordValidate)}</span>
                </div>
                <div className="mb-3">
                  <Button
                    type="button"
                    className="btn btn-link btn-lb text-capitalize"
                    onClick={submitLogin}
                    disabled={!buttonState}
                  >
                    {t("login")}
                  </Button>
                </div>
                <div className="row">
                  <div className="col-md-6 text-left">
                    {/* <a style={{ color: "gray", cursor: "context-menu", textDecoration:"underline" }}>
                      {t("change_password")}
                    </a> */}
                  </div>
                  <div className="col-md-6 text-right">
                    {/* <a href={baseLink + routePath + "agent/resetpassword"}>
                      {t("forgot_password")}
                    </a> */}
                    <a href="" className="login-text-footer" onClick={(e)=>navigate("/agent/resetpassword", {state:{email:email}})}>
                      {t("forgot_password")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Loading Spinner */}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
    </div>
  );
};

export default SigninAgent;
