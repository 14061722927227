import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BackToTopPackget from "../component/Ticket/BackToTop";
import Cart from "../component/Ticket/Cart";
import Checkout from "../component/Ticket/Checkout";
import MiniCart from "../component/Ticket/MiniCart";
import Rightbar from "../component/Ticket/Rightbar";
import StepBar from "../component/Ticket/StepBar";
import Step1 from "../component/Ticket/Step1";
import Step2 from "../component/Ticket/Step2";
import Step3 from "../component/Ticket/Step3";
import Step4 from "../component/Ticket/Step4";
import { apiLink, apiLink_ecom, deviceWith } from "../constants/DataPublic";
import { useGlobalState } from "../global";
import { useNavigate } from "react-router-dom";
import { GET_PACKAGE_BY_CONTRACT, REMOVE_CART } from "../services/BASE_API";
import SelectAgent from "../component/Modal/SelectAgent";
import { useLocation } from "react-router-dom";
import ModalFailed from "../component/Modal/ModalFailed";
import { useTranslation } from "react-i18next";

const Ticket = (props) => {
  let user = sessionStorage.getItem("UserLogin");
  user = JSON.parse(user);

  let paymentRoute = JSON.parse(sessionStorage.getItem("PaymentRoute"));
  let agentRoute = JSON.parse(sessionStorage.getItem("AgentRoute"));

  let myCart = sessionStorage.getItem("MyCart");
  myCart = JSON.parse(myCart);
  const navigate = useNavigate();
  const [routePath, setRoutePath] = useGlobalState("route");
  const { packageCode } = useParams();
  const { step } = useParams();
  const { language, packageSubTypeCode, getCartItem } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [status, setStatus] = useState(false);
  const [itemGroup, setItemGroup] = useState([]);
  const [packageTicket, setPackageTicket] = useState([]);
  const [cart, setCart] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [cartUpdate, setCartUpdate] = useState({});
  const [moretrillItem, setMoretrillItem] = useState([]);
  const [fbitem, setFBItem] = useState([]);
  const [extra, setExtra] = useState([]);
  const [paymentGateway, SetPaymentGateway] = useState(null);
  const [publicKey, setPublicKey] = useGlobalState("publicKey");
  const [canPlaceOrder, setCanPlaceOrder] = useGlobalState("canPlaceOrder");
  const [isSelectedAgent, setSelectedAgent] = useState(false);

  const [visitingDate, setVisitingDate] = useGlobalState("visitingDate");

  const [isMobile, setIsMobile] = useState(false);
  const [needCheckout, setNeedCheckout] = useState(false);

  const { state } = useLocation();
  const [cabanaList, setCabanaList] = useState(
    state != null ? (state.cabanaList != null ? state.cabanaList : null) : null
  );
  const [fromBuyNow, setFromBuyNow] = useState(state != null ? true : false);

  const [openSelectAgent, setOpenSelectAgent] = useState(false);
  // const [cabanaListAll, setCabanaListAll] = useState(state != null ? state.cabanaList != null ? state.cabanaList : null : null);

  const setCloseSelectAgentModal = () => {
    setOpenSelectAgent(false);
  };
  const [loading, setLoading] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false); //Failed Modal
  const [aleart, setAleart] = useState("");
  const { t } = useTranslation();
  const [visitingDateCabana, setVisitingDateCabana] =
    useGlobalState("visitingDateCabana");

  const setCloseFailedModal = () => {
    setOpenFailedModal(false);
  };

  const setAgent = () => {
    setSelectedAgent(true);
    setPackageTicket([]);
    initCabanaList([]);
    user = JSON.parse(sessionStorage.getItem("UserLogin"));
    myCart = JSON.parse(sessionStorage.getItem("MyCart"));
    setUserInfo(user);
    getPackageCardBycontract();
    getCartItem();
    setCart([]);
    sessionStorage.setItem("CabanaAllotment", null);
    sessionStorage.setItem("CabanaVisitingDate", null);
    setVisitingDate(null);
    setVisitingDateCabana(null);
  };

  useEffect(() => {
    //Remove payment route and restore old data
    // if (paymentRoute != null && cart.length < 1) {
    //   user.confirmationNo =
    //     paymentRoute.cartConfirmationNo == ""
    //       ? ""
    //       : paymentRoute.cartConfirmationNo;
    //   user.totalPrice =
    //     paymentRoute.totalPrice == 0
    //       ? user.totalPrice
    //       : paymentRoute.oldtotalPrice;
    //   sessionStorage.setItem("UserLogin", JSON.stringify(user));
    // }
    sessionStorage.removeItem("PaymentRoute");

    console.log("Ticket, ", packageSubTypeCode);
    console.log("Ticket Package, ", packageCode, " Step: ", step);
    props.setActiveTab("ticket");
    // props.setRoutePath(window.location.pathname.split('/')[1])
    // Mobile Device 575.98 , Tablet 768

    if (user == null) {
      navigate("/");
    }
    if (document.body.offsetWidth < deviceWith) {
      setIsMobile(true);
    }

    if (myCart) {
      if (myCart.filter((e) => e.step == 1).length == 0) {
        // sessionStorage.setItem('MyCart', JSON.stringify([]));
        // setCart([])
        setCurrentStep(1);
        navigate("/ticket/" + packageCode + "/" + 1);
        setStatus(true);
      } else {
        setCanPlaceOrder(false);
        // setCurrentStep(1);
        navigate("/ticket/" + packageCode + "/" + step);
        setCurrentStep(step);
        setStatus(false);
      }
    }

    if (myCart !== null) {
      console.log("Set Cart", myCart);
      setCart(myCart);
    }

    if (user !== null) {
      setUserInfo(user);
    }

    if (agentRoute === "Landing") {
      setSelectedAgent(true);
    } else {
      if (user.isStaff) {
        setOpenSelectAgent(true);
      } else {
        setSelectedAgent(true);
      }
    }

    console.log("currentStep currentStep", currentStep);
    console.log("indexxxcartcart", cart);
    // getPackageCard();
  }, []);

  useEffect(() => {
    if (language) {
      // getPackageCard();
      getPackageCardBycontract();
      if (routePath == "") {
        // getMoretrill();
        // getFB();
        // getExtra();
      }
    }
  }, [language]);

  useEffect(() => {
    console.log("packageTicket", packageTicket);
    let pk = packageTicket.filter((a) => {
      return myCart.find((b) => {
        return a.PackageCode === b.PackageCode;
      });
    });
    pk.forEach((a) => {
      myCart.forEach((b) => {
        if (a.PackageCode === b.PackageCode) {
          b.PackageName = a.PackageName;
        }
      });
    });
    sessionStorage.setItem("MyCart", JSON.stringify(myCart));
    setCart(myCart);
  }, [packageTicket]);

  useEffect(() => {
    console.log("moretrillItem", moretrillItem);
    let pk = moretrillItem.filter((a) => {
      return myCart.find((b) => {
        return a.PackageCode === b.PackageCode;
      });
    });
    pk.forEach((a) => {
      myCart.forEach((b) => {
        if (a.PackageCode === b.PackageCode) {
          b.PackageName = a.PackageName;
        }
      });
    });
    sessionStorage.setItem("MyCart", JSON.stringify(myCart));
    setCart(myCart);
  }, [moretrillItem]);

  useEffect(() => {
    console.log("fbitem", fbitem);
    let pk = fbitem.filter((a) => {
      return myCart.find((b) => {
        return a.PackageCode === b.PackageCode;
      });
    });
    pk.forEach((a) => {
      myCart.forEach((b) => {
        if (a.PackageCode === b.PackageCode) {
          b.PackageName = a.PackageName;
        }
      });
    });
    sessionStorage.setItem("MyCart", JSON.stringify(myCart));
    setCart(myCart);
  }, [fbitem]);

  useEffect(() => {
    console.log("extra", extra);
    let pk = extra.filter((a) => {
      return myCart.find((b) => {
        return a.PackageCode === b.PackageCode;
      });
    });
    pk.forEach((a) => {
      myCart.forEach((b) => {
        if (a.PackageCode === b.PackageCode) {
          b.PackageName = a.PackageName;
        }
      });
    });
    sessionStorage.setItem("MyCart", JSON.stringify(myCart));
    setCart(myCart);
  }, [extra]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log("DDDDDDDDDDDDDDDDDDDDD");
  }, [currentStep]);

  useEffect(() => {
    setCurrentStep(parseInt(step));
  }, [step]);

  const getPackageCardBycontract = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    let path = "?contractId=" + user.userId;
    const res = await fetch(
      apiLink_ecom + GET_PACKAGE_BY_CONTRACT + path,
      requestOptions
    );
    res
      .json()
      .then(async (res) => {
        // let packageTypeList = res['Result']['PackageSteps'];
        // packageTypeList = packageTypeList.sort((a, b) => (a.StepId > b.StepId ? 1 : -1));
        // setItemGroup(packageTypeList);
        setPackageTicket(res["Result"]["Packages"]);
        initCabanaList(res["Result"]["Packages"]);
      })
      .catch((error) => {
        // console.log(error);
        // alert('Error: API /afcl/Package/Ticket/ ' , error)
      });
  };

  const initCabanaList = (allPackage) => {
    let newCabanaList = [];
    allPackage.forEach((item) => {
      if (item.VisitingDateItemCode != null) {
        if (!newCabanaList.includes(item.VisitingDateItemCode)) {
          newCabanaList.push(item.VisitingDateItemCode);
        }
      }
    });
    setCabanaList(newCabanaList);
  };

  const getPackageCard = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    let path = routePath != "" ? "?prefix=" + routePath.replace("/", "") : "";
    const res = await fetch(
      apiLink_ecom + "/afcl/Package/Ticket" + path,
      requestOptions
    );
    res
      .json()
      .then(async (res) => {
        let packageTypeList = res["Result"]["PackageSteps"];
        packageTypeList = packageTypeList.sort((a, b) =>
          a.StepId > b.StepId ? 1 : -1
        );
        setItemGroup(packageTypeList);
        setPackageTicket(res["Result"]["Packages"]);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Ticket/ ' , error)
      });
  };

  const getMoretrill = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    const res = await fetch(
      apiLink_ecom + "/afcl/Package/MoreThill",
      requestOptions
    );
    res
      .json()
      .then((res) => {
        const packageAll = res["Result"]["Packages"];
        setMoretrillItem(packageAll);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/MoreThill/ ', error)
      });
  };

  const getFB = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    const res = await fetch(apiLink_ecom + "/afcl/Package/FB", requestOptions);
    res
      .json()
      .then((res) => {
        console.log("Step 3 : ", res);
        setFBItem(res["Result"]["Packages"]);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/FB/ ', error)
      });
  };

  const getExtra = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language },
    };
    const res = await fetch(
      apiLink_ecom + "/afcl/Package/Extra",
      requestOptions
    );
    res
      .json()
      .then((res) => {
        console.log("Step 4: ", res);
        let iList = res["Result"]["Packages"];
        setExtra(iList);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Extra/ ', error)
      });
  };

  const onAddToCart = (item, isEdit) => {
    if (!isEdit) {
      // เพิ่ม Cart
      // if (item.Qty > 0) {
      //   if(item.VisitDate != null){
      //    myCart.forEach((b) => {
      //       if(b.VisitDate != null){
      //         b.VisitDate = item.VisitDate;
      //       }
      //     });
      //     sessionStorage.setItem('MyCart', JSON.stringify(myCart));
      //     setCart(myCart);
      //     setCart((cart) => {
      //       return [...cart, item];
      //     });
      //   } else {
      //     setCart((cart) => {
      //       return [...cart, item];
      //     });
      //   }
      // }
      if (item.Qty > 0) {
        setCart((cart) => {
          return [...cart, item];
        });
      }
    } else {
      // แก้ไข Cart
      console.log("item edit", item);
      console.log("item.Qty edit", item.Qty);
      if (item.Qty > 0) {
        const editCart = cart.map((e) => {
          if (
            e.PackageCode === item.PackageCode &&
            e.ItemCode === item.ItemCode
          ) {
            return {
              ...e,
              Qty: item.Qty,
              VisitDate: item.VisitDate,
              VisitLocation: item.VisitLocation,
              VisitTime: item.VisitTime,
              status: "normal",
              PackageName: item.PackageName,
              ItemPrice: item.ItemPrice,
              TotalPrice: item.TotalPrice,
            };
          }
          return e;
        });
        console.log("editCart", editCart);
        setCart(editCart);
      } else {
        onRemoveCart(item.PackageCode, item.ItemCode);
      }
    }
  };

  const onUpdateVisitDateAll = (newVisitDate, newAddPk) => {
    const editCart = cart.map((e) => {
      if (e.VisitDate != null) {
        return {
          ...e,
          VisitDate: newVisitDate,
        };
      }
      return e;
    });
    if(newAddPk != null){
      editCart.push(newAddPk);
    }
    console.log("editCart", editCart);
    setCart(editCart);
  };

  const onEditCart = (pCode, iCode) => {
    const editCart = cart.map((e) => {
      if (e.PackageCode === pCode && e.ItemCode === iCode) {
        return { ...e, status: "edit" };
      }
      return e;
    });

    setCart(editCart);
  };

  const onRemoveCart = (pCode, iCode) => {
    const index = cart.findIndex((e) => {
      return e.PackageCode === pCode && e.ItemCode === iCode;
    });

    setCart([...cart.slice(0, index), ...cart.slice(index + 1, cart.length)]);

    if (cart.length == 1) {
      sessionStorage.setItem(
        "Promo",
        JSON.stringify({
          discountPromo: "",
          promoCode: "",
          havePro: false,
          promoType: "",
        })
      );
    }

    console.log("indexxx", cart);
  };

  useEffect(() => {
    console.log("cart", cart);
    if (cart != null) {
      // Save Route Sessions
      let route = "Landing";
      sessionStorage.setItem("AgentRoute", JSON.stringify(route));
      props.getCartItem(cart.length);
      sessionStorage.setItem("MyCart", JSON.stringify(cart));
      if (status && myCart.length == 0) {
        sessionStorage.setItem("MyCart", JSON.stringify([]));
        sessionStorage.setItem("CabanaAllotment", null);
        sessionStorage.setItem("CabanaVisitingDate", null);
        setVisitingDate(null);
        setStatus(false);
        if (user.confirmationNo !== "" && !user.isStaff) {
          CallServicePostRemoveCart();
        }
        // window.location.reload();
      }
      // if(myCart.length == 0){
      //   console.log("status >> ", status);
      //   console.log("myCart length >> 0");
      // }
    }
  }, [cart]);

  async function CallServicePostRemoveCart() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ContractId: user.userId,
        ConfirmationNo: user.confirmationNo,
      }),
    };
    await fetch(apiLink_ecom + REMOVE_CART, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        setLoading(false);
        if (data["Success"]) {
          user.confirmationNo = "";
          sessionStorage.setItem("UserLogin", JSON.stringify(user));
          sessionStorage.removeItem("GuestInfo");
          sessionStorage.removeItem("CabanaVisitingDate");
          setVisitingDate(null);
        } else {
          setOpenFailedModal(true);
          setAleart(data["Message"]);
          user.confirmationNo = "";
          sessionStorage.setItem("UserLogin", JSON.stringify(user));
          sessionStorage.removeItem("GuestInfo");
          sessionStorage.removeItem("CabanaVisitingDate");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        alert("Error: " + REMOVE_CART, error);
        setLoading(false);
        return false;
      });
  }

  useEffect(() => {
    if (currentStep === 5) {
      getPaymentGateway();
      setCanPlaceOrder(false);
    } else {
      //ResetGlobalState
      setCanPlaceOrder(true);
    }
    console.log("publicKey ticket", publicKey);
  }, [currentStep]);

  const getPaymentGateway = async () => {
    // /afcl/Package/GetActivePayment
    const requestOptions = {
      method: "GET",
      headers: {
        "accept-language": language,
        "Content-Type": "application/json",
      },
    };
    await fetch(apiLink_ecom + "/afcl/Package/GetActivePayment", requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data["Success"]) {
          const items = data["Result"];
          // SetPaymentGateway(items[0]['PaymentGatwayCode'])
          SetPaymentGateway("P004"); // Omise
        } else {
          console.log("bad request", data["Result"]);
        }
      });
  };

  return (
    <>
      {!isSelectedAgent ? (
        <>
          {" "}
          <div className="page page-steps"></div>{" "}
          <SelectAgent
            open={openSelectAgent}
            handleClose={setCloseSelectAgentModal}
            setSelectedAgent={setAgent}
          />{" "}
        </>
      ) : (
        <div className="page page-steps">
          {routePath == "" && (
            <>
              {/* {itemGroup.length > 0 && (
             <header className="sticky-top">
               <StepBar itemGroup={itemGroup} packageCode={packageCode} currentStep={currentStep} setCurrentStep={setCurrentStep} />
             </header>
           )} */}
            </>
          )}

          <div className="main ">
            <div className="container">
              <div className="row">
                {currentStep === 1 && (
                  <Step1
                    step={step}
                    packageCode={packageCode}
                    packageTicket={packageTicket}
                    currentStep={currentStep}
                    cartUpdate={cartUpdate}
                    onAddToCart={onAddToCart}
                    onRemoveCart={onRemoveCart}
                    onUpdateVisitDateAll={onUpdateVisitDateAll}
                    cabanaList={cabanaList}
                    language={language}
                    cart={cart}
                    fromBuyNow={fromBuyNow}
                  />
                )}
                {currentStep === 5 && (needCheckout || !isMobile) && (
                  <Checkout
                    language={language}
                    cart={cart}
                    userInfo={userInfo}
                    setCart={setCart}
                    onUpdateVisitDateAll={onUpdateVisitDateAll}
                  />
                )}

                <Rightbar
                  publicKey={publicKey}
                  language={language}
                  packageCode={packageCode}
                  currentStep={currentStep}
                  cart={cart}
                  paymentGateway={paymentGateway}
                  setCartUpdate={setCartUpdate}
                  onAddToCart={onAddToCart}
                  onEditCart={onEditCart}
                  onRemoveCart={onRemoveCart}
                  setCurrentStep={setCurrentStep}
                  onUpdateVisitDateAll={onUpdateVisitDateAll}
                />

                {currentStep === 5 && (
                  <Cart
                    publicKey={publicKey}
                    language={language}
                    packageCode={packageCode}
                    needCheckout={needCheckout}
                    setNeedCheckout={setNeedCheckout}
                    currentStep={currentStep}
                    paymentGateway={paymentGateway}
                    setCartUpdate={setCartUpdate}
                    cart={cart}
                    onAddToCart={onAddToCart}
                    onEditCart={onEditCart}
                    onRemoveCart={onRemoveCart}
                    onUpdateVisitDateAll={onUpdateVisitDateAll}
                  />
                )}
                {currentStep !== 5 && (
                  <MiniCart
                    packageCode={packageCode}
                    currentStep={currentStep}
                    cart={cart}
                  />
                )}
              </div>
            </div>
          </div>

          <BackToTopPackget />

          <ModalFailed
            open={openFailedModal}
            body={t(aleart)}
            setClose={setCloseFailedModal}
            //back={backToOrderPage}
            back={setCloseFailedModal}
          />
        </div>
      )}
    </>
  );
};

export default Ticket;
