import React, { useEffect, useState, useLocation, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { ReactComponent as NewOrder } from "../component/icons/new_order.svg";
import { ReactComponent as Payment } from "../component/icons/payment.svg";
import { ReactComponent as Print } from "../component/icons/print.svg";
import { ReactComponent as Upload } from "../component/icons/slip.svg";
import { ReactComponent as IssueTicket } from "../component/icons/issue_ticket.svg";
import { ReactComponent as LinkPayment } from "../component/icons/link_payment.svg";
import { ReactComponent as CancelBook } from "../component/icons/cancel.svg";
import { ReactComponent as IssueTicketSendMail } from "../component/icons/send_mail.svg";
import { ReactComponent as ViewSlip } from "../component/icons/view_slip.svg";
import { ReactComponent as BookingReport } from "../component/icons/booking_report.svg";

import { Alert, Button, Col, Row } from "react-bootstrap";

import { apiLink_ecom } from "../constants/DataPublic";
import {
  PRINT_PERFORMA_INVOICE,
  PRINT_BOOKING_FORM,
  PRINT_BOOKING_REPORT,
  STAFF_CANCEL_BOOKING,
} from "../services/BASE_API";

import { Booking } from "../component/Booking/Booking.jsx";
import SendEmail from "./../component/Modal/SendEmail";
import LinkPay from "../component/Modal/LinkPay";
import LoadingModal from "./../component/Modal/LoadingModal";
import ModalSuccess from "../component/Modal/ModalSuccess";
import ModalFailed from "../component/Modal/ModalFailed";
import { ParameterReportModal } from "../component/Modal/ParameterReportModal";
import { CancelBookingModal } from "../component/Modal/CancelBookingModal";
import { ContactWarningModal } from "../component/Modal/ContactWarningModal";

const OrderManagement = (props) => {
  const navigate = useNavigate();
  const { language, setActiveTab } = props;

  const [arrowsEnable, setArrowEnable] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("UserLogin"))
  );
  const [selectedBooking, setSelectBooking] = useState();

  const [buttonSingleState, setButtonSingleState] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [successModal, setOpenSuccessModal] = useState(false);
  const [failedModal, setOpenFailedModal] = useState(false);
  const [parameterReportModal, setOpenParameterReportModal] =
    React.useState(false);
  const [cancelBooking, setOpenCancelBookingModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [clearCheck, setClearCheck] = useState(false);

  const [cancelButtonState, setCancelButtonState] = useState(true);
  const [paymentButtonState, setPaymentButtonState] = useState(true);
  const [uploadButtonState, setUploadButtonState] = useState(true);
  const [printButtonState, setPrintButtonState] = useState(false);

  const preparePayment = () => {
    const payload = {
      confirmationNo: selectedBooking[0].confirmationNo,
      userCode: selectedBooking[0].contractId,
      totalPrice: selectedBooking[0].totalPrice,
      guestCode: selectedBooking[0].guestCode,
      guestEmail: selectedBooking[0].email,
      route: "Booking",
      bookingStatus:
        selectedBooking[0].bookingStatus === "Y" ||
          selectedBooking[0].bookingStatus === "C"
          ? true
          : false, // if tentaive set this to false to enable staff button
    };
    sessionStorage.setItem("PaymentRoute", JSON.stringify(payload));
    var route = JSON.parse(sessionStorage.getItem("PaymentRoute"));
    console.log(route);
    navigate("/confirm");
  };
  // ภาษา
  const { t } = useTranslation();

  const scrollRef = React.useRef(null);

  useEffect(() => {
    if (user == null || user == undefined) {
      navigate("/");
    } else {
      setActiveTab("ordered");
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // useEffect(() => {
  //   const div = scrollRef.current;
  //   if (
  //     div.scrollWidth > div.clientWidth ||
  //     div.scrollHeight > div.clientHeight
  //   ) {
  //     console.log("Overflow detected!");
  //   } else {
  //     console.log("Not Overflow");
  //   }
  // }, [scrollRef.current.scrollWidth]);

  useEffect(() => {
    console.log(selectedBooking);

    //handle button enable state
    if (selectedBooking != null) {
      if (selectedBooking.length > 1 || selectedBooking.length == 0) {
        setButtonSingleState(true);
      } else {
        if (selectedBooking[0].isOpenBill) {
          openWarningModal();
          return false;
        }

        setButtonSingleState(false);

        const isCancelled = selectedBooking[0].bookingStatus === "C";
        const isConfirm = selectedBooking[0].bookingStatus === "Y";
        const isPaidOrCancelled =
          isCancelled || selectedBooking[0].paidStatus === "Y";

        if (isConfirm || isCancelled) {
          setCancelButtonState(true);
        } else {
          setCancelButtonState(false);
        }
        if (isCancelled) {
          setUploadButtonState(true);
        } else {
          setUploadButtonState(false);
        }

        setPaymentButtonState(isPaidOrCancelled);
      }
    }
  }, [selectedBooking]);

  useEffect(() => {
    // console.log("EmailStatus", emailStatus);
    if (emailStatus != "") {
      if (emailStatus == "Success") {
        setModalMessage("sendemail_success");
        openSuccessModal();
        setEmailStatus("");
      } else if (emailStatus == "Failed") {
        setModalMessage("sendemail_failed");
        openFailedModal();
        setEmailStatus("");
      } else {
        Alert(emailStatus);
        setEmailStatus("");
      }
    }
  }, [emailStatus]);

  const handleScroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  //Modal
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [linkPayModal, setLinkPayModal] = useState(false);

  const openSendEmailModal = () => {
    setSendEmailModal(true);
  };
  const closeSendEmailModal = () => {
    setSendEmailModal(false);
  };

  const openlinkPayModal = () => {
    setLinkPayModal(true);
  };
  const closelinkPayModal = () => {
    setLinkPayModal(false);
  };

  const openLoadingModal = () => {
    setOpenModal(true);
  };
  const closeLoadingModal = () => {
    setOpenModal(false);
  };

  const openSuccessModal = () => {
    setOpenSuccessModal(true);
  };
  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
    setRefresh(true);
  };

  const openFailedModal = () => {
    setOpenFailedModal(true);
  };
  const closeFailedModal = () => {
    setOpenFailedModal(false);
  };

  const openParameterReportModal = () => {
    console.log("openParameterReportModal");
    setOpenParameterReportModal(true);
  };

  const closeParameterReportModal = () => {
    setOpenParameterReportModal(false);
  };

  const closeCancelBookingModal = () => {
    setOpenCancelBookingModal(false);
  };

  const callBookingReport = () => {
    console.log("callBookingReport");
    openParameterReportModal();
  };

  const openWarningModal = () => {
    setWarningModal(true);
  };

  const closeWarningModal = () => {
    setWarningModal(false);
    setClearCheck(true);
  };

  async function callPrintServices(apiPath, orderId, confirmNo) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + apiPath + "?OrderId=" + orderId, requestOptions)
        .then(function (response) {
          closeLoadingModal();
          // Get the filename from the Content-Disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const match =
            contentDisposition && contentDisposition.match(/filename="(.+)"/);
          const originalFilename = match && match[1] ? match[1] : "";
          const date = new Date().toISOString().slice(0, 10).replace(/-/g, "_"); // Format current date as yyyy_mm_dd
          const filename = confirmNo + originalFilename + "_" + date; // Concatenate the orderId, original filename, and current date
          return response.blob().then((blob) => ({ blob, filename }));
        })
        .then(function ({ blob, filename }) {
          // Use the filename as the title of the new tab
          const url = URL.createObjectURL(blob);
          const newtab = window.open(url, "_blank");
          const link = document.createElement("a");
          // link.href = url;
          // link.download = filename;
          link.click();
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + apiPath, error);
          closeLoadingModal();
          return false;
        });
    }, 1000);
  }

  async function callSendEmailServices(apiPath, orderId, email) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
      body: {
        OrderId: orderId,
        GuestEmail: email,
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + apiPath, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) { })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + apiPath, error);
          return false;
        });
    }, 1000);
  }

  async function callCancelBookingServices() {
    setOpenModal(true);
    let payload = JSON.stringify({
      OrderId: selectedBooking[0].id,
      StaffId: user.staffId,
      Remark: ""
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
      body: payload
    };
    console.log(requestOptions.body);
    setTimeout(() => {
      fetch(
        apiLink_ecom +
        STAFF_CANCEL_BOOKING,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data["Success"]) {
            let items = data["Result"];
            setModalMessage("cancel_success");
            setOpenModal(false);
            openSuccessModal(true);
            closeCancelBookingModal();
            return true;
          } else {
            setModalMessage("cancel_failed");
            setOpenModal(false);
            openFailedModal(true);
            closeCancelBookingModal();
            return true;
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + STAFF_CANCEL_BOOKING, error);
          return false;
        });
    }, 1000);
  }

  return (
    <>
      <div className="page page-py5" style={{ overflowX: "hidden" }}>
        <div className="section-btn">
          <div className="row-control row">
            <div className="arrow-col col-1 text-end">
              <div className="scroll-arrows">
                {arrowsEnable && user && user.isStaff && (
                  <button
                    onClick={() => {
                      handleScroll(-3000);
                      setArrowEnable(false);
                    }}
                  >
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            </div>

            {/* Main Buttons */}

            <div className="button-container col-md-11 text-start">
              <div className="control-container" ref={scrollRef}>
                <Row className="row-buttons-menu" xs="auto">
                  <Col>
                    <Button
                      variant="light"
                      className="btn-menu btn-menu"
                      onClick={() => {
                        setActiveTab("ticket");
                        navigate("/ticket/AWPK001/1");
                        sessionStorage.removeItem("AgentRoute");
                      }}
                    // disabled={true}
                    >
                      <span>
                        <NewOrder />
                      </span>
                      {t("new_order")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      // variant="light"
                      className="btn-menu btn-menu"
                      disabled={
                        buttonSingleState != null
                          ? buttonSingleState || paymentButtonState
                          : false
                      }
                      onClick={async () => {
                        if (selectedBooking.length == 1) {
                          preparePayment();
                        }
                      }}
                    >
                      <span>
                        <Payment />
                      </span>
                      {t("payment")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="light"
                      className="btn-menu btn-menu"
                      disabled={
                        buttonSingleState != null ? buttonSingleState || uploadButtonState : false
                      }
                      onClick={async () => {
                        if (selectedBooking.length == 1) {
                          openLoadingModal();
                          await callPrintServices(
                            PRINT_PERFORMA_INVOICE,
                            selectedBooking[0].id,
                            selectedBooking[0].confirmationNo
                          );
                        }
                      }}
                    >
                      <span>
                        <Print />
                      </span>
                      {t("print_performa_invoice")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="light"
                      className="btn-menu"
                      disabled={
                        buttonSingleState != null
                          ? buttonSingleState || uploadButtonState
                          : false
                      }
                      onClick={() => {
                        if (selectedBooking.length == 1) {
                          navigate("/order/viewslip", {
                            state: { selectedBooking: selectedBooking[0].id },
                          });
                          setActiveTab("");
                        }
                      }}
                    >
                      <span>
                        <Upload />
                      </span>
                      {t("upload_slip")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="light"
                      className="btn-menu"
                      disabled={
                        buttonSingleState != null ? buttonSingleState || uploadButtonState : false
                      }
                      onClick={() => {
                        if (selectedBooking.length == 1) {
                          openSendEmailModal();
                        }
                      }}
                    >
                      <span>
                        <IssueTicket />
                      </span>
                      {t("issue_ticket")}
                    </Button>
                  </Col>
                  {user && user.isStaff && (
                    <>
                      <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          disabled={
                            buttonSingleState != null
                              ? buttonSingleState || uploadButtonState
                              : false
                          }
                          onClick={async () => {
                            if (selectedBooking.length == 1) {
                              openLoadingModal();
                              await callPrintServices(
                                PRINT_BOOKING_FORM,
                                selectedBooking[0].id,
                                selectedBooking[0].confirmationNo
                              );
                            }
                          }}
                        >
                          <span>
                            <Print />
                          </span>
                          {t("print_booking_form")}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          disabled={
                            buttonSingleState != null
                              ? buttonSingleState || paymentButtonState
                              : false
                          }
                          onClick={() => {
                            if (selectedBooking.length == 1) {
                              openlinkPayModal();
                            }
                          }}
                        >
                          <span>
                            <LinkPayment />
                          </span>
                          {t("link_payment")}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          disabled={
                            buttonSingleState != null
                              ? buttonSingleState ||
                              paymentButtonState ||
                              cancelButtonState
                              : false
                          }
                          onClick={() => {
                            let status = selectedBooking[0].bookingStatus;
                            console.log(status);
                            if (
                              status == "T" ||
                              status == "" ||
                              status == null
                            ) {
                              setOpenCancelBookingModal(true);
                            } else {
                              setModalMessage("cancel_warning");
                              // alert(status);
                              openFailedModal();
                            }
                          }}
                        >
                          <span>
                            <CancelBook />
                          </span>
                          {t("cancel_book")}
                        </Button>
                      </Col>
                      {/* <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          // disabled={
                          //   buttonSingleState != null
                          //     ? buttonSingleState
                          //     : false
                          // }
                          disabled={true}
                        >
                          <span>
                            <IssueTicketSendMail />
                          </span>
                          {t("issue_ticket_mail")}
                        </Button>
                      </Col> */}
                      {/* <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          // disabled={
                          //   buttonSingleState != null
                          //     ? buttonSingleState
                          //     : false
                          // }
                          disabled={
                            buttonSingleState != null
                              ? buttonSingleState
                              : false
                          }
                          onClick={() => {
                            if (selectedBooking.length == 1) {
                              navigate("/order/viewslip", {
                                state: {
                                  selectedBooking: selectedBooking[0].id,
                                },
                              });
                              setActiveTab("");
                            }
                          }}
                        >
                          <span>
                            <ViewSlip />
                          </span>
                          {t("view_slip")}
                        </Button>
                      </Col> */}
                      {/* <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          // disabled={
                          //   buttonSingleState != null
                          //     ? buttonSingleState
                          //     : false
                          // }
                          disabled={true}
                        >
                          <span>
                            <Print />
                          </span>
                          {t("print_performa")}
                        </Button>
                      </Col> */}
                      <Col>
                        <Button
                          variant="light"
                          className="btn-menu"
                          // disabled={
                          //   buttonSingleState != null
                          //     ? buttonSingleState
                          //     : false
                          // }
                          onClick={() => {
                            callBookingReport();
                          }}
                        >
                          <span>
                            <BookingReport />
                          </span>
                          {t("booking_report")}
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            </div>

            {/* Main Buttons */}

            {!arrowsEnable && user && user.isStaff && (
              <div className="arrow-col col-1 text-start">
                <div className="scroll-arrows">
                  <button
                    onClick={() => {
                      handleScroll(3000);
                      setArrowEnable(true);
                    }}
                  >
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <Booking
          setSelectBooking={setSelectBooking}
          userInfo={user}
          refresh={refresh}
          setRefresh={setRefresh}
          clearCheck={clearCheck}
          setClearCheck={setClearCheck}
          language={language}
        />
      </div>
      {selectedBooking && selectedBooking.length == 1 && (
        <>
          <SendEmail
            order={selectedBooking}
            open={sendEmailModal}
            setClose={closeSendEmailModal}
            disabled={user != null && user.isStaff ? false : true}
            setEmailStatus={setEmailStatus}
          />
          <LinkPay
            order={selectedBooking}
            open={linkPayModal}
            setClose={closelinkPayModal}
            email={user.email}
            disabled={user != null && user.isStaff ? false : true}
          />
          {openModal && (
            <Backdrop
              sx={{ color: "#fff", zIndex: "1" }}
              style={{ zIndex: 1100 }}
              open
            >
              <div
                class="spinner-border"
                role="status"
                aria-hidden="true"
                style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
              ></div>
            </Backdrop>
          )}

          <ModalSuccess
            open={successModal}
            body={t(modalMessage)}
            setClose={closeSuccessModal}
            back={closeSuccessModal}
          />
          <ModalFailed
            open={failedModal}
            body={t(modalMessage)}
            setClose={closeFailedModal}
            back={closeFailedModal}
          />

          <CancelBookingModal
            orderNo={selectedBooking[0].confirmationNo}
            open={cancelBooking}
            setClose={closeCancelBookingModal}
            onConfirm={callCancelBookingServices}
          />

          <ContactWarningModal
            open={warningModal}
            setClose={closeWarningModal}
          />
        </>
      )}
      {parameterReportModal && (
        <ParameterReportModal
          open={parameterReportModal}
          setClose={closeParameterReportModal}
          language={language}
        />
      )}
    </>
  );
};
export default OrderManagement;
