import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Form, Button } from "react-bootstrap";
import { apiLink_ecom } from "../../constants/DataPublic";
import { GET_AGENT, PRINT_BOOKING_REPORT } from "../../services/BASE_API";
import Backdrop from "@mui/material/Backdrop";
import { useTranslation } from "react-i18next";
// Date Picker
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  CssBaseline,
  createTheme,
  ThemeProvider,
  TextField,
} from "@mui/material";

import "moment/locale/th";
import moment from "moment";
import { red } from "@mui/material/colors";

export const ParameterReportModal = (props) => {
  const { open, setClose, language } = props;
  const [selectedSortBy, setSelectedSortBy] = useState("order");
  const [selectedReportType, setSelectedReportType] = useState("summary");
  const [agentUser, setAgentUser] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const { t, i18n } = useTranslation();

  const handleChangeSortBy = (event) => {
    console.log(event.target.value);
    setSelectedSortBy(event.target.value);
  };

  const handleChangeReportBy = (event) => {
    console.log(event.target.value);
    setSelectedReportType(event.target.value);
  };

  const handleClose = () => {
    setClose(false);
  };

  useEffect(() => {
    callGetAgentService();
  }, []);

  useEffect(() => {
    if (selectedFromDate != null && selectedToDate != null) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [selectedFromDate, selectedToDate]);

  useEffect(()=>{
    moment.locale(i18n.language);
  }, [language])

  async function callGetAgentService() {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "EN",
      },
    };
    setTimeout(() => {
      fetch(apiLink_ecom + GET_AGENT, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          let listAgent = [
            {
              ContractId: 0,
              ContractName: t("all"),
            },
          ];
          data["Result"].map((item) => {
            listAgent.push(item);
          });
          setAgentUser(listAgent);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          alert("Error: " + apiLink_ecom, error);
          setLoading(false);
          return false;
        });
    }, 1000);
  }

  useEffect(() => {
    if (agentUser != null) {
      setSelectedOption(agentUser[0].ContractId);
    }
  }, [agentUser]);

  function handleSelectChange(event) {
    setSelectedOption(event.target.value);
  }

  useEffect(() => {
    console.log(selectedFromDate);
  }, [selectedFromDate]);

  useEffect(() => {
    console.log(selectedToDate);
  }, [selectedToDate]);

  function onClickView() {
    console.log("sortBy => " + selectedSortBy);
    console.log("fromDate => " + selectedFromDate);
    console.log("toDate => " + selectedToDate);
    console.log("agent => " + selectedOption);
    console.log("reportType => " + selectedReportType);
    const payload = {
      SortBy: selectedSortBy === "order" ? 1 : 2,
      FromDate: selectedFromDate,
      ToDate: selectedToDate,
      ContractId: selectedOption,
      ReportType: selectedReportType === "summary" ? 1 : 2,
    };
    console.log("payload");
    console.log(payload);
    CallServicePosPrintBookingReport(payload);
  }

  async function CallServicePosPrintBookingReport(payload) {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    await fetch(apiLink_ecom + PRINT_BOOKING_REPORT, requestOptions)
      .then(function (response) {
        handleClose();
        const contentDisposition = response.headers.get("Content-Disposition");
        const match =
          contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const originalFilename = match && match[1] ? match[1] : "";
        const date = new Date().toISOString().slice(0, 10).replace(/-/g, "_"); // Format current date as yyyy_mm_dd
        const filename = "Agent Booking Report" + originalFilename + "_" + date; // Concatenate the orderId, original filename, and current date
        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(function ({ blob, filename }) {
        setLoading(false);
        const url = URL.createObjectURL(blob);
        const newtab = window.open(url, "_blank");
        const link = document.createElement("a");
        // link.href = url;
        // link.download = filename;
        link.click();
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }

  const today = moment();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0E315C",
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "&.invalid .MuiOutlinedInput-notchedOutline": {
              borderColor: red[500],
            },
          },
        },
      },
    },
  });

  return (
    <Modal
      show={open}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="body-modal"
      aria-labelledby="example-custom-modal-styling-title"
      className="modal-prarmeter-report"
    >
      <Modal.Header
        className="filter-header"
        //style={{ margin: "10px 20px 10px 20px"}}
        closeButton
      >
        <p>{t("parameter_report")}</p>
      </Modal.Header>
      <ModalBody>
        <div style={{ paddingLeft: 40 }}>
          <div style={{ marginBottom: 20 }}>
            <div class="row">
              <div class="col-6">
                <div class="row justify-content-center">
                  <div class="col-6 col-sm-5">
                    <label className="container-radio-btn">
                      <input
                        type="radio"
                        value="order"
                        checked={selectedSortBy === "order"}
                        onChange={handleChangeSortBy}
                      />
                      {t("order_date")}
                    </label>
                  </div>
                  <div class="col-6 col-sm-5">
                    <label className="container-radio-btn">
                      <input
                        type="radio"
                        value="arrival"
                        checked={selectedSortBy === "arrival"}
                        onChange={handleChangeSortBy}
                      />
                      {t("arrival_date")}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-6"></div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div class="row">
              <div class="col-6">
                <div class="row justify-content-center">
                  <div class="col-10">
                    <label
                      for="orderNo"
                      class="form-label"
                      className="label-parameter-report"
                    >
                      {t("from_date")}
                    </label>
                    {/* <input
                      id="fromDate"
                      type="date"
                      className="input-date-parameter-report"
                      value={selectedFromDate}
                      max={selectedToDate}
                      onChange={(e) => {
                        // const pickDate = e.target.value
                        // if (pickDate < new Date().toISOString().split('T')[0]) {
                        //   let datePicker = document.getElementById("fromDate")
                        //   setSelectedFromDate("")
                        //   datePicker.focus()
                        // } else {
                        setSelectedFromDate(e.target.value);
                        // }
                      }}
                      required
                    /> */}
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={i18n.language}
                        dateFormats={"DD/MM/YYYY"}
                      >
                        <DatePicker
                          id="startDate"
                          defaultValue={today}
                          // style={{
                          //   borderColor:
                          //     requiredVisitDate == true ? "red" : "#ced4da",
                          // }}
                          className={
                            false ? "form-control invalid" : "form-control"
                          }
                          type="date"
                          maxDate={moment(selectedToDate)}
                          // minDate={onGetDateSelectVisitDate()} //new Date().toISOString().split("T")[0]
                          value={
                            selectedFromDate == null
                              ? selectedFromDate
                              : moment(selectedFromDate)
                          }
                          onChange={(e) => {
                            setSelectedFromDate(moment(e).format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => (
                            <input
                              {...params.inputProps}
                              className={
                                false ? "form-control invalid" : "form-control"
                              }
                            />
                          )}
                          format={"DD/MM/YYYY"}
                          dayOfWeekFormatter={(day) => `${day}`}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-10">
                    <label
                      for="orderNo"
                      class="form-label"
                      className="label-parameter-report"
                    >
                      {t("to_date")}
                    </label>
                    {/* <input
                      id="toDate"
                      type="date"
                      className="input-date-parameter-report"
                      value={selectedToDate}
                      // minDate={moment(selectedFromDate)}
                      min={selectedFromDate}
                      onChange={(e) => {
                        // const pickDate = e.target.value
                        // if (pickDate < new Date().toISOString().split('T')[0]) {
                        //   let datePicker = document.getElementById("startDate")
                        //   setSelectedToDate("")
                        //   datePicker.focus()
                        // } else {
                        setSelectedToDate(e.target.value);
                        // }
                      }}
                    /> */}
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <LocalizationProvider
                        dateAdapter={AdapterMoment}
                        adapterLocale={i18n.language}
                        dateFormats={"DD/MM/YYYY"}
                      >
                        <DatePicker
                          id="startDate"
                          defaultValue={today}
                          // style={{
                          //   borderColor:
                          //     requiredVisitDate == true ? "red" : "#ced4da",
                          // }}
                          className={
                            false ? "form-control invalid" : "form-control"
                          }
                          type="date"
                          minDate={moment(selectedFromDate)}
                          // minDate={onGetDateSelectVisitDate()} //new Date().toISOString().split("T")[0]
                          value={
                            selectedToDate == null
                              ? selectedToDate
                              : moment(selectedToDate)
                          }
                          onChange={(e) => {
                            setSelectedToDate(moment(e).format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => (
                            <input
                              {...params.inputProps}
                              className={
                                false ? "form-control invalid" : "form-control"
                              }
                            />
                          )}
                          format={"DD/MM/YYYY"}
                          dayOfWeekFormatter={(day) => `${day}`}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <div class="row">
              <div class="col-6">
                <div class="row justify-content-center">
                  <div class="col-10">
                    <label
                      for="orderNo"
                      class="form-label"
                      className="label-parameter-report"
                    >
                      {t("agent")}
                    </label>
                    <Form.Select
                      value={selectedOption}
                      className="selection-parameter-report form-control"
                      onChange={handleSelectChange}
                    >
                      {agentUser != null ? (
                        agentUser.map((row, index) => (
                          <option value={row.ContractId}>
                            {row.ContractName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6 col-sm-5">
                    <label className="container-radio-btn">
                      <input
                        type="radio"
                        value="summary"
                        checked={selectedReportType === "summary"}
                        onChange={handleChangeReportBy}
                      />
                      {t("summary")}
                    </label>
                  </div>
                  <div class="col-6 col-sm-5">
                    <label className="container-radio-btn">
                      <input
                        type="radio"
                        value="detail"
                        checked={selectedReportType === "detail"}
                        onChange={handleChangeReportBy}
                      />
                      {t("detail")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <Modal.Footer className="footer-order-detail-modal">
        <div className="layout-btn-payment">
          <Button
            type="button"
            className="btn-payment-modal"
            onClick={(e) => onClickView()}
            disabled={!buttonState}
          >
            {t("view")}
          </Button>
        </div>
      </Modal.Footer>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: "1" }}
          style={{ zIndex: 1100 }}
          open
        >
          <div
            class="spinner-border"
            role="status"
            aria-hidden="true"
            style={{ color: "#0e315c", width: "3rem", height: "3rem" }}
          ></div>
        </Backdrop>
      )}
    </Modal>
  );
};
