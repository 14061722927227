import React, { useEffect, useState } from "react";

//icons
import iconViewBill from "../img/view_bill.png";
import { ReactComponent as BookingTernative } from "../icons/booking_ternative.svg";
import { ReactComponent as BookingCancel } from "../icons/booking_cancel.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as StatusPaid } from "../icons/status_paid.svg";
import { ReactComponent as StatusUnPaid } from "../icons/status_unpaid.svg";
import { ReactComponent as StatusPartialPaid } from "../icons/status_partial_paid.svg";
import { ReactComponent as CloseModal } from "../icons/close.svg";
import { ReactComponent as CheckBorder } from "../icons/checkbox.svg";

//components
import TabPanel from "@mui/lab/TabPanel";
import TableCell from "@mui/material/TableCell";
import moment from "moment/moment";
import * as CurrencyFormat from "react-currency-format";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@radix-ui/colors";
import { CheckBoxOutlineBlank } from "@material-ui/icons";

export const BookingTab = (props) => {
  const {
    tableBody,
    index,
    setDataModal,
    checkAll,
    setCheckAll,
    setSelectBooking,
    setOrderNo,
    clearCheck,
    setClearCheck,
  } = props;

  const { t } = useTranslation();

  //store select Row object
  const [selectedRows, setSelectedRows] = useState();

  //row index check
  const [checkedState, setCheckedState] = useState(
    tableBody.map(function (item) {
      return {
        id: item.OrderId != null ? item.OrderId : "",
        check: false,
        email: item.GuestEmail,
        guestCode: item.GuestCode,
        confirmationNo: item.OrderNo,
        totalPrice: item.TotalCharge,
        bookingStatus: item.BookStatus,
        paidStatus: item.PaidStatus,
        contractId: item.ContractId,
        isOpenBill: item.IsOpenBill,
        outStanding: item.Outstanding
      };
    })
  );

  // #region Call Back To OrderManagement Page
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  useEffect(async () => {
    //store check object to this
    const _check = checkedState;
    const select = _check.filter((obj) => {
      return obj.check === true;
    });

    // Debounce setSelectedRows with a 100ms delay
    const debounceSetSelectedRows = debounce(async (rows) => {
      await setSelectBooking(rows);
    }, 100);

    debounceSetSelectedRows(select);

    // Clear the debounce function when the component unmounts
    return () => {
      debounceSetSelectedRows.clear();
    };
  }, [checkedState]);

  useEffect(() => {
    if (clearCheck) {
      console.log("clearCheck", clearCheck);
      //Set checkbox is check
      setCheckedState((prevState) =>
        prevState.map((checkbox) => ({ ...checkbox, check: false }))
      );
      setSelectBooking([]);
      setClearCheck(false);
    }
  }, [clearCheck]);
  // #endregion

  useEffect(() => {
    setCheckedState(
      tableBody.map(function (item) {
        return {
          id: item.OrderId != null ? item.OrderId : "",
          check: false,
          email: item.GuestEmail,
          guestCode: item.GuestCode,
          confirmationNo: item.OrderNo,
          totalPrice: item.TotalCharge,
          bookingStatus: item.BookStatus,
          paidStatus: item.PaidStatus,
          contractId: item.ContractId,
          isOpenBill: item.IsOpenBill,
          outStanding: item.Outstanding
        };
      })
    );
  }, [tableBody]);

  const onCheckAll = (value) => {
    // console.log(value);
    // console.log("checkedState on onCheckAll : " + checkedState.length);
    setCheckedState((prevState) =>
      prevState.map((checkbox) => ({ ...checkbox, check: !checkAll }))
    );
    setCheckAll((prevState) => !prevState);
  };

  const handleOnChange = (id) => {
    //Set checkbox is check
    setCheckedState((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, check: !checkbox.check } : checkbox
      )
    );
  };

  const handleClick = async (event, value) => {
    // console.log(value);
    setDataModal(value);
  };

  function dateTimeFormat(date) {
    return moment(new Date(date)).format("DD/MM/YYYY HH:mm").toString();
  }

  function dateFormat(date) {
    return moment(new Date(date)).format("DD/MM/YYYY").toString();
  }

  const checkboxStyle = makeStyles({
    checkbox: {
      "&.Mui-checked": {
        color: "#000000",
        // borderColor: '#000000',
        // borderWidth: '2px',
        // borderStyle: 'solid',
        "& .MuiSvgIcon-root": {
          color: "#000000",
        },
      },
    },
  });

  const classes = checkboxStyle();

  const CustomCheckboxIcon = (props) => (
    <div className="custom-checkbox">{props.children}</div>
  );

  const CustomCheckboxBorder = (props) => (
    <div className="custom-checkbox-border"></div>
  );

  return (
    <TabPanel
      value={index}
      sx={{ padding: "1px", border: 0, boxShadow: 2 }}
      style={tableBody.length > 9 ? { height: "90%" } : { height: "inherit" }}
      className="tabpanel-custom"
    >
      <TableContainer
        sx={{ minHeight: 500 }}
        className="datatable-container"
        style={tableBody.length > 9 ? { height: "80%" } : {}}
      >
        <Table
          sx={{ minWidth: 650, border: 0 }}
          size="small"
          aria-label="a dense table"
          className="data-table"
        >
          <TableHead>
            <TableRow className="tableHead">
              <TableCell width={10} align="center">
                {/* <Checkbox
                  id={0}
                  onChange={(value) => onCheckAll(value.target.checked)}
                  checked={checkAll}
                  className={classes.checkbox}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "28px",
                    },
                  }}
                  checkedIcon={<Check width={"21px"} height={"21px"} />}
                /> */}
                <Checkbox
                  id={0}
                  onChange={(value) => onCheckAll(value.target.checked)}
                  checked={checkAll}
                  className="form-check-table"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "36px",
                      color: "#A7A7A7",
                    },
                  }}
                  icon={
                    // <i
                    //   className="custom-checkbox-border fa fa-square-o"
                    //   aria-hidden="true"
                    // />
                    <i style={{boxShadow:"0px -1px 4px rgb(0 0 0 / 10%)"}}>
                      <CheckBorder/>
                    </i>
                  }
                  checkedIcon={
                    <CustomCheckboxIcon>
                      <Check width={"26px"} height={"26px"} />
                    </CustomCheckboxIcon>
                  }
                  classes={{ root: classes.root }}
                />
              </TableCell>
              <TableCell width={10} align="center">
                {t("paid_status")}
              </TableCell>
              <TableCell width={10} align="center">
                {t("booking_status")}
              </TableCell>
              <TableCell align="center">{t("email_status")}</TableCell>
              <TableCell align="center">{t("order_no")}</TableCell>
              <TableCell align="center">{t("agent_name")}</TableCell>
              <TableCell align="center">{t("guest_name")}</TableCell>
              <TableCell align="center">{t("tel_no")}</TableCell>
              <TableCell align="center">{t("arrival")}</TableCell>
              <TableCell align="center">{t("expire")}</TableCell>
              <TableCell align="center">{t("total_charge")}</TableCell>
              <TableCell align="center">{t("total_paid")}</TableCell>
              <TableCell align="center">{t("outstanding")}</TableCell>
              <TableCell align="center" width={50}></TableCell>
            </TableRow>
          </TableHead>
          {tableBody && (
            <TableBody>
              {tableBody.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={
                    checkedState[index].check == true
                      ? {
                          borderBottom: 0,
                          height: "5px",
                          backgroundColor: "#79e7ff",
                        }
                      : {
                          borderBottom: 0,
                          height: "5px",
                        }
                  }
                >
                  <TableCell width={10} align="center">
                    {/* <Checkbox
                      id={row.OrderId}
                      onChange={() => handleOnChange(row.OrderId)}
                      checked={
                        checkedState[index].id == row.OrderId
                          ? checkedState[index].check
                          : false
                      }
                      className={classes.checkbox}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: '28px'
                        }
                      }}
                      checkedIcon={<Check width={"21px"} height={"21px"} />}
                    /> */}
                    {/* <div class="form-check">
                      <input
                        type="checkbox"
                        value=""
                        id={row.OrderId}
                        className="form-check-table"
                        onChange={() => handleOnChange(row.OrderId)}
                        checked={
                          checkedState[index].id == row.OrderId
                            ? checkedState[index].check
                            : false
                        }
                      />
                    </div> */}
                    <Checkbox
                      id={row.OrderId}
                      onChange={() => handleOnChange(row.OrderId)}
                      checked={
                        checkedState[index].id == row.OrderId
                          ? checkedState[index].check
                          : false
                      }
                      className="form-check-table"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "36px",
                          borderColor: "#A7A7A7",
                          color: "#A7A7A7",
                        },
                      }}
                      icon={
                        // <i
                        //   className="custom-checkbox-border fa fa-square-o"
                        //   aria-hidden="true"
                        // />
                        <i style={{boxShadow:"0px -1px 4px rgb(0 0 0 / 10%)"}}>
                          <CheckBorder />
                        </i>
                      }
                      checkedIcon={
                        <CustomCheckboxIcon>
                          <Check width={"26px"} height={"26px"} />
                        </CustomCheckboxIcon>
                      }
                      classes={{ root: classes.root }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row.PaidStatus == "Y" ? (
                      <StatusPaid className="paidStatus" />
                    ) : row.PaidStatus == "P" ? (
                      <StatusPartialPaid className="paidStatus" />
                    ) : (
                      <StatusUnPaid className="paidStatus" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.BookStatus == "C" ? (
                      <BookingCancel className="bookingStatus" />
                    ) : row.BookStatus == "Y" ? (
                      <StatusPaid className="bookingStatus" />
                    ) : (
                      <BookingTernative className="bookingStatus" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.SendEmail == true ? t("done") : t("wait")}
                  </TableCell>
                  <TableCell align="center">
                    <a
                      //href="#"

                      style={{
                        textDecoration: "underline",
                        color: "#0e315c",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOrderNo(row.OrderId);
                        console.log("Click: " + row.OrderId);
                      }}
                    >
                      {row.OrderNo}
                    </a>
                  </TableCell>
                  <TableCell align="center">{row.ContractName}</TableCell>
                  <TableCell align="center">{row.GuestName}</TableCell>
                  <TableCell align="center">{row.TelNo}</TableCell>
                  <TableCell align="center">
                    {dateFormat(row.ArrivalDate) + " " + row.ArrivalTime}
                  </TableCell>
                  <TableCell align="center">
                    {dateTimeFormat(row.Expire)}
                  </TableCell>
                  <TableCell align="center">
                    {
                      <CurrencyFormat
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={row.TotalCharge == null ? 0 : row.TotalCharge}
                      />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      <CurrencyFormat
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={row.TotalPaid == null ? 0 : row.TotalPaid}
                      />
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      <CurrencyFormat
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={row.Outstanding == null ? 0 : row.Outstanding}
                      />
                    }
                  </TableCell>
                  <TableCell align="center" width={20}>
                    <img
                      style={{ cursor: "pointer" }}
                      src={iconViewBill}
                      alt=""
                      className="img-view-bill"
                      onClick={(event) => handleClick(event, row)}
                      hidden={row.PaidStatus == "N"}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </TabPanel>
  );
};
