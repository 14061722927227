// ใช้ Config รูปแบบ Card ที่แสดงที่หน้าจอ อ้างอิงกับ PackageTypeCode
const verticalCardStart = ["T001", "T003"];
const verticalCardDiscount = ["T002"];
const verticalCardDetail = ["T005"];
const horizontalCardDiscount = ["T004"];

// Package Type Other ที่ไว้แสดง DayPass, Extreme Pass ที่หน้า Package
const packageExtraType = "T005";
const packageTypeOther = "T009";
const dayPassPackageCode = "P026";
const extremePassPackageCode = "P027";

const packageTypeTicket = "T006";

// PackageGroupTypeCode สำหรับกำหนด Card แบบ Cabana หรือ Transportation
const cabanaPackageGroupTypeCode = "PG02";
const transportPackageGroupTypeCode = "PG03";

// ClientId -> สำหรับ Sign in ด้วย Google Account
const clientId =
  "868544482928-0rs2320pm8m2c1313tehi6lsmn62u07k.apps.googleusercontent.com";
// AppId -> สำหรับ Sign in ด้วย Facebook Account
const appId = "626252768404900";
const fbRedirectURL =
  "https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df18e7f90d8f3d5c%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Flocalhost%253A3000%252Ffb124c175f3e2%26relation%3Dopener%26frame%3Df39a2cc9164eb24";

// const baseLink = '/afcl'
const baseLink = "";

// const apiLink = 'https://cabinet-api-dev.smartfinder.asia'
// const apiLink = 'https://afcl-api-core.azurewebsites.net'
// const apiLink = "https://afcl2-api-dev.azurewebsites.net";
// const apiLink_ecom = "https://afcl2-api-ecom.azurewebsites.net";

// For UAT Test
// const apiLink = 'https://afcl2-api-dev-uat.azurewebsites.net'
// const apiLink_ecom = 'https://afcl2-api-ecom-uat.azurewebsites.net'

// Production
const apiLink = 'https://afcl-api-core.azurewebsites.net'
const apiLink_ecom = 'https://agentbooking-api.azurewebsites.net'

// Cart for Tablet & Mobile
const deviceWith = 768;

// Payment CountDown
const checkStatusTimerMS = 15000; // 15 seconds x 1000

// Cart
const incTax = 7;

const pageLimit = 50;

export {
  verticalCardStart,
  verticalCardDiscount,
  verticalCardDetail,
  horizontalCardDiscount,
  packageExtraType,
  packageTypeOther,
  packageTypeTicket,
  dayPassPackageCode,
  extremePassPackageCode,
  cabanaPackageGroupTypeCode,
  transportPackageGroupTypeCode,
  clientId,
  appId,
  fbRedirectURL,
  baseLink,
  apiLink,
  apiLink_ecom,
  deviceWith,
  incTax,
  checkStatusTimerMS,
  pageLimit
};
